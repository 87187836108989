import * as TransactionActions from './actions';
import {all, takeEvery} from "redux-saga/effects";
import {loadBankTransactions, updateBankTransactions, createAndUpdateTransactionCategory} from "./saga/saga";

export default function* saga() {
    yield all([
        yield takeEvery(TransactionActions.REDUCE_TRANSACTIONS_REQUEST, loadBankTransactions),
        yield takeEvery(TransactionActions.UPDATE_CASHFLOW_TRANSACTIONS, updateBankTransactions),
        yield takeEvery(TransactionActions.CREATE_AND_UPDATE_TRANSACTION_CATEGORY, createAndUpdateTransactionCategory)
    ]);
}
