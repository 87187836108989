import { call, put } from "redux-saga/effects"
import axios from "../../../axios";
import * as actionTypes from "../actionTypes";
import * as RestV1 from "./RestV1Types";
import tld from "../../../tld";

function* loadRemoteRestV1Account(accountId: string) {
    const restV1AccountingAccount: RestV1.RemoteAccount =
        yield axios.get(
            tld(process.env.REACT_APP_URL_API_CONNECT) + "/v1/accounting/accounts/" + accountId,
            {
                withCredentials: true,
                headers: {
                    "Accept": "application/json",
                    "Content-Type": "application/json"
                },
            }
        ).then(response => response.data);
    const accountingAccount: AccountingAccount = {
        ...restV1AccountingAccount
    };
    return accountingAccount;
}

export function* loadAccount(accountId: string) {
    const account: AccountingAccount = yield call(loadRemoteRestV1Account, accountId);
    yield put({type: actionTypes.REDUCE_ACCOUNTING_ACCOUNT_LOADED, payload: account});
    return account;
}
