import { combineReducers } from "redux";

import { flagsReducer as flags } from "./flags";
import app, * as appSelectors from "./services/App/reducer";
import auth, * as authSelectors from "./services/Auth/reducer";
import bank from "./services/Bank/reducer";
import invitation from "./services/Auth/Workflow/Invitation/reducer";
import cashFlow from "./services/CashFlow/reducer";
import accounting from "./services/Accounting/reducer";
import category from "./services/Category/reducer";
import participant from "./services/Participant/reducer";
import balance from "./services/Balance/reducer";
import record from "./services/Record/reducer";
import statement, * as statementSelectors from "./services/Statement/reducer";
import {
    getStatementRow as getStatementRowSelector,
    getStatements as getStatementsSelector
} from "./services/Statement/selectors";
import planning, * as planningSelectors from "./services/Planning/reducer";
import posting from "./services/Posting/reducer";
import scenarios from "./services/Scenarios/reducer";
import filter from "./services/Filter/reducer";
import settings from "./services/Settings/reducer";
import forecast from "./services/Forecast/reducer";
import drawer from "./services/Drawer/reducer";
import * as filterSelectors from "./services/Filter/selectors";
import integrations from "./services/Integrations/reducer";
import timeSeries from "./services/TimeSeries/reducer";
import timeTravel from "./services/TimeTravel/reducer";
import { AppMode } from "./model/App/enums";
import transactions from './services/Transactions/reducer'
import chart from './services/Chart/reducer'

// -----------------------------------------------------------------------------
// REDUCER
// -----------------------------------------------------------------------------

export default combineReducers({
    flags,
    app,
    auth,
    bank,
    invitation,
    cashFlow,
    accounting,
    category,
    participant,
    balance,
    record,
    statement,
    planning,
    posting,
    scenarios,
    filter,
    integrations,
    timeTravel,
    settings,
    forecast,
    drawer,
    timeSeries,
    transactions,
    chart
});


// -----------------------------------------------------------------------------
// PUBLIC SELECTORS
// -----------------------------------------------------------------------------

// -----------------------------------------------------------------------------
// App

export function getAppMode(state: RootState): AppMode {
    return appSelectors.getAppMode(state.app);
}

// -----------------------------------------------------------------------------
// Auth

export function isLoginOfRole(state: RootState, role: AuthRole): boolean {
    return authSelectors.isLoginOfRole(state.auth, role);
}

export function isLoginWithEmail(state: RootState, email: string|string[]): boolean {
    return authSelectors.isLoginWithEmail(state.auth, email);
}

export function isCompany(state: RootState, companyNameLookup: string): boolean {
    return authSelectors.isCompany(state.auth, companyNameLookup);
}

export function findLoginNamespace(state): string|null {
    return authSelectors.findLoginNamespace(state.auth);
}

export function isMultiUnitCompany(state: RootState): boolean {
    return authSelectors.isMultiUnit(state.auth);
}

// -----------------------------------------------------------------------------
// Statement

export function findStatementById(state: RootState, statementId: string): Statement|null {
    return statementSelectors.findStatementById(state.statement, statementId);
}

export function getStatementById(state: RootState, statementId: string): Statement {
    return statementSelectors.getStatementById(state.statement, statementId);
}

export function getStatements(state: RootState): Statement[] {
    return getStatementsSelector(state.statement);
}

export function getStatementRow(state: RootState, statementId: string, rowId: string): StatementRow {
    return getStatementRowSelector(state.statement, statementId, rowId);
}

// -----------------------------------------------------------------------------
// Planning

export function findPlanningById(state: RootState, planningId: string): Planning|null {
    return planningSelectors.findPlanningById(state.planning, planningId);
}

export function findPlanningRange(state: RootState): TimeRange|undefined {
    return planningSelectors.findPlanningRange(state.planning);
}

export function getPlannings(state: RootState): Planning[] {
    return planningSelectors.getPlannings(state.planning);
}

export function findFirstPlanningPostingDate(state: RootState): Date|null {
    return planningSelectors.findFirstPostingDate(state.planning);
}

// -----------------------------------------------------------------------------
// Filter

export function getSelectedCompanyUnitId(state: RootState): string {
    return filterSelectors.getSelectedCompanyUnitId(state.filter);
}

export function findSelectedCompanyUnit(state: RootState): CompanyUnit|undefined {
    return isMultiUnitCompany(state) ? filterSelectors.getSelectedCompanyUnit(state.filter) : undefined;
}
