import buildRows from "./buildRows";
import calculateTotalDataPoints from "./calculateTotalDataPoints";
import debug from "../../../services/Debug/functions";
import isMimeType from "../../../model/Mime/functions/isMimeType";

/**
 * @deprecated for slim client
 */
const buildRowsWithTotal = (
    statement: Statement,
    totalsByAccountId: TimeSeriesValueNestedMap,
    accounts: AccountRegistry,
    mergeValuesByRowId: TimeSeriesValueNestedMap = {}
): StatementDeepTimeSeriesRow[] => {
    debug("buildTableRowsWithTotal_raw()", statement, totalsByAccountId);
    let tableRows: StatementDeepTimeSeriesRow[] = buildRows(
        statement,
        statement.rows,
        totalsByAccountId,
        accounts,
        mergeValuesByRowId
    );
    const totalRow = tableRows[0];
    if (isMimeType(totalRow.constraintType, "group", "total")) {
        totalRow.dataPoints = calculateTotalDataPoints(tableRows);
    }
    debug("buildTableRowsWithTotal_raw() - tableRows", tableRows);
    return tableRows;
};

export default buildRowsWithTotal;
