import { Map } from "immutable";
import buildKeyPathMap from "./buildKeyPathMap";
import insertRow from "./insertRow";
import { RowLinkRelation } from "../StatementUri/enums";

export default function insertStatementRow(
    statement: Statement,
    row: StatementRow,
    destination?: StatementRowLink
): Statement {
    const rowId: string = row.id;
    const keyMap: Map<string, KeyPath> = buildKeyPathMap(statement.rows);
    // default place on statement top
    let locationParentKeyPath: KeyPath|null = null;
    let locationIndex: number = 0;
    if (destination) {
        switch (destination.relation) {
            case RowLinkRelation.PREDECESSOR: {
                    const predecessorRowId: string = destination.id;
                    const predecessorRowKeyPath: KeyPath | undefined = keyMap.get(predecessorRowId);
                    if (!predecessorRowKeyPath) {
                        throw Error("Predecessor row " + predecessorRowId + " not found to insert row " + rowId);
                    }
                    const predecessorRowLastKey = predecessorRowKeyPath[predecessorRowKeyPath.length - 1];
                    if (typeof predecessorRowLastKey === "string" && !Number.isNaN(Number(predecessorRowLastKey))) {
                        throw new Error("Unexpected key path structure, last element is not a position index.");
                    }
                    const predecessorIndex = Number(predecessorRowLastKey);
                    // noinspection UnnecessaryLocalVariableJS
                    const parentRowKeyPath = predecessorRowKeyPath.slice(0, -2);
                    //
                    locationParentKeyPath = parentRowKeyPath;
                    locationIndex = predecessorIndex + 1;
                }
                break;
            case RowLinkRelation.PARENT: {
                    const parentRowId: string = destination.id;
                    const parentRowKeyPath: KeyPath | undefined = keyMap.get(parentRowId);
                    if (!parentRowKeyPath) {
                        throw Error("Parent row " + parentRowId + " not found to insert row " + rowId);
                    }
                    locationParentKeyPath = parentRowKeyPath;
                }
                break;
            default:
                throw Error("Destination role not known: " + destination.relation);
        }
    }
    //
    const movedRows = insertRow(
        statement.rows,
        row,
        locationParentKeyPath,
        locationIndex
    );
    return {
        ...statement,
        rows: movedRows,
    };
}
