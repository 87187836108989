import buildRowsWithTotal from "./buildRowsWithTotal";
import getPostingTotalsByAccountId from "./getPostingTotalsByAccountId";

/**
 * @deprecated for slim client
 */
export default function buildStatementDeepRowSet(
    statement: Statement,
    accounts: AccountRegistry,
    postings: Posting[] = [],
    timeSeriesResolution: TimeSeriesResolution,
    mergeValuesByRowId: TimeSeriesValueNestedMap = {}

): StatementDeepTimeSeriesRowSet {
    const postingTotalsByAccountId = getPostingTotalsByAccountId(postings, timeSeriesResolution);
    const timeSeriesSet: TimeSeriesSet = {
        resolution: timeSeriesResolution,
        timeSeries: postingTotalsByAccountId,
    };
    const statementDeepRows: StatementDeepTimeSeriesRow[] = buildRowsWithTotal(
        statement,
        timeSeriesSet.timeSeries,
        accounts,
        mergeValuesByRowId
    );
    return {
        timeSeriesResolution,
        rows: statementDeepRows,
    };
}
