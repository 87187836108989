import axios from '../../../axios';
import {call, put, select} from "redux-saga/effects";

import * as RestV1 from "./RestV1Types";
import * as actionTypes from "../actionTypes";
import {ScenarioDraft} from "./RestV1Types";
import {CreateScenarioAction} from "../actionTypes";
import {isMultiUnitCompany, getSelectedCompanyUnitId} from "../../../reducer";
import tld from "../../../tld";

function* enforceCompanyUnitScope(data: ScenarioDraft) {
    if (undefined === data.companyUnitId) {
        if (yield select(isMultiUnitCompany)) {
            return {
                ...data,
                companyUnitId: yield select(getSelectedCompanyUnitId)
            };
        }
    }
    return data;
}

function* axiosCreateRemoteRestV1Scenarios(data: ScenarioDraft) {
    return yield axios.post(tld(process.env.REACT_APP_URL_API_PLAN) + '/v1/scenarios', data);
}

export default function* createScenario(scenarioData: CreateScenarioAction) {
    const dataInCompanyUnitScope: ScenarioDraft = yield call(enforceCompanyUnitScope, scenarioData.payload);
    const {data}: RestV1.ScenarioDataResponse = yield axiosCreateRemoteRestV1Scenarios(dataInCompanyUnitScope);
    yield put({
        type: actionTypes.REDUCE_SCENARIO_CREATED,
        payload: data
    });
}
