import { List, Map } from "immutable";
import memoizeOne from "memoize-one";

type ListToMapTransformer = (statementRows: List<StatementRow>) => Map<string, StatementRow>;

function statementRowDeepListToMap(statementRows: List<StatementRow>, mutableResultMap: Map<string, StatementRow>): void {
    statementRows.forEach(statementRow => {
        mutableResultMap.set(statementRow.id, statementRow);
        if (undefined !== statementRow.rows) {
            statementRowDeepListToMap(statementRow.rows, mutableResultMap);
        }
    });
}

function transformStatementRowListToMap(statementRows: List<StatementRow>): Map<string, StatementRow> {
    const mutableMap = Map<string, StatementRow>().asMutable();
    statementRowDeepListToMap(statementRows, mutableMap);
    return mutableMap.asImmutable();
}

const statementTransformers: {
    [statementId: string]: ListToMapTransformer
} = {};

function getListTransformer(statementId: string): ListToMapTransformer {
    if (!statementTransformers[statementId]) {
        statementTransformers[statementId] = memoizeOne(transformStatementRowListToMap);
    }
    return statementTransformers[statementId];
}

export default function getStatementRowsMap(statement: Statement): Map<string, StatementRow> {
    return getListTransformer(statement.id)(statement.rows);
}
