import axios from "../../../axios";
import {put} from "redux-saga/effects";
import {reduceCreditLine} from "../actions";
import tld from "../../../tld";

interface RemoteCreditLine {
    date: string,
    amount: number,
}

interface RemoteCreditLineResponse {
    data: RemoteCreditLine[];
}

function* loadRemoteCreditLine() {
    return yield axios.get(tld(process.env.REACT_APP_URL_API_SETTINGS) + "/v1/creditLines", {
        withCredentials:  true,
        headers: {
            "Accept": "application/json",
            "Content-Type": "application/json"
        }
    });
}

export default function* loadCreditLine() {
    const {data}: RemoteCreditLineResponse = yield loadRemoteCreditLine();
    yield put(reduceCreditLine(data[0] || null));
}
