import {createSelector} from "reselect";

export const historicalEndDateSelector: (state: RootState) => Date|undefined = (state: RootState) =>
    state.accounting.stats?.stages?.historicalBank?.endDate
    || state.accounting.stats?.stages?.historicalAccounting?.endDate;

export const bankStartDateSelector: (state: RootState) => Date|undefined = (state: RootState) => {
    return state.accounting.stats?.stages?.historicalBank?.startDate
        || state.accounting.stats?.channels?.bank?.firstDate;
}

export const bankEndDateSelector: (state: RootState) => Date|undefined = (state: RootState) => {
    return state.accounting.stats?.stages?.historicalBank?.endDate
        || state.accounting.stats?.channels?.bank?.lastDate;
}

export const hasBankDataSelector: (state: RootState) => boolean|undefined = createSelector<
    // State
    RootState,
    // Parameters
    RootState,
    Date|undefined,
    // Result
    boolean|undefined
>(
    // Selectors
    state => state,
    bankEndDateSelector,
    // Combiner
    (state, bankEndDate) => {
        return state.accounting.initialized ? undefined !== bankEndDate : undefined;
    }
);

export const accountingStartDateSelector: (state: RootState) => Date|undefined = (state: RootState) => {
    return state.accounting.stats?.stages?.historicalAccounting?.startDate
        || state.accounting.stats?.channels?.accounting?.firstDate;
}

export const accountingEndDateSelector: (state: RootState) => Date|undefined = (state: RootState) => {
    return state.accounting.stats?.stages?.historicalAccounting?.endDate
        || state.accounting.stats?.channels?.accounting?.lastDate;
}

export const openingBalancesSelector: (state: RootState) => OpeningBalance[]|undefined
    = (state: RootState) => state.accounting.openingBalances;

export const accountsSelector: (state: RootState) => AccountRegistry|undefined
    = (state: RootState) => state.accounting.accounts;
