import * as actionTypes from "./actionTypes";
import { PostingChannel } from "../../model/Posting/enums";

const shallowequal = require("shallowequal");

function getPostingIndexById(postingId: string, postings: Posting[]): number
{
    for (let i = 0; i < postings.length; i++) {
        if (postingId === postings[i].id) {
            return i;
        }
    }
    throw new Error("Posting not found: " + postingId);
}

function getLoadedStateWithPostings(
    state: PostingState,
    postings: Posting[],
    defaultChannel: PostingChannel
): PostingState {
    let newPostingsById = { ...state.postingsById };
    let newPostings = [ ...state.postings ];
    let postingsById: PostingDirectory = {};
    let postingsByChannel: PostingChannelDirectory = {};
    postings.forEach(posting => {
        const postingId = posting.id;
        const postingChannel = posting.channel;
        if (undefined === newPostingsById[postingId]) {
            newPostingsById[postingId] = posting;
            postingsById[postingId] = posting;
            //
            newPostings.push(posting);
            //
            if (undefined === postingsByChannel[postingChannel]) {
                postingsByChannel[postingChannel] = [];
            }
            postingsByChannel[postingChannel].push(posting);
        } else if (!shallowequal(posting, newPostingsById[postingId])) {
            newPostingsById[postingId] = posting;
            postingsById[postingId] = posting;
            //
            const index = getPostingIndexById(postingId, newPostings);
            newPostings[index] = posting;
            //
            if (undefined === postingsByChannel[postingChannel]) {
                postingsByChannel[postingChannel] = [];
            }
            postingsByChannel[postingChannel].push(posting);
        }
    });
    let newPostingsByChannel = { ...state.postingsByChannel };
    if (undefined === state.postingsByChannel[defaultChannel]) {
        newPostingsByChannel[defaultChannel] = undefined === postingsByChannel[defaultChannel]
            ? [] : postingsByChannel[defaultChannel];
    }
    for (let channel in postingsByChannel) {
        if (postingsByChannel.hasOwnProperty(channel)) {
            if (undefined === state.postingsByChannel[channel]) {
                newPostingsByChannel[channel] = postingsByChannel[channel];
            } else {
                newPostingsByChannel[channel] = [
                    ...(state.postingsByChannel[channel].filter(posting => undefined === postingsById[posting.id])),
                    ...postingsByChannel[channel],
                ]
            }
        }
    }
    return {
        ...state,
        postingsById: newPostingsById,
        postings: newPostings,
        postingsByChannel: newPostingsByChannel,
        initialized: undefined !== newPostingsByChannel[PostingChannel.ACCOUNTING]
            && undefined !== newPostingsByChannel[PostingChannel.PLANNING]
            && undefined !== newPostingsByChannel[PostingChannel.ADJUSTMENT],
        loading: false,
    };
}

export default function reducer(state: PostingState = {
    initialized: false,
    loading: false,
    postingsById: {},
    postings: [],
    postingsByChannel: {},
}, action: actionTypes.PostingReducerAction) {
    switch (action.type) {
        case actionTypes.REDUCE_POSTINGS_LOADING: {
            return state.loading ? state : { ...state, loading: true };
        }
        case actionTypes.REDUCE_POSTINGS_LOADED: {
            const postings: Posting[] = action.payload.postings;
            const defaultChannel: PostingChannel = action.payload.defaultChannel;
            return getLoadedStateWithPostings(state, postings, defaultChannel);
        }
        case actionTypes.REDUCE_POSTING_DELETED: {
            const channel: PostingChannel = action.payload.channel;
            const sourceId: string = action.payload.sourceId;
            let newPostingsById: PostingDirectory = {};
            const newPostings = state.postings.filter(posting => {
                if (channel !== posting.channel ||
                    undefined === posting.source ||
                    sourceId !== posting.source.id
                ) {
                    newPostingsById[posting.id] = posting;
                    return true;
                }
                return false;
            });
            let newPostingsByChannel = {
               ...state.postingsByChannel,
            };
            newPostingsByChannel[channel] = newPostingsByChannel[channel].filter(posting =>
                undefined === posting.source || sourceId !== posting.source.id
            );
            return {
                ...state,
                postingsById: newPostingsById,
                postings: newPostings,
                postingsByChannel: newPostingsByChannel,
            };
        }
        default: {
            return state;
        }
    }
}
