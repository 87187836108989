export const INITIALIZE_STATEMENTS = 'INITIALIZE_STATEMENTS';
export const REDUCE_STATEMENTS_INITIALIZED = 'REDUCE_STATEMENTS_INITIALIZED';
export const REDUCE_COMPANY_UNIT_CONTROL = 'REDUCE_COMPANY_UNIT_CONTROL';
export const STATEMENT_ROW_RENAME = 'STATEMENT_ROW_RENAME';
export const STATEMENT_ROW_DELETE = 'STATEMENT_ROW_DELETE';
export const REDUCE_STATEMENT_LOADING = 'REDUCE_STATEMENT_LOADING'

export const X_STATEMENT_CHANGED = 'X_STATEMENTS_CHANGED';

export interface InitializeStatementsAction {
    type: typeof INITIALIZE_STATEMENTS
}

export interface ReduceStatementsInitializedAction {
    type: typeof REDUCE_STATEMENTS_INITIALIZED
}

export const REDUCE_STATEMENT_LOADED = 'REDUCE_STATEMENT_LOADED';
export interface ReduceStatementLoadedAction {
    type: typeof REDUCE_STATEMENT_LOADED
    payload: Statement
}
export interface ReduceStatementLoadingAction {
    type: typeof REDUCE_STATEMENT_LOADING
}

export interface ReduceCompanyUnitControlAction {
    type: typeof REDUCE_COMPANY_UNIT_CONTROL
    payload: CompanyUnitControl
}

export const STATEMENT_GROUP_ROW_CREATE = 'STATEMENT_GROUP_ROW_CREATE';

export interface StatementGroupRowCreateAction {
    type: typeof STATEMENT_GROUP_ROW_CREATE
    payload: StatementGroupRowCreation
}

export const STATEMENT_ROW_RELOCATE = 'STATEMENT_ROW_RELOCATE';
export interface StatementRowRelocateAction {
    type: typeof STATEMENT_ROW_RELOCATE
    payload: StatementRowRelocation
}

export const REDUCE_STATEMENT_ROW_CREATE = 'REDUCE_STATEMENT_ROW_CREATE';
export interface ReduceStatementRowCreateAction {
    type: typeof REDUCE_STATEMENT_ROW_CREATE
    payload: {
        statementId: string,
        row: StatementRow,
        destination?: StatementRowLink
    }
}

export const REDUCE_STATEMENT_ROW_UPDATE = 'REDUCE_STATEMENT_ROW_UPDATE';
export interface ReduceStatementRowUpdateAction {
    type: typeof REDUCE_STATEMENT_ROW_UPDATE
    payload: {
        statementId: string,
        row: StatementRow,
    }
}

export const REDUCE_STATEMENT_ROW_RELOCATE = 'REDUCE_STATEMENT_ROW_RELOCATE';
export interface ReduceStatementRowRelocateAction {
    type: typeof REDUCE_STATEMENT_ROW_RELOCATE
    payload: StatementRowRelocation
}

export const REDUCE_STATEMENT_ROW_DELETE = 'REDUCE_STATEMENT_ROW_DELETE';
export interface ReduceStatementRowDeleteAction {
    type: typeof REDUCE_STATEMENT_ROW_DELETE
    payload: {
        statementId: string,
        rowId: string,
    }
}

export const REDUCE_STATEMENT_ROW_DRAG = 'REDUCE_STATEMENT_ROW_DRAG';
export interface ReduceStatementRowDrag {
    type: typeof REDUCE_STATEMENT_ROW_DRAG,
    payload: {
        statementId: string;
        rowId: string;
    }
}
export const REDUCE_STATEMENT_ROW_DROP = 'REDUCE_STATEMENT_ROW_DROP';
export interface ReduceStatementRowDrop {
    type: typeof REDUCE_STATEMENT_ROW_DROP,
    payload: {
        statementId: string;
    }
}

export const REDUCE_STATEMENT_ROW_COLLAPSE = 'REDUCE_STATEMENT_ROW_COLLAPSE';
export interface ReduceStatementRowCollapseAction {
    type: typeof REDUCE_STATEMENT_ROW_COLLAPSE
    payload: {
        statementId: string,
        rowId: string,
    }
}
export const REDUCE_STATEMENT_ROW_EXPAND = 'REDUCE_STATEMENT_ROW_EXPAND';
export interface ReduceStatementRowExpandAction {
    type: typeof REDUCE_STATEMENT_ROW_EXPAND
    payload: {
        statementId: string,
        rowId: string,
    }
}

export const REDUCE_STATEMENT_COLLAPSE_LEVEL = 'REDUCE_STATEMENT_COLLAPSE_LEVEL';
export interface ReduceStatementCollapseLevelAction {
    type: typeof REDUCE_STATEMENT_COLLAPSE_LEVEL
    payload: {
        statementId: string,
        level: number,
    }
}

export const REDUCE_STATEMENT_EXPAND_TO_LEVEL = 'REDUCE_STATEMENT_EXPAND_TO_LEVEL';
export interface ReduceStatementExpandToLevelAction {
    type: typeof REDUCE_STATEMENT_EXPAND_TO_LEVEL
    payload: {
        statementId: string,
        level: number,
    }
}

export const REDUCE_STATEMENT_ERROR = 'REDUCE_STATEMENT_ERROR';
export interface ReduceStatementError {
    type: typeof REDUCE_STATEMENT_ERROR
    payload: string
}
export interface StatementGroupRowCreation {
    statementId: string,
    label: string,
    parentGroup?: GroupDraft
}


export interface StatementRowRelocation {
    statementId: string,
    rowId: string,
    destination?: StatementRowLink
}

export type StatementReducerAction =
    ReduceStatementsInitializedAction |
    ReduceStatementLoadedAction |
    ReduceCompanyUnitControlAction |
    ReduceStatementRowCreateAction |
    ReduceStatementRowUpdateAction |
    ReduceStatementRowRelocateAction |
    ReduceStatementRowDeleteAction |
    ReduceStatementRowCollapseAction |
    ReduceStatementRowExpandAction |
    ReduceStatementCollapseLevelAction |
    ReduceStatementExpandToLevelAction |
    ReduceStatementRowDrag |
    ReduceStatementRowDrop|
    ReduceStatementLoadingAction|
    ReduceStatementError

