import { select, call } from 'redux-saga/effects'
import * as selectors from '../../../reducer';
import PropTypes from 'prop-types';
import getStatementRowById from "../../../model/Statement/getStatementRowById";
import { updateRemoteStatementRow } from "./functions";

export default function* renameStatementRow(action) {
    PropTypes.checkPropTypes(
        {
            statementId: PropTypes.string.isRequired,
            rowId: PropTypes.string.isRequired,
            label: PropTypes.string.isRequired,
        }, {
            ...action.payload
        },
        'args',
        'Statement/Saga::renameStatementRow'
    );
    const { statementId, rowId, label } = action.payload;
    const statement: Statement | null = yield select(selectors.findStatementById, statementId);
    if (!statement) {
        throw new Error('Statement not found: ' + statementId);
    }
    const statementRow = getStatementRowById(statement, rowId);
    yield call(updateRemoteStatementRow, statement.id, statementRow, undefined, label);
}
