import {TimeSeriesFlow} from "../../TimeSeries/enums";
import isMimeType from "../../../model/Mime/functions/isMimeType";

export default function getFlowForRow(row?: StatementRow): TimeSeriesFlow {
    if (isMimeType(row?.constraintType, "category", "flowIn")) {
        return TimeSeriesFlow.IN;
    } else if (isMimeType(row?.constraintType, "category", "flowOut")) {
        return TimeSeriesFlow.OUT;
    } else {
        return TimeSeriesFlow.IN_OUT;
    }
}
