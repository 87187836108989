import React, {useCallback, useEffect, useMemo, useRef} from "react";
import AutoNumeric from "autonumeric";
import debug from "../../services/Debug/functions/debug";

import {Input} from "antd";

const defaultAutoNumericOptions: AutoNumeric.Options = {
    ...AutoNumeric.getPredefinedOptions().French,
    isCancellable: true,
    unformatOnHover: false,
    emptyInputBehavior: "null",
    selectOnFocus: true,
    modifyValueOnWheel: false,
    onInvalidPaste: "truncate",
    // @ts-ignore this value is valid and required but was denied
    caretPositionOnFocus: null,
};

export type ReactNumericProps = AntdFormInputProps<number> & {
    autoNumericRef?: (ref?: AutoNumeric) => void,
    autoNumericOptions?: AutoNumeric.Options,
    onPressEsc?: () => void,
    onEnterFormulaMode?: () => void,
}

const AutoNumericInput: React.VFC<ReactNumericProps> = ({
    defaultValue,
    value,
    onChange = () => {},
    autoNumericRef = () => {},
    autoNumericOptions: autoNumericOptionProps,
    onBlur = () => {},
    autoFocus,
    onPressEnter = () => {},
    onPressEsc = () => {},
    onEnterFormulaMode = () => {},
    ...inputProps
}) => {

    const autoNumericOptions = useMemo(
        () => ({
            ...defaultAutoNumericOptions,
            ...autoNumericOptionProps,
            modifyValueOnWheel: false, // #DATACORE-722 don"t enable until this is fixed: https://github.com/autoNumeric/vue-autoNumeric/issues/42
        }),
        [autoNumericOptionProps]
    );

    if (!AutoNumeric.areSettingsValid(autoNumericOptions)) {
        // required due to poor type script definitions
        console.error("Invalid auto numeric options" , autoNumericOptions);
    }

    const ref = useRef<Input>(null);
    const autoNumeric = useRef<AutoNumeric|undefined>(
        ref?.current?.input
            ? AutoNumeric.getAutoNumericElement(ref?.current?.input)
            : undefined
    );

    debug("AutoNumericInput::render", value, autoNumericOptions, inputProps, ref, autoNumeric);

    const emptyInputValue = useMemo(
        () => {
            debug("AutoNumericInput::emptyInputValue()", value);
            if (value) {
                return AutoNumeric.format(value || "", autoNumericOptions);
            } else {
                switch (autoNumericOptions.emptyInputBehavior) {
                    case "null":
                    case "focus":
                    case "press":
                    case "always":
                    case "min":
                    case "max":
                    case undefined:
                        return "";
                    case "zero":
                        return AutoNumeric.format(0, autoNumericOptions);
                    default: // number, string
                        return AutoNumeric.format("" + autoNumericOptions.emptyInputBehavior, autoNumericOptions);
                }
            }
        },
        [value, autoNumericOptions]
    );

    const defaultInputValue = defaultValue || emptyInputValue;

    useEffect(() => {
        if (ref.current) {
            if (autoNumeric.current) {
                debug("AutoNumericInput update", autoNumericOptions);
                autoNumeric.current.update(autoNumericOptions);
            } else {
                ref.current.input.value = "" + defaultInputValue;
                debug("AutoNumericInput init", value, ref.current.input.value, autoNumericOptions);
                // leave initial value to undefined, it didn't work correctly
                autoNumeric.current = new AutoNumeric(ref.current.input, undefined, autoNumericOptions);
                if (undefined !== value) {
                    // initial value didn't work correctly, but this did
                    autoNumeric.current.set(value);
                }
                autoFocus && autoNumeric.current.node().focus();
                debug("AutoNumericInput after init", ref.current.input.value, autoNumeric.current.getNumericString(), autoNumeric.current.getNumber());
                autoNumericRef(autoNumeric.current);
            }
        }
        return () => {
            if (autoNumeric.current) {
                debug("AutoNumericInput unmount");
                autoNumeric.current.remove();
                autoNumeric.current = undefined;
            }
        };
    }, [autoNumericOptions, autoNumeric, autoNumericRef, value, autoFocus, defaultInputValue]);

    const getCurrentValue = useCallback(
        (): number|undefined => {
            const inputRawValue = ref.current?.input.value;
            // required to take over values by browser based selection
            if (ref.current && ref.current?.input.value !== autoNumeric.current?.getFormatted()) {
                autoNumeric.current?.set(ref.current?.input.value);
            }
            const autoNumericValue = autoNumeric.current?.getNumber();
            const autoNumericRawValue = autoNumeric.current?.getNumericString();
            debug("AutoNumericInput::getCurrentValue()", inputRawValue, autoNumericRawValue, autoNumericValue);
            return null === autoNumericRawValue || undefined === autoNumericRawValue || "" === autoNumericRawValue
                ? undefined
                : (autoNumericValue || 0);
        },
        [autoNumeric]
    );

    const handleChange = useCallback(
        () => {
            const newValue = getCurrentValue();
            if (newValue !== value) {
                debug("AutoNumericInput::handleChange()", value, newValue);
                onChange(newValue);
            } else {
                debug("AutoNumericInput::handleChange() skipped", value, newValue);
            }
        },
        [getCurrentValue, onChange, value]
    );

    const onInputBlur = useCallback(
        () => {
            handleChange();
            onBlur();
        },
        [onBlur, handleChange]
    );

    const onInputKeyDown = useCallback(
        e => {
            debug("AutoNumericInput::onInputKeyDown()", e.key);
            switch (e.key) {
                case "Enter":
                    handleChange();
                    onPressEnter();
                    break;
                case "Escape":
                    e.stopPropagation();
                    onPressEsc();
                    break;
                case "=":
                    onEnterFormulaMode();
                    break;
                default:
            }
        },
        [handleChange, onPressEnter, onPressEsc, onEnterFormulaMode]
    );

    return <Input
        {...inputProps}
        ref={ref}
        onBlur={onInputBlur}
        onKeyDown={onInputKeyDown}
        defaultValue={defaultInputValue}
    />;
}

export default AutoNumericInput;
