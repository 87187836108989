import React from "react";
import { Row, Col } from "antd";
import {useTranslation} from "react-i18next";
import useBrand from "../../hooks/useBrand";

import "./component.css";

const FooterNav: React.VFC = () => {
    const brand = useBrand();
    const { t } = useTranslation();

    return (
        <Row>
            <Col xs={24} sm={{ span: 8, push: 8 }}><ul className="footerNav">
                <li><a href={brand.imprintUrl} target="_blank" rel="noopener noreferrer">{t("imprint")}</a></li>
                <span className="delimiter"> • </span>
                <li><a href={brand.privacyUrl} target="_blank" rel="noopener noreferrer">{t("privacy")}</a></li>
            </ul></Col>
            <Col xs={24} sm={{ span: 8, pull: 8 }} style={{color:"#999",padding:"0 25px", whiteSpace: "nowrap"}}>Made with <span
                role="img" aria-label="heart" style={{fontSize:"smaller"}}>💙️</span> in Berlin</Col>
            <Col xs={0} sm={{ span: 8, offset: 0 }}/>
        </Row>
    );
}

export default FooterNav;

