import React, {useCallback, useMemo, useRef, useState} from "react";
import {useTranslation} from "react-i18next";
import debug from "../../../../services/Debug/functions/debug";

import {AutoComplete, Select} from "antd";
import {AutoCompleteProps} from "antd/lib/auto-complete";
import {RefSelectProps} from "antd/lib/select";

import {MinusSquareOutlined} from "@ant-design/icons";
import {OptionData, OptionGroupData} from "rc-select/lib/interface";

import color from "../../../colors";

export interface SelectGroupProps {
    onChange?: (group?: GroupDraft) => void,
    groups?: FormGroup[],
    placeholder?: string,
    value?: GroupDraft,
    draftEnabled?: boolean,
    className?: any
    onBlur?: (any?) => void,
    onFocus?: (any?) => void,
    isOpened?: boolean
    isDisabled?: boolean,
    allowClear?: boolean
}

type GroupOption = (OptionData|OptionGroupData) & {
    name: string,
    group: FormGroup,
}

const SelectGroup:React.VFC<SelectGroupProps> = ({
                                                     onChange = () => {},
                                                     groups = [],
                                                     placeholder,
                                                     value,
                                                     draftEnabled = false,
                                                     className,
                                                     onFocus,
                                                     onBlur,
                                                     isOpened,
                                                     isDisabled,
                                                     allowClear = true,
                                                 }) => {


    debug("SelectGroup::render()", onChange, groups, placeholder, value, draftEnabled);

    const getValueForGroup = (group: GroupDraft): string => {
        return undefined === group.id ? group.label : "id://" + group.id;
    };

    const {t} = useTranslation();
    const [searchTerm, setSearchTerm] = useState<string|undefined>(undefined);
    debug("SelectGroup::searchTerm", searchTerm);

    const inputRef = useRef<RefSelectProps>({
        focus: () => {},
        blur: () => {},
        scrollTo: () => {}
    });

    const findGroupByLabel = useCallback(
        (label: string, groups: FormGroup[]): FormGroup|undefined => {
            const labelLowerCase = label.toLowerCase();
            let result: FormGroup|undefined = undefined;
            groups.every(group => {
                result = (labelLowerCase === group.label.toLowerCase())
                    ? group
                    : ( group.groups ? findGroupByLabel(label, group.groups) : undefined)
                return undefined === result;
            });
            return result;
        },
        []
    );

    const resetSearch = useCallback(
        () => {
            debug("SelectGroup::resetSearch()");
            setSearchTerm(undefined);
        },
        []
    );

    const findGroupForSearch = useCallback(
        (searchTerm: string): GroupDraft|undefined => {
            debug("SelectGroup::findGroupForSearch()", searchTerm);
            const label = searchTerm.trim();
            return ("" === label) ? undefined : (findGroupByLabel(label, groups) || {label: label});
        },
        [groups, findGroupByLabel]
    );

    const onInputClear = useCallback(
        () => {
            debug("SelectGroup::onInputClear()");
            resetSearch();
            onChange(undefined);
            onBlur && onBlur()
        },
        [resetSearch, onChange, onBlur]
    );

    const onInputSelect = useCallback(
        (value, option) => {
            debug("SelectGroup::onInputSelect()", value, option);
            resetSearch();
            onChange({id: option.group.id, label: option.group.label});
        },
        [resetSearch, onChange]
    );

    const onInputSearch = useCallback(
        (value) => {
            debug("SelectGroup::onInputSearch()", value);
            setSearchTerm(value);
        },
        []
    );

    const onInputKeyDown = (event) => {
        debug('SelectGroup::onInputKeyDown()', event.keyCode);
        if(!searchTerm) return;
        const group = findGroupByLabel(searchTerm, groups)
        if(!group) return;
        switch (event.keyCode) {
            case 13: // Enter
                event.preventDefault();
                inputRef.current.blur();
                break;
            default:
        }
    };

    const onInputBlur = useCallback(
        () => {
            debug("SelectGroup::onBlur()", searchTerm);
            if (undefined !== searchTerm) {
                const group = findGroupForSearch(searchTerm!);
                resetSearch();
                if (undefined === group) {
                    if (undefined !== value) {
                        onChange(undefined);
                    }
                } else {
                    onChange(group);
                }
            }
            onBlur && onBlur()
        },
        [onBlur,value, searchTerm, findGroupForSearch, resetSearch, onChange]
    );

    const getOptionStyle = (level: number): React.CSSProperties => {
        return {
            paddingLeft: (level + 1) * 10,
        };
    };

    const renderOptionLabel = (group: FormGroup) => {
        return <><MinusSquareOutlined style={{color: color.grey, fontSize: "0.8em"}}/> {group.label}</>;
    }

    const renderPlaceholder = () => {
        return undefined !== placeholder
            ? placeholder
            : (draftEnabled
                    ? (options.length ? t("select or reassign group") : t("reassign group"))
                    : t("select group")
            );
    }

    const getGroupOptions = useCallback(
        (groups: FormGroup[], level: number = 0): GroupOption[] => {
            debug("SelectGroup::getGroupOptions()", groups, level);
            const result: GroupOption[] = [];
            const subLevel = level + 1;
            groups.forEach(group => {
                const isDisabled = !group.isSelectable;
                const hasSubGroups = group.groups?.length;
                if (!isDisabled || hasSubGroups) {
                    result.push({
                        value: getValueForGroup(group),
                        style: getOptionStyle(level),
                        label: renderOptionLabel(group),
                        disabled: isDisabled,
                        name: group.label,
                        group: group,
                    });
                    if (hasSubGroups && group.groups) {
                        const subOptions = getGroupOptions(group.groups, subLevel);
                        result.push(...subOptions)
                    }
                }
            });
            return result;
        },
        []
    );

    const options = useMemo(
        () => getGroupOptions(groups),
        [groups, getGroupOptions]
    );
    debug("SelectGroup::options", options);

    const inputProps: AutoCompleteProps<any,GroupOption> = {
        allowClear: allowClear,
        placeholder: renderPlaceholder(),
        value: undefined === searchTerm ? (undefined === value ? "" : value.label) : searchTerm,
        onSearch: onInputSearch,
        onKeyDown: onInputKeyDown,
        onSelect: onInputSelect,
        onBlur: onInputBlur,
        onClear: onInputClear,
        optionFilterProp: "name",
        filterOption: true,
        options: options,
        onFocus,
        className,
        disabled: isDisabled
    };
    if(isOpened !== undefined){
        inputProps.open = isOpened
    }

    return (!groups?.length || draftEnabled)
        ? <AutoComplete {...inputProps} ref={inputRef} />
        : <Select {...inputProps} showSearch optionLabelProp="label" ref={inputRef}/>;
}

export default SelectGroup;
