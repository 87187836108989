import axios from "../../../axios";
import {notification} from "antd";
import i18n from "../../../i18n";
import tld from "../../../tld";

function* axiosDeleteIntegrationDemo() {
    return yield axios.delete(tld(process.env.REACT_APP_URL_API_INTEGRATION) + '/v1/demo');
}

export default function* deleteIntegrationDemo() {
    yield axiosDeleteIntegrationDemo();
    notification.success({message: i18n.t("intro.step.demo.resources.deleteDemo.confirmation"), duration: 5});

    window.location.reload();
}
