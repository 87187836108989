import {TimeSeriesDensity} from "./enums";
import {makeDailyTimeFramesForTimeRange} from "../TimeRange";
import {getDailyTimeFrames, timeFrameToIsoString, timeFrameToTimeCode} from "../TimeFrame/functions";

export function makeDailyTimeSeriesByRange(
    timeRange: TimeRange,
    values: number[],
    paddingLeftDays: number = 0
): TimeSeries {
    const visibleTimeFrames: TimeFrame[] = makeDailyTimeFramesForTimeRange(timeRange, paddingLeftDays);
    return makeTimeSeriesByTimeFrames(visibleTimeFrames, values);
}

export function makeDailyTimeSeriesByDates(
    startDate: Date,
    endDate: Date,
    values: (number | null)[]
): TimeSeries {
    const visibleTimeFrames: TimeFrame[] = getDailyTimeFrames(startDate, endDate);
    return makeTimeSeriesByTimeFrames(visibleTimeFrames, values);
}

export function makeTimeSeriesByTimeFrames(timeFrames: TimeFrame[], values: (number | null)[]) {
    return {
        resolution: {
            density: TimeSeriesDensity.DAY,
            count: 1
        },
        timeCodes: timeFrames.map(timeFrame => timeFrameToTimeCode(timeFrame)),
        isoCodes: timeFrames.map(timeFrame => timeFrameToIsoString(timeFrame)),
        values: values
    };
}
