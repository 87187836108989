import _ from 'lodash';

const findGroupByStatementRowId = (groupOptions, statementRowId) => {
    let result = _.find(groupOptions, {id:statementRowId});
    if(!result){
        groupOptions.forEach(group => {
            if(group.id === statementRowId){
                return result = group;
            }
            if(group.groups && group.groups.length > 0){
                const deepResult = findGroupByStatementRowId(group.groups, statementRowId);
                if(deepResult){
                    return result = deepResult
                }
            }else{
                return undefined
            }
        })
    }


    return result;
}
export default findGroupByStatementRowId;
