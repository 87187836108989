import { StatementRowConstraintMainType } from "../index";
import { CashFlowRole } from "../../CashFlow/enums";

export function mutateWithParticipantRow(
    profile: CashFlowStatementProfile,
    row: StatementRow,
    groupRowId: string,
    categoryRowId: string
): void  {
    if (undefined !== row.constraintType &&
        StatementRowConstraintMainType.CASH_FLOW_ROLE === row.constraintType.mainType
    ) {
        const rowId = row.id;
        switch (row.constraintType.subType) {
            case CashFlowRole.CREDITOR:
                if (undefined === profile.creditorRowIdByGroupRowId[groupRowId]) {
                    profile.creditorRowIdByGroupRowId[groupRowId] = rowId;
                }
                if (undefined === profile.creditorRowIdByCategoryRowId[categoryRowId]) {
                    profile.creditorRowIdByCategoryRowId[categoryRowId] = rowId;
                } else {
                    console.error(`Category row "${categoryRowId}" already has a creditor row assigned. Skipping assigning creditor row "${rowId}"`);
                }
                break;
            case CashFlowRole.DEBTOR:
                if (undefined === profile.debtorRowIdByGroupRowId[groupRowId]) {
                    profile.debtorRowIdByGroupRowId[groupRowId] = rowId;
                }
                if (undefined === profile.debtorRowIdByCategoryRowId[categoryRowId]) {
                    profile.debtorRowIdByCategoryRowId[categoryRowId] = rowId;
                } else {
                    console.error(`Category row "${categoryRowId}" already has a debtor row assigned. Skipping assigning debtor row "${rowId}"`);
                }
                break;
            default:
                console.error('Cash flow role constraint is not a participant constraint: ' + row.constraintType.subType);
        }
    }
}
