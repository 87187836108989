import React, {ChangeEventHandler, useCallback} from "react";
import {Input} from "antd";

type InputSingleLineText255Props = AntdFormInputProps<string>;

const InputSingleLineText255: React.VFC<InputSingleLineText255Props> = ({
    onChange = () => {},
    ...inputProps
}) => {
    const onInputChange: ChangeEventHandler<HTMLInputElement> = useCallback(
        (e) => {
            const value = e.target.value;
            let newValue = '';
            if (null !== value) {
                const valueCompressed = value.replace(/\s+/g, ' ');
                const valueTrimmed = valueCompressed.trim();
                newValue = ('' === valueTrimmed) ? valueTrimmed : valueCompressed;
            }
            onChange(newValue);
        },
        [onChange]
    );

    return <Input
        {...inputProps}
        type="text"
        maxLength={255}
        onChange={onInputChange}
    />;
};

export default InputSingleLineText255;
