import React, {useMemo} from "react";
import {useTranslation} from "react-i18next";

import {CashFlowRole} from "../../model/CashFlow/enums";
import SelectAccount from "./SelectAccount";
import {isCashFlowCreditor} from "../../model/Accounting/AccountQualification";
import filterObject from "../../model/Object/filterObject";

const SelectCreditorAccount: React.VFC<SelectCashAccountProps> = (props) => {
    const {t} = useTranslation();

    const accounts = useMemo(
        () => filterObject(props.accounts, isCashFlowCreditor),
        [props.accounts]
    );

    return <SelectAccount
        {...props}
        placeholder={props.placeholder || t("select or assign a new vendor")}
        accounts={accounts}
        cashFlowRole={CashFlowRole.CREDITOR}
    />;
}

export default SelectCreditorAccount;
