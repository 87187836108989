import {BroadcastChannel, createLeaderElection} from "broadcast-channel";
import store from "../../store";
import * as actionTypes from "./actionTypes";
import redirectToSignIn from "./saga/functions/redirectToSignIn";
import {LogoutTrigger} from "./enums";

type AuthMessage = {
    namespace: string,
}

export enum AuthMessageType {
    LOGOUT = "logout",
    REFRESH = "refresh",
}

export type LogoutMessage = AuthMessage & {
    type: AuthMessageType.LOGOUT,
    trigger: LogoutTrigger,
}

export type RefreshMessage = AuthMessage & {
    type: AuthMessageType.REFRESH,
}

export type AuthBroadcastMessage = LogoutMessage | RefreshMessage;

type IFrameRef = {
    window: Window,
    origin: string,
};

let channel: BroadcastChannel|undefined = undefined;
let iframes: Record<string, IFrameRef> = {};
let isElected = false;
let login: AuthLogin|undefined;

function registerBroadcast(newLogin: AuthLogin) {
    if (login && login.namespace !== newLogin.namespace) {
        redirectToSignIn();
    } else {
        login = newLogin;

        if (!channel) {
            channel = new BroadcastChannel<AuthBroadcastMessage>("auth", {webWorkerSupport: true});
            channel.onmessage = msg => store.dispatch({
                type: actionTypes.RECEIVE_AUTH_BROADCAST,
                payload: msg
            });

            const elector = createLeaderElection(channel);
            elector.awaitLeadership()
                .then(() => isElected = true)
                .catch(e => console.error(e));
        }
    }
}

export async function unregisterBroadcast() {
    login = undefined;
    await channel?.close();
    channel = undefined;
    iframes = {};
    isElected = false;
}

const instance = {
    register: registerBroadcast,
    unregister: unregisterBroadcast,
    isLeader: () => isElected,
    broadcastLogout: (trigger: LogoutTrigger) => {
        if (channel && login) {
            channel.postMessage({
                type: AuthMessageType.LOGOUT,
                namespace: login.namespace,
                trigger,
            }).catch(e => console.error(e));
        }
    },
    broadcastRefresh: () => {
        if (login) {
            if (channel) {
                channel.postMessage({type: AuthMessageType.REFRESH, namespace: login.namespace})
                    .catch(e => console.error(e));
            }
            Object.keys(iframes).forEach(iframeId => iframes[iframeId].window.postMessage(
                {type: "scsIFrameAuthRefresh", namespace: login!.namespace},
                iframes[iframeId].origin
            ));
        }
    },
    registerIframe: (id: string, iframe: Window, origin: string) => {
        iframes[id] = {window: iframe, origin};
    },
};

export default instance;
