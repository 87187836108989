import {CloseDrawerAction, DrawerReducerActionType, OpenDrawerAction} from "./index";

export function openDrawerAction(drawer: Drawer): OpenDrawerAction {
    return {
        type: DrawerReducerActionType.REDUCE_DRAWER_OPEN,
        payload: drawer
    };
}

export function closeDrawerAction(drawerId: string): CloseDrawerAction {
    return {
        type: DrawerReducerActionType.REDUCE_DRAWER_CLOSE,
        payload: {
            id: drawerId
        }
    };
}
