import axios from '../../../axios';
import {put} from "redux-saga/effects";

import * as actionTypes from '../actionTypes';
import * as RestV1 from "./RestV1Types";
import tld from "../../../tld";


function* axiosRequestRemoteRestV1Integrations() {
    return yield axios.get(tld(process.env.REACT_APP_URL_API_CONNECT) + '/v1/integrations');
}

export default function* getIntegrations() {
    const {data}: RestV1.IntegrationDataResponse = yield axiosRequestRemoteRestV1Integrations();

    yield put({
        type: actionTypes.REDUCE_INTEGRATIONS_LOADED,
        payload: data
    });

}
