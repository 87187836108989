import axios from '../../../axios';

import {DeleteScenarioExclusionAction} from "../actionTypes";
import tld from "../../../tld";


function* axiosDeleteRemoteRestV1Filters(id) {
    return yield axios.delete(tld(process.env.REACT_APP_URL_API_PLAN) + '/v1/scenarioExclusions/' + id);
}

export default function* deleteScenarioExclusion(action: DeleteScenarioExclusionAction) {
    const scenarioId = action.payload.scenarioId;
    yield axiosDeleteRemoteRestV1Filters(scenarioId);


}
