import { filterExpiredPlanning } from "../Statement/filterExpiredPlanning";
import { CashFlowRole } from "../CashFlow/enums";
import moment from "moment";
import debug from "../../services/Debug/functions";

export function isPostingFixed(posting: Posting): boolean {
    return posting.id !== undefined;
}

export function isPostingVolatile(posting: Posting): boolean {
    return posting.id === undefined;
}

export function filterFixedPostings(postings: Posting[]): PostingFixed[] {
    // @ts-ignore
    return postings.filter(posting => isPostingFixed(posting));
}

export function filterVolatilePostings(postings: Posting[]): PostingVolatile[] {
    // @ts-ignore
    return postings.filter(posting => isPostingVolatile(posting));
}

export function searchPostings(postings: Posting[], accountIds: string[], timeFrame?: TimeFrame): Posting[] {
    return postings.filter(posting => {
        let result = accountIds.indexOf(posting.accountId) > -1 || accountIds.indexOf(posting.contraAccountId) > -1;
        if (result && undefined !== timeFrame) {
            const postingYear = posting.receiptDate.getFullYear();
            const postingHalfYear = posting.receiptDate.getMonth() < 6 ? 1 : 2;
            const postingQuarter = moment(posting.receiptDate).quarter();
            const postingMonth = posting.receiptDate.getMonth() + 1;
            const postingWeek = moment(posting.receiptDate).isoWeek();
            const postingDay = posting.receiptDate.getDate();
            if (postingYear !== timeFrame.year) {
                result = false;
            } else if (undefined !== timeFrame.halfYear && postingHalfYear !== timeFrame.halfYear) {
                result = false
            } else if (undefined !== timeFrame.quarter && postingQuarter !== timeFrame.quarter) {
                result = false
            } else if (undefined !== timeFrame.month && postingMonth !== timeFrame.month) {
                result = false;
            } else if (undefined !== timeFrame.week && postingWeek !== timeFrame.week) {
                result = false
            } else if (undefined !== timeFrame.day && postingDay !== timeFrame.day) {
                result = false
            }
        }
        return result;
    });
}

/**
 * @deprecated in favor of a filter method matching PostingFilter type
 * @todo #FEATURE-624
 */
export function matchesCompanyUnitFilter(posting: Posting, postingFiltersSet?: PostingFilterSet) {
    const companyUnitIds = postingFiltersSet?.companyUnitIds || [];
    if (Array.isArray(companyUnitIds)) {
        if (companyUnitIds.length === 0) {
            return true;
        }
        return companyUnitIds.some(id => posting.companyUnitId === id);
    } else {
        throw Error("@todo implement #FEATURE-624");
    }
}

function hasCashFlowRole(account: AccountingAccount): boolean {
    return undefined !== account.cashFlowRole;
}

function hasCashFlowRolePostingEffect(account: AccountingAccount): boolean {
    return undefined !== account.postingEffects
        && -1 !== account.postingEffects.indexOf("cashFlowRole");
}

function isAccountSetMandatory(account: AccountingAccount, contraAccount: AccountingAccount): boolean {
    return hasCashFlowRolePostingEffect(account) || hasCashFlowRolePostingEffect(contraAccount);
}

function matchesCashFlowRoleFilter(cashFlowRole: CashFlowRole, accountFilter: AccountFilter): boolean {
    if (accountFilter.cashFlowRoleBlacklist && accountFilter.cashFlowRoleBlacklist.length) {
        if (accountFilter.cashFlowRoleBlacklist.indexOf(cashFlowRole) > -1) {
            return false;
        }
    }
    if (accountFilter.cashFlowRoleWhitelist && accountFilter.cashFlowRoleWhitelist.length) {
        return accountFilter.cashFlowRoleWhitelist.indexOf(cashFlowRole) > -1;
    }
    return true;
}

export const scenarioFilter = (scenario, posting, scenarioPlannings, exclusions?: ScenarioExclusion[]) => {
    if (scenario) {
        const isBasePosting = undefined === posting.scenarioId;
        if (!isBasePosting && scenario.id !== posting.scenarioId) {
            return false;
        }
        // hide base plannings overwritten by scenario plannings
        if (undefined !== scenarioPlannings) {
            const scenarioPlanningsWithBaseSource = scenarioPlannings.filter(planning => !!planning.scenarioBaseSourceId);
            const overwrittenSourceIds = scenarioPlanningsWithBaseSource.map(planning => planning.scenarioBaseSourceId);
            if (isBasePosting && posting.source && overwrittenSourceIds.includes(posting.source.id)) {
                return false;
            }
        }
        // hide excluded base plannings
        if (posting.source
            && undefined !== exclusions
            && exclusions.find(exclusion => exclusion.planningId === posting.source.id)) {
            return false;
        }
    } else {
        if (undefined !== posting.scenarioId) {
            return false;
        }
    }
    return true;
}

export const filterPostingsForCashFlowStatement = (
    postings: Posting[],
    expirationDate?: Date,
    accounts?: AccountRegistry,
    accountFilter?: AccountFilter,
    postingFilters?: PostingFilterSet,
    scenario?: Scenario,
    scenarioPlannings?: Planning[],
    exclusions?: ScenarioExclusion[]
): Posting[] => {
    debug('filterPostingsForCashFlowStatement()', postings, postingFilters);
    return postings.filter(posting => {
        if (!scenarioFilter(scenario, posting, scenarioPlannings, exclusions)) return false;
        if (accounts) {
            const account = accounts[posting.accountId];
            const contraAccount = accounts[posting.contraAccountId];
            if (undefined === account) {
                console.error('Integrity failure - account id not known: ' + posting.accountId);
                return false;
            }

            if (undefined === contraAccount) {
                console.error('Integrity failure - account id not known: ' + posting.contraAccountId);
                return false;
            }

            if (postingFilters) {
                for (const postingFilterId in postingFilters) {
                    if (postingFilters.hasOwnProperty(postingFilterId) &&
                        // @todo #FEATURE-624
                        // companyUnitIds filter is somewhat else, not fulfilling PostingFilter type
                        "companyUnitIds" !== postingFilterId
                    ) {
                        const postingFilter = postingFilters[postingFilterId];
                        if (!postingFilter.filterPosting(posting, account, contraAccount)) return false;
                    }
                }
            }

            if (!matchesCompanyUnitFilter(posting, postingFilters)) {
                return false;
            }

            if (account.isOpeningBalance || contraAccount.isOpeningBalance) {
                return false;
            }

            if (
                (!hasCashFlowRole(account) || !hasCashFlowRole(contraAccount)) &&
                !isAccountSetMandatory(account, contraAccount)
            ) {
                return false;
            }

            if (accountFilter) {
                if (hasCashFlowRole(account) && !matchesCashFlowRoleFilter(account.cashFlowRole, accountFilter)) {
                    return false;
                }
                if (hasCashFlowRole(contraAccount) && !matchesCashFlowRoleFilter(contraAccount.cashFlowRole, accountFilter)) {
                    return false;
                }
            }
        }
        // noinspection RedundantIfStatementJS
        if (!filterExpiredPlanning(posting, expirationDate)) {
            return false;
        }
        return true;
    });
};
