import {RemoteAbstractExplanationSeries} from "../RestV1Types";
import {TimeSeriesDensity} from "../../../../model/TimeSeries/enums";
import iso8601ToTimeCode from "../../../../model/TimeSeries/functions/iso8601ToTimeCode";

export default function transformRestV1ExplanationSeries(explanations:RemoteAbstractExplanationSeries|undefined):ExplanationSeries{
    if(!explanations || !explanations.date || !explanations.explanation){
        return {
            resolution: {
                density: TimeSeriesDensity.DAY,
                count: 1,
            },
            timeCodes: [],
            isoCodes: [],
            values: []
        }
    }
    const timeCodes  = explanations.date.map(date => iso8601ToTimeCode(date))
    const isoCodes =  explanations.date;
    const values:Explanation[][] = explanations.explanation.map(explanationArr => explanationArr.map( (e) => {

        const explanation:Explanation = {
            blueprintGroupId: e.blueprintGroupId,
            weight: Number(e.weight.toFixed(2)),
            isoCode: e.date
        }
        return explanation
    }));

    return {
        resolution: {
            density: TimeSeriesDensity.DAY,
            count: 1,
        },
        timeCodes,
        isoCodes,
        values,
    }
}
