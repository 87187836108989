import {call, put, select} from "redux-saga/effects"
import * as selectors from "../../../reducer";
import { createRemoteStatementRow } from "./functions";
import { StatementGroupRowCreateAction } from "../actionTypes";
import { RowLinkRelation } from "../../../model/StatementUri/enums";
import {reduceStatementLoading} from "../actions";

function* assertStatementExists(statementId: string) {
    yield select(selectors.getStatementById, statementId);
}

export default function* createStatementGroupRow(action: StatementGroupRowCreateAction) {

    const { statementId, label, parentGroup }  = action.payload;
    yield put(reduceStatementLoading())
    yield call(assertStatementExists, statementId);
    //
    let parentRow: StatementRow|null = null;
    if (parentGroup) {
        if (parentGroup.id) {
            parentRow = yield select(selectors.getStatementRow, statementId, parentGroup.id);
        } else {
            parentRow = yield call(createRemoteStatementRow, statementId, parentGroup.label);
        }
    }
    //
    if (parentRow) {
        const rowLink: StatementRowLink = {
            id: parentRow.id,
            relation: RowLinkRelation.PARENT,
        };
        return yield call(
            createRemoteStatementRow,
            statementId,
            label,
            undefined,
            undefined,
            rowLink
        );
    } else {
        return yield call(createRemoteStatementRow, statementId, label);
    }
}
