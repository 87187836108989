import debug from "../../../services/Debug/functions/debug";
import {PaymentTypeEnum} from "../../../model/Planning/Enums";
import filterPaymentTypes from "./filterPaymentTypes";

const defaultPaymentTypeOptions = [PaymentTypeEnum.DEPOSIT, PaymentTypeEnum.DISBURSEMENT];

const reduceToPaymentTypes = (
    paymentCashFlowRole: PaymentCashFlowRole,
    isReversal: boolean,
): PaymentCashFlowRole => {
    debug("getPaymentTypeOptions()", paymentCashFlowRole, isReversal);

    return filterPaymentTypes(defaultPaymentTypeOptions, isReversal, paymentCashFlowRole);
}

export default reduceToPaymentTypes;
