import * as actionTypes from './actionTypes';

const defaultState: ScenarioState = {
    loading: true,
    scenarios: [],
    exclusions: [],
    selected: undefined
}

export default function reducer(state: ScenarioState = defaultState, action: actionTypes.ScenarioReducerAction) {
    switch (action.type) {
        case actionTypes.REDUCE_SCENARIOS_LOADED: {
            if (action.payload.length > 0) {
                return ({...state, scenarios: action.payload, loading: false})
            } else {
                return ({...state, loading: false})
            }
        }
        case actionTypes.REDUCE_SCENARIO_CREATED: {
            return ({...state, scenarios: [...state.scenarios, action.payload] })
        }
        case actionTypes.REDUCE_SCENARIO_DELETED: {
            return ({...state, scenarios: state.scenarios.filter(scenario => scenario.id !== action.payload)})
        }
        case actionTypes.REDUCE_SCENARIO_UPDATED: {
            return ({...state, scenarios: state.scenarios.map(scenario => scenario.id === action.payload.id ? action.payload : scenario)})
        }
        case actionTypes.REDUCE_SCENARIO_SELECTED: {
            return ({...state, selected: action.payload})
        }
        case actionTypes.REDUCE_SCENARIO_DESELECTED: {
            return ({...state, selected: undefined})
        }
        case actionTypes.REDUCE_SCENARIO_EXCLUSIONS_CREATED: {
            return ({...state, exclusions: [...state.exclusions, action.payload] })
        }
        case actionTypes.REDUCE_SCENARIO_EXCLUSIONS_LOAD: {
            if (action.payload.exclusions.length > 0) {
                return ({
                    ...state,
                    exclusions: [
                        ...state.exclusions.filter(exclusion => action.payload.scenarioId !== exclusion.scenarioId),
                        ...action.payload.exclusions
                    ]
                });
            } else {
                return state;
            }
        }
        default: {
            return state;
        }
    }
}

