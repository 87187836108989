import { List } from "immutable";
import { CashFlowRole, CashFlowType } from "../../CashFlow/enums";
import { StatementRowType } from "../index";

function mutateProfileWithCashFlowRow(
    profile: CashFlowStatementProfile,
    cashFlowType: CashFlowType,
    rowId: string,
    level: number
) {
    if (level > 0) {
        profile.errors.push(`Non-top level ${cashFlowType} cash flow row found: ${rowId}`);
    }
    switch (cashFlowType) {
        case CashFlowType.OPERATING:
            if (profile.operatingCashFlowRowId) {
                profile.errors.push(`Multiple ${cashFlowType} cash flow rows found. Skipped row: ${rowId}`);
            } else {
                profile.operatingCashFlowRowId = rowId;
            }
            break;
        case CashFlowType.INVESTMENT:
            if (profile.investmentCashFlowRowId) {
                profile.errors.push(`Multiple ${cashFlowType} cash flow rows found. Skipped row: ${rowId}`);
            } else {
                profile.investmentCashFlowRowId = rowId;
            }
            break;
        case CashFlowType.FINANCING:
            if (profile.financingCashFlowRowId) {
                profile.errors.push(`Multiple ${cashFlowType} cash flow rows found. Skipped row: ${rowId}`);
            } else {
                profile.financingCashFlowRowId = rowId;
            }
            break;
        default:
            throw Error(`Cash flow type not supported: "${cashFlowType}"`);
    }
}

function getCashFlowType(cashFlowTypeValue: string): CashFlowType {
    switch (cashFlowTypeValue) {
        case CashFlowType.OPERATING: return CashFlowType.OPERATING;
        case CashFlowType.INVESTMENT: return CashFlowType.INVESTMENT;
        case CashFlowType.FINANCING: return CashFlowType.FINANCING;
        default:
            throw Error(`Cash flow type not known: "${cashFlowTypeValue}"`);
    }
}

export function hasRowConstraint(row: StatementRow|StatementDeepTimeSeriesRow, mimeType: MimeTypeStruct): boolean {
    return undefined !== row.constraintType
        && mimeType.mainType === row.constraintType.mainType
        && mimeType.subType === row.constraintType.subType;
}

function mutateProfileWithRefreshedParticipants(profile: CashFlowStatementProfile) {
    for (const categoryRowId in profile.groupRowIdByCategoryRowId) {
        const groupRowId = profile.groupRowIdByCategoryRowId[categoryRowId];
        if (profile.creditorRowIdByGroupRowId[groupRowId]) {
            profile.creditorRowIdByCategoryRowId[categoryRowId] = profile.creditorRowIdByGroupRowId[groupRowId];
        }
        if (profile.debtorRowIdByGroupRowId[groupRowId]) {
            profile.debtorRowIdByCategoryRowId[categoryRowId] = profile.debtorRowIdByGroupRowId[groupRowId];
        }
    }

    for (const subGroupRowId in profile.groupRowIdBySubGroupRowId) {
        const groupRowId = profile.groupRowIdBySubGroupRowId[subGroupRowId];

        if (profile.creditorRowIdByGroupRowId[groupRowId]) {
            profile.creditorRowIdByGroupRowId[subGroupRowId] = profile.creditorRowIdByGroupRowId[groupRowId];
        }
        if (profile.debtorRowIdByGroupRowId[groupRowId]) {
            profile.debtorRowIdByGroupRowId[subGroupRowId] = profile.debtorRowIdByGroupRowId[groupRowId];
        }
    }
}

function mutateProfileWithRows(
    profile: CashFlowStatementProfile,
    rows: List<StatementRow>,
    level: number = 0,
    cashFlowType?: CashFlowType,
    cashFlowGroupRowId?: string
) {
    for (const row of rows) {
        let parentCashFlowType = cashFlowType;
        let parentCashFlowGroupRowId = cashFlowGroupRowId;
        switch (row.type) {
            case StatementRowType.GROUP:
                if (row.constraintType) {
                    switch (row.constraintType.mainType) {
                        case "cashFlowType":
                            const rowCashFlowType = getCashFlowType(row.constraintType.subType);
                            if (parentCashFlowType) {
                                profile.errors.push(`Nested cash flow types ${parentCashFlowType} > ${rowCashFlowType}`);
                            } else {
                                parentCashFlowType = rowCashFlowType;
                            }
                            mutateProfileWithCashFlowRow(profile, rowCashFlowType, row.id, level);
                            break;
                        default:
                        // intentionally doing nothing
                    }
                }
                if (cashFlowType && !cashFlowGroupRowId) {
                    parentCashFlowGroupRowId = row.id;
                } else if (parentCashFlowGroupRowId) {
                    // A group with a parentCashFlowGroupRowId is a subgroup
                    profile.groupRowIdBySubGroupRowId[row.id] = parentCashFlowGroupRowId;
                }
                break;
            case StatementRowType.CATEGORY:
                if (cashFlowGroupRowId) {
                    if (hasRowConstraint(row, {
                        mainType: "cashFlowRole",
                        subType: CashFlowRole.CREDITOR
                    })) {
                        profile.creditorRowIdByGroupRowId[cashFlowGroupRowId] = row.id;
                    } else if (hasRowConstraint(row, {
                        mainType: "cashFlowRole",
                        subType: CashFlowRole.DEBTOR
                    })) {
                        profile.debtorRowIdByGroupRowId[cashFlowGroupRowId] = row.id;
                    } else {
                        profile.groupRowIdByCategoryRowId[row.id] = cashFlowGroupRowId;
                    }
                }
                break;
            default:
                throw Error(`Statement row type not known: "${row.type}"`);
        }
        if (row.rows) mutateProfileWithRows(profile, row.rows, level + 1, parentCashFlowType, parentCashFlowGroupRowId);
    }
    mutateProfileWithRefreshedParticipants(profile);
}

export default function profileCashFlowStatement(statement: Statement): CashFlowStatementProfile {
    const result = {
        catchAllRowId: undefined,
        operatingCashFlowRowId: undefined,
        investmentCashFlowRowId: undefined,
        financingCashFlowRowId: undefined,
        creditorRowIdByCategoryRowId: {},
        debtorRowIdByCategoryRowId: {},
        groupRowIdByCategoryRowId: {},
        creditorRowIdByGroupRowId: {},
        debtorRowIdByGroupRowId: {},
        groupRowIdBySubGroupRowId: {},
        errors: [],
    };
    mutateProfileWithRows(result, statement.rows);
    return result;
}
