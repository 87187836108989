import {Integration, IntegrationDraft} from "./saga/RestV1Types";

export const INTEGRATIONS_LOAD = 'INTEGRATIONS_LOAD';
export const INTEGRATION_UPDATE = 'INTEGRATION_UPDATE';
export const INTEGRATION_CREATE = 'INTEGRATION_CREATE';
export const INTEGRATION_DELETE = 'INTEGRATION_DELETE';
export const INTEGRATION_DEMO_DELETE = 'INTEGRATION_DEMO_DELETE';

export const REDUCE_INTEGRATIONS_LOADED = 'REDUCE_INTEGRATIONS_LOADED';
export const REDUCE_INTEGRATION_UPDATED = 'REDUCE_INTEGRATION_UPDATED';
export const REDUCE_INTEGRATION_CREATED = 'REDUCE_INTEGRATION_CREATED';
export const REDUCE_INTEGRATION_DELETED = 'REDUCE_INTEGRATION_DELETED';

export interface CreateIntegrationAction {
    type: typeof INTEGRATION_CREATE,
    payload: IntegrationDraft
}

export interface GetIntegrationsAction {
    type: typeof INTEGRATIONS_LOAD
}

export interface DeleteIntegrationAction {
    type: typeof INTEGRATION_DELETE,
    payload: string
}

export interface UpdateIntegrationAction {
    type: typeof INTEGRATION_UPDATE,
    payload: IntegrationDraft
}

export interface DeleteIntegrationDemo {
    type: typeof INTEGRATION_DEMO_DELETE
}

export interface ReduceLoadIntegrations {
    type: typeof REDUCE_INTEGRATIONS_LOADED
    payload: Integration[]
}

export interface ReduceCreateIntegration {
    type: typeof REDUCE_INTEGRATION_CREATED
    payload: Integration
}

export interface ReduceUpdateIntegration {
    type: typeof REDUCE_INTEGRATION_UPDATED
    payload: Integration
}

export interface ReduceDeleteIntegration {
    type: typeof REDUCE_INTEGRATION_DELETED
    payload: string
}

export type IntegrationReducerAction =
    ReduceLoadIntegrations |
    ReduceCreateIntegration |
    ReduceUpdateIntegration |
    ReduceDeleteIntegration;
