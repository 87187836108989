import axios from '../../../axios';
import {put} from "redux-saga/effects";

import * as RestV1 from "./RestV1Types";
import * as actionTypes from "../actionTypes";
import tld from "../../../tld";


function* axiosUpdateRemoteRestV1Integrations({id, ...payload}) {
    return yield axios.put(tld(process.env.REACT_APP_URL_API_CONNECT) + '/v1/integrations/' + id, payload);
}

export default function* updateIntegration(integrationData) {
    const {data}: RestV1.IntegrationDataResponse = yield axiosUpdateRemoteRestV1Integrations(integrationData.payload);

    yield put({
        type: actionTypes.REDUCE_INTEGRATION_UPDATED,
        payload: data
    });
}
