import { CashFlowRole } from "../CashFlow/enums";
import debug from "../../services/Debug/functions";

export function findAccountNumberByAccountId(accountRegistry: AccountRegistry, accountId: string): AccountNumber|null {
    if (undefined !== accountRegistry[accountId] && undefined !== accountRegistry[accountId].accountNumber) {
        // @ts-ignore
        return accountRegistry[accountId].accountNumber;
    }
    return null;
}

export function getAccountNumberCodesByAccountIds(accountRegistry: AccountRegistry, accountIds: string[]): number[] {
    let result: number[] = [];
    for (let accountId of accountIds) {
        const accountNumber: AccountNumber|null = findAccountNumberByAccountId(accountRegistry, accountId);
        if (null !== accountNumber) {
            result.push(accountNumber.code);
        }
    }
    return result;
}

export function getAccountsByAccountIds(accountRegistry: AccountRegistry, accountIds: string[]): AccountingAccount[] {
    let result: AccountingAccount[] = [];
    for (let accountId of accountIds) {
        if (undefined !== accountRegistry[accountId]) {
            result.push(accountRegistry[accountId]);
        }
    }
    return result;
}

function accountMatchesCashFlowRole(account: AccountingAccount, cashFlowRole?: CashFlowRole): boolean {
    debug('accountMatchesCashFlowRole()', account, cashFlowRole);
    let result: boolean;
    if (undefined === account.cashFlowRole) {
        result = cashFlowRole === account.cashFlowRole;
    } else {
        if (cashFlowRole === account.cashFlowRole) {
            result = true;
        } else {
            let validCashFlowRoles: CashFlowRole[] = [];
            switch (cashFlowRole) {
                case CashFlowRole.DISBURSEMENT:
                    validCashFlowRoles = [
                        CashFlowRole.DISBURSEMENT,
                        CashFlowRole.PAYMENT
                    ];
                    break;
                case CashFlowRole.DEPOSIT:
                    validCashFlowRoles = [
                        CashFlowRole.DEPOSIT,
                        CashFlowRole.PAYMENT
                    ];
                    break;
                case CashFlowRole.PAYMENT:
                    validCashFlowRoles = [
                        CashFlowRole.DISBURSEMENT,
                        CashFlowRole.DEPOSIT,
                        CashFlowRole.PAYMENT
                    ];
                    break;
            }
            debug('accountMatchesCashFlowRole() - lookup', validCashFlowRoles, account.cashFlowRole);
            result = validCashFlowRoles.indexOf(account.cashFlowRole) > -1;
        }
    }
    debug('accountMatchesCashFlowRole() - result', result);
    return result;
}

export function findAccountsByLabel(
    accounts: AccountRegistry,
    label: string,
    cashFlowRole?: CashFlowRole
): AccountingAccount[] {
    debug('findAccountsByLabel()', accounts, label, cashFlowRole);
    let result: AccountingAccount[] = [];
    for (let accountId in accounts) {
        const account = accounts[accountId];
        if (label === account.label && accountMatchesCashFlowRole(account, cashFlowRole)) {
            result.push(account);
        }
    }
    debug('findAccountsByLabel() - result', result);
    return result;
}

export function findAccountByLabel(
    accounts: AccountRegistry,
    label: string,
    cashFlowRole?: CashFlowRole
): AccountingAccount|null {
    const loadedAccounts: AccountingAccount[] = findAccountsByLabel(accounts, label, cashFlowRole);
    if (loadedAccounts.length > 0) {
        return loadedAccounts[0];
    }
    return null;
}
