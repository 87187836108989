function fromAccountNumber(type, accountNumber, contraAccountNumber)
{
    return 'Cr' === type ? contraAccountNumber : accountNumber;
}

function toAccountNumber(type, accountNumber, contraAccountNumber)
{
    return 'Dr' === type ? contraAccountNumber : accountNumber;
}

export default function reducer(state = {
    loading: true,
    error: null,
    records: {},
}, action) {
    switch (action.type) {
        case 'GET_RECORDS_PENDING': {
            return {
                ...state,
                loading: true,
            };
        }
        case 'GET_RECORDS_FULFILLED': {
            const newState = {
                ...state,
                loading: false,
                error: null,
                records: {
                    ...state.records
                }
            };
            newState.records[action.meta.queryId] = action.payload.data.map(record => {
                record.fromAccountNumber = fromAccountNumber(record.type, record.accountNumber, record.contraAccountNumber);
                record.toAccountNumber = toAccountNumber(record.type, record.accountNumber, record.contraAccountNumber);
                return record;
            });
            return newState;
        }
        case 'GET_RECORDS_REJECTED': {
            let message = ('undefined' === typeof action.payload.response)
                ? 'GET_RECORDS_REJECTED'
                : action.payload.response.data.message;
            return {
                ...state,
                loading: false,
                error: message,
            };
        }
        default: {
            return state;
        }
    }
}
