import React from "react";

import {Drawer as AntdDrawer} from "antd";

type DrawerProps = {
    drawer: Drawer,
    onClose: (id: string) => void;
}

const DrawerContainer = ({drawer: {id, content, title}, onClose}: DrawerProps) => (
    <AntdDrawer
        visible={true}
        onClose={() => onClose(id)}
        placement={"right"}
        title={title}
        getContainer={false}
        width={"auto"}
    >
        {content}
    </AntdDrawer>
);


export default DrawerContainer;
