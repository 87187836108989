import {PlanningFormInternalValues} from "./PlanningForm";
import {PaymentTypeEnum} from "../../model/Planning/Enums";
import {PlanningComplexity} from "./enums";

function fullEdit(values: PlanningFormInternalValues) {
    return !!values.group && !!values.paymentType;
}

function isDirectPlanning(values: PlanningFormInternalValues) {
    return fullEdit(values)
        && PlanningComplexity.DIRECT === values.planningComplexity;
}

function isTransferPlanning(values: PlanningFormInternalValues) {
    return fullEdit(values)
        && PlanningComplexity.TRANSFER === values.planningComplexity;
}

function isTransferDepositPlanning(values: PlanningFormInternalValues) {
    return fullEdit(values)
        && PaymentTypeEnum.DEPOSIT === values.paymentType
        && PlanningComplexity.TRANSFER === values.planningComplexity;
}

function isTransferDisbursementPlanning(values: PlanningFormInternalValues) {
    return fullEdit(values)
        && PaymentTypeEnum.DISBURSEMENT === values.paymentType
        && PlanningComplexity.TRANSFER === values.planningComplexity;
}

function isRecurrentPlanning(values: PlanningFormInternalValues) {
    return fullEdit(values) && values.recurrence.active;
}

const VisibilityRules: FormVisibilityRuleSet<PlanningFormInternalValues> = {
    paymentType: values => !!values.group,
    planningComplexity: values => fullEdit(values),
    // planning sub forms - shared
    invoiceDate: values => fullEdit(values),
    // direct planning sub form (already exclusively visible for direct plannings)
    directPlanning: values => isDirectPlanning(values),
    // direct planning - deposit
    // direct planning - disbursement
    // transfer planning sub form (already exclusively visible for transfer plannings)
    paymentDate: values => fullEdit(values),
    transferAccount: values => isTransferPlanning(values),
    depositAccount: values => isTransferDepositPlanning(values),
    disbursementAccount: values => isTransferDisbursementPlanning(values),
    // general
    cashAccount: values => fullEdit(values),
    amountTotal: values => fullEdit(values),
    recurrence: values => fullEdit(values),
    recurrenceEnd: values => isRecurrentPlanning(values),
    recurrenceIncrease: values => isRecurrentPlanning(values),
    // general - expert panel
    isReversal: values => fullEdit(values),
    plannedAmount: values => fullEdit(values),
    planningType: values => fullEdit(values),
    probability: values => fullEdit(values),
    annotation: values => fullEdit(values),
    receiptNumber: values => fullEdit(values),
    // controls
    _next: values => !fullEdit(values),
    _submit: values => fullEdit(values),
    _expertForm: values => fullEdit(values),
};

export default VisibilityRules;
