import axios from '../../../axios';
import {put} from "redux-saga/effects";
import * as actionTypes from "../actionTypes";
import tld from "../../../tld";

interface RemoteSettingsResponse {
    data: CompanySettings[];
}

function* loadRemoteSettings() {
    return yield axios.get(tld(process.env.REACT_APP_URL_API_SETTINGS) + '/v1/settings');
}

export default function* loadCompanySettings() {
    const {data}: RemoteSettingsResponse = yield loadRemoteSettings();

    const isDemoActive = data.some(d => d.properties?.isDemo);

    yield put({
        type: actionTypes.REDUCE_COMPANY_SETTINGS,
        payload: {isDemoActive}
    });
}
