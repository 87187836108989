import {all, takeEvery} from "redux-saga/effects"
import {BankReducerAction} from "../Bank/reducer";
import applyTimeSeriesStatsUpdate from "./saga/applyTimeSeriesStatsUpdate";
import loadStatementBankTimeSeries from "./saga/loadStatementBankTimeSeries";
import openTimeSeriesEventSource from "./saga/openTimeSeriesEventSource"
import {TimeSeriesAction} from "./actions";
import * as TimeTravelActions from "../TimeTravel/actionTypes";
import * as StatementActions from "../Statement/actionTypes";
import * as AuthActions from "../Auth/actionTypes";

export default function* saga() {
    yield all([
        yield takeEvery(TimeSeriesAction.TIME_SERIES_STATS_UPDATE_PUSHED, applyTimeSeriesStatsUpdate),
        yield takeEvery(StatementActions.X_STATEMENT_CHANGED, loadStatementBankTimeSeries),
        yield takeEvery(TimeTravelActions.REDUCE_DENSITY_SELECTED, loadStatementBankTimeSeries),
        yield takeEvery(TimeTravelActions.REDUCE_TIMEFRAME_SELECTED, loadStatementBankTimeSeries),
        // yield takeEvery(StatementActions.REDUCE_STATEMENT_ROW_EXPAND, loadStatementBankTimeSeries),
        yield takeEvery(BankReducerAction.REDUCE_VISIBILITY, loadStatementBankTimeSeries),
        yield takeEvery(AuthActions.X_AUTH_CHANGED, openTimeSeriesEventSource),
    ]);
}
