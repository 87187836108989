export function encodeTimeSeriesKey(key: TimeSeriesKey): string {
    let urnParts = [
        "timeSeries:/",
        key.type,
        key.topic,
        key.flow,
        key.channels?.join(",") || "",
        key.resolution.density,
        "" + key.resolution.count,
    ];
    if (key.subjectUrn) {
        urnParts.push(key.subjectUrn);
    }
    return urnParts.join("/");
}
