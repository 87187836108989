import React, {CSSProperties, MouseEventHandler} from 'react';
import {formatDefaultLocale, FormatLocaleDefinition} from "d3-format";
import * as colors from './colors';
import debug from "../services/Debug/functions";

export interface CurrencyAmountProps {
    hideCurrency?: boolean;
    precision?:  string;
    unicolor?: boolean;
    style?: CSSProperties;
    children: any;
    onClick?: MouseEventHandler;
}


const CurrencyAmount = ({hideCurrency = false, onClick, precision = '2', unicolor = false, children, style}: CurrencyAmountProps) => {
    debug('CurrencyAmount::render()');

    const currencyFormatDefinition: FormatLocaleDefinition = {
        decimal: ',',
        thousands: '.',
        grouping: [3],
        currency: ['', ' €']
    };

    const formatCurrency = hideCurrency ? '' : '$';
    const parsedPrecision = parseInt(precision, 10);

    const d3Format = formatDefaultLocale(currencyFormatDefinition).format(formatCurrency + ',.' + parsedPrecision + 'f');

    let newStyle = (!unicolor && parseFloat(children) < 0) ? {...style, color: colors.numberNegative} : {...style};

    if (onClick) {
        newStyle.cursor = 'pointer';
    }
    newStyle.whiteSpace = "nowrap";

    return (<span style={newStyle} onClick={onClick}>{d3Format(children)}</span>);

}


export default CurrencyAmount;
