import store from "../../../store";
import React from "react";
import {all, call, put, select} from "redux-saga/effects"
import * as selectors from "../../../reducer";
import {loadPlanning} from "./planning-functions";
import {CreatePlanningAction, EditPlanningAction} from "../actionTypes";

import i18n from "../../../i18n";
import {closeDrawerAction, openDrawerAction} from "../../Drawer/actions";
import PlanningFormConnected from "../../../components/PlanningForm/PlanningFormConnected";
import {companyUnitSelector, scenarioSelector, selectedCompanyUnitSelector} from "../../selectors";
import {selectedScenarioSelector} from "../../Scenarios/selectors";
import {FlaggedFeature} from "../../../flags";
import {featureWall} from "../../functions";

function* getPlanning(planningId: string) {
    let result: Planning = yield select(selectors.findPlanningById, planningId);
    if (!result) {
        result = yield call(loadPlanning, planningId);
    }
    return result;
}

let planningCreationCounter = 0;

function getPlanningModalUri(planningId?: string): string {
    return "planning://" + (
        undefined === planningId ? ("tmp/" + (++planningCreationCounter)) : planningId
    );
}

const renderTitle = (title: string, company: CompanyUnit, scenario: Scenario): JSX.Element => {
    return <div>
        {title}
        {company ? i18n.t("für Unternehmen") + ": " + company.name : null}
        {scenario ? <span style={{color: scenario.color}}> {i18n.t("scenario")}: {scenario.name}</span> : null}
    </div>
}

const onFinish = (drawerId: string) => () => store.dispatch(closeDrawerAction(drawerId));

export function* openCreatePlanningForm(action: CreatePlanningAction) {
    yield call(featureWall, FlaggedFeature.planning);

    const [companyUnit, scenario] = yield all([
        select(selectedCompanyUnitSelector),
        select(selectedScenarioSelector),
    ]);

    const drawerId = getPlanningModalUri();
    const dispatchAction = openDrawerAction({
        id: drawerId,
        title: renderTitle(i18n.t("create planning"), companyUnit, scenario),
        content: <PlanningFormConnected {...action.payload.scope} onFinish={onFinish(drawerId)}/>,
    });

    yield put(dispatchAction);
}

export function* openEditPlanningModal(action: EditPlanningAction) {
    yield call(featureWall, FlaggedFeature.planning);

    const planningId = action.payload.planningId;
    const planning: Planning = yield call(getPlanning, planningId);
    const [companyUnit, scenario] = yield all([
        select(companyUnitSelector, planning.companyUnitId),
        select(scenarioSelector, planning.scenarioId),
    ]);

    const drawerId = getPlanningModalUri(planningId);
    const dispatchAction = openDrawerAction({
        id: drawerId,
        title: renderTitle(i18n.t("edit planning"), companyUnit, scenario),
        content: <PlanningFormConnected planning={planning} onFinish={onFinish(drawerId)}/>,
    });

    yield put(dispatchAction);
}
