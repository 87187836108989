import {all, call, take, takeEvery} from "redux-saga/effects"
import * as actionTypes from "./actionTypes";
import * as planningActionTypes from "../Planning/actionTypes";
import {initializeStatements} from "./saga/initializeStatements";
import createStatementGroupRow from "./saga/createStatementGroupRow";
import renameStatementRow from "./saga/renameStatementRow";
import relocateStatementRow from "./saga/relocateStatementRow";
import deleteStatementRow from "./saga/deleteStatementRow";
import applyPlanning from "./saga/applyPlanning";

export default function* saga() {
    yield take(actionTypes.INITIALIZE_STATEMENTS);
    yield call(initializeStatements);
    yield all([
        yield takeEvery(actionTypes.STATEMENT_GROUP_ROW_CREATE, createStatementGroupRow),
        yield takeEvery(actionTypes.STATEMENT_ROW_RENAME, renameStatementRow),
        yield takeEvery(actionTypes.STATEMENT_ROW_RELOCATE, relocateStatementRow),
        yield takeEvery(actionTypes.STATEMENT_ROW_DELETE, deleteStatementRow),
        yield takeEvery(planningActionTypes.PLANNING_CREATED, applyPlanning),
        yield takeEvery(planningActionTypes.PLANNING_UPDATED, applyPlanning),
    ]);
}
