import axios from '../../../axios';
import {put} from "redux-saga/effects";

import * as actionTypes from '../actionTypes';
import * as RestV1 from "./RestV1Types";
import {LoadScenarioExclusionsAction} from "../actionTypes";
import tld from "../../../tld";

function* axiosRequestRemoteRestV1Scenarios(id) {
    return yield axios.get(tld(process.env.REACT_APP_URL_API_PLAN) + '/v1/scenarios/' + id + '/exclusions');
}

export default function* getScenarioExclusions(action: LoadScenarioExclusionsAction) {
    const scenarioId = action.payload;
    const { data }: RestV1.ScenarioExclusionsResponse = yield axiosRequestRemoteRestV1Scenarios(scenarioId);
    yield put({
        type: actionTypes.REDUCE_SCENARIO_EXCLUSIONS_LOAD,
        payload: {
            scenarioId,
            exclusions: data
        }
    });
}
