import store from "../../../store";
import debug from "../../Debug/functions/debug";
import {PostingChannel} from "../../../model/Posting/enums";
import getAxiosAuthorization from "../../../axios/functions/getAxiosAuthorization";
import {applyTimeSeriesStatsUpdateAction} from "../actions";
import {waitForStateReady} from "../../functions";
import trimEnd from "../../../model/String/trimEnd";
import tld from "../../../tld";

type RestV1TimeSeriesChannelStats = {
    lastSync?: string,
}

type RestV1TimeSeriesStats = {
    channels?: Partial<Record<PostingChannel, RestV1TimeSeriesChannelStats>>
}

function transformRemoteStats(remoteStats: RestV1TimeSeriesStats): TimeSeriesStats {
    const result: TimeSeriesStats = {};
    if (undefined !== remoteStats.channels) {
        result.channels = {};
        Object.keys(remoteStats.channels).forEach(channel => {
            const remoteChannelStats: RestV1TimeSeriesChannelStats = remoteStats.channels![channel];
            if (remoteChannelStats.lastSync) {
                result.channels![channel] = {
                    lastSync: new Date(remoteChannelStats.lastSync),
                };
            }
        });
    }
    return result;
}

let activeEventSource: EventSource|undefined = undefined;
let lastEventId: string|undefined;

function closeEventSource() {
    activeEventSource?.close();
}

function openEventSource() {
    const urlSearchParams = new URLSearchParams();
    const auth = getAxiosAuthorization();
    if (auth) {
        urlSearchParams.set('authorization', auth);
    }
    if (undefined !== lastEventId) {
        urlSearchParams.set('lastEventId', lastEventId);
    }
    const query = trimEnd("?" + urlSearchParams.toString(), "?");
    const url = `${tld(process.env.REACT_APP_URL_API_TIME_SERIES)}/v1/stats${query}`;
    const eventSource = new EventSource(url, {withCredentials: true});
    eventSource.onerror = (event) => {
        // this occurs regularly e.g. when the server closes the connection gracefully
        debug("Time series event source on error.", event);
    }
    eventSource.onopen = (event) => {
        debug("Time series event source on open.", event);
    }
    eventSource.onmessage = (event) => {
        debug("Time series event source on message.", event);
        lastEventId = event.lastEventId;
        const remoteStats = JSON.parse(event.data);
        store.dispatch(applyTimeSeriesStatsUpdateAction(transformRemoteStats(remoteStats)));
    }
    activeEventSource = eventSource;
    debug("Time series event source opened.", activeEventSource);
}

export default function* openTimeSeriesEventSource() {
    yield* waitForStateReady("auth");
    yield* waitForStateReady("accounting");
    closeEventSource();
    openEventSource();
}
