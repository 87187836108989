import {dateToIso8601, iso8601ToDate} from "../../../model/date";
import getTimeFrameStartDate from "../../../model/TimeFrame/functions/getTimeFrameStartDate";
import getTimeFrameEndDate from "../../../model/TimeFrame/functions/getTimeFrameEndDate";
import axios from "../../../axios";
import {put, select} from "redux-saga/effects";
import {loadCashFlowTransactions} from "../actions";
import findGroupByStatementRowId from "../../../components/TransactionTable/functions/findGroupByStatementRowId";
import {cashFlowStatementSelector} from "../../selectors";
import getFormGroups from "../../../components/PlanningForm/functions/getPlanningGroupsForStatement";
import {waitForStateReady} from "../../functions";
import tld from "../../../tld";

export function* loadBankTransactions(action) {
    waitForStateReady('statement')
    const cashFlowStatement = yield select(cashFlowStatementSelector);
    const {startDate, resolution, flow, accountIds, channels, statementRowId, statementId} = action.payload;
    let {endDate} = action.payload;
    const url = tld(process.env.REACT_APP_URL_API_TIME_SERIES) + "/v1/cashInBank/transactions";
    if (resolution?.density === 'month' && startDate.day !== undefined || endDate.day !== undefined) {
        endDate = {year: endDate.year, month: endDate.month}
    }
    const start = dateToIso8601(getTimeFrameStartDate(startDate));
    const end = dateToIso8601(getTimeFrameEndDate(endDate));
    const requestParams:any = {
        params: {
            start,
            end,
            accountIds: accountIds?.join(","),
            flow,
            channels: channels.join(","),
        }
    }
    if(statementId && statementRowId){
        requestParams.params.statementId = statementId;
        requestParams.params.statementRowId = statementRowId
    }
    const data = yield axios.get<RemoteTransaction[]>(url, requestParams).then((response) => {
        const transactions: Transaction[] = response.data.map(
            (remoteTransaction) => {
                return ({
                    id: remoteTransaction.id,
                    accountId: remoteTransaction.accountId,
                    date: iso8601ToDate(remoteTransaction.date),
                    amount: remoteTransaction.amount,
                    annotation: remoteTransaction.annotation,
                    categorizations: remoteTransaction.categorizations
                })
            }
        );
        return transactions
    })
    const groups = getFormGroups(cashFlowStatement)
    const dataWithCategorizationsLabels = data.map((t: Transaction) => {
        const updatedCategorizations = t.categorizations.map((category: TransactionCategorizations) => {
            const {statementRowId} = category;

            return {...category,
                statementRowId: {
                    ...statementRowId,
                    label: findGroupByStatementRowId(groups, statementRowId.user || statementRowId.system)?.label
                }
            }
        })
        return {
            ...t,
            categorizations: updatedCategorizations

        }
    })
    yield put(loadCashFlowTransactions(dataWithCategorizationsLabels))
}
