import React from "react";
import {useTranslation} from "react-i18next";
import debug from "../../../services/Debug/functions/debug";

import {Radio} from "antd";

import {PaymentTypeEnum} from "../../../model/Planning/Enums";

export type PaymentTypeSwitchProps = {
    value?: PaymentType,
    onChange?: (paymentType?: PaymentType) => void,
    options?: PaymentType[],
}

const PaymentTypeSwitch: React.VFC<PaymentTypeSwitchProps> = ({
    value,
    onChange = () => {},
    options = [PaymentTypeEnum.DISBURSEMENT, PaymentTypeEnum.DEPOSIT],
}) => {
    const {t} = useTranslation();

    debug("PaymentTypeSwitch::render()", options, value);

    const renderStatic = (paymentType: PaymentType) => {
        switch (paymentType) {
            case PaymentTypeEnum.DISBURSEMENT:
                return <span>{t("disbursement")}</span>;
            case PaymentTypeEnum.DEPOSIT:
                return <span>{t("deposit")}</span>;
            default:
                throw new Error("invalid payment " + paymentType);
        }
    }

    const labels = {
        [PaymentTypeEnum.DISBURSEMENT]: t("disbursement"),
        [PaymentTypeEnum.DEPOSIT]: t("deposit"),
    };

    const radios = options.map(paymentType =>
        <Radio.Button
            key={paymentType}
            value={paymentType}
        >{labels[paymentType]}</Radio.Button>
    );

    const renderRadio = (paymentType?: PaymentType) => {
        return <Radio.Group
            buttonStyle="solid"
            value={paymentType}
            onChange={(e) => {
                const paymentType = e.target.value;
                debug("PaymentTypeSwitch::onChange()", paymentType);
                onChange(paymentType);
            }}
        >{radios}</Radio.Group>;
    }

    if (1 === options.length) {
        if (undefined === value) {
            return null;
        } else {
            return renderStatic(value);
        }
    } else {
        return renderRadio(value);
    }
}

export default PaymentTypeSwitch;
