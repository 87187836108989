export default function findDebtorRowIdForSubGroupRowId(profile: CashFlowStatementProfile, subGroupRowId: string): string|null {
    if (profile.groupRowIdBySubGroupRowId[subGroupRowId]) {
        const groupRowId = profile.groupRowIdBySubGroupRowId[subGroupRowId];
        if (profile.debtorRowIdByGroupRowId[groupRowId]) {
            return profile.debtorRowIdByGroupRowId[groupRowId];
        }
    }
    
    return null;
}
