import {call, put, StrictEffect} from "redux-saga/effects"
import axios from "../../../axios";
import {errorAlert} from "../../UserAlert";
import {
    RestV1FinApiBankAccount,
    RestV1FinApiBankAccountsResponse,
    RestV1FinApiConnectionStatus, RestV1FinApiSyncStatus
} from "../../../scenes/Bank/components/FinApi/RestV1.types";
import {ConnectionStatus, SyncStatus} from "../../../scenes/Bank/components/enums";
import {BankReducerAction} from "../reducer";
import {featureWall} from "../../functions";
import {FlaggedFeature} from "../../../flags";
import tld from "../../../tld";

function* loadRemoteRestV1BankAccounts(): Generator<
    StrictEffect | Promise<RestV1FinApiBankAccountsResponse>,
    RestV1FinApiBankAccount[],
    RestV1FinApiBankAccountsResponse
> {
    try {
        const response = yield axios.get(tld(process.env.REACT_APP_URL_API_INTEGRATION) + "/v1/finApi/bankAccounts");
        return response.data || [];
    } catch (axiosError) {
        errorAlert("FinApi", axiosError, "load bank accounts");
        return [];
    }
}

function transformRemoteConnectionStatus(
    remoteConnectionStatus: RestV1FinApiConnectionStatus
): ConnectionStatus|undefined {
    switch (remoteConnectionStatus) {
        case RestV1FinApiConnectionStatus.ENABLED_ACTIVE:
            return ConnectionStatus.CONNECTED;
        case RestV1FinApiConnectionStatus.ENABLED_PAUSED:
            return ConnectionStatus.DISCONNECTED;
        case RestV1FinApiConnectionStatus.ENABLED_PERMISSION_EXPIRED:
            return ConnectionStatus.DISCONNECTED_AUTO;
        case RestV1FinApiConnectionStatus.DEAD:
            return ConnectionStatus.DISCONNECTED_GHOST;
        case RestV1FinApiConnectionStatus.DISABLED:
            return ConnectionStatus.DISABLED;
        case RestV1FinApiConnectionStatus.PENDING:
            return undefined;
        default:
            console.error("Remote connection status not known, bank account skipped: " + remoteConnectionStatus);
            return undefined;
    }
}

function transformRemoteSyncStatus(
    remoteConnectionStatus: RestV1FinApiSyncStatus
): SyncStatus|undefined {
    switch (remoteConnectionStatus) {
        case RestV1FinApiSyncStatus.SUCCESS:
            return SyncStatus.SUCCESS;
        case RestV1FinApiSyncStatus.FAILURE:
            return SyncStatus.FAILURE;
        case RestV1FinApiSyncStatus.PENDING:
            return SyncStatus.NEVER;
        case RestV1FinApiSyncStatus.PROGRESS:
            return SyncStatus.RUNNING;
        default:
            console.error("Remote sync status not known, bank account sync marked as failure: " + remoteConnectionStatus);
            return SyncStatus.FAILURE;
    }
}

function transformRemoteBankAccount(remoteBankAccount: RestV1FinApiBankAccount): BankAccount|undefined {
    const connectionStatus = transformRemoteConnectionStatus(remoteBankAccount.connectionStatus);
    if (connectionStatus) {
        const syncStatus = transformRemoteSyncStatus(remoteBankAccount.syncStatus);
        return {
            id: remoteBankAccount.id,
            isOwner: remoteBankAccount.isOwner,
            name: remoteBankAccount.accountName,
            iban: remoteBankAccount.iban,
            bic: remoteBankAccount.bank.bic,
            bankName: remoteBankAccount.bank.name,
            integrationId:remoteBankAccount.integrationId,
            latestSync: remoteBankAccount.latestSyncDateTime ? new Date(remoteBankAccount.latestSyncDateTime) : undefined,
            connectionStatus,
            syncStatus,
        };
    } else {
        return undefined;
    }
}

export default function* loadBankAccounts(): Generator<StrictEffect, void, RestV1FinApiBankAccount[]> {
    yield call(featureWall, FlaggedFeature.finApi);
    const bankAccounts: BankAccount[] = [];
    const remoteBankAccounts: RestV1FinApiBankAccount[] = yield call(loadRemoteRestV1BankAccounts);

    remoteBankAccounts.forEach(remoteBankAccount => {
        const bankAccount = transformRemoteBankAccount(remoteBankAccount);
        if (undefined !== bankAccount) {
            bankAccounts.push(bankAccount);
        }
    });

    yield put({
        type: BankReducerAction.REDUCE_BANK_ACCOUNTS,
        payload: {bankAccounts},
    });
}
