import {isMasterUserLogin} from "../../model/Auth/functions";
import {debugSelect} from "../Debug/functions";

export function isAuthStateInitialized(state: RootState): boolean {
    const result = state.auth.initialized;
    debugSelect("isAuthStateInitialized", result);
    return result;
}

export function isLoggedInSelector(state: RootState): boolean {
    const result = true === state.auth.login?.active;
    debugSelect("isLoggedInSelector", result);
    return result;
}

export function isMasterUserSelector(state: RootState): boolean {
    return !!state.auth.login && isMasterUserLogin(state.auth.login);
}

export function loginIdSelector(state: RootState): string {
    if (!state.auth.login?.id) {
        throw Error("Not logged in.");
    }
    return state.auth.login.id;
}
