import Bugsnag from "@bugsnag/js";
import {AxiosError} from "axios";

export default function notifyBugsnag(errorId: string, action: string|undefined, subject: any, error: Error|AxiosError) {
    if (process.env.REACT_APP_BUGSNAG_KEY) {
        const errorIdTail = " (" + errorId + ")";
        const payload = {action, subject, message: error.message, errorId};
        const errorTypeLabel = (error as AxiosError).isAxiosError ? "API" : "General";
        Bugsnag.notify({
            name: "User Alert (" + errorTypeLabel + " error): " + (action || "") + errorIdTail,
            message: JSON.stringify(payload)
        });
    }
}
