import Bugsnag from "@bugsnag/js";
import {all, put} from "redux-saga/effects";
import moment from "moment";
import * as actionTypes from "../../actionTypes";
import {setFlagsAction} from "../../../../flags";
import {Login} from "../../RestV1Types";
import i18n from "../../../../i18n";
import setAxiosAuthorization from "../../../../axios/functions/setAxiosAuthorization";
import {UserAnalytics} from "../../../UserAnalytics/functions";
import redirectToSignIn from "./redirectToSignIn";
import AuthBroadcast from "../../AuthBroadcast";
import {LogoutTrigger} from "../../enums";

const transform = (remoteLogin: Login): AuthLogin => ({
    id: remoteLogin.id,
    namespace: remoteLogin.namespace,
    expires: new Date(remoteLogin.expires),
    active: remoteLogin.status === "active",
    roles: remoteLogin.roles,
    userName: remoteLogin.userName,
    userEmail: remoteLogin.userEmail,
    userLocale: remoteLogin.userLocale,
    companyName: remoteLogin.companyName,
    isMultiUnit: remoteLogin.isMultiUnit,
    isMultiUnitAdmin: remoteLogin.isMultiUnit && !remoteLogin.companyUnitIds,
    companyUnitIds: remoteLogin.companyUnitIds,
    actorId: remoteLogin.actorId,
});

export default function* applyLogin(remoteLogin: Login|null) {
    if (remoteLogin) {
        if (process.env.REACT_APP_BUGSNAG_KEY) {
            Bugsnag.setUser(remoteLogin.actorId || undefined);
        }
        UserAnalytics.identify(remoteLogin.actorId, remoteLogin.id);
        setAxiosAuthorization(remoteLogin.http.authorization);
        if (remoteLogin.userLocale) {
            i18n.changeLanguage(remoteLogin.userLocale);
            moment.locale(remoteLogin.userLocale.substring(0,2))
        }
        const login = transform(remoteLogin);
        if (login.active) {
            AuthBroadcast.register(login);
            yield all([
                put({
                    type: actionTypes.REDUCE_LOGIN,
                    payload: login
                }),
                put(setFlagsAction({
                    multiUnit: !!remoteLogin?.isMultiUnit,
                }))
            ]);
            yield put({
                type: actionTypes.X_AUTH_CHANGED,
            });
        } else {
            redirectToSignIn(LogoutTrigger.TIMEOUT);
        }
    } else {
        redirectToSignIn();
    }
}
