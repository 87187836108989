import {PlanningFormValues} from "../PlanningForm";
import debug from "../../../services/Debug/functions/debug";
import store from "../../../store";
import {DirectAccountMode, PlanningComplexity, TransferAccountMode} from "../enums";
import {CashFlowRole} from "../../../model/CashFlow/enums";
import {selectedCompanyUnitSelector, standardAccountsSelector} from "../../../services/selectors";
import {PaymentTypeEnum} from "../../../model/Planning/Enums";

const getAccount = (
    accountValue: AccountingAccountDraft | null | undefined,
    cashFlowRole: CashFlowRole
): AccountingAccountDraft => {
    if (accountValue) {
        const accountId = accountValue.id;
        if (undefined === accountId) {
            return {
                label: accountValue.label,
                cashFlowRole
            };
        } else {
            const accounts = standardAccountsSelector(store.getState());
            if (undefined === accounts) {
                throw new Error("Account id selected without provided account registry: " + accountId);
            }
            if (undefined === accounts[accountId]) {
                throw new Error("Account id is not registered: " + accountId);
            }
            return accounts[accountId];
        }
    }
    throw new Error("Account is not set for cash flow role " + cashFlowRole);
}

const getAccountFormValue = (values: PlanningFormValues): AccountingAccountDraft => {
    const isCashIn = values.paymentType === PaymentTypeEnum.DEPOSIT;
    switch (values.planningComplexity) {
        case PlanningComplexity.TRANSFER:
            return isCashIn
                ? getAccount(values.depositAccount, CashFlowRole.DEPOSIT)
                : getAccount(values.disbursementAccount, CashFlowRole.DISBURSEMENT);
        case PlanningComplexity.DIRECT:
            if (undefined === values.directPlanning) {
                throw Error("No direct planning data available for direct planning");
            }
            const account = values.directPlanning.account;
            switch (values.directPlanning.mode) {
                case DirectAccountMode.CATEGORY:
                    return isCashIn
                        ? getAccount(account, CashFlowRole.DEPOSIT)
                        : getAccount(account, CashFlowRole.DISBURSEMENT);
                case DirectAccountMode.RECEIVABLE_PAYABLE:
                    return isCashIn
                        ? getAccount(account, CashFlowRole.RECEIVABLE)
                        : getAccount(account, CashFlowRole.PAYABLE);
                default:
                    throw new Error("Direct planning mode not known: " + values.directPlanning.mode);
            }
        default:
            throw new Error("Planning complexity not supported: " + values.planningComplexity);
    }
}

const getTransferAccountFormValue = (values: PlanningFormValues): AccountingAccountDraft|undefined => {
    const isCashIn = values.paymentType === PaymentTypeEnum.DEPOSIT;
    switch (values.planningComplexity) {
        case PlanningComplexity.TRANSFER:
            if (undefined === values.transferAccount) {
                throw Error("No transfer account set for transfer planning");
            }
            const account = values.transferAccount.account;
            switch (values.transferAccount.mode) {
                case TransferAccountMode.PARTICIPANT:
                    return isCashIn
                        ? getAccount(account, CashFlowRole.DEBTOR)
                        : getAccount(account, CashFlowRole.CREDITOR);
                case TransferAccountMode.OTHER:
                    return isCashIn
                        ? getAccount(account, CashFlowRole.RECEIVABLE)
                        : getAccount(account, CashFlowRole.PAYABLE);
                default:
                    throw new Error("Transfer account mode not supported: " + values.transferAccount.mode);
            }
        case PlanningComplexity.DIRECT:
            return undefined;
        default:
            throw new Error("Planning complexity not supported: " + values.planningComplexity);
    }
}

const makePlanningDraftByFormValues = (values: PlanningFormValues, originalPlanning?: Planning, scenario?: Scenario): PlanningDraft => {
    debug("makePlanningDraftByFormValues", values);
    if (undefined === values.paymentType) {
        throw new Error("Payment type not set when saving the planning.");
    }
    if (undefined === values.amountTotal.amount) {
        throw new Error("Amount not set when saving the planning.");
    }
    const result: PlanningDraft = {
        isEnabled: true,
        paymentType: values.paymentType,
        isReversal: values.isReversal,
        planningType: values.planningType,
        invoiceDate: values.invoiceDate.toDate(),
        paymentDate: values.paymentDate ? values.paymentDate.toDate() : values.invoiceDate.toDate(),
        account: getAccountFormValue(values),
        transferAccount: getTransferAccountFormValue(values),
        cashAccount: getAccount(values.cashAccount, CashFlowRole.CASH),
        amount: Math.round(values.amountTotal.amount * 100),
        probability: values.probability,
        companyUnitId: selectedCompanyUnitSelector(store.getState())?.id,
    };
    if (originalPlanning) {
        // #FEATURE-585 editing a base planning when a scenario is selected will technically create a new planning
        // instead of technically updating the current
        if (scenario) {
            const scenarioId = scenario.id;
            if (undefined === originalPlanning.scenarioId) {
                // new overwriting scenario planning
                result.scenarioId = scenarioId;
                result.scenarioBaseSourceId = originalPlanning.id;
            } else if (scenarioId === originalPlanning.scenarioId) {
                // editing a scenario planning
                result.scenarioId = originalPlanning.scenarioId;
                result.scenarioBaseSourceId = originalPlanning.scenarioBaseSourceId;
                result.id = originalPlanning.id;
            } else {
                // a planning from a different scenario
                result.scenarioId = scenarioId;
                result.scenarioBaseSourceId = originalPlanning.scenarioBaseSourceId;
            }
        } else { // editing planning
            result.id = originalPlanning.id;
        }
    } else { // new planning
        result.scenarioId = scenario?.id;
    }
    if (values.recurrence.active) {
        const [ period, unit ] = values.recurrence.interval.split(" ");
        switch (unit) {
            case "day":
            case "week":
            case "month":
            case "year":
                result.recurrence = {
                    period: parseInt(period),
                    unit,
                };
                break;
            default:
                throw new Error("Recurrence unit not known: " + unit);
        }
        if (values.recurrenceEnd.active) {
            result.recurrence.endDate = values.recurrenceEnd.date.toDate();
        }
        if (values.recurrenceIncrease.active) {
            result.recurrence.gradientAmount = values.recurrenceIncrease.gradientAmount;
            result.recurrence.growthRate = values.recurrenceIncrease.growthRate;
        }
    }
    if (undefined !== values.amountTotal.expression) {
        result.amountExpression = values.amountTotal.expression.trim();
    }
    if (undefined !== values.amountTotal.expression) {
        result.amountExpression = values.amountTotal.expression.trim();
    }
    if (undefined !== values.annotation) {
        result.annotation = values.annotation.trim();
    }
    if (undefined !== values.receiptNumber) {
        result.receiptNumber = values.receiptNumber.trim();
    }
    
    debug("makePlanningDraftByFormValues result", result);
    return result;
};

export default makePlanningDraftByFormValues;
