import {subAxios as axios} from "../../../axios";
import {call, put, select} from "redux-saga/effects";
import {loginIdSelector} from "../selectors";
import * as RestV1 from "../RestV1Types";
import applyLogin from "./functions/applyLogin";
import AuthBroadcast from "../AuthBroadcast";
import {timeoutLogout} from "../actions";
import {UserAnalytics} from "../../UserAnalytics/functions";
import tld from "../../../tld";

type LoginsResult = Generator<Promise<undefined | RestV1.Login>, undefined | RestV1.Login, undefined | RestV1.Login>;

function* axiosRequestRemoteRestV1Logins(loginId: string): LoginsResult {
    const url = tld(process.env.REACT_APP_URL_API_AUTH) + "/v1/logins/" + loginId;
    return yield axios.post<RestV1.Login>(url)
        .then(response => 200 === response.status ? response.data : undefined)
        // make it never fail
        .catch(() => undefined);
}

export default function* renewLogin() {
    const loginId = yield select(loginIdSelector);
    const login = yield axiosRequestRemoteRestV1Logins(loginId);
    if (undefined === login) {
        yield put(timeoutLogout());
    } else {
        if (AuthBroadcast.isLeader()) {
            AuthBroadcast.broadcastRefresh();
        }
        yield call(applyLogin, login);
        UserAnalytics.track('Login Refresh');
    }
}
