import URLSearchParams from "@ungap/url-search-params";
import axios from "axios";
import {AppMode} from "../model/App/enums";

export default function applyApplicationModeDefaultHeader() {
    const url = new URL(window.location.href);
    const params = new URLSearchParams(url.search);
    const appModeQueryParam = params.get('mode');
    if (appModeQueryParam === AppMode.PREFLIGHT) {
        axios.defaults.headers.common["Application-Mode"] = appModeQueryParam;
    }
}
