import { put, take, spawn } from 'redux-saga/effects'
import axios from '../../../axios';
import * as actionTypes from '../actionTypes';
import {legacyAlert} from "../../UserAlert";
import * as postingActions from '../../Posting/actions';
import * as RestV1 from './RestV1Types';
import { PostingChannel } from '../../../model/Posting/enums';
import {transformRestV1OpeningBalanceResource, transformRestV1PostingResource} from "./functions";
import {transformRestV1Stats} from "./functions/transformRestV1Stats";
import tld from "../../../tld";

// ------------- init accounting stats

function axiosRequestRemoteRestV1AccountingStats(): Promise<RestV1.RemoteStatsResponse> {
    return axios.get(tld(process.env.REACT_APP_URL_API_CONNECT) + '/v1/stats');
}

function* loadRemoteRestV1AccountingStats() {
    try {
        return yield axiosRequestRemoteRestV1AccountingStats();
    } catch (axiosError) {
        legacyAlert(axiosError, 'init accounting stats');
        return { data: {} };
    }
}

export function* initAccountingStats() {
    const { data } = yield loadRemoteRestV1AccountingStats();
    yield put({
        type: actionTypes.REDUCE_ACCOUNTING_STATS_INIT,
        payload: transformRestV1Stats(data)
    });
}

// ------------- init postings

function axiosRequestRemoteRestV1Postings(): Promise<RestV1.RemotePostingsResponse> {
    const url = tld(process.env.REACT_APP_URL_API_CONNECT) + '/v1/accounting/postings';
    return axios.get(url, {params:{channels: "accounting"}});
}

function* loadRemoteRestV1Postings() {
    try {
        return yield axiosRequestRemoteRestV1Postings();
    } catch (axiosError) {
        legacyAlert(axiosError, 'init postings');
        return { data: [] };
    }
}

function* initPostings() {
    const { data } = yield loadRemoteRestV1Postings();
    const postings: Posting[] = data.map(posting => transformRestV1PostingResource(posting, PostingChannel.ACCOUNTING));
    yield put(postingActions.postingsLoaded(postings, PostingChannel.ACCOUNTING));
}

// ------------- init accounts

function axiosRequestRemoteRestV1Accounts(): Promise<RestV1.RemoteAccountsResponse> {
    return axios.get(tld(process.env.REACT_APP_URL_API_CONNECT) + '/v1/accounting/accounts');
}

function* loadRemoteRestV1Accounts() {
    try {
        return yield axiosRequestRemoteRestV1Accounts();
    } catch (axiosError) {
        legacyAlert(axiosError, 'init accounting accounts');
        return { data: [] };
    }
}

export function* initAccounts() {
    const { data: accounts } = yield loadRemoteRestV1Accounts();
    let accountRegistry = {};
    for (let account of accounts) {
        accountRegistry[account.id] = account;
    }
    yield put({type: actionTypes.REDUCE_ACCOUNTING_ACCOUNTS_INIT, payload: accountRegistry});
}

// ------------- init opening balances

function axiosRequestRemoteRestV1OpeningBalances(): Promise<RestV1.RemoteAccountsResponse> {
    return axios.get(tld(process.env.REACT_APP_URL_API_CONNECT) + '/v1/accounting/openingCashBalances');
}

function* loadRemoteRestV1OpeningBalances() {
    try {
        return yield axiosRequestRemoteRestV1OpeningBalances();
    } catch (axiosError) {
        legacyAlert(axiosError, 'init opening balances');
        return { data: [] };
    }
}

export function* initOpeningBalances() {
    const { data } = yield loadRemoteRestV1OpeningBalances();
    const openingBalances: OpeningBalance[]
        = data.map(openingBalance => transformRestV1OpeningBalanceResource(openingBalance));
    yield put({type: actionTypes.REDUCE_ACCOUNTING_OPENING_BALANCES_INIT, payload: openingBalances});
}

// -------------

export default function* initAll() {
    yield take(actionTypes.ACCOUNTING_INIT);
    yield spawn(initAccountingStats);
    yield spawn(initPostings);
    yield spawn(initAccounts);
    yield spawn(initOpeningBalances);
}
