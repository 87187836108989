import React from "react";
import {Layout} from "antd";
import FooterNav from "../FooterNav";
import styles from "./AppFooter.module.css"

const AppFooter: React.VFC<AppLayoutSection> = ({
    className = ""
}) => <Layout.Footer className={`${className} ${styles.footer}`}><FooterNav/></Layout.Footer>;
export default AppFooter;

