import {range} from 'lodash';

export const amountsForPeriod = (
    cellTimeFrame: TimeFrame,
    existingValues: TimeSeriesDataPoint[],
    quickPlanningDataPoint: TimeSeriesDataPoint[],
    amount: number,
    period: number
): number[]|undefined => {
    const {month, year, day} = cellTimeFrame;

    // if the monthly view is active
    if (month && year && !day) {
        const cellDate = new Date(year, month - 1, 1);

        const dataPointsPerMonth = (ts: TimeSeriesDataPoint[]) => ts
            .reduce((acc, {timeFrame, value}) => {
                const {month, year} = timeFrame;
                const label = `${month}-${year}`;
                acc[label] = value;

                return acc;
            }, {});

        const existingValuesPerMonth = dataPointsPerMonth(existingValues);
        const existingQuickPlanningValuesPerMonth = dataPointsPerMonth(quickPlanningDataPoint);

        return range(0, period).map(v => {
            const d = new Date(
                cellDate.getFullYear(),
                cellDate.getMonth() + v,
                1
            );

            // JS Date start date at 0 so we add 1 to have their human readable number
            // and to be able to compare them with tbe timeframe values
            const label = `${d.getMonth() + 1}-${d.getFullYear()}`;

            const existingValue = existingValuesPerMonth[label] ?? 0;
            const existingQuickPlanningValue = existingQuickPlanningValuesPerMonth[label] ?? 0;
            const realPlanningValue = existingValue - existingQuickPlanningValue;

            return amount - realPlanningValue;
        });
    }

    return undefined;
}
