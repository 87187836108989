import React, {useCallback, useMemo, useState} from "react";
import debug from "../../services/Debug/functions/debug";

import Input from "antd/lib/input";
import "antd/lib/input/style/css";
import CurrencyAmount from "../CurrencyAmount";

const mexp = require("math-expression-evaluator");

// https://stackoverflow.com/questions/3446170/escape-string-for-use-in-javascript-regex
function escapeRegExp(string) {
    return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'); // $& means the whole matched string
}

const leftTrim = (value: string, char: string): string => {
    debug("leftTrim", value, char);
    const regex = new RegExp("^" + escapeRegExp(char) + "+");
    return value.replace(regex, "");
}

const withPrefix = (value: string, prefix: string): string => {
    debug("withPrefix", value, prefix);
    return prefix + leftTrim(value, prefix);
}

const localizeExpression = (expression: string): string => {
    // 59500/1.19 => 59500/1,19
    return expression.replace(/\./g, ",");
};

const delocalizeExpression = (expression: string): string => {
    // 59500/1,19 => 59500/1.19
    return expression.replace(/,/g, ".");
};

const asString = (value?: string|number): string => {
    return undefined === value ? "" : "" + value;
}

type FormulaInputProps = AntdFormInputProps<ExpressionAmount> & {
    onCancel?: () => void,
    onBlur?: () => void,
    onPressEnter?: () => void,
};

function calculateValue(nativeExpression: string): number|undefined {
    const result = mexp.eval(nativeExpression);
    return isFinite(result) ? Math.round(result * 100)/100 : undefined;
}

const FormulaInput: React.VFC<FormulaInputProps> = ({
    defaultValue= {},
    value,
    onChange = () => {},
    onCancel = () => {},
    onBlur = () => {},
    onPressEnter = () => {},
    ...inputProps
}) => {
    debug("FormulaInput::render()", defaultValue, value, inputProps);

    const renderValue = withPrefix(
        value
            ? asString(value.expression || value.amount)
            : asString(defaultValue.expression || defaultValue.amount)
        ,  "=");

    const [inputValue, setInputValue] = useState<string>(renderValue);

    const onInputChange = useCallback(
        event => {
            debug("FormulaInput::onInputChange()", event);
            const inputValue = event.target.value;
            if ("=" !== inputValue[0]) {
                onCancel();
                setInputValue(renderValue);
            } else {
                const expression = inputValue.replace(/[^0-9,()+\-*/]/g, "");
                setInputValue("=" + expression);
            }
        },
        [renderValue, setInputValue, onCancel]
    );

    const handleChange = useCallback(
        () => {
            if (inputValue !== value?.expression) {
                debug("FormulaInput::handleChange()", value, inputValue);
                const nativeExpression = delocalizeExpression(leftTrim(inputValue, "="));
                try {
                    const calculatedValue = calculateValue(nativeExpression);
                    onChange({
                        amount: calculatedValue,
                        expression: nativeExpression,
                    });
                } catch (e) {
                    onChange({
                        amount: undefined,
                        expression: nativeExpression,
                    });
                }
            } else {
                debug("FormulaInput::handleChange() skipped", value, inputValue);
            }
        },
        [value, inputValue, onChange]
    );

    const onInputBlur = useCallback(
        () => {
            handleChange();
            onBlur();
        },
        [onBlur, handleChange]
    );

    const onInputKeyDown = useCallback(
        e => {
            debug("FormulaInput::onInputKeyDown()", e.key);
            switch (e.key) {
                case "Enter":
                    handleChange();
                    onPressEnter();
                    break;
                case "Escape":
                    e.stopPropagation();
                    onCancel();
                    break;
                default:
            }
        },
        [handleChange, onPressEnter, onCancel]
    );

    const infoSuffix = useMemo(
        () => {
            const nativeExpression = delocalizeExpression(leftTrim(inputValue, "="));
            debug("FormulaInput::infoSuffix()", inputValue, nativeExpression);
            try {
                const calculatedValue = calculateValue(nativeExpression);
                return calculatedValue
                    ? <CurrencyAmount style={{marginLeft:10}} unicolor>{calculatedValue}</CurrencyAmount>
                    : null;
            } catch (e) {
                return null;
            }
        },
        [inputValue]
    );

    return <><Input
        {...inputProps}
        defaultValue={localizeExpression(renderValue)}
        value={inputValue}
        onChange={onInputChange}
        onKeyDown={onInputKeyDown}
        onBlur={onInputBlur}
    />{infoSuffix}</>;
};

export default FormulaInput;
