import {StrictEffect} from "redux-saga/effects"
import axios from "../../../axios";
import {legacyAlert} from "../../UserAlert";
import PropTypes from "prop-types";
import {StatementRestV1RowPropType} from "../../../model/Statement/PropTypes";
import {List} from "immutable";
import * as RestV1 from "./RestV1Types";
import {transformStatementRowResource} from "./functions";
import tld from "../../../tld";

function axiosRequestRemoteRestV1Statements(): Promise<RestV1.StatementsResponse> {
    const url = tld(process.env.REACT_APP_URL_API_CONNECT) + "-statement/v1/statements";
    return axios.get(url, {
        withCredentials: true,
        headers: {
            "Accept": "application/json",
            "Content-Type": "application/json"
        }
    });
}

function* loadRemoteRestV1Statements(): Generator<
    StrictEffect | Promise<RestV1.StatementsResponse>,
    RestV1.StatementsResponse,
    RestV1.StatementsResponse
> {
    try {
        return yield axiosRequestRemoteRestV1Statements();
    } catch (axiosError) {
        legacyAlert(axiosError, "load statements");
        return {data: []};
    }
}

function axiosRequestRemoteRestV1StatementRows(statementId: string): Promise<RestV1.StatementRowsResponse> {
    const url = tld(process.env.REACT_APP_URL_API_STATEMENT) + "/v1/statements/" + statementId + "/rows";
    return axios.get(url, {
        withCredentials: true,
        headers: {
            "Accept": "application/json",
            "Content-Type": "application/json"
        }
    });
}

function* loadRemoteRestV1StatementRows(statementId: string): Generator<
    StrictEffect | Promise<RestV1.StatementRowsResponse>,
    RestV1.StatementRowsResponse,
    RestV1.StatementRowsResponse
> {
    try {
        return yield axiosRequestRemoteRestV1StatementRows(statementId);
    } catch (axiosError) {
        legacyAlert(axiosError, "load statement rows");
        return {data: []};
    }
}

function transformStatementResource(
    statementResource: RestV1.Statement,
    statementRowResources: RestV1.StatementRow[]
): Statement {
    const statementId = statementResource.id;
    const rowsMutable = List<StatementRow>().asMutable();
    const defaultCollapseLevel = 1;
    statementRowResources.forEach(restV1StatementRow => {
        PropTypes.checkPropTypes(
            StatementRestV1RowPropType.isRequired,
            restV1StatementRow,
            "prop",
            "Statement/Saga::initRestV1Statement"
        );
        let row: StatementRow = transformStatementRowResource(restV1StatementRow, statementId, defaultCollapseLevel);
        rowsMutable.push(row);
    });
    const rows: List<StatementRow> = rowsMutable.asImmutable();
    return {
        id: statementId,
        label: statementResource.label,
        profileType: statementResource.profileType,
        rows,
        defaultCollapseLevel
    };
}

export function* loadRemoteStandardStatements(): Generator<
    any,
    Statement[],
    any
> {
    const statementsResponse: RestV1.StatementsResponse = yield* loadRemoteRestV1Statements();
    const result: Statement[] = [];
    for (let statementResource of statementsResponse.data) {
        const statementRowsResponse: RestV1.StatementRowsResponse
            = yield* loadRemoteRestV1StatementRows(statementResource.id);
        result.push(transformStatementResource(statementResource, statementRowsResponse.data));
    }
    return result;
}
