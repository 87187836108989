import axios from '../../../axios';
import {all, put} from "redux-saga/effects";

import * as actionTypes from '../actionTypes';
import * as RestV1 from "./RestV1Types";
import {loadScenarioExclusions} from "../actions";
import tld from "../../../tld";


function* axiosRequestRemoteRestV1Scenarios() {
    return yield axios.get(tld(process.env.REACT_APP_URL_API_PLAN) + '/v1/scenarios');
}

export default function* getScenarios() {
    const {data}: RestV1.ScenarioDataResponse = yield axiosRequestRemoteRestV1Scenarios();

    yield put({
        type: actionTypes.REDUCE_SCENARIOS_LOADED,
        payload: data
    });

    const exclusions = data.map(scenario => put(loadScenarioExclusions(scenario.id)))
    yield all(exclusions)
}
