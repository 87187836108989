type DragDropState = {
    draggingItemId: string,
    isTargetValid: boolean
}

const dragDropState: {
    [viewId: string]: DragDropState
} = {};

export function dragEnd(dragDropViewId): void {
    delete dragDropState[dragDropViewId];
}

export function dragUpdate(dragDropViewId: string, draggingItemId: string, isTargetValid: boolean): void {
    if (!dragDropState[dragDropViewId]
        || dragDropState[dragDropViewId].draggingItemId !== draggingItemId
        || dragDropState[dragDropViewId].isTargetValid !== isTargetValid
    ) {
        dragDropState[dragDropViewId] = { draggingItemId, isTargetValid };
    }
}

export function findDragDropStateForItem(dragDropViewId: string, draggingItemId: string): DragDropState|undefined {
    return dragDropState[dragDropViewId] && draggingItemId === dragDropState[dragDropViewId].draggingItemId
        ? dragDropState[dragDropViewId] : undefined;
}
