export function dateToIso8601(date: Date): string {
    const dateParts = [
        date.getFullYear().toString(),
        (date.getMonth() + 1).toString().padStart(2, '0'),
        date.getDate().toString().padStart(2, '0')
    ];
    return dateParts.join('-');
}

export function iso8601ToDate(iso8601: string): Date {
    if (null === iso8601.match(/^\d{4}-\d{2}-\d{2}$/)) {
        throw new Error('Value is not an ISO 8601 date: ' + iso8601);
    }
    const [year, month, day] = iso8601.split('-');
    return new Date(Date.UTC(parseInt(year), parseInt(month) - 1, parseInt(day)));
}

/**
 * Returns YYYY-MM from YYYY-MM-DD.
 * @param {String} date
 */
export function yearMonthFromDateString(date: string): string {
    return date.slice(0, date.length - 3);
}

/**
 * Returns YYYY-MM from YYYY-MM-DD.
 * @param {Date} date
 */
export function yearMonthFromDate(date: Date): string {
    return date.getFullYear() + '-' + ('0' + (date.getMonth() + 1)).slice(-2);
}

/**
 * Returns YYYY from YYYY-MM-DD.
 * @param {String} date
 */
export function yearFromDateString(date: string): string {
    return date.slice(0, 4);
}

/**
 * Returns YYYY from YYYY-MM-DD.
 * @param {Date} date
 */
export function yearFromDate(date: Date): string {
    return date.getFullYear() + '';
}

export function dateToLocaleDateString(date: Date): string {
    return date.toLocaleString(undefined, {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
    });
}

export function dateToLocaleDateTimeString(date: Date): string {
    return date.toLocaleString(undefined, {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit",
    });
}
