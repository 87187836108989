import {timeFrameToIsoString, timeFrameToTimeCode} from "../../../model/TimeFrame/functions";

function* deepReadRows(rows: StatementDeepTimeSeriesRow[]): Generator<StatementDeepTimeSeriesRow, void, void> {
    for (let row of rows) {
        yield row;
        if (row.rows) {
            yield *deepReadRows(row.rows);
        }
    }
}

function transformDataPointsToSparseTimeSeries(resolution: TimeSeriesResolution, dataPoints: TimeSeriesDataPoint[]): SparseTimeSeries {
    const timeCodes: number[] = [];
    const isoCodes: string[] = [];
    const values: number[] = [];
    for (let dataPoint of dataPoints) {
        const timeFrame = dataPoint.timeFrame;
        timeCodes.push(timeFrameToTimeCode(timeFrame));
        isoCodes.push(timeFrameToIsoString(timeFrame));
        values.push(dataPoint.value);
    }
    return {resolution, timeCodes, isoCodes, values};
}

/**
 * @deprecated for slim client
 */
export function buildSparseTimeSeriesMap(deepTimeSeriesRowSet?: StatementDeepTimeSeriesRowSet): SparseTimeSeriesMap {
    const result = {};
    if (deepTimeSeriesRowSet) {
        for (let row of deepReadRows(deepTimeSeriesRowSet.rows)) {
            if (row.dataPoints) {
                result[row.id] = transformDataPointsToSparseTimeSeries(deepTimeSeriesRowSet.timeSeriesResolution, row.dataPoints);
            }
        }
    }
    return result;
}
