import * as actionTypes from './actionTypes';
import PropTypes from 'prop-types';
import { PlanningPropType } from '../../model/Planning/PropTypes';
import { TimeRangePropType } from '../../model/TimeSeries/PropTypes';
import { mergeTimeRanges } from "../../model/TimeRange";
const shallowequal = require('shallowequal');

function getPlanningIndexById(planningId: string, plannings: Planning[]): number
{
    for (let i = 0; i < plannings.length; i++) {
        if (planningId === plannings[i].id) {
            return i;
        }
    }
    throw new Error("Planning not found: " + planningId);
}

function getStateWithPlannings(state: PlanningState, plannings: Planning[]): PlanningState {
    let newPlanningsById = { ...state.planningsById };
    let newPlannings = [ ...state.plannings ];
    let firstPostingDate: Date|undefined = state.stats.firstPostingDate;
    plannings.forEach(planning => {
        const planningId = planning.id;
        if (undefined === newPlanningsById[planningId]) {
            newPlanningsById[planningId] = planning;
            newPlannings.push(planning);
        } else if (!shallowequal(planning, newPlanningsById[planningId])) {
            const index = getPlanningIndexById(planningId, newPlannings);
            newPlanningsById[planningId] = planning;
            newPlannings[index] = planning;
        }
        //
        const firstPlanningDate = planning.invoiceDate < planning.paymentDate ? planning.invoiceDate : planning.paymentDate;
        if (!firstPostingDate || firstPostingDate > firstPlanningDate) firstPostingDate = firstPlanningDate;
    });
    return {
        ...state,
        planningsById: newPlanningsById,
        plannings: newPlannings,
        stats: {
            ...state.stats,
            firstPostingDate
        }
    };
}

function getStateWithoutPlanning(state: PlanningState, planningId: string): PlanningState {
    if (undefined === state.planningsById[planningId]) {
        return state;
    } else {
        const index = getPlanningIndexById(planningId, state.plannings);
        let newPlanningsById = { ...state.planningsById };
        delete newPlanningsById[planningId];
        let newPlannings = [ ...state.plannings ];
        newPlannings.splice(index, 1);
        return {
            ...state,
            planningsById: newPlanningsById,
            plannings: newPlannings,
        };
    }
}

export default function reducer(state: PlanningState = {
    visible: true,
    planningsById: {},
    plannings: [],
    range: undefined,
    stats: {},
}, action) {
    switch (action.type) {
        case actionTypes.REDUCE_PLANNING_VISIBILITY_TOGGLED: {
            return action.payload !== state.visible ? {
                ...state,
                visible: action.payload,
            } : state;
        }
        case actionTypes.REDUCE_PLANNINGS_LOADED: {
            PropTypes.checkPropTypes(
                {
                    plannings: PropTypes.arrayOf(PlanningPropType).isRequired,
                    range: TimeRangePropType.isRequired
                },
                action.payload,
                'args',
                action.type
            );
            const plannings: Planning[] = action.payload.plannings;
            const range: TimeRange = action.payload.range;

            return {
                ...getStateWithPlannings(state, plannings),
                range: undefined === state.range ? range : mergeTimeRanges(state.range, range),
            };
        }
        case actionTypes.REDUCE_PLANNING_LOADED: {
            PropTypes.checkPropTypes(
                {
                    payload: PlanningPropType.isRequired,
                },
                action,
                'args',
                action.type
            );
            const plannings: Planning[] = [ action.payload ];
            return getStateWithPlannings(state, plannings);
        }
        case actionTypes.REDUCE_PLANNING_DELETED: {
            PropTypes.checkPropTypes(
                {
                    payload: PropTypes.string.isRequired,
                },
                action,
                'args',
                action.type
            );
            const planningId: string = action.payload;
            return getStateWithoutPlanning(state, planningId);
        }
        default: {
            return state;
        }
    }
}


// -----------------------------------------------------------------------------
// PUBLIC SELECTORS
// -----------------------------------------------------------------------------

export function findPlanningById(state: PlanningState, planningId: string): Planning|null {
    return state.planningsById[planningId];
}

export function findPlanningRange(state: PlanningState): TimeRange|undefined {
    return state.range;
}

export function getPlannings(state: PlanningState): Planning[] {
    return state.plannings;
}

export function findFirstPostingDate(state: PlanningState): Date|null {
    return state.stats.firstPostingDate ? state.stats.firstPostingDate : null;
}
