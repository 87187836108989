import {AxiosError} from "axios";
import {generateShortId} from "../../../model/shortId";
import track from "../../UserAnalytics/track";
import notifyBugsnag from "./notifyBugsnag";
import showError from "./showError";

export default function errorAlert(
    featureName: string,
    error: Error|AxiosError,
    actionName: string,
    actionContext: object = {},
    componentName?: string,
) {
    const errorId = generateShortId();
    notifyBugsnag(errorId, actionName, actionContext, error);
    track(
        featureName,
        "Error Alert",
        {errorId, triggerError: error.message, triggerAction: actionName},
        componentName
    );
    showError(errorId);
}
