import React, {useCallback, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import debug from "../../../services/Debug/functions/debug";

import {Input, Select} from "antd";

import {PlanningMode, TransferAccountMode} from "../enums";
import SelectReceivableAccount from "../../SelectAccount/SelectReceivableAccount";
import SelectPayableAccount from "../../SelectAccount/SelectPayableAccount";
import SelectDebtorAccount from "../../SelectAccount/SelectDebtorAccount";
import SelectCreditorAccount from "../../SelectAccount/SelectCreditorAccount";
import {PlanningMetaAccounts} from "../PlanningForm";

export type TransferAccountSelectValue = {
    mode: TransferAccountMode,
    account?: AccountingAccountDraft,
}

type TransferAccountSelectProps = {
    onChange?: (value?: TransferAccountSelectValue) => void,
    value?: TransferAccountSelectValue,
    accountOptions?: AccountRegistry,
    planningMode: PlanningMode,
    planningAccounts?: PlanningMetaAccounts,
}

type AccountSelection = Record<PlanningMode, Record<TransferAccountMode, AccountingAccountDraft|undefined>>;

const defaultAccountSelection: AccountSelection = {
    receivable: {
        participant: undefined,
        other: undefined,
    },
    payable: {
        participant: undefined,
        other: undefined,
    },
};

const reduceToAccountSelection = (
    selection: AccountSelection,
    planningAccounts: PlanningMetaAccounts
): AccountSelection => {
    const selectionUpdate: Partial<AccountSelection> = {};
    if (undefined !== planningAccounts.debtorAccount
        && selection.receivable.participant !== planningAccounts.debtorAccount
    ) {
        selection.receivable.participant = planningAccounts.debtorAccount;
    }
    if (undefined !== planningAccounts.receivableAccount
        && selection.receivable.other !== planningAccounts.receivableAccount
    ) {
        selection.receivable.other = planningAccounts.receivableAccount;
    }
    if (undefined !== planningAccounts.creditorAccount
        && selection.payable.participant !== planningAccounts.creditorAccount
    ) {
        selection.payable.participant = planningAccounts.creditorAccount;
    }
    if (undefined !== planningAccounts.payableAccount
        && selection.payable.other !== planningAccounts.payableAccount
    ) {
        selection.payable.other = planningAccounts.payableAccount;
    }
    return (Object.keys(selectionUpdate).length > 0)
        ? {...selection, ...selectionUpdate}
        : selection;
};

const TransferAccountSelect: React.VFC<TransferAccountSelectProps> = ({
    value,
    onChange = () => {},
    accountOptions,
    planningMode,
    planningAccounts = {},
}) => {
    debug("TransferAccountSelect::render()", value, accountOptions, planningMode, planningAccounts);

    const {t} = useTranslation();
    const [mode, setMode] = useState<TransferAccountMode>(value?.mode || TransferAccountMode.PARTICIPANT);
    const [accountSelection, setAccountSelection] = useState<AccountSelection>(defaultAccountSelection);

    debug("TransferAccountSelect state", mode, accountSelection);

    const participantLabel = PlanningMode.RECEIVABLE === planningMode ? t("customer") : t("vendor");

    const currentAccount: AccountingAccountDraft|undefined = accountSelection[planningMode][mode];

    const setCurrentAccount = useCallback(
        (account: AccountingAccountDraft|undefined) => {
            const newAccountSelection = {
                ...accountSelection,
                [planningMode]: {
                    ...accountSelection[planningMode],
                    [mode]: account,
                }
            }
            setAccountSelection(newAccountSelection);
        },
        [accountSelection, mode, planningMode]
    );

    useEffect(() => {
        if (undefined !== value) {
            if (value.mode !== mode) {
                setMode(value.mode);
            }
            if (value.account !== currentAccount) {
                setCurrentAccount(value.account);
            }
        }
        const newAccountSelection = reduceToAccountSelection(accountSelection, planningAccounts);
        if (newAccountSelection !== accountSelection) {
            setAccountSelection(newAccountSelection);
        }
    }, [value, accountSelection, currentAccount, mode, planningAccounts, setCurrentAccount]);

    const onSelectMode = (mode: TransferAccountMode) => {
        debug("TransferAccountSelect::onSelectMode()", mode);
        setMode(mode);
        const account = accountSelection[planningMode][mode];
        onChange({mode, account});
    };

    const onSelectAccount = (account: AccountingAccountDraft|undefined) => {
        debug("TransferAccountSelect::onSelectAccount()", account);
        if (currentAccount !== account) {
            setCurrentAccount(account);
            onChange({mode, account});
        }
    };

    const renderAccountSelector = () => {
        switch (planningMode) {
            case PlanningMode.PAYABLE:
                switch (mode) {
                    case TransferAccountMode.OTHER: {
                        const value = accountSelection[planningMode][mode];
                        debug("TransferAccountSelect::renderAccountSelector()", "SelectPayableAccount", value);
                        return <SelectPayableAccount
                            width="70%"
                            value={value}
                            onChange={(account?: AccountingAccountDraft) => onSelectAccount(account)}
                            accounts={accountOptions}
                        />;
                    }
                    case TransferAccountMode.PARTICIPANT:
                    default: {
                        const value = accountSelection[planningMode][TransferAccountMode.PARTICIPANT];
                        debug("TransferAccountSelect::renderAccountSelector()", "SelectCreditorAccount", value);
                        return <SelectCreditorAccount
                            width="70%"
                            value={value}
                            onChange={(account?: AccountingAccountDraft) => onSelectAccount(account)}
                            accounts={accountOptions}
                        />;
                    }
                }
            case PlanningMode.RECEIVABLE:
            default:
                switch (mode) {
                    case TransferAccountMode.OTHER: {
                        const value = accountSelection[PlanningMode.RECEIVABLE][mode];
                        debug("TransferAccountSelect::renderAccountSelector()", "SelectReceivableAccount", value);
                        return <SelectReceivableAccount
                            width="70%"
                            value={value}
                            onChange={(account?: AccountingAccountDraft) => onSelectAccount(account)}
                            accounts={accountOptions}
                        />;
                    }
                    case TransferAccountMode.PARTICIPANT:
                    default: {
                        const value = accountSelection[PlanningMode.RECEIVABLE][TransferAccountMode.PARTICIPANT];
                        debug("TransferAccountSelect::renderAccountSelector()", "SelectDebtorAccount", value);
                        return <SelectDebtorAccount
                            width="70%"
                            value={value}
                            onChange={(account?: AccountingAccountDraft) => onSelectAccount(account)}
                            accounts={accountOptions}
                        />;
                    }
                }
        }
    };

    return <><Input.Group compact>
        <Select
            className={undefined === value?.mode ? undefined : "suppress-error"}
            style={{ width: "30%" }}
            value={mode}
            onChange={onSelectMode}
        >
            <Select.Option value={TransferAccountMode.PARTICIPANT}>{participantLabel}</Select.Option>
            <Select.Option
                value={TransferAccountMode.OTHER}>{t("other")}</Select.Option>
        </Select>
        {renderAccountSelector()}
    </Input.Group></>
}

export default TransferAccountSelect;
