import store from "../../store";
import {
    dragStatementRow,
    dropStatementRow,
    reduceStatementError,
    relocateStatementRow
} from "../../services/Statement/actions";
import {getStatementRow} from "../../reducer";
import {parseStatementUri} from "../StatementUri/parseStatementUri";
import {RowLinkRelation, StatementUriExtension} from "../StatementUri/enums";
import {getDragDropListView} from "./DragDropViewRegistry";
import {StatementRowType} from "../Statement";
import {List} from "immutable";
import {verifyTransition} from "../StatementUri/verifyTransition";
import {dragEnd, dragUpdate} from "./DragDropStateManager";
import {StatementRowRelocateAction} from "../../services/Statement/actionTypes";
import {Combine, OnDragEndResponder} from "react-beautiful-dnd";
import {findQuickPlanningRow} from "../Statement/findChildRow";
import {isQuickPlanningRow} from "../Statement/Profile/rowQualifier";
import debug from "../../services/Debug/functions/debug";

export const onBeforeCapture = async (event) => {
    debug('onBeforeCapture', event);

    // draggableId: "statement://961798c3-1799-4257-8675-b5fb4e07c26f//g:7f292db6-f917-47db-be40-12554cc038bd"
    // mode: "FLUID"
    //
    const subjectLocation = parseStatementUri(event.draggableId);
    if (subjectLocation && subjectLocation.row) {
        // dnd snapshot based handling is not sufficient
        // cause drag drop view must be updated before rendering
        // to actual collapse dragging row and apply collapsing margins
        await store.dispatch(dragStatementRow(subjectLocation.statement.id, subjectLocation.row.id));
    }
};

function isQuickPlanningUri(targetUri: string | undefined, state, statementId: string) {
    let isTargetQuickPlanning = false;
    if (targetUri) {
        const targetLocation = getRowLocationFromUri(targetUri);
        const targetRow = getStatementRow(state, statementId, targetLocation.id);

        isTargetQuickPlanning = isQuickPlanningRow(targetRow);
    }
    return isTargetQuickPlanning;
}

export const onDragUpdate = async (result) => {
    debug('onDragUpdate', result);

    // - drop-in
    // draggableId: "statement://961798c3-1799-4257-8675-bbfb4e07c26f/g:80a7b1ba-4401-4153-b69b-97a70278254d/g:37b78bbf-bf98-4156-8d99-65454e04a51e"
    // type: "DEFAULT"
    // source: {droppableId: "statement://961798c3-1799-4257-8675-bbfb4e07c26f", index: 6}
    // mode: "FLUID"
    // combine: null
    // destination: {droppableId: "statement://961798c3-1799-4257-8675-bbfb4e07c26f", index: 5}
    //
    // - combine
    // draggableId: "statement://961798c3-1799-4257-8675-bbfb4e07c26f/g:80a7b1ba-4401-4153-b69b-97a70278254d/g:d8574f21-eeda-4b38-9456-b6bb8e218b1b"
    // type: "DEFAULT"
    // source: {droppableId: "statement://961798c3-1799-4257-8675-bbfb4e07c26f", index: 6}
    // mode: "FLUID"
    // combine: {draggableId: "statement://961798c3-1799-4257-8675-bbfb4e07c26f/g…a70278254d/g:37b78bbf-bf98-4156-8d99-65454e04a51e", droppableId: "statement://961798c3-1799-4257-8675-bbfb4e07c26f"}
    // destination: null
    //
    const subjectLocation = parseStatementUri(result.draggableId);
    if (subjectLocation && subjectLocation.row) {
        const subjectRowLocation = subjectLocation.row;
        const statementLocation = subjectLocation.statement;
        const statementId = statementLocation.id;
        const {destination, source, combine} = result;
        const targetLocation = destination ? parseStatementUri(destination.droppableId) : null;

        if (targetLocation) {
            if (targetLocation.row) {
                throw Error("Logic error. A drag and drop target must not be a row.");
            }
            if (targetLocation.statement.uri !== statementLocation.uri) {
                throw Error("Logic error. Cross statement drag and drop is not supported.");
            }
        }

        const dragDropViewId = statementLocation.uri;
        const subjectPosition = source.index;
        const destinationPosition = destination ? destination.index : undefined;
        if (subjectPosition === destinationPosition) {
            dragUpdate(dragDropViewId, result.draggableId, true);
        } else {
            const lookupDragDropView = getDragDropViewWithoutPosition(dragDropViewId, subjectPosition);
            let isTransitionValid = false;
            //  On Combine
            if (null === targetLocation) {
                if (combine) {
                    const combineLocation = parseStatementUri(result.combine.draggableId);
                    if (combineLocation && combineLocation.row) {
                        const combineRowLocation = combineLocation.row;
                        if (StatementUriExtension.AFTER === combineRowLocation.extension) {
                            isTransitionValid = verifyTransition(subjectLocation.row.uri, combineRowLocation.uri, RowLinkRelation.PREDECESSOR);
                        } else {
                            isTransitionValid = verifyTransition(subjectLocation.row.uri, combineRowLocation.uri, RowLinkRelation.PARENT);
                        }
                    }
                }
            } else {
                let destinationReference: StatementRowLink | undefined;
                const state = store.getState();

                const targetUri: string | undefined = lookupDragDropView.dragDropUris[destinationPosition];
                let isTargetQuickPlanning = isQuickPlanningUri(targetUri, state, statementId);

                const targetPredecessorUri: string | undefined = lookupDragDropView.dragDropUris[destinationPosition - 1];

                if (isTargetQuickPlanning) {
                    isTransitionValid = false;
                } else {
                    if (targetPredecessorUri) {
                        const state = store.getState();

                        const targetPredecessorLocation = getRowLocationFromUri(targetPredecessorUri);
                        const targetPredecessorRow: StatementRow = getStatementRow(state, statementId, targetPredecessorLocation.id);
                        const visibleTargetPredecessorSubRows = getVisibleSubRows(targetPredecessorRow, lookupDragDropView);

                        const isParent = StatementUriExtension.AFTER !== targetPredecessorLocation.extension
                            && visibleTargetPredecessorSubRows.size
                            && StatementRowType.GROUP === targetPredecessorRow.type
                            && !targetPredecessorRow.isCollapsed;
                        destinationReference = {
                            id: targetPredecessorLocation.id,
                            relation: isParent ? RowLinkRelation.PARENT : RowLinkRelation.PREDECESSOR
                        };
                    }

                    isTransitionValid = verifyTransition(
                        subjectRowLocation.uri,
                        targetPredecessorUri ? targetPredecessorUri : statementLocation.uri,
                        destinationReference ? destinationReference.relation : RowLinkRelation.PARENT
                    );
                }
            }

            dragUpdate(dragDropViewId, result.draggableId, isTransitionValid);
        }
    }
};

function doCombine(
    combine: Combine,
    subjectLocation: StatementUriLocationReference,
    statementId: string,
    subjectRowLocation: StatementUriRowLocation
): StatementRowRelocateAction | undefined {
    const combineLocation = parseStatementUri(combine.draggableId);

    debug('combineLocation', combineLocation);
    if (!combineLocation || !combineLocation.row || !subjectLocation?.row?.uri) {
        return undefined;
    }

    const {extension, id, uri} = combineLocation.row

    let relation = StatementUriExtension.AFTER === extension ? RowLinkRelation.PREDECESSOR : RowLinkRelation.PARENT;

    if (verifyTransition(subjectLocation.row.uri, uri, relation)) {
        const state = store.getState();

        const isTopLevelGroup = combineLocation.row.level === 1 && combineLocation.row.type === StatementRowType.GROUP;
        let destinationId = id;

        if (isTopLevelGroup) {
            const combineLocationRow: StatementRow = getStatementRow(state, statementId, combineLocation.row.id);
            const qp = findQuickPlanningRow(combineLocationRow.rows?.toArray() || []);

            if (qp) {
                destinationId = qp.id;
                relation = RowLinkRelation.PREDECESSOR;
            }
        }

        debug('relocate', destinationId, relation);
        return relocateStatementRow(
            statementId,
            subjectRowLocation.id,
            {
                id: destinationId,
                relation
            }
        );
    }
}

export const onDragEnd: OnDragEndResponder = async (props) => {
    debug('onDragEnd', props);
    const {reason, draggableId, destination, source, combine} = props;
    // - drop-in:
    // draggableId: "statement://961798c3-1799-4257-8675-b5fb4e07c26f/g:80a7b1ba-4401-4153-b69b-97a70278254d/g:7f292db6-f917-47db-be40-12554cc038bd"
    // type: "DEFAULT"
    // source: {index: 3, droppableId: "statement://961798c3-1799-4257-8675-b5fb4e07c26f"}
    // reason: "DROP"
    // mode: "FLUID"
    // destination: {droppableId: "statement://961798c3-1799-4257-8675-b5fb4e07c26f", index: 2}
    // combine: null
    //
    // - combine:
    // {draggableId: "statement://961798c3-1799-4257-8675-b5fb4e07c26f//g:7f292db6-f917-47db-be40-12554cc038bd", type: "DEFAULT", source: {…}, reason: "DROP", mode: "FLUID", …}
    // draggableId: "statement://961798c3-1799-4257-8675-b5fb4e07c26f//g:7f292db6-f917-47db-be40-12554cc038bd"
    // type: "DEFAULT"
    // source: {index: 2, droppableId: "statement://961798c3-1799-4257-8675-b5fb4e07c26f"}
    // reason: "DROP"
    // mode: "FLUID"
    // destination: null
    // combine: {draggableId: "statement://961798c3-1799-4257-8675-b5fb4e07c26f//g:80a7b1ba-4401-4153-b69b-97a70278254d", droppableId: "statement://961798c3-1799-4257-8675-b5fb4e07c26f"}

    const subjectLocation = parseStatementUri(draggableId);

    if (!subjectLocation || !subjectLocation.row) {
        return;
    }

    const subjectRowLocation = subjectLocation.row;
    const statementLocation = subjectLocation.statement;
    const statementId = statementLocation.id;

    if ('DROP' !== reason) {
        store.dispatch(dropStatementRow(statementId));
        return;
    }

    const targetLocation = destination ? parseStatementUri(destination.droppableId) : null;
    if (targetLocation) {
        if (targetLocation.row) {
            throw Error("Logic error. A drag and drop target must not be a row.");
        }
        if (targetLocation.statement.uri !== statementLocation.uri) {
            throw Error("Logic error. Cross statement drag and drop is not supported.");
        }
    }

    const dragDropViewId = statementLocation.uri;
    const subjectPosition = source.index;
    const destinationPosition = destination ? destination.index : undefined;
    const lookupDragDropView = getDragDropViewWithoutPosition(dragDropViewId, subjectPosition);
    if (subjectPosition !== destinationPosition) {
        let action: StatementRowRelocateAction | undefined;
        if (targetLocation === null && combine)  {
            action = doCombine(combine, subjectLocation, statementId, subjectRowLocation);
        } else if (undefined !== destinationPosition) {
            action = doInsert(statementLocation, subjectRowLocation, destinationPosition, lookupDragDropView);
        }

        if (action) {
            await store.dispatch(action);
            await setTimeout(async () => {
                const error = store.getState().statement.error;
                if(error){
                    let action = doInsert(statementLocation, subjectRowLocation, subjectPosition, lookupDragDropView);
                    await store.dispatch(action);
                    await store.dispatch(reduceStatementError(''))
                }
            }, 1500)
            await store.dispatch(dropStatementRow(statementId));
        }
    }
    dragEnd(dragDropViewId);
};

function doInsert(
    statementLocation: StatementUriLocation,
    subjectRowLocation: StatementUriRowLocation,
    destinationPosition: number,
    lookupDragDropView: DragDropView
) {
    const statementId = statementLocation.id;
    let destinationReference: StatementRowLink | undefined;
    const targetPredecessorUri: string | undefined = lookupDragDropView.dragDropUris[destinationPosition - 1];
    const targetUri:  string | undefined = lookupDragDropView.dragDropUris[destinationPosition];
    const state = store.getState();
    const isQuickPlanning = isQuickPlanningUri(targetUri, state, statementId);

    if (!isQuickPlanning && targetPredecessorUri) {
        const targetPredecessorLocation = getRowLocationFromUri(targetPredecessorUri);
        const targetPredecessorRow: StatementRow = getStatementRow(state, statementId, targetPredecessorLocation.id);

        const visibleTargetPredecessorSubRows = getVisibleSubRows(targetPredecessorRow, lookupDragDropView);

        const isParent = StatementUriExtension.AFTER !== targetPredecessorLocation.extension
            && visibleTargetPredecessorSubRows.size
            && StatementRowType.GROUP === targetPredecessorRow.type
            && !targetPredecessorRow.isCollapsed;

        destinationReference = {
            id: targetPredecessorLocation.id,
            relation: isParent ? RowLinkRelation.PARENT : RowLinkRelation.PREDECESSOR
        };
    }

    if (verifyTransition(
        subjectRowLocation.uri,
        targetPredecessorUri ? targetPredecessorUri : statementLocation.uri,
        destinationReference ? destinationReference.relation : RowLinkRelation.PARENT
    ) && !isQuickPlanning) {
        return relocateStatementRow(
            statementId,
            subjectRowLocation.id,
            destinationReference
        );
    }
}

function getDragDropViewWithoutPosition(dragDropViewId: string, position: number): DragDropView {
    const dragDropView = getDragDropListView(dragDropViewId);
    const dragDropIds = [...dragDropView.dragDropIds];
    dragDropIds.splice(position, 1);
    const dragDropUris = [...dragDropView.dragDropUris];
    dragDropUris.splice(position, 1);
    return {
        ...dragDropView,
        dragDropIds,
        dragDropUris,
    }
}

function getRowLocationFromUri(uri: string): StatementUriRowLocation {
    const location = parseStatementUri(uri);
    if (!location.row) {
        throw Error("Logic error. Uri has no row.");
    }
    return location.row;
}


function getVisibleSubRows(row: StatementRow, dragDropView: DragDropView): List<StatementRow> {
    if (row.isCollapsed || undefined === row.rows) {
        return List<StatementRow>([]);
    } else {
        const visibleRowIds = dragDropView.dragDropIds;
        return row.rows.filter((v: StatementRow) => visibleRowIds.indexOf(v.id) > -1);
    }
}
