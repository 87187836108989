import * as actions from "./actionTypes";
import {
    ReduceStatementCollapseLevelAction, ReduceStatementExpandToLevelAction,
    ReduceStatementRowCollapseAction,
    ReduceStatementRowExpandAction, StatementGroupRowCreateAction
} from "./actionTypes";

export function initializeStatements(): actions.InitializeStatementsAction {
    return {
        type: actions.INITIALIZE_STATEMENTS
    };
}

export function createStatementGroupRow(
    statementId: string,
    label: string,
    parentGroup?: GroupDraft
): StatementGroupRowCreateAction {
    return {
        type: actions.STATEMENT_GROUP_ROW_CREATE,
        payload: { statementId, label, parentGroup }
    };
}
export function reduceStatementLoading(){
    return {
        type: actions.REDUCE_STATEMENT_LOADING,
    };
}
export function renameStatementRow(statementId: string, rowId: string, label: string) {
    return {
        type: actions.STATEMENT_ROW_RENAME,
        payload: { statementId, rowId, label }
    };
}

export function deleteStatementRow(
    statementId: string,
    rowId: string
)  {
    return {
        type: actions.STATEMENT_ROW_DELETE,
        payload: { statementId, rowId }
    };
}

export function relocateStatementRow(
    statementId: string,
    rowId: string,
    destination?: StatementRowLink
): actions.StatementRowRelocateAction {
    return {
        type: actions.STATEMENT_ROW_RELOCATE,
        payload: { statementId, rowId, destination }
    };
}

/**
 *
 * Collapsing
 *
 */

export function collapseStatementRow(statementId: string, rowId: string): ReduceStatementRowCollapseAction {
    return {
        type: actions.REDUCE_STATEMENT_ROW_COLLAPSE,
        payload: { statementId, rowId }
    };
}

export function expandStatementRow(statementId: string, rowId: string): ReduceStatementRowExpandAction {
    return {
        type: actions.REDUCE_STATEMENT_ROW_EXPAND,
        payload: { statementId, rowId }
    };
}

export function collapseStatementLevel(statementId: string, level: number): ReduceStatementCollapseLevelAction {
    return {
        type: actions.REDUCE_STATEMENT_COLLAPSE_LEVEL,
        payload: { statementId, level }
    };
}

export function expandStatementToLevel(statementId: string, level: number): ReduceStatementExpandToLevelAction {
    return {
        type: actions.REDUCE_STATEMENT_EXPAND_TO_LEVEL,
        payload: { statementId, level }
    };
}

/**
 *
 * Drag and Drop
 *
 */

export function dragStatementRow(statementId: string, rowId: string) {
    return {
        type: actions.REDUCE_STATEMENT_ROW_DRAG,
        payload: { statementId, rowId }
    };
}

export function dropStatementRow(statementId: string) {
    return {
        type: actions.REDUCE_STATEMENT_ROW_DROP,
        payload: { statementId }
    };
}

export function reduceStatementError(error: string) {
    return {
        type: actions.REDUCE_STATEMENT_ERROR,
        payload: error
    };
}
