export default function findCreditorRowIdForSubGroupRowId(profile: CashFlowStatementProfile, subGroupRowId: string): string|null {
    if (profile.groupRowIdBySubGroupRowId[subGroupRowId]) {
        const groupRowId = profile.groupRowIdBySubGroupRowId[subGroupRowId];
        if (profile.creditorRowIdByGroupRowId[groupRowId]) {
            return profile.creditorRowIdByGroupRowId[groupRowId];
        }
    }

    return null;
}
