import React, {useRef, useState} from "react";
import {useTranslation} from "react-i18next";
import debug from "../../../services/Debug/functions/debug";

import {Col, Input, Row, Select, Switch} from "antd";

import CurrencyNumberInput from "../../FormInput/CurrencyNumberInput";
import PercentageNumberInput from "../../FormInput/PercentageNumberInput";

export type SelectRecurrenceIncreaseValue = {
    active: boolean,
    gradientAmount?: number,
    growthRate?: number,
};

type IncreaseType = "fixed"|"dynamic";

type SelectRecurrenceIncreaseProps = AntdFormControlProps<SelectRecurrenceIncreaseValue>;

const SelectRecurrenceIncrease: React.VFC<SelectRecurrenceIncreaseProps> = ({
    defaultValue = {active: false, growthRate: 0},
    value,
    onChange = () => {},
}) => {
    debug("SelectRecurrenceIncrease::render()", defaultValue, value);

    const {t} = useTranslation();

    const renderValue = value || defaultValue;

    const [selection, setSelection] = useState<IncreaseType>(
        undefined === renderValue.growthRate ? "fixed" : "dynamic"
    );

    const gradientAmountRef = useRef<number>(
        renderValue.gradientAmount ? renderValue.gradientAmount/100 : 0
    );
    const growthRateRef = useRef<number>(renderValue.growthRate || 0);

    const onSwitchChange = (active) => {
        debug("SelectRecurrenceIncrease::onGrowthRateChange()", onSwitchChange);
        onChange(active ? {
            active: true,
            gradientAmount: "fixed" === selection ? gradientAmountRef.current : undefined,
            growthRate: "dynamic" === selection ? growthRateRef.current : undefined
        } : {active: false});
    };

    const onSelectChange = (selection) => {
        debug("SelectRecurrenceIncrease::onSelectChange()", onSwitchChange);
        setSelection(selection);
        onChange({
            ...renderValue,
            gradientAmount: "fixed" === selection ? gradientAmountRef.current : undefined,
            growthRate: "dynamic" === selection ? growthRateRef.current : undefined
        });
    };

    const onGrowthRateChange = (growthRate) => {
        debug("SelectRecurrenceIncrease::onGrowthRateChange()", growthRate);
        growthRateRef.current = growthRate;
        onChange({
            ...renderValue,
            gradientAmount: undefined, // FEATURE-426
            growthRate: growthRate ? growthRate : undefined
        });
    };

    const onGradientChange = (gradientAmount) => {
        debug("SelectRecurrenceIncrease::onGradientChange()", gradientAmount);
        gradientAmountRef.current = gradientAmount;
        onChange({
            ...renderValue,
            gradientAmount: gradientAmount ? gradientAmount * 100 : undefined,
            growthRate: undefined, // FEATURE-426
        });
    };

    const inputNumber = "dynamic" === selection
        ? <PercentageNumberInput
            value={growthRateRef.current}
            onChange={onGrowthRateChange}
            style={{ width: "45%" }}
        /> : <CurrencyNumberInput
            value={gradientAmountRef.current}
            onChange={onGradientChange}
            style={{ width: "45%" }}
        />;

    const inputGroup = renderValue.active
        ? <Col span={21}><Input.Group compact>
            <Select value={selection} onChange={onSelectChange} style={{width: "55%"}}>
                <Select.Option key={"dynamic"} value={"dynamic"}>{t("Dynamisch in %")}</Select.Option>
                <Select.Option key={"fixed"} value={"fixed"}>{t("Fester Betrag in €")}</Select.Option>
            </Select>
            {inputNumber}
        </Input.Group></Col>
        : null;

    return <Row>
        <Col span={3}>
            <Switch defaultChecked={renderValue.active} onChange={onSwitchChange}/>
        </Col>
        {inputGroup}
    </Row>;
}

export default SelectRecurrenceIncrease;
