import * as actions from "./actionTypes";
import {notification} from "antd";
import i18n from "../../i18n";

enum ScenarioNotification {
    SCENARIOSELECTED = 'SCENARIOSELECTED'
}

export function loadScenarios(): actions.GetScenariosAction {
    return {
        type: actions.SCENARIOS_LOAD
    };
}

export function updateScenario(scenario: Scenario): actions.UpdateScenarioAction {
    return {
        type: actions.SCENARIO_UPDATE,
        payload: scenario
    };
}

export function deleteScenario(scenarioId: string): actions.DeleteScenarioAction {
    return {
        type: actions.SCENARIO_DELETE,
        payload: scenarioId
    };
}

export function createScenario(scenario: ScenarioDraft): actions.CreateScenarioAction {
    return {
        type: actions.SCENARIO_CREATE,
        payload: scenario
    };
}

export function selectScenario(scenario: Scenario): actions.ReduceSelectScenario {
    notification.warning({message: i18n.t("Filter aktiv (Szenario ausgewählt)"), duration: 5, key: ScenarioNotification.SCENARIOSELECTED});
    return {
        type: actions.REDUCE_SCENARIO_SELECTED,
        payload: scenario
    };
}

export function deselectScenario(): actions.ReduceDeselectScenario {
    notification.close(ScenarioNotification.SCENARIOSELECTED);
    return {
        type: actions.REDUCE_SCENARIO_DESELECTED
    };
}

export function createScenarioExclusion(scenarioId: string, planningId: string): actions.CreateScenarioExclusionAction {
    return {
        type: actions.SCENARIO_EXCLUSIONS_CREATE,
        payload: {scenarioId, planningId}
    };
}

export function deleteScenarioExclusion(scenarioId: string, id: string): actions.DeleteScenarioExclusionAction {
    return {
        type: actions.SCENARIO_EXCLUSIONS_DELETE,
        payload: {scenarioId, id}
    };
}

export function loadScenarioExclusions(scenarioId: string): actions.LoadScenarioExclusionsAction {
    return {
        type: actions.SCENARIO_EXCLUSIONS_LOAD,
        payload: scenarioId
    };
}
