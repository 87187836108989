import {createSelector} from "reselect"
import {createCachedSelector} from "re-reselect";

import debug, {debugSelect} from "./Debug/functions";

import {DeleteRowStatus} from "../model/RowBehaviour/types";
import {FlaggedFeature, getFlagsSelector} from "../flags";
import {StatementProfileType} from "../model/Statement";

import {AppMode} from "../model/App/enums";
import {AuthRoleEnum} from "../model/Auth/enum";
import {PostingChannel} from "../model/Posting/enums";

import buildStatementDeepRowSet from "../legacy/fatClient/functions/buildStatementDeepRowSet";
import sumUpBalances from "../legacy/fatClient/functions/sumUpBalances";

import {filterPostingsForCashFlowStatement} from "../model/Posting/Posting.filter";
import {getAppMode, isCompany, isLoginOfRole} from "../reducer";
import {getAccountNumberCodesByAccountIds} from "../model/Accounting/AccountRegistry";
import {isSystemAccount} from "../model/Accounting/AccountQualification";
import filterObject from "../model/Object/filterObject";

import * as statementSelectors from "./Statement/selectors"
import {statementSelector} from "./Statement/selectors"
import {activeTimeRangeSelector, timeSeriesResolutionSelector} from "./TimeTravel/selectors";
import {postingSelector} from "./Posting/selectors";
import {accountFilterSelector, postingFiltersSelector} from "./Filter/selectors";
import {selectedScenarioSelector} from "./Scenarios/selectors";
import {planningSelector} from "./Planning/selectors";
import {
    accountingEndDateSelector,
    accountingStartDateSelector,
    accountsSelector,
    bankEndDateSelector,
    bankStartDateSelector,
    hasBankDataSelector,
    openingBalancesSelector
} from "./Accounting/selectors";
import {cashInBankTotalBalancesSelector} from "./TimeSeries/selectors";
import {CashFlowRole} from "../model/CashFlow/enums";
import buildCashInBankTimeSeries from "../legacy/fatClient/functions/buildCashInBankTimeSeries";
import {ConnectionStatus} from "../scenes/Bank/components/enums";
import shouldSync from "../components/FinApi/functions/shouldSync";
import {buildSparseTimeSeriesMap} from "../legacy/fatClient/functions/buildSparseTimeSeriesMap";
import {TimeSeriesTopic, TimeSeriesType} from "./TimeSeries/enums";
import getFlowForRow from "./Statement/functions/getFlowForRow";
import {encodeStatementRowUrn} from "../model/StatementUri/functions/encodeStatementRowUrn";
import {encodeTimeSeriesKey} from "./TimeSeries/functions/encodeTimeSeriesKey";
import {List} from "immutable";
import {decodeRestTimeFrame} from "./TimeSeries/functions/decodeRestTimeFrame";
import {RowTimeSeriesSelection} from "../legacy/fatClient/selectors";
import isMimeType from "../model/Mime/functions/isMimeType";
import {CashFlowCategorizationEnum} from "../components/DataExplorer/enums";
import {BarsData} from "../components/PostingChart/types";

function isDevEnv() {
    return process.env.NODE_ENV === "development";
}

function isDevUser(state: RootState) {
    return isLoginOfRole(state, AuthRoleEnum.DEV);
}

export const isDevSelector: (state: RootState) => boolean = (state) => {
    return isDevEnv() || isDevUser(state);
}

//  @see #DATACORE-741
const isForecastBetaUser_DATACORE741 = (state: RootState) => {

    // const isBetaCustomer = false;
    const isBetaCustomer =
        isCompany(state, 'dev ai')
        || isCompany(state, 'demo ai 1')
        || isCompany(state, 'demo ai 2')
        || isCompany(state, 'Gairing & Gairing GmbH')
        || isCompany(state, 'Aloha Talents GmbH')
        || isCompany(state, 'THOLD-IT GmbH')
    ;

    const isBetaDev = isLoginOfRole(state, AuthRoleEnum.DEV)
        && (
            isCompany(state, 'grill sportivo automotive GmbH')
            || isCompany(state, 'Aloha Talents GmbH')
            || isCompany(state, 'Praktikawelten')
            || isCompany(state, 'KEFFEKT DESIGN GmbH & Co. KG')
            || isCompany(state, 'THOLD-IT GmbH')
            || isCompany(state, 'Trauminsel Reisen')
            || isCompany(state, 'Innkaufhaus Schuhmacher KG')
        );

    return isBetaCustomer || isBetaDev;
}

export const isFeatureActive: (state: RootState, feature: FlaggedFeature) => boolean
    = (state: RootState, feature: FlaggedFeature) => {
    const isActive = getFlagsSelector(state);
    const hasScenarioSelected = isActive[FlaggedFeature.scenario] && state.scenarios.selected;

    // The multiUnit flag is set to true automagically if the logged tenant is a multiUnit company
    const isMultiUnit = isActive[FlaggedFeature.multiUnit];
    const hasCompanyUnitSelected = !!state.filter?.selectedCompanyUnit;

    if (!isActive[feature]) {
        return false
    }

    switch (feature) {
        case FlaggedFeature.planning:
            return hasCompanyUnitSelected || !isMultiUnit;

        case FlaggedFeature.quickPlanning:
            return !hasScenarioSelected && (!isMultiUnit || hasCompanyUnitSelected);

        case FlaggedFeature.deleteRow:
            return getDeleteRowStatus(state) === DeleteRowStatus.ACTIVE;

        case FlaggedFeature.forecast:
            return AppMode.MASTER === getAppMode(state)
                && (!isMultiUnit || !hasCompanyUnitSelected)
                && isForecastBetaUser_DATACORE741(state);

        case FlaggedFeature.finApi:
            return isActive[feature] && true !== state.auth.login?.isMultiUnit;

        default:
            return isActive[feature];
    }
}

export const isFeatureActiveSelector = createCachedSelector<RootState, FlaggedFeature, boolean, boolean>(
    isFeatureActive,
    (isFeatureActive) => isFeatureActive
)(
    (_state_, feature) => feature
);

export function areStatesReady(state: RootState, lookupStates: (keyof RootState)[]): boolean {
    return lookupStates
        .map(lookupState => isStateReady(state, lookupState))
        .reduce(
            (accumulator, currentValue) => accumulator && currentValue,
            true
        );
}

export function isStateReady(state: RootState, lookupState: keyof RootState): boolean {
    const subState = state[lookupState];
    if (subState.hasOwnProperty("initialized")) {
        const initialized = isStateInitialized(state, lookupState);
        debug("isStateReady()", lookupState, "initialized", initialized);
        return initialized;
    } else if (subState.hasOwnProperty("loading")) {
        const loaded = isStateLoaded(state, lookupState);
        debug("isStateReady()", lookupState, "loaded", loaded);
        return loaded;
    } else {
        debug("isStateReady()", lookupState, "always");
        return true;
    }
}

export function isStateLoaded(state: RootState, lookupState: keyof RootState): boolean {
    return false === state[lookupState].loading;
}

export const isStateLoadingSelector = createCachedSelector<RootState, keyof RootState, boolean, boolean>(
    (state, subStateKey) => state[subStateKey] && true === state[subStateKey].loading,
    (isLoading) => isLoading
)(
    (_state_, subStateKey) => subStateKey
);

export function isStateInitialized(state: RootState, lookupState: keyof RootState): boolean {
    return true === state[lookupState].initialized;
}

export function isBankStateInitialized(state: RootState): boolean {
    return isStateInitialized(state, "bank");
}

export function isAccountingStateInitialized(state: RootState): boolean {
    return isStateInitialized(state, "accounting");
}

export function isBalanceStateInitialized(state: RootState): boolean {
    return isStateInitialized(state, "balance");
}

export function isPostingStateInitialized(state: RootState): boolean {
    return isStateInitialized(state, "posting");
}

export function isAppInitializedSelector(state: RootState): boolean {
    return isAccountingStateInitialized(state) && isPostingStateInitialized(state);
}

export function isStatementReadySelector(state: RootState): boolean {
    return areStatesReady(state, ["statement", "scenarios", "settings", "accounting", "posting", "timeSeries"]);
}

export const hasCashFlowTableDataSelector: (state: RootState) => boolean = (state: RootState) => {
    return undefined !== state.accounting.stats?.stages?.historicalAccounting
        || undefined !== state.accounting.stats?.stages?.planned;
}

export const hasDataSelector: (state: RootState) => boolean = (state: RootState) =>
    undefined !== state.accounting.stats?.stages;

export const isBankActiveSelector: (state: RootState) => boolean | undefined = createSelector<// State
    RootState,
    // Parameters
    RootState,
    boolean | undefined,
    // Result
    boolean | undefined>(
    // Selectors
    state => state,
    hasBankDataSelector,
    // Combiner
    (state, hasBankData) =>
        isFeatureActiveSelector(state, FlaggedFeature.finApi) && undefined === state.bank.visible ? hasBankData : state.bank.visible
);

export const activeChannelsSelector: (state: RootState) => PostingChannel[] | undefined = createSelector<// State
    RootState,
    // Parameters
    boolean | undefined,
    // Result
    PostingChannel[] | undefined>(
    // Selectors
    isBankActiveSelector,
    // Combiner
    (isBankActive) =>
        // @todo #FEATURE-1570
        // isBankActive ? [PostingChannel.BANK, PostingChannel.ACCOUNTING] : [PostingChannel.ACCOUNTING]
        isBankActive ? [PostingChannel.BANK] : undefined
    // when slim client is done:
    // isBankActive ? undefined : [PostingChannel.ACCOUNTING, PostingChannel.PLANNING, PostingChannel.ADJUSTMENT]
);

export const activeBankStartDateSelector: (state: RootState) => Date | undefined = createSelector<// State
    RootState,
    // Parameter
    boolean | undefined,
    Date | undefined,
    //
    Date | undefined>(
    // Selectors
    isBankActiveSelector,
    bankStartDateSelector,
    // Combiner
    (isBankActive, bankStartDate) => isBankActive ? bankStartDate : undefined
);

export const activeBankEndDateSelector: (state: RootState) => Date | undefined = createSelector<// State
    RootState,
    // Parameter
    boolean | undefined,
    Date | undefined,
    //
    Date | undefined>(
    // Selectors
    isBankActiveSelector,
    bankEndDateSelector,
    // Combiner
    (isBankActive, bankEndDate) => isBankActive ? bankEndDate : undefined
);

export const activeAccountingEndDateSelector: (state: RootState) => Date | undefined = accountingEndDateSelector;

export const activeHistoricalEndDateSelector: (state: RootState) => Date | undefined = createSelector<// State
    RootState,
    // Parameter
    Date | undefined,
    Date | undefined,
    //
    Date | undefined>(
    // Selectors
    activeBankEndDateSelector,
    activeAccountingEndDateSelector,
    // Combiner
    (bankEndDate, accountingEndDate) =>
        bankEndDate ? bankEndDate : accountingEndDate
);

export const activeTimeSeriesViewportSelector: (state: RootState) => TimeSeriesViewPort | undefined
    = createSelector<// State
    RootState,
    // Parameter
    PostingChannel[] | undefined,
    Date | undefined,
    Date | undefined,
    Date | undefined,
    // Results
    TimeSeriesViewPort | undefined>(
    // selectors
    activeChannelsSelector,
    accountingStartDateSelector,
    bankStartDateSelector,
    activeHistoricalEndDateSelector,
    // combiner
    (
        channels,
        accountingStartDate,
        bankStartDate,
        endDate
    ) => {
        const startDate: Date | undefined = bankStartDate
            ? (accountingStartDate && accountingStartDate.getTime() > bankStartDate.getTime() ? accountingStartDate : bankStartDate)
            : accountingStartDate;
        if (undefined !== startDate && undefined !== endDate && endDate.getTime() >= startDate.getTime()) {
            return {startDate, endDate, channels};
        }
        return undefined;
    }
);

export const experimentalCashInBankTableViewportSelector: (state: RootState) => TimeSeriesViewPort | undefined
    = createSelector<// State
    RootState,
    // Parameter
    PostingChannel[] | undefined,
    Date | undefined,
    Date | undefined,
    Date | undefined,
    // Results
    TimeSeriesViewPort | undefined>(
    // selectors
    activeChannelsSelector,
    accountingStartDateSelector,
    bankStartDateSelector,
    activeHistoricalEndDateSelector,
    // combiner
    (
        channels,
        accountingStartDate,
        bankStartDate,
        endDate
    ) => {
        const startDate: Date | undefined = accountingStartDate ? accountingStartDate : bankStartDate;
        if (undefined !== startDate && undefined !== endDate && endDate.getTime() >= startDate.getTime()) {
            return {startDate, endDate, channels};
        }
        return undefined;
    }
);

export const getDeleteRowStatus = (state: RootState): DeleteRowStatus => {
    const isActive = getFlagsSelector(state);

    if (!isActive[FlaggedFeature.deleteRow]) {
        return DeleteRowStatus.FEATURE_INACTIVE;
    }

    if (state.scenarios.selected !== undefined) {
        return DeleteRowStatus.SCENARIO_SELECTED;
    }

    return DeleteRowStatus.ACTIVE;
}

export const loginSelector: (state: RootState) => AuthLogin | undefined
    = (state: RootState) => (state.auth.login || undefined);

export const companyUnitControlSelector: (state: RootState) => CompanyUnitControl | undefined
    = (state: RootState) => state.statement.controls.companyUnit;

export const bankAccountsSelector: (state: RootState) => BankAccount[]
    = (state: RootState) => state.bank.bankAccounts;

export const bankWorkflowSelector: (state: RootState) => BankWorkflow | undefined
    = (state: RootState) => state.bank.workflow;

export const latestBankSyncSelector: (state: RootState) => Date | undefined
    = createSelector<// State
    RootState,
    // Parameter
    BankAccount[],
    // Results
    Date | undefined>(
    // selectors
    bankAccountsSelector,
    // combiner
    bankAccounts => bankAccounts.reduce((result: Date | undefined, bankAccount) => {
        const latestSync = bankAccount.latestSync;
        return latestSync && (undefined === result || result < latestSync) ? latestSync : result;
    }, undefined)
);

export const balancesSelector: (state: RootState) => AccountBalanceRegistry | undefined
    = (state: RootState) => state.balance.balances;

export const standardAccountsSelector: (state: RootState) => AccountRegistry | undefined
    = createSelector<// State
    RootState,
    // Parameter
    AccountRegistry | undefined,
    // Results
    AccountRegistry | undefined>(
    // selectors
    accountsSelector,
    // combiner
    accounts => filterObject(accounts, account => !isSystemAccount(account))
);

export const systemAccountsSelector: (state: RootState) => AccountRegistry | undefined
    = createSelector<// State
    RootState,
    // Parameter
    AccountRegistry | undefined,
    // Results
    AccountRegistry | undefined>(
    // selectors
    accountsSelector,
    // combiner
    accounts => filterObject(accounts, account => isSystemAccount(account))
);

export const standardStatementSelector: (state: RootState, statementProfile: StatementProfileType) => Statement | undefined
    = (state: RootState, statementProfile: StatementProfileType) => {
    const isStatementFeatureActive = statementProfile === StatementProfileType.CASH_IN_BANK
        ? isFeatureActive(state, FlaggedFeature.finApi)
        : true;
    return isStatementFeatureActive && isStatementReadySelector(state)
        ? statementSelectors.standardStatementSelector(state, statementProfile)
        : undefined;
}

export const cashFlowStatementSelector: (state: RootState) => Statement | undefined
    = (state: RootState) => standardStatementSelector(state, StatementProfileType.CASH_FLOW);

export const cashInBankStatementSelector: (state: RootState) => Statement | undefined
    = (state: RootState) => standardStatementSelector(state, StatementProfileType.CASH_IN_BANK);

/**
 * @deprecated in favor of statementSelector
 */
export const currentStatementSelector: (state: RootState) => Statement | undefined
    = (state: RootState) => cashFlowStatementSelector(state);

/**
 * @deprecated in favor of statementSelector
 */
export const currentStatementLabelSelector: (state: RootState) => string | undefined
    = (state: RootState) => currentStatementSelector(state)?.label;

export const scenariosSelector: (state: RootState) => Scenario[] = (state: RootState) => state.scenarios.scenarios;

export const scenarioSelector = createCachedSelector<RootState,
    string | undefined,
    Scenario | undefined,
    Scenario | undefined>(
    (state, scenarioId) => {
        return undefined === scenarioId
            ? undefined
            : scenariosSelector(state)?.find(scenario => scenario.id === scenarioId);
    },
    (scenario) => scenario
)(
    (_state_, scenarioId) => scenarioId
);

const companyUnitsSelector: (state: RootState) => CompanyUnit[] | undefined
    = createSelector<// State
    RootState,
    // Parameter
    CompanyUnitControl | undefined,
    // Results
    CompanyUnit[] | undefined>(
    // selectors
    companyUnitControlSelector,
    // combiner
    (companyUnitControl) => companyUnitControl?.options
);

export const companyUnitSelector = createCachedSelector<RootState,
    string | undefined,
    CompanyUnit | undefined,
    CompanyUnit | undefined>(
    (state, companyUnitId) => {
        return undefined === companyUnitId
            ? undefined
            : companyUnitsSelector(state)?.find(companyUnit => companyUnit.id === companyUnitId);
    },
    (companyUnit) => companyUnit
)(
    (_state_, companyUnitId) => companyUnitId
);

export const selectedCompanyUnitSelector: (state: RootState) => CompanyUnit | undefined
    = (state: RootState) => state.filter.selectedCompanyUnit;

export const statementControlsSelector: (state: RootState) => StatementControls
    = (state: RootState) => state.statement.controls;

export const isForecastVisibleSelector: (state: RootState) => boolean = (state: RootState) => state.forecast.visible;

const scenarioExclusionsSelector: (state: RootState) => ScenarioExclusion[]
    = (state: RootState) => state.scenarios.exclusions;
export const explanationSelector: (state: RootState) => ExplanationSeries | undefined = (state: RootState) => state.forecast.explanation;

const selectedScenarioExclusionsSelector: (state: RootState) => ScenarioExclusion[] | undefined
    = createSelector<// State
    RootState,
    // Parameter
    Scenario | undefined,
    ScenarioExclusion[],
    // Results
    ScenarioExclusion[] | undefined>(
    // selectors
    selectedScenarioSelector,
    scenarioExclusionsSelector,
    // combiner
    (scenario, exclusions) => {
        const result = scenario
            ? exclusions.filter(exclusion => scenario.id === exclusion.scenarioId)
            : undefined;
        debugSelect("selectedScenarioExclusionsSelector", result, scenario, exclusions);
        return result;
    }
);

const selectedScenarioPlanningSelector: (state: RootState) => Planning[] | undefined
    = createSelector<// State
    RootState,
    // Parameter
    Scenario | undefined,
    Planning[] | undefined,
    // Results
    Planning[] | undefined>(
    // selectors
    selectedScenarioSelector,
    planningSelector,
    // combiner
    (scenario, plannings) => {
        const result = scenario
            ? plannings?.filter(planning => planning.scenarioId === scenario.id)
            : undefined;
        debugSelect("selectedScenarioPlanningSelector", result, scenario, plannings);
        return result;
    }
);

export const selectedScenarioEnhancedSelector: (state: RootState) => ScenarioEnhanced | undefined
    = createSelector<// State
    RootState,
    // Parameter
    Scenario | undefined,
    Planning[] | undefined,
    // Results
    ScenarioEnhanced | undefined>(
    // selectors
    selectedScenarioSelector,
    planningSelector,
    // combiner
    (scenario, plannings) => {
        if (undefined === scenario) {
            return undefined;
        }
        const scenarioId = scenario.id;
        const scenarioPlannings = plannings?.filter(planning => planning.scenarioId === scenarioId);
        const scenarioAccountIds = scenarioPlannings?.reduce<string[]>((result, planning) => {
            const accountId = planning.account.id;
            const transferAccountId = planning.transferAccount?.id;
            const cashAccountId = planning.cashAccount.id;
            if (-1 === result.indexOf(accountId)) {
                result.push(accountId);
            }
            if (undefined !== transferAccountId && -1 === result.indexOf(transferAccountId)) {
                result.push(transferAccountId);
            }
            if (-1 === result.indexOf(cashAccountId)) {
                result.push(cashAccountId);
            }
            return result;
        }, []);
        const result = {
            ...scenario,
            accountIds: scenarioAccountIds
        };
        debugSelect("selectedScenarioEnhancedSelector", result, scenario, plannings);
        return result;
    }
);

export const statementPostingsSelector = createCachedSelector<// State
    RootState,
    // single(!) Selector Parameter (no multi parameter support for type script)
    string,
    // Combiner Parameters
    RootState,
    Statement | undefined,
    // Result
    Posting[] | undefined>(
    (state) => state,
    statementSelector,
    (state, statement) => {
        switch (statement?.profileType) {
            case undefined:
                return undefined;
            case StatementProfileType.CASH_FLOW:
                return cashFlowStatementPostingsSelector(state);
            case StatementProfileType.CASH_IN_BANK:
                return cashInBankStatementPostingsSelector(state);
            default:
                throw Error("Statement postings provisioning not implemented yet for " + statement?.profileType);
        }
    }
)(
    (_state_, statementId) => statementId
);

/**
 * @deprecated in favor of statementPostingsSelector
 */
export const cashFlowStatementPostingsSelector: (state: RootState) => Posting[] | undefined
    = createSelector<// State
    RootState,
    // Parameter
    boolean,
    Posting[] | undefined,
    Date | undefined,
    AccountRegistry | undefined,
    AccountFilter | undefined,
    PostingFilterSet | undefined,
    Scenario | undefined,
    Planning[] | undefined,
    ScenarioExclusion[] | undefined,
    // Results
    Posting[] | undefined>(
    // selectors
    isAppInitializedSelector,
    postingSelector,
    activeHistoricalEndDateSelector,
    accountsSelector,
    accountFilterSelector,
    postingFiltersSelector,
    selectedScenarioSelector,
    selectedScenarioPlanningSelector,
    selectedScenarioExclusionsSelector,
    // combiner
    (
        isAppInitialized,
        postings,
        latestPostingDate,
        accounts,
        accountFilter,
        postingFilters,
        scenario,
        scenarioPlannings,
        scenarioExclusions
    ): Posting[] | undefined => {
        const filteredPostings = !isAppInitialized || undefined === postings
            ? []
            : filterPostingsForCashFlowStatement(
                postings,
                latestPostingDate,
                accounts,
                accountFilter,
                postingFilters,
                scenario,
                scenarioPlannings,
                scenarioExclusions
            );
        const result = filteredPostings.length > 0 ? filteredPostings : undefined;
        debugSelect(
            "cashFlowStatementPostingsSelector",
            result,
            postings,
            latestPostingDate,
            accounts,
            accountFilter,
            postingFilters,
            scenario,
            scenarioPlannings,
            scenarioExclusions
        );
        return result;
    }
);

/**
 * @deprecated in favor of statementPostingsSelector
 */
export const cashInBankStatementPostingsSelector: (state: RootState) => Posting[] | undefined
    = createSelector<// State
    RootState,
    // Parameter
    Posting[] | undefined,
    AccountRegistry | undefined,
    // Results
    Posting[] | undefined>(
    // selectors
    cashFlowStatementPostingsSelector,
    accountsSelector,
    // combiner
    (
        postings,
        accounts,
    ): Posting[] | undefined => {
        if (postings && accounts) {
            return postings.filter(posting => {
                const account = accounts[posting.accountId];
                const contraAccount = accounts[posting.contraAccountId];
                return CashFlowRole.CASH === account.cashFlowRole
                    || CashFlowRole.CASH === contraAccount.cashFlowRole;
            });
        } else {
            return undefined;
        }
    }
);

export const cashFlowPlannedPostingsSelector: (state: RootState) => Posting[] | undefined
    = createSelector<// State
    RootState,
    // Parameter
    boolean,
    Posting[] | undefined,
    AccountRegistry | undefined,
    AccountFilter | undefined,
    PostingFilterSet | undefined,
    Scenario | undefined,
    Planning[] | undefined,
    ScenarioExclusion[] | undefined,
    // Results
    Posting[] | undefined>(
    // selectors
    isAppInitializedSelector,
    postingSelector,
    accountsSelector,
    accountFilterSelector,
    postingFiltersSelector,
    selectedScenarioSelector,
    selectedScenarioPlanningSelector,
    selectedScenarioExclusionsSelector,
    // combiner
    (
        isAppInitialized,
        postings,
        accounts,
        accountFilter,
        postingFilters,
        scenario,
        scenarioPlannings,
        scenarioExclusions
    ): Posting[] | undefined => {
        const filteredPostings = !isAppInitialized || undefined === postings
            ? []
            : filterPostingsForCashFlowStatement(
                postings.filter(posting => PostingChannel.PLANNING === posting.channel),
                undefined,
                accounts,
                accountFilter,
                postingFilters,
                scenario,
                scenarioPlannings,
                scenarioExclusions
            );
        const result = filteredPostings.length > 0 ? filteredPostings : undefined;
        debugSelect(
            "cashFlowPlannedPostingsSelector",
            result,
            postings,
            accounts,
            accountFilter,
            postingFilters,
            scenario,
            scenarioPlannings,
            scenarioExclusions
        );
        return result;
    }
);

export const cashFlowStatementBasePostingsSelector: (state: RootState) => Posting[] | undefined
    = createSelector<// State
    RootState,
    // Parameter
    boolean,
    Posting[] | undefined,
    Date | undefined,
    AccountRegistry | undefined,
    AccountFilter | undefined,
    PostingFilterSet | undefined,
    // Results
    Posting[] | undefined>(
    // selectors
    isAppInitializedSelector,
    postingSelector,
    activeHistoricalEndDateSelector,
    accountsSelector,
    accountFilterSelector,
    postingFiltersSelector,
    // combiner
    (
        isAppInitialized,
        postings,
        latestPostingDate,
        accounts,
        accountFilter,
        postingFilters,
    ): Posting[] | undefined => {
        const filteredPostings = !isAppInitialized || undefined === postings
            ? []
            : filterPostingsForCashFlowStatement(
                postings,
                latestPostingDate,
                accounts,
                accountFilter,
                postingFilters
            );
        const result = filteredPostings.length > 0 ? filteredPostings : undefined;
        debugSelect(
            "cashFlowStatementBasePostingsSelector",
            result,
            postings,
            latestPostingDate,
            accounts,
            accountFilter,
            postingFilters
        );
        return result;
    }
);

export const accountNumbersMountedToRowSelector = createCachedSelector<RootState,
    FlatStatementRow,
    AccountRegistry | undefined,
    FlatStatementRow,
    number[] | undefined>(
    (state) => state.accounting.accounts,
    (state, row) => row,
    (accounts, row) => {
        const result = undefined === accounts || undefined === row.accountIds
            ? undefined
            : getAccountNumberCodesByAccountIds(accounts, row.accountIds);
        debugSelect("accountNumbersMountedToRowSelector", result, accounts, row);
        return result;
    }
)(
    (_state_, row) => row.id
);

export const activeCashInBankTotalBalancesSelector: (state: RootState) => SparseTimeSeries | undefined = createSelector<// State
    RootState,
    // Parameter
    boolean | undefined,
    SparseTimeSeries | undefined,
    //
    SparseTimeSeries | undefined>(
    // Selectors
    isBankActiveSelector,
    cashInBankTotalBalancesSelector,
    // Combiner
    (isBankActive, cashInBankTotalBalances) =>
        isBankActive ? cashInBankTotalBalances : undefined
);

/**
 * @deprecated in favor of sparseTimeSeriesForRowSelector
 */
export const catchAllBankCashFlowSelector = (state: RootState): TimeSeriesValueNestedMap | undefined => {
    if (state.bank.visible === false ||
        state.filter.accountFilter?.cashFlowRoleBlacklist?.includes('cash')) {
        return undefined
    }
    if (state.filter.postingFilters) {
        if (state.filter.postingFilters['dataExplorer']?.cashFlowCategorizationType === CashFlowCategorizationEnum.REFINEMENT) {
            return undefined;
        }
    }
    return state.timeSeries.catchAllBankCashFlow || undefined;
}

export const cashFlowStatementDeepRowSetSelector: (state: RootState) => StatementDeepTimeSeriesRowSet | undefined
    = createSelector<// State
    RootState,
    // Parameter
    Statement | undefined,
    AccountRegistry | undefined,
    Posting[] | undefined,
    TimeSeriesResolution,
    TimeSeriesValueNestedMap | undefined,
    // Results
    StatementDeepTimeSeriesRowSet | undefined>(
    // selectors
    cashFlowStatementSelector,
    accountsSelector,
    cashFlowStatementPostingsSelector,
    timeSeriesResolutionSelector,
    catchAllBankCashFlowSelector,
    // combiner
    (
        statement,
        accounts,
        postings,
        timeSeriesResolution,
        catchAllBankCashFlow,
    ) => {

        const result = statement && accounts
            ? buildStatementDeepRowSet(statement, accounts, postings, timeSeriesResolution, catchAllBankCashFlow)
            : undefined;
        debugSelect("currentStatementDeepRowSetSelector", result, statement, accounts, postings, timeSeriesResolution);
        return result;
    }
);
export const cashFlowStatementSparseTimeSeriesSelector: (state: RootState) => SparseTimeSeriesMap
    = createSelector<// State
    RootState,
    // Parameter
    StatementDeepTimeSeriesRowSet | undefined,
    // Results
    SparseTimeSeriesMap>(
    // selectors
    cashFlowStatementDeepRowSetSelector,
    // combiner
    (deepTimeSeriesRowSet) => {
        const result = buildSparseTimeSeriesMap(deepTimeSeriesRowSet);
        debugSelect("currentStatementTimeSeriesSelector", result);
        return result;
    }
);

function subjectUrnForRow(row: StatementRow): string | undefined {
    const quickHackSkipSubject = (row: StatementRow) => {
        return isMimeType(row.constraintType, "group", "total");
    }
    return quickHackSkipSubject(row) ? undefined : encodeStatementRowUrn(row);
}


export function keyForSelection(
    selection: RowTimeSeriesSelection,
    resolution: TimeSeriesResolution,
    channels?: PostingChannel[]
): TimeSeriesKey {
    return {
        type: selection.type,
        topic: TimeSeriesTopic.CASH_IN_BANK,
        flow: getFlowForRow(selection.row),
        channels,
        resolution,
        subjectUrn: subjectUrnForRow(selection.row),
    };
}

export const cashInBankStatementDeepRowSetSelector: (state: RootState) => StatementDeepTimeSeriesRowSet | undefined
    = createSelector<// State
    RootState,
    // Parameter
    TimeSeriesRegistry,
    Statement | undefined,
    TimeSeriesResolution,
    SparseTimeSeriesMap | undefined,
    // Results
    StatementDeepTimeSeriesRowSet | undefined>(
    // selectors
    (state) => state.timeSeries.registry,
    cashInBankStatementSelector,
    timeSeriesResolutionSelector,
    cashFlowStatementSparseTimeSeriesSelector,

    // combiner
    (
        registry,
        statement,
        timeSeriesResolution,
        map
    ) => {
        if (!map || !statement) {
            return undefined
        }
        const cashInBankRow = statement.rows.toArray()[0];
        if (!cashInBankRow) {
            return undefined;
        }
        const buildBankRowsWithTimeSeries = (rowToBuild) => {

            return rowToBuild.rows?.toArray().map(row => {
                const isCashFlowRow = "category" === row.constraintType?.mainType
                    && (["flowIn", "flowOut"].indexOf(row.constraintType?.subType) > -1);
                const selection = {type: isCashFlowRow ? TimeSeriesType.CASH_FLOW : TimeSeriesType.BALANCES, row};
                const timeSeriesKey = keyForSelection(selection, timeSeriesResolution, [PostingChannel.BANK])
                const timeSeries = registry.get(encodeTimeSeriesKey(timeSeriesKey))?.timeSeries;
                const dataPoints = timeSeries?.isoCodes.map((iso, i) => (

                    {
                        timeFrame: decodeRestTimeFrame(iso),
                        value: timeSeries.values[i]
                    }))
                const data = {
                    ...row,
                    dataPoints: dataPoints
                }

                if (data.rows) {
                    const subRows = buildBankRowsWithTimeSeries(data)
                    data.rows = List(subRows);
                }
                return data;

            })
        }
        const selection = {type: TimeSeriesType.BALANCES, row: cashInBankRow};
        const timeSeriesKey = keyForSelection(selection, timeSeriesResolution, [PostingChannel.BANK])
        const timeSeries = registry.get(encodeTimeSeriesKey(timeSeriesKey))?.timeSeries;
        const dataPoints = timeSeries?.isoCodes.map((iso, i) => (
            {
                timeFrame: decodeRestTimeFrame(iso),
                value: timeSeries.values[i]
            }))

        const builtRow = buildBankRowsWithTimeSeries(cashInBankRow);

        const cashInBankDeepRowSet = {
            ...cashInBankRow,
            dataPoints,
            rows: builtRow
        }
        return {timeSeriesResolution, rows: [cashInBankDeepRowSet]};
    }
);


/**
 * @deprecated for slim client
 */
export const filteredTotalOpeningBalanceSelector: (state: RootState) => number | undefined
    = createSelector<// State
    RootState,
    // Parameter
    boolean,
    OpeningBalance[] | undefined,
    CompanyUnit | undefined,
    // Results
    number | undefined>(
    // selectors
    isAccountingStateInitialized,
    openingBalancesSelector,
    selectedCompanyUnitSelector,
    // combiner
    (isAccountingStateInitialized, openingBalances, companyUnit) => {
        const companyUnitIds = companyUnit ? [companyUnit.id] : undefined;
        const result = isAccountingStateInitialized
            ? (openingBalances ? sumUpBalances(openingBalances, companyUnitIds) : 0)
            : undefined;
        debugSelect("filteredTotalOpeningBalanceSelector", result, openingBalances, companyUnit);
        return result;
    }
);

export const filteredCashInBankBaseTimeSeriesSelector: (state: RootState) => TimeSeries | undefined
    = createSelector<// State
    RootState,
    // Parameter
    AccountRegistry | undefined,
    Posting[] | undefined,
    TimeRange,
    number | undefined,
    TimeSeries | undefined,
    Date | undefined,
    // Results
    TimeSeries | undefined>(
    // selectors
    accountsSelector,
    cashFlowStatementBasePostingsSelector,
    activeTimeRangeSelector,
    filteredTotalOpeningBalanceSelector,
    activeCashInBankTotalBalancesSelector,
    activeAccountingEndDateSelector,
    // combiner
    (
        accounts,
        postings,
        timeRange,
        totalOpeningBalance,
        cashInBankTotalBalances,
        accountingEndDate
    ) => {
        // @todo #FEATURE-1524 revive the check in a stable way
        // cash flow table integrity check
        // if (cashFlowStatement && cashFlowStatementDeepRowSet && postingTimeSeries && !selectedScenario) {
        //     // (!) chartBaseTimeSeries is already updated but the values of cashFlowTotalRow not
        //     // => validation errors at this point
        //     const cashFlowTotalRow = cashFlowStatementDeepRowSet.rows[0];
        //     CashFlowValidator.validateChartTableSumIntegrity(
        //         cashFlowStatement.id,
        //         cashFlowTotalRow,
        //         postingTimeSeries
        //     );
        // }
        //
        const result = buildCashInBankTimeSeries(
            accounts,
            postings,
            timeRange,
            totalOpeningBalance,
            cashInBankTotalBalances,
            accountingEndDate
        );
        debugSelect("filteredCashInBankBaseTimeSeriesSelector", result, accounts, postings, timeRange, totalOpeningBalance, cashInBankTotalBalances);
        return result;
    }
);

export const filteredCashInBankScenarioTimeSeriesSelector: (state: RootState) => TimeSeries | undefined
    = createSelector<// State
    RootState,
    // Parameter
    AccountRegistry | undefined,
    Posting[] | undefined,
    TimeRange,
    number | undefined,
    TimeSeries | undefined,
    Date | undefined,
    // Result
    TimeSeries | undefined>(
    // selectors
    accountsSelector,
    cashFlowStatementPostingsSelector,
    activeTimeRangeSelector,
    filteredTotalOpeningBalanceSelector,
    activeCashInBankTotalBalancesSelector,
    activeAccountingEndDateSelector,
    // combiner
    (
        accounts,
        postings,
        timeRange,
        totalOpeningBalance,
        cashInBankTotalBalances,
        accountingEndDate
    ) => {
        const result = buildCashInBankTimeSeries(
            accounts,
            postings,
            timeRange,
            totalOpeningBalance,
            cashInBankTotalBalances,
            accountingEndDate
        );
        debugSelect("filteredCashInBankScenarioTimeSeriesSelector", result, accounts, postings, timeRange, totalOpeningBalance, cashInBankTotalBalances);
        return result;
    }
);
export const getExpiredBankAccountsSelector: (state: RootState) => BankAccount[] | undefined = createSelector<RootState, RootState, BankAccount[] | undefined>(
    state => state,
    (state) => {
        return state.bank.bankAccounts.filter(acc => acc.connectionStatus === ConnectionStatus.DISCONNECTED_AUTO)
    }
)

export const shouldSyncSelector: (state: RootState) => boolean | undefined = createSelector<RootState,
    RootState,
    BankAccount[] | undefined,
    BankAccount[] | undefined,
    boolean | undefined>(
    state => state,
    getExpiredBankAccountsSelector,
    bankAccountsSelector,
    (state, expiredAccounts, bankAccounts) => {
        return shouldSync(expiredAccounts, bankAccounts)
    }
)
export const getAccountIdsSelector: (state: RootState) => string[] | undefined = createSelector(
    state => state.accounting,
    (accounting) => {
        const accounts = Object.values(accounting.accounts || {});
        return accounts?.reduce((acc: string[], current) => current.cashFlowRole === CashFlowRole.CASH ? acc.concat(current.id) : acc, [])
    }
)

export const hasConnectedBankSelector: (state: RootState) => boolean = createSelector(
    bankAccountsSelector,
    (bankAccounts) => {
        return bankAccounts.some(bank => bank.connectionStatus === ConnectionStatus.CONNECTED)
    }
)


export const chartBarsSelector: (state: RootState) => BarsData|undefined = createSelector(
    activeTimeRangeSelector,
    state => state.chart,
    (timeRange, chart) => {
        if (chart.actual.length > 0 || chart.planning.length > 0) {
            const positivePlannings = {...chart.planning[0]};
            const negativePlannings = {...chart.planning[1]};
            const actual = {...chart.actual[0]};
            const negativeActual = {...chart.actual[1]};

            return {
                positivePlannings,
                negativePlannings,
                actual,
                negativeActual
            }
        }
        return undefined
    }
);
