import getTimeFrameEndDate from "../../../model/TimeFrame/functions/getTimeFrameEndDate";
import {dateToIso8601} from "../../../model/date";
import Bugsnag from "@bugsnag/js";
import isNumeric from "antd/lib/_util/isNumeric";
import * as actions from "./actionTypes";
import debug from "../../Debug/functions";

export function validateStatementCategorization(postingFilter: PostingFilter): actions.ReduceValidateStatementCategorizationAction {
    return {
        type: actions.VALIDATE_STATEMENT_CATEGORIZATION,
        payload: postingFilter
    };
}

const logValidationResult = (validatorName: string, resultInfo: object[], statementId: string) => {
    if (resultInfo.length !== 0) {
        const message = validatorName + " failed for statement " + statementId + " " + JSON.stringify(resultInfo);
        if (process.env.REACT_APP_BUGSNAG_KEY) {
            Bugsnag.notify(new Error(message));
        }
        debug(message, resultInfo);
    } else {
        const message = validatorName + " succeeded for statement " + statementId;
        debug(message);
    }
};

const CashFlowValidator = {
    validateChartTableSumIntegrity: (
        statementId: string,
        statementTimeSeriesRow: StatementDeepTimeSeriesRow,
        chartTimeSeries: TimeSeries
    ) => {
        let resultInfo: object[] = [];
        const rowDataPoints: TimeSeriesDataPoint[] | undefined = statementTimeSeriesRow.dataPoints;
        if (!!rowDataPoints) {
            const chartIsoCodes = chartTimeSeries.isoCodes;
            const chartValues = chartTimeSeries.values;
            let previousMonthsChartValue: number | null = null;

            for (let dataPoint of rowDataPoints) {
                const rowIsoDate = dateToIso8601(getTimeFrameEndDate(dataPoint.timeFrame));
                const chartValueIndex = chartIsoCodes.indexOf(rowIsoDate);
                const chartValue = chartValues[chartValueIndex];

                if (chartValueIndex >= 0 && previousMonthsChartValue !== null && isNumeric(previousMonthsChartValue) && chartValue !== null) {
                    const delta = Math.round((chartValue - previousMonthsChartValue) * 100);
                    if (delta !== dataPoint.value) {
                        resultInfo.push({
                            date: rowIsoDate,
                            chart: {
                                value: chartValue,
                                previousValue: previousMonthsChartValue,
                                delta: delta,
                            },
                            rowValue: dataPoint.value,
                        });
                    }
                }
                previousMonthsChartValue = chartValue;
            }
        }
        logValidationResult("Chart Table Sum Integrity Validation", resultInfo, statementId);

        return {
            success: resultInfo.length === 0,
            payload: resultInfo,
        };
    },
    validateCategorization: (
        statementId: string,
        statementTimeSeriesRow: StatementDeepTimeSeriesRow
    ) => {
        let resultInfo: object[] = [];
        const rowDataPoints: TimeSeriesDataPoint[] | undefined = statementTimeSeriesRow.dataPoints;
        if (!!rowDataPoints) {
            for (let dataPoint of rowDataPoints) {
                if (dataPoint.value !== 0) {
                    resultInfo.push({
                        value: dataPoint.value,
                        timeFrame: dataPoint.timeFrame
                    });
                }
            }
        }
        logValidationResult("Categorization Validation", resultInfo, statementId);

        return {
            success: resultInfo.length === 0,
            payload: resultInfo,
        };
    }
}

export default CashFlowValidator;
