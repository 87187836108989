import { call, put, select } from 'redux-saga/effects';
import axios from '../../../axios';
import * as selectors from '../../../reducer';
import {legacyAlert} from "../../UserAlert";
import { transformRestV1PostingResource } from '../../Accounting/saga/functions';
import * as RestV1Accounting from '../../Accounting/saga/RestV1Types';
import { dateToIso8601 } from '../../../model/date';
import { getTimeRangeEndDate, getTimeRangeStartDate } from '../../../model/TimeRange';
import * as postingActions from '../../Posting/actions';
import { PostingChannel } from '../../../model/Posting/enums';
import { getForecastStartDate } from './function-getForecastStartDate';
import { PlanningTypeEnum } from "../../../model/Planning/Enums";
import * as RestV1 from "./RestV1Types";
import { RemotePosting } from "../../Accounting/saga/RestV1Types";
import tld from "../../../tld";

function axiosRequestRemoteRestV1PlanningPosting(
    startDate: Date|null,
    endDate: Date,
    planningId: string
): Promise<RestV1Accounting.RemotePostingsResponse> {
    if (startDate && startDate > endDate) {
        return new Promise<RestV1Accounting.RemotePostingsResponse>(resolve => resolve({ data: [] }));
    }
    const url = tld(process.env.REACT_APP_URL_API_PLAN) + '/v1/accounting/postings';
    let params: RestV1.GetPostingsParams = {
        endDate: dateToIso8601(endDate),
        sourceIds: planningId,
    };
    if (startDate) params.startDate = dateToIso8601(startDate);
    return axios.get(url, {params}).then(response => response.data);
}

function* loadRemoteRestV1PlanningPosting(startDate: Date|null, endDate: Date, planningId: string) {
    try {
        return yield axiosRequestRemoteRestV1PlanningPosting(startDate, endDate, planningId);
    } catch (axiosError) {
        legacyAlert(axiosError, 'load postings');
        return { data: [] };
    }
}

export function* loadPlanningPostings(planning: Planning) {
    try {
        const range: TimeRange = yield select(selectors.findPlanningRange);
        if (undefined === range) {
            console.warn("Planning range was undefined when loading planning postings.");
        } else {
            const forecastStartDate: Date = yield call(getForecastStartDate, getTimeRangeStartDate(range));
            const rangeEndDate = getTimeRangeEndDate(range);
            //
            let remotePostings: RemotePosting[];
            let postingChannel: PostingChannel;
            switch (planning.planningType) {
                case PlanningTypeEnum.FORECAST:
                    remotePostings = yield call(loadRemoteRestV1PlanningPosting, forecastStartDate, rangeEndDate, planning.id);
                    postingChannel = PostingChannel.PLANNING;
                    break;
                case PlanningTypeEnum.ADJUSTMENT:
                    remotePostings = yield call(loadRemoteRestV1PlanningPosting, null, rangeEndDate, planning.id);
                    postingChannel = PostingChannel.ADJUSTMENT;
                    break;
                default:
                    // noinspection ExceptionCaughtLocallyJS
                    throw new Error('Planning type not known: ' + planning.planningType);
            }
            const postings: Posting[] = remotePostings.map(
                remotePosting => transformRestV1PostingResource(remotePosting, postingChannel)
            );
            // #STATEMENT-102 delete existing planning postings to update recurrent entries properly
            // #STATEMENT-119 required to delete both channels to also support planning type change
            yield put(postingActions.postingDeleted(PostingChannel.ADJUSTMENT, planning.id));
            yield put(postingActions.postingDeleted(PostingChannel.PLANNING, planning.id));
            yield put(postingActions.postingsLoaded(postings, postingChannel));
            return postings;
        }
    } catch (error) {
        if (error.isAxiosError) {
            legacyAlert(error, 'load planning postings');
        } else {
            throw error;
        }
    }
}
