import {PlanningMode, TransferAccountMode} from "../enums";
import {PaymentTypeEnum} from "../../../model/Planning/Enums";
import {PlanningFormInternalValues, PlanningMetaAccounts} from "../PlanningForm";

export default function reduceTransferPlanningAccounts(
    oldPlanningAccounts: PlanningMetaAccounts,
    newPlanningAccounts: PlanningMetaAccounts,
    valueState: PlanningFormInternalValues,
    planningMode?: PlanningMode
): PlanningFormInternalValues {
    if (oldPlanningAccounts.depositAccount !== newPlanningAccounts.depositAccount) {
        if (valueState.depositAccount !== newPlanningAccounts.depositAccount) {
            valueState = {
                ...valueState,
                depositAccount: newPlanningAccounts.depositAccount,
            };
        }
    }
    if (oldPlanningAccounts.disbursementAccount !== newPlanningAccounts.disbursementAccount) {
        if (valueState.disbursementAccount !== newPlanningAccounts.disbursementAccount) {
            valueState = {
                ...valueState,
                disbursementAccount: newPlanningAccounts.disbursementAccount,
            };
        }
    }
    if (oldPlanningAccounts.receivableAccount !== newPlanningAccounts.receivableAccount) {
        if (valueState.transferAccount?.mode === TransferAccountMode.OTHER &&
            valueState.paymentType === PaymentTypeEnum.DEPOSIT
        ) {
            valueState = {
                ...valueState,
                transferAccount: {
                    ...valueState.transferAccount,
                    account: newPlanningAccounts.receivableAccount
                },
            };
        }
    }
    if (oldPlanningAccounts.payableAccount !== newPlanningAccounts.payableAccount) {
        if (valueState.transferAccount?.mode === TransferAccountMode.OTHER &&
            valueState.paymentType === PaymentTypeEnum.DISBURSEMENT
        ) {
            valueState = {
                ...valueState,
                transferAccount: {
                    ...valueState.transferAccount,
                    account: newPlanningAccounts.payableAccount
                },
            };
        }
    }
    if (valueState.transferAccount?.mode === TransferAccountMode.PARTICIPANT) {
        switch (planningMode) {
            case PlanningMode.RECEIVABLE:
                if (newPlanningAccounts.debtorAccount !== valueState.transferAccount?.account) {
                    valueState = {
                        ...valueState,
                        transferAccount: {
                            ...valueState.transferAccount,
                            account: newPlanningAccounts.debtorAccount
                        },
                    };
                }
                break;
            case PlanningMode.PAYABLE:
                if (newPlanningAccounts.creditorAccount !== valueState.transferAccount?.account) {
                    valueState = {
                        ...valueState,
                        transferAccount: {
                            ...valueState.transferAccount,
                            account: newPlanningAccounts.creditorAccount
                        },
                    };
                }
                break;
            default:
                if (undefined !== valueState.transferAccount?.account) {
                    valueState = {
                        ...valueState,
                        transferAccount: {
                            ...valueState.transferAccount,
                            account: undefined
                        },
                    };
                }
        }
    }
    return valueState;
}
