function deepFindRowByAccountId(rowContainer: StatementRowContainer, accountId: string): StatementRow|undefined {
    if (undefined !== rowContainer.rows) {
        for (let row of rowContainer.rows) {
            if (undefined !== row.accountIds && row.accountIds.indexOf(accountId) > -1) {
                return row;
            } else {
                const subResult = deepFindRowByAccountId(row, accountId);
                if (undefined !== subResult) {
                    return subResult;
                }
            }
        }
    }
    return undefined;
}

export default function findStatementRowByAccountId(statement: Statement, accountId: string): StatementRow|undefined {
    return deepFindRowByAccountId(statement, accountId);
}
