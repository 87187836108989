import {all, call, put, select} from "redux-saga/effects";
import debug from "../../Debug/functions/debug";

import {StatementProfileType} from "../../../model/Statement";

import {activeDensitySelector} from "../../TimeTravel/selectors";
import {standardStatementSelector} from "../../Statement/selectors";
import {
    activeTimeSeriesViewportSelector,
    experimentalCashInBankTableViewportSelector,
    isBankActiveSelector
} from "../../selectors";

import {waitForStateReady} from "../../functions";
import getRemoteTimeSeries from "./getRemoteTimeSeries";
import {TimeSeriesTopic, TimeSeriesTrim, TimeSeriesType} from "../enums";
import {
    ReduceTimeSeriesLoadedAction,
    ReduceTimeSeriesLoadingAction,
    TimeSeriesReducerAction, TransactionDataLoaded,
} from "../reducer";
import {TimeSeriesDensity} from "../../../model/TimeSeries/enums";
import {encodeStatementRowUrn} from "../../../model/StatementUri/functions/encodeStatementRowUrn";
import getFlowForRow from "../../Statement/functions/getFlowForRow";
import loadCashInBankBalancesTimeSeries from "./loadCashInBankBalancesTimeSeries";
import axios from "axios";
import moment from "moment";
import transformBankDataMap from "../../../model/TimeFrame/functions/transformBankDataMap";
import tld from "../../../tld";

type StatementType = ReturnType<typeof standardStatementSelector>;
type DensityType = ReturnType<typeof activeDensitySelector>;

function* requestTransactionDataByRowId(channels, density, statementId, rowId) {
    const start = moment(channels.accounting ? channels.accounting.lastCashDate : channels.bank.firstCashDate).add(1, 'day').format('YYYY-MM-DD');
    const end = moment(channels.bank.lastCashDate).format('YYYY-MM-DD');
    const endPoint = '/v1/cashFlow/cashFlows?'
    const paramsObj = {
        start,
        end,
        density,
        channels: 'bank',
        trim: 'all',
        statementId,
        statementRowId: rowId
    }

    const params = Object.keys(paramsObj).map(function (key) {
        return key + '=' + paramsObj[key];
    }).join('&');

    const data: { timeFrames: string[], values: number[] } = yield axios.get(tld(process.env.REACT_APP_URL_API_TIME_SERIES) + endPoint + params).then(res => res.data)
    return yield data;
}

function* getNestedTransactionDataForStatementRows(statementRows: StatementRow[], channels, density, transactionsTransformedData: TimeSeriesValueNestedMap = {}) {
    for (let row of statementRows) {

        if(row?.constraintType?.subType === 'bank' && row?.constraintType?.mainType === 'category'){
            yield;
        }
        const bankRow: StatementRow | undefined = row.rows?.find(subRow => {
            if (subRow.constraintType) {
                return subRow?.constraintType?.subType === 'bank' && subRow?.constraintType?.mainType === 'category'
            }
            return false;
        });
        if (bankRow) {
            const data: { timeFrames: string[], values: number[] } = yield* requestTransactionDataByRowId(channels, density, row.statementId, row.id);
            transactionsTransformedData = transformBankDataMap(transactionsTransformedData, bankRow.id, data)
        }
        if (row.rows && row.rows.size >= 1) {
            transactionsTransformedData = yield getNestedTransactionDataForStatementRows(row.rows.toArray(), channels, density, transactionsTransformedData)
        }
    }
    return transactionsTransformedData
}

function* loadTotalCashFlowBankTransactionData() {
    const density: ReturnType<typeof activeDensitySelector> = yield select(activeDensitySelector);
    const stats: AccountingStats = yield select(state => state.accounting.stats);
    const cashFlowStatement: ReturnType<typeof standardStatementSelector> = yield select(standardStatementSelector, StatementProfileType.CASH_FLOW);

    if (stats.channels && cashFlowStatement) {
        const {channels} = stats;
        if (channels.bank) {
            if (cashFlowStatement?.rows) {
                let data: TimeSeriesValueNestedMap = yield getNestedTransactionDataForStatementRows(cashFlowStatement.rows.toArray(), channels, density)

                const action: TransactionDataLoaded = {
                    type: TimeSeriesReducerAction.REDUCE_TRANSACTION_DATA_LOADED,
                    payload: data
                }
                yield put(action);
            }
        }
    }
}


function* executeLoadStatementTimeSeries(
    density: TimeSeriesDensity,
    viewport: TimeSeriesViewPort,
    row?: StatementRow,
    isTotalRow: boolean = false,
) {
    const {channels, endDate} = viewport;
    const stats: AccountingStats = yield select(state => state.accounting.stats);

    const defaultDate = new Date(2014);

    const startDate = stats.channels?.bank?.firstDate || stats.channels?.bank?.firstCashDate || defaultDate
    if (undefined === channels) return; // @todo #FEATURE-1570
    const accountIds = row?.accountIds;
    if (!row || undefined !== accountIds && accountIds.length) {
        const isCashFlowRow = "category" === row?.constraintType?.mainType
            && (["flowIn", "flowOut"].indexOf(row?.constraintType?.subType) > -1);
        const topic = TimeSeriesTopic.CASH_IN_BANK;
        const type = isCashFlowRow ? TimeSeriesType.CASH_FLOW : TimeSeriesType.BALANCES;
        const flow = getFlowForRow(row);

        const subjectUrn = row && !isTotalRow ? encodeStatementRowUrn(row) : undefined;
        const key = {
            type,
            topic,
            channels,
            flow,
            resolution: {
                density,
                count: 1,
            },
            subjectUrn: subjectUrn,
        };
        const loadingAction: ReduceTimeSeriesLoadingAction = {
            type: TimeSeriesReducerAction.REDUCE_TIME_SERIES_LOADING,
            payload: {key}
        };
        yield put(loadingAction);
        const timeSeries = yield* getRemoteTimeSeries(
            startDate,
            endDate,
            density,
            topic,
            type,
            flow,
            channels,
            accountIds,
            TimeSeriesTrim.RIGHT
        );
        const action: ReduceTimeSeriesLoadedAction = {
            type: TimeSeriesReducerAction.REDUCE_TIME_SERIES_LOADED,
            payload: {
                key: key,
                timeSeries: timeSeries,
            }
        };
        yield put(action);

        if (row?.rows) {
            for (let subRow of row.rows) {
                yield* executeLoadStatementTimeSeries(density, viewport, subRow);
            }
        }
    }
}


export function* loadNestedBankTimeSeries(cashInBankStatement) {
    const viewport: ReturnType<typeof activeTimeSeriesViewportSelector> = yield select(activeTimeSeriesViewportSelector);
    const density: DensityType = yield select(activeDensitySelector);
    const cashInBankTotalRow = cashInBankStatement.rows.toArray()[0];
    if(viewport) {
        yield* executeLoadStatementTimeSeries(density, viewport, cashInBankTotalRow, true);
        for (let row of cashInBankStatement.rows) {
            yield* executeLoadStatementTimeSeries(density, viewport, row);
        }
    }
}

export default function* loadStatementBankTimeSeries() {
    const isBankActive = yield select(isBankActiveSelector);

    if (!isBankActive) {
        return;
    }
    debug("loadStatementBankTimeSeries");

    yield* waitForStateReady("accounting");
    yield* waitForStateReady("statement");
    yield* waitForStateReady("timeTravel");


    const viewport: ReturnType<typeof experimentalCashInBankTableViewportSelector> = yield select(experimentalCashInBankTableViewportSelector);

    if (undefined === viewport) return;
    if (undefined === viewport?.channels) return; // @todo #FEATURE-1570
    const cashInBankStatement: StatementType = yield select(standardStatementSelector, StatementProfileType.CASH_IN_BANK);
    if (cashInBankStatement) {
        yield all([call(loadTotalCashFlowBankTransactionData), call(loadCashInBankBalancesTimeSeries), call(loadNestedBankTimeSeries, cashInBankStatement)]);
    }

}
