import PropTypes from 'prop-types';
import { List } from "immutable";

export const StatementRestV1RowPropType = PropTypes.shape({
    id: PropTypes.string, // optional
    label: PropTypes.string.isRequired,
    rows: PropTypes.array, // recursive spec, optional
});

let statementRowPropTypeSpec = PropTypes.shape({
    id: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
});
statementRowPropTypeSpec.rows = PropTypes.arrayOf(statementRowPropTypeSpec);

export const  StatementPropType = PropTypes.shape({
    id: PropTypes.string.isRequired,
    rows: PropTypes.instanceOf(List).isRequired,
    draggingRowId: PropTypes.oneOfType([PropTypes.string, PropTypes.oneOf([null]).isRequired]),
});

export const StatementRowLocationPropType = PropTypes.shape({
    type: PropTypes.oneOf(['flat', 'deep']).isRequired,
    index: PropTypes.number.isRequired,
    parentRowId: PropTypes.string,
});
