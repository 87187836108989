import {all, call, put, select} from "redux-saga/effects";
import {
    loadCashFlowTransactions,
    resetTransactionLoading,
    setLoadingTransactions,
    unselectTransactions
} from "../actions";
import {cashFlowStatementSelector} from "../../selectors";
import axios from "axios";
import {getTransactionsSelector} from "../selectors";
import {notification} from "antd";
import tld from "../../../tld";


export function* assignCategoryToTransaction({transactionId, statement, category}) {
    const transactions = yield select(getTransactionsSelector);
    const url = tld(process.env.REACT_APP_URL_API_STATEMENT) + `/v1/statements/${statement.id}/rows/${category.id}/postingsCategorization`;

    const res = yield axios.post(url, {
        postingId: transactionId,
        postingSide: 'account'
    })
    if (!res.data) return;

    const transformedResponse = {
        statementId: statement.id,
        statementRowId: {
            user: category.id,
            label: category.label
        },
    }
    const transactionToUpdate = transactions.find(t => t.id === transactionId);
    return {
        ...transactionToUpdate,
        categorizations: [...transactionToUpdate.categorizations.filter(category => category.statementId !== transformedResponse.statementId),
            transformedResponse]
    }

}

export function* updateBankTransactions(action) {
    const cashFlowStatement = yield select(cashFlowStatementSelector);
    const transactions = yield select(getTransactionsSelector);
    const {transactionIds, category} = action.payload;

    yield put(setLoadingTransactions(transactionIds));
    try {
        const updatedTransactions: Transaction[] = yield all(transactionIds.map(id => call(assignCategoryToTransaction, {
            transactionId: id,
            statement: cashFlowStatement,
            category
        })))
        if(transactionIds.length > 1){
            yield put(unselectTransactions())
        }
        yield put(resetTransactionLoading())

        const updatedData = transactions.map((t: Transaction) => {
            const transactionToUpdate = updatedTransactions.find(ut => ut.id === t.id)
            return transactionToUpdate?.id === t.id ? {
                ...transactionToUpdate,
            } : t
        })

        yield put(loadCashFlowTransactions(updatedData))
    } catch (ex) {
        yield put(resetTransactionLoading())
        notification.error({message: 'Error occurred while assigning category', duration: 5})
    }
}
