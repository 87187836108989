import React, {useCallback} from "react";
import {useTranslation} from "react-i18next";
import moment, {Moment} from "moment";
import debug from "../../../services/Debug/functions/debug";

import {Col, DatePicker, Row, Switch} from "antd";

type SelectRecurrenceEndValue = {
    active: boolean,
    date?: Moment,
};

const defaultDate = moment().add(3, 'months');

const SelectRecurrenceEnd: React.VFC<AntdFormControlProps<SelectRecurrenceEndValue>> = ({
                                                                                            defaultValue = {
                                                                                                active: false,
                                                                                                date: defaultDate
                                                                                            },
                                                                                            value,
                                                                                            onChange = () => {
                                                                                            },
                                                                                        }) => {
    debug("SelectRecurrenceEnd::render()", defaultValue, value);

    const {t} = useTranslation();

    const active = value ? value.active : defaultValue.active;
    const date = value?.date ? value.date : defaultValue.date;

    const onSwitchChange = useCallback(
        (inputActive) => {
            if (inputActive !== active) {
                onChange({active: inputActive, date: !inputActive? undefined: date});
            }
        }, [active, date, onChange]);

    const onDateChange = useCallback(
        (inputDate) => {
            if (inputDate !== date) {
                onChange({active, date: inputDate});
            }
        }, [active, date, onChange]);

    // @todo localize date format
    const datePicker = active ? <Col span={21}><DatePicker
        format="DD.MM.YYYY"
        placeholder={t("Datum auswählen")}
        showToday={false}
        onChange={onDateChange}
        defaultValue={date}
    /></Col> : null;

    return <Row>
        <Col span={3}><Switch defaultChecked={active} onChange={onSwitchChange}/></Col>
        {datePicker}
    </Row>;
}

export default SelectRecurrenceEnd;
