import DateRange from "../../../model/DateRange";
import {
    makeDailyTimeFramesForTimeRange,
    getTimeRangeStartYear, getTimeRangeEndYear,
    getTimeRangeStartMonth, getTimeRangeEndMonth,
    getTimeRangeStartDay, getTimeRangeEndDay,
} from "../../../model/TimeRange";
import {TimeSeriesDensity} from "../../../model/TimeSeries/enums";
import {makeTimeSeriesByTimeFrames} from "../../../model/TimeSeries/functions";
import {timeFrameToIsoString} from "../../../model/TimeFrame/functions";
import {dateToIso8601} from "../../../model/date";
import dateToTimeCode from "../../../model/TimeSeries/functions/dateToTimeCode";

function getDailyPostingTotals(postings: Posting[], accountIds: string[]): TimeSeries {
    let totals: {[isoDate: string]: number} = {};
    let startDate: Date|undefined = undefined;
    let endDate: Date|undefined = undefined;
    for (let posting of postings) {
        const receiptDate = posting.receiptDate;
        const dateKey = dateToIso8601(receiptDate);
        if (undefined === startDate || receiptDate < startDate) {
            startDate = receiptDate;
        }
        if (undefined === endDate || receiptDate > endDate) {
            endDate = receiptDate;
        }
        if (undefined === totals[dateKey]) {
            totals[dateKey] = 0;
        }
        if (accountIds.indexOf(posting.accountId) > -1) {
            const flowFactor = "Cr" === posting.type ? -1 : 1;
            const amount = flowFactor * posting.amount;
            totals[dateKey] += amount;
        }
        if (accountIds.indexOf(posting.contraAccountId) > -1) {
            const flowFactor = "Cr" === posting.type ? 1 : -1;
            const amount = flowFactor * posting.amount;
            totals[dateKey] += amount;
        }
    }
    const result: TimeSeries = {
        resolution: {
            density: TimeSeriesDensity.DAY,
            count: 1
        },
        timeCodes: [],
        isoCodes: [],
        values: []
    };
    if (undefined !== startDate && undefined !== endDate) {
        let indexDate = new Date(startDate.getTime()); // clone required to avoid side effects
        while (indexDate <= endDate) {
            const dateKey = dateToIso8601(indexDate);
            const timeCode = dateToTimeCode(indexDate);
            result.timeCodes.push(timeCode);
            result.isoCodes.push(dateKey);
            result.values.push(undefined === totals[dateKey] ? 0 : totals[dateKey]);
            // @see #FEATURE-887 - leave this time based approach for the +1 day increment to not break again
            indexDate.setTime(indexDate.getTime() + 86400000);
        }
    }
    return result;
}

/**
 * @deprecated for slim client
 */
export default function getCumulatedTimeSeries(
    timeRange: TimeRange,
    postings: Posting[],
    accountIds: string[],
    totalOpeningBalance: number
): TimeSeries {
    const cumulate = true;
    const cashFlowDailyTotals: TimeSeries = getDailyPostingTotals(postings, accountIds);

    const fullRange = new DateRange(
        getTimeRangeStartYear(timeRange), getTimeRangeEndYear(timeRange),
        getTimeRangeStartMonth(timeRange), getTimeRangeEndMonth(timeRange),
        getTimeRangeStartDay(timeRange), getTimeRangeEndDay(timeRange)
    );
    const fullRangeIsoDays = fullRange._iso8601DateTimeCodes;

    const iso8601DateStampToDateString = (dateStamp: number): string =>
    {
        const dateString = dateStamp.toString();
        return dateString.substr(0, 4)
            + '-' + dateString.substr(4, 2)
            + '-' + dateString.substr(6, 2);
    };

    // calculate full range data
    let fullRangeDailyTotalSum = {};
    let fullRangeTotal = totalOpeningBalance;
    for (let i = 0; i < fullRangeIsoDays.length; i++) {
        if (!cumulate) {
            fullRangeTotal = 0;
        }
        let dateStamp = fullRangeIsoDays[i];
        const dataPointIndex = cashFlowDailyTotals.timeCodes.indexOf(dateStamp);
        if (dataPointIndex > -1) {
            fullRangeTotal += (cashFlowDailyTotals.values[dataPointIndex] || 0);
        }
        fullRangeDailyTotalSum[iso8601DateStampToDateString(dateStamp)] = fullRangeTotal;
    }

    // grab visible range data
    const visibleTimeFrames: TimeFrame[] = makeDailyTimeFramesForTimeRange(timeRange, 1);
    const timeSeries: TimeSeries = makeTimeSeriesByTimeFrames(visibleTimeFrames, []);

    let datesIso8601: string[] = visibleTimeFrames.map(timeFrame => timeFrameToIsoString(timeFrame));
    for (let i = 0; i < datesIso8601.length; i++) {
        const dateIso8601 = datesIso8601[i];
        if (0 === i && undefined === fullRangeDailyTotalSum[dateIso8601]) {
            timeSeries.values.push(totalOpeningBalance);
        } else {
            timeSeries.values.push(fullRangeDailyTotalSum[dateIso8601]);
        }
    }
    return timeSeries;
}
