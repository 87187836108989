export enum TimeSeriesAction {
    TIME_SERIES_STATS_UPDATE_PUSHED = "TIME_SERIES_STATS_UPDATE_PUSHED",
}

export type TimeSeriesStatsUpdate = {
    type: typeof TimeSeriesAction.TIME_SERIES_STATS_UPDATE_PUSHED,
    payload: TimeSeriesStats,
}

export function applyTimeSeriesStatsUpdateAction(stats: TimeSeriesStats): TimeSeriesStatsUpdate
{
    return {
        type: TimeSeriesAction.TIME_SERIES_STATS_UPDATE_PUSHED,
        payload: stats,
    };
}
