import { all, takeEvery } from "redux-saga/effects"
import * as actionTypes from "./actionTypes";
import postingsLoaded from "./saga/postingsLoaded";
import postingDeleted from "./saga/postingDeleted";

export default function* saga() {
    yield all([
        yield takeEvery(actionTypes.POSTINGS_LOADED, postingsLoaded),
        yield takeEvery(actionTypes.POSTING_DELETED, postingDeleted),
    ]);
}
