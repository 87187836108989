import axios from "../../../axios";
import {errorAlert} from "../../UserAlert";
import {RestV1TimeSeries, RestV1TimeSeriesResponse} from "../RestV1.types";
import {dateToIso8601} from "../../../model/date";
import {TimeSeriesDensity} from "../../../model/TimeSeries/enums";
import {TimeSeriesFlow, TimeSeriesTopic, TimeSeriesTrim, TimeSeriesType} from "../enums";
import {decodeRestTimeFrame} from "../functions/decodeRestTimeFrame";
import {timeFrameToIsoString, timeFrameToTimeCode} from "../../../model/TimeFrame/functions";
import {PostingChannel} from "../../../model/Posting/enums";
import tld from "../../../tld";

function getDensityParam(density: TimeSeriesDensity): string {
    switch (density) {
        case TimeSeriesDensity.DAY:
            return "day";
        case TimeSeriesDensity.WEEK:
            return "week";
        case TimeSeriesDensity.MONTH:
            return "month";
        case TimeSeriesDensity.QUARTER:
            return "quarter";
        case TimeSeriesDensity.HALFYEAR:
            return "halfYear";
        case TimeSeriesDensity.YEAR:
            return "year";
        default:
            throw Error(`Density not known: ${density}`);
    }
}

function getTopicParam(topic: TimeSeriesTopic): string {
    switch (topic) {
        case TimeSeriesTopic.CASH_IN_BANK:
            return "cashInBank";
        default:
            throw Error(`Topic not known: ${topic}`);
    }
}

function getTypeParam(type: TimeSeriesType): string {
    switch (type) {
        case TimeSeriesType.BALANCES:
            return "balances";
        case TimeSeriesType.CASH_FLOW:
            return "cashFlows";
        default:
            throw Error(`Type not known: ${type}`);
    }
}

function getFlowParam(flow: TimeSeriesFlow): string|undefined {
    switch (flow) {
        case TimeSeriesFlow.IN:
            return "in";
        case TimeSeriesFlow.OUT:
            return "out";
        case TimeSeriesFlow.IN_OUT:
            return undefined;
        default:
            throw Error(`Flow not known: ${flow}`);
    }
}

function* loadRemoteRestV1TimeSeries(
    startDate: Date,
    endDate: Date,
    density: TimeSeriesDensity,
    topic: TimeSeriesTopic,
    type: TimeSeriesType,
    flow: TimeSeriesFlow = TimeSeriesFlow.IN_OUT,
    channels?: PostingChannel[],
    accountIds?: string[],
    trim?: TimeSeriesTrim
): Generator<
    Promise<RestV1TimeSeriesResponse>,
    RestV1TimeSeries,
    RestV1TimeSeriesResponse
> {
    try {
        const topicParam = getTopicParam(topic);
        const typeParam = getTypeParam(type);
        const url = `${tld(process.env.REACT_APP_URL_API_TIME_SERIES)}/v1/${topicParam}/${typeParam}`;
        const params = {
            start: dateToIso8601(startDate),
            end: dateToIso8601(endDate),
            density: getDensityParam(density),
            flow: getFlowParam(flow),
            channels: channels?.length ? channels.join(",") : undefined,
            accountIds: accountIds?.length ? accountIds.join(",") : undefined,
            trim: trim ? trim : TimeSeriesTrim.ALL,
        };
        const response = yield axios.get<RestV1TimeSeries>(url, {params});
        return response.data;
    } catch (axiosError) {
        errorAlert("TimeSeries", axiosError, "load cash in bank");
        return {timeFrames:[], values:[]};
    }
}

function transformRemoteTimeSeries(remoteTimeSeries: RestV1TimeSeries, density: TimeSeriesDensity): SparseTimeSeries {
    const timeFrames = remoteTimeSeries.timeFrames.map(restValue => decodeRestTimeFrame(restValue));
    return {
        resolution: {
            density,
            count: 1,
        },
        values: remoteTimeSeries.values,
        isoCodes: timeFrames.map(timeFrame => timeFrameToIsoString(timeFrame)),
        timeCodes: timeFrames.map(timeFrame => timeFrameToTimeCode(timeFrame)),
    };
}

export default function* getRemoteTimeSeries(
    startDate: Date,
    endDate: Date,
    density: TimeSeriesDensity,
    topic: TimeSeriesTopic,
    type: TimeSeriesType,
    flow: TimeSeriesFlow = TimeSeriesFlow.IN_OUT,
    channels?: PostingChannel[],
    accountIds?: string[],
    trim?: TimeSeriesTrim
): Generator<
    Promise<RestV1TimeSeriesResponse>,
    SparseTimeSeries,
    RestV1TimeSeriesResponse
> {
    const remoteTimeSeries = yield* loadRemoteRestV1TimeSeries(startDate, endDate, density, topic, type, flow, channels, accountIds, trim);
    return transformRemoteTimeSeries(remoteTimeSeries, density);
}
