// https://github.com/garbles/flag

import createFlags from "flag";
import {createReduxBindings} from "flag/redux";
import {reduce} from 'lodash';
import tld from "./tld";

export enum FlaggedFeature {
    inviteUser = 'inviteUser',
    scenario = 'scenario',
    planning = 'planning',
    multiUnit= 'multiUnit', // This flag is set ON/OFF at runtime
    quickPlanning = 'quickPlanning',
    deleteRow = 'deleteRow',
    forecast = 'forecast',
    addCompany = 'addCompany',
    drawers = 'drawers',
    sevDesk = 'sevDesk',
    buchhaltungsButler = 'buchhaltungsButler',
    finApi = 'finApi',
    upload = 'upload',
    dashboard = 'dashboard',
}

export type FeatureFlags = {
    [k in keyof typeof FlaggedFeature]: boolean
};

const {FlagsProvider, Flag, useFlag, useFlags} = createFlags<FeatureFlags>();

export { FlagsProvider, Flag, useFlag, useFlags };

const {
    setFlagsAction,
    getFlagsSelector,
    createFlagsReducer,
    ConnectedFlagsProvider
} = createReduxBindings<FeatureFlags>(FlagsProvider);

export {
    setFlagsAction,
    getFlagsSelector,
    createFlagsReducer,
    ConnectedFlagsProvider
};


type FeatureFlagMapping = { [k in keyof typeof FlaggedFeature]: string };
const defaultFeatureEnvMapping: FeatureFlagMapping = {
    [FlaggedFeature.inviteUser]: 'REACT_APP_FEATURE_INVITE_USER',
    [FlaggedFeature.multiUnit]: 'REACT_APP_FEATURE_QUICK_MULTI_UNIT',
    [FlaggedFeature.scenario]: 'REACT_APP_FEATURE_SCENARIO',
    [FlaggedFeature.planning]: 'REACT_APP_FEATURE_PLANNING',
    [FlaggedFeature.quickPlanning]: 'REACT_APP_FEATURE_QUICK_PLANNING',
    [FlaggedFeature.deleteRow]: 'REACT_APP_FEATURE_DELETE_ROW',
    [FlaggedFeature.forecast]: 'REACT_APP_FEATURE_FORECAST',
    [FlaggedFeature.addCompany]: 'REACT_APP_FEATURE_ADD_COMPANY',
    [FlaggedFeature.drawers]: 'REACT_APP_FEATURE_DRAWERS',
    [FlaggedFeature.sevDesk]: 'REACT_APP_FEATURE_SEVDESK',
    [FlaggedFeature.buchhaltungsButler]: 'REACT_APP_FEATURE_BUCHHALTUNGSBUTLER',
    [FlaggedFeature.finApi]: 'REACT_APP_FEATURE_FINAPI',
    [FlaggedFeature.upload]: 'REACT_APP_FEATURE_UPLOAD',
    [FlaggedFeature.dashboard]: 'REACT_APP_FEATURE_DASHBOARD',
}

export const buildFlags = (env: { [k in string]: string|undefined }, envMapping: FeatureFlagMapping): FeatureFlags => {
    return reduce(envMapping, (result, envVarName, feature) =>  {
        switch (env[envVarName]) {
            case "true":
                result[feature] = true;
                break;
            case "false":
                result[feature] = false;
                break;
            default:
                const enabledDomains= ("" + env[envVarName]).split(',');
                result[feature] = enabledDomains.indexOf(tld()) > -1;
        }
        return result;
    }, {}) as FeatureFlags;
}

export const flagsReducer = createFlagsReducer(buildFlags(process.env, defaultFeatureEnvMapping));
