import {all, spawn, takeEvery} from "redux-saga/effects"
import * as actionTypes from "./actionTypes";
import initAll from "./saga/initAll";
import loadLogins from "./saga/loadLogins";
import logout from "./saga/logout";
import receiveBroadcast from "./saga/receiveBroadcast";
import renewLogin from "./saga/renewLogin";

export default function* saga() {
    yield all([
        yield spawn(initAll),
        yield takeEvery(actionTypes.RECEIVE_AUTH_BROADCAST, receiveBroadcast),
        yield takeEvery(actionTypes.LOAD_LOGINS, loadLogins),
        yield takeEvery(actionTypes.LOGOUT, logout),
        yield takeEvery(actionTypes.RENEW_LOGIN, renewLogin),
    ]);
}
