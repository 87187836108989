import axios from '../../../axios';
import {put} from "redux-saga/effects";

import * as RestV1 from "./RestV1Types";
import * as actionTypes from "../actionTypes";
import tld from "../../../tld";


function* axiosUpdateRemoteRestV1Scenarios({id, ...payload}) {
    return yield axios.put(tld(process.env.REACT_APP_URL_API_PLAN) + '/v1/scenarios/' + id, payload);
}

export default function* updateScenarios(scenarioData) {
    const {data}: RestV1.ScenarioDataResponse = yield axiosUpdateRemoteRestV1Scenarios(scenarioData.payload);

    yield put({
        type: actionTypes.REDUCE_SCENARIO_UPDATED,
        payload: data
    });
}
