import {all, takeEvery} from "redux-saga/effects";
import * as TimeTravelActions from "../TimeTravel/actionTypes";
import requestBarsData from "./saga/verticalBars";
import {BankReducerAction} from "../Bank/reducer";
import {TimeSeriesReducerAction} from "../TimeSeries/reducer";
export default function* saga() {
    yield all([
        yield takeEvery(TimeTravelActions.REDUCE_DENSITY_SELECTED, requestBarsData),
        yield takeEvery(TimeTravelActions.REDUCE_TIMEFRAME_SELECTED, requestBarsData),
        yield takeEvery(BankReducerAction.REDUCE_VISIBILITY, requestBarsData),
        yield takeEvery(TimeSeriesReducerAction.REDUCE_TIME_SERIES_LOADED, requestBarsData),
        ]
    )
}
