import store from "../store";
import {AxiosError, AxiosInstance} from "axios";
import {subAxios as axios} from "./index";
import debug from "../services/Debug/functions/debug";
import {timeoutLogout} from "../services/Auth/actions";

function timeout(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
}

async function axiosRetryLoop(
    error: AxiosError,
    retries: number = 3,
    delay: number = 1000,
) {
    if (error.isAxiosError && 401 === error.response?.status) {
        debug("axiosRetryLoop", error, retries);
        if (retries) {
            await timeout(delay);
            const config = error.config;
            // apply renewed authorization properly
            config.headers["Authorization"] = axios.defaults.headers.common["Authorization"];
            return axios.request(config)
                .catch(nextError => axiosRetryLoop(nextError, retries - 1, delay));
        } else {
            store.dispatch(timeoutLogout());
            throw error;
        }
    } else {
        throw error;
    }
}

export default function applyRetryInterceptor(axios: AxiosInstance) {
    axios.interceptors.response.use((response) => response, error => axiosRetryLoop(error));
}
