import {ReceiveAuthBroadcastAction} from "../actionTypes";
import {AuthMessageType} from "../AuthBroadcast";
import {put} from "redux-saga/effects";
import {loadLogins, logout} from "../actions";
import {LogoutTrigger} from "../enums";

export default function* receiveBroadcast(action: ReceiveAuthBroadcastAction) {
    const messageType = action.payload.type;
    switch (action.payload.type) {
        case AuthMessageType.LOGOUT:
            yield put(logout(LogoutTrigger.BROADCAST));
            break;
        case AuthMessageType.REFRESH:
            yield put(loadLogins());
            break;
        default:
            console.error("Auth broadcast message type not supported: " + messageType);
    }
}
