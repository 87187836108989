import {getTimeRangeStartDate} from "../../../model/TimeRange";
import getEndDate from "../../../model/TimeSeries/functions/getEndDate";
import overloadTimeSeries, {OverloadMode} from "../../../model/TimeSeries/functions/overloadTimeSeries";
import dateToTimeFrame from "../../../model/TimeFrame/functions/dateToTimeFrame";
import {TimeSeriesDensity} from "../../../model/TimeSeries/enums";
import calculateCashInBankTimeSeries from "./calculateCashInBankTimeSeries";
import getEndValue from "../../../model/TimeSeries/functions/getEndValue";
import trimLeft from "../../../model/TimeSeries/functions/trimLeft";

export default function buildCashInBankTimeSeries(
    accounts: AccountRegistry|undefined,
    postings: Posting[]|undefined,
    timeRange: TimeRange,
    totalOpeningBalance: number|undefined,
    cashInBankTotalBalances: TimeSeries|undefined,
    accountingEndDate: Date|undefined
): TimeSeries|undefined {
    const postingTimeSeries = undefined === accounts || undefined === postings || undefined === totalOpeningBalance
        ? undefined
        : calculateCashInBankTimeSeries(
            accounts,
            postings,
            timeRange,
            totalOpeningBalance
        );
    let result: TimeSeries|undefined;
    if (cashInBankTotalBalances) {
        const resultingStartDate = getTimeRangeStartDate(timeRange);
        const bankEndDate = getEndDate(cashInBankTotalBalances);
        // noinspection UnnecessaryLocalVariableJS
        const bankEndsInOrAfterTimeRange = bankEndDate.getTime() >= resultingStartDate.getTime();
        // noinspection UnnecessaryLocalVariableJS
        const isBankInPeriod = bankEndsInOrAfterTimeRange; // is this actual correct?
        if (isBankInPeriod) {
            if (postingTimeSeries) {
                // #FEATURE-189
                const adjustingTimeSeries = accountingEndDate
                    ? trimLeft(cashInBankTotalBalances, accountingEndDate)
                    : cashInBankTotalBalances;
                result = overloadTimeSeries(postingTimeSeries, adjustingTimeSeries, OverloadMode.ADJUST);
            } else {
                result = cashInBankTotalBalances;
            }
        } else if (postingTimeSeries && undefined !== accounts && undefined !== postings && undefined !== totalOpeningBalance) {
            const bankEndTimeFrame = dateToTimeFrame(bankEndDate, {density: TimeSeriesDensity.DAY, count: 1})
            const lastBankDaySnapshot = calculateCashInBankTimeSeries(
                accounts,
                postings,
                {start: bankEndTimeFrame, count: 1},
                totalOpeningBalance
            );
            const lastBankDayPostingBalance = getEndValue(lastBankDaySnapshot) || 0;
            const lastBankDayBankBalance = getEndValue(cashInBankTotalBalances) || 0;
            const adjustmentValue = lastBankDayBankBalance - lastBankDayPostingBalance;
            result = {
                ...postingTimeSeries,
                values: postingTimeSeries.values.map(v => null === v ? null : v + adjustmentValue),
            };
        }
    } else {
        result = postingTimeSeries;
    }
    return result;
}
