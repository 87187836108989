import axios from '../../../axios';
import * as RestV1 from "./RestV1Types";
import {all, put} from "redux-saga/effects";
import * as filterActionTypes from "../actionTypes";
import { ReduceCompanyUnitControlAction } from "../actionTypes";
import {setCompanyUnitFilterAction} from "../../Filter/actions";
import {PutEffect} from "@redux-saga/core/effects";
import tld from "../../../tld";

function* axiosRequestRemoteRestV1Controls() {
    try {
        return yield axios.get(tld(process.env.REACT_APP_URL_API_STATEMENT) + '/v1/controls');
    } catch (axiosError) {
        console.error(axiosError);
    }
}

export default function* loadStatementControls() {
    const {data}: RestV1.CompanyUnitControlResponse = yield axiosRequestRemoteRestV1Controls();
    if (data && data.length > 0) {
        if (data.length > 1) {
            console.error("Multiple statement controls not supported yet.");
        } else {
            const [companyUnitControl] = data;
            const optionCount = companyUnitControl.options.length;
            const options: CompanyUnit[] = companyUnitControl.options.map(({label, values: {companyUnitId}}) => ({
                id: companyUnitId,
                name: label,
                selected: false
            }))

            const control: CompanyUnitControl = {name: companyUnitControl.label, options};
            const putEffects: PutEffect[] = [put<ReduceCompanyUnitControlAction>({
                type: filterActionTypes.REDUCE_COMPANY_UNIT_CONTROL,
                payload: control
            })];

            if (1 === optionCount) {
                const [option] = options;
                putEffects.push(put(setCompanyUnitFilterAction({
                    id: option.id,
                    name: option.name
                })));
            }

            yield all(putEffects);
        }
    }
}
