import * as actions from "./actionTypes";
import { DeletePlanningAction, SavePlanningAction } from "../../model/Planning";
import {EditPlanningAction, CreatePlanningAction, TogglePlanningVisibilityAction} from "./actionTypes";

export function loadPlannings(range: TimeRange) {
    return {
        type: actions.PLANNING_LOAD,
        payload: { range }
    };
}

export function savePlanning(
    planningDraft: PlanningDraft,
    statementAssignments: StatementAssignmentDirectory,
    onSuccess?: () => void,
    onError?: (e: Error) => void
): SavePlanningAction {
    return {
        type: actions.PLANNING_SAVE,
        payload: { planningDraft, statementAssignments },
        meta: { onSuccess, onError },
    };
}

export function deletePlanning(
    planning: Planning,
    scenario?: Scenario,
    onSuccess?: () => void,
    onError?: (e: Error) => void
): DeletePlanningAction {
    return {
        type: actions.PLANNING_DELETE,
        payload: { planning, scenario },
        meta: { onSuccess, onError },
    };
}

export function createPlanningAction(scope?: PlanningScope): CreatePlanningAction {
    return {
        type: actions.PLANNING_CREATE,
        payload: {scope}
    };
}

export function editPlanningAction(planningId: string): EditPlanningAction {
    return {
        type: actions.PLANNING_EDIT,
        payload: {planningId}
    };
}

export function togglePlanningVisibility(checked: boolean): TogglePlanningVisibilityAction {
    return {
        type: actions.PLANNING_VISIBILITY_TOGGLED,
        payload: checked,
    }
}
