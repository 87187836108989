
export enum ChartReducerActions {
    SET_CHART_DATA = "SET_CHART_DATA",
    REDUCE_BANK_ACCOUNTS = "CHART_LOADING",
    REDUCE_WORKFLOW = "CHART_FAILED",
}

export type SetChartData = {
    type: ChartReducerActions.SET_CHART_DATA,
    payload: {
        actual: TimeSeries[]
        planning: TimeSeries[]
    }
}
export const setChartData = (payload):SetChartData  =>({
        type: ChartReducerActions.SET_CHART_DATA,
        payload
})

export type ChartActions = SetChartData

export type ChartState = {
    actual: TimeSeries[]
    planning: TimeSeries[],
    isLoading: boolean
}
const defaultState = {
    actual: [],
    planning: [],
    isLoading: false
}

export default function reducer(
    state = defaultState,
    action: ChartActions
): ChartState {
    switch (action.type) {
        case ChartReducerActions.SET_CHART_DATA:
          return {
              ...state,
              actual: action.payload.actual,
              planning: action.payload.planning,
          }
        default: {
            return state
        }
    }
}
