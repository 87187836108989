import * as actions from "./actionTypes";
import {
    ForecastBasePlanningToggle,
    ForecastExplanationUpdated,
    ForecastLoadAction,
    ForecastToggledAction
} from "./actionTypes";

export function loadForecast(): ForecastLoadAction {
    return {
        type: actions.FORECAST_LOAD
    };
}

export function toggleForecast(checked: boolean): ForecastToggledAction {
    return {
        type: actions.FORECAST_TOGGLED,
        payload: {
            visible: checked
        }
    }
}
export function updateExplanations(explanation: ExplanationSeries): ForecastExplanationUpdated {
    return {
        type: actions.FORECAST_EXPLANATION_UPDATED,
        payload: explanation
    }
}
export function toggleForecastBasePlanning(basePlanning: string): ForecastBasePlanningToggle {
    return {
        type: actions.FORECAST_BASE_PLANNING_TOGGLE,
        payload: basePlanning
    }
}
