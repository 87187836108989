import { encodeStatementRowUri } from "./encode.functions";

export function rebuildStatementRowUri(statementUri: StatementUriRowLocation): string
{
    return encodeStatementRowUri(
        statementUri.statementId,
        statementUri.id,
        statementUri.type,
        statementUri.path,
        statementUri.minLevel,
        statementUri.maxLevel,
        statementUri.rootBound,
        statementUri.extension
    );
}
