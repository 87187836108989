import getStartIndexForTimeCode from "./getStartIndexForTimeCode";
import {timeFrameToTimeCode} from "../../TimeFrame/functions";
import dateToTimeFrame from "../../TimeFrame/functions/dateToTimeFrame";

export default function trimLeft(timeSeries: TimeSeries, trimInclusiveDate: Date): TimeSeries
{
    const trimTimeCode = timeFrameToTimeCode(dateToTimeFrame(trimInclusiveDate, timeSeries.resolution));
    if (!timeSeries.timeCodes.length ||
        trimTimeCode < timeSeries.timeCodes[0]
    ) {
        return timeSeries;
    } else if (trimTimeCode >= timeSeries.timeCodes[timeSeries.timeCodes.length - 1]) {
        return {
            ...timeSeries,
            timeCodes: [],
            isoCodes: [],
            values: [],
        }
    } else {
        const startIndex = getStartIndexForTimeCode(timeSeries, trimTimeCode + 1);
        return {
            ...timeSeries,
            timeCodes: timeSeries.timeCodes.slice(startIndex),
            isoCodes: timeSeries.isoCodes.slice(startIndex),
            values: timeSeries.values.slice(startIndex),
        };
    }
}
