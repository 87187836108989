export enum TimeSeriesType {
    BALANCES = "balances",
    CASH_FLOW = "cashFlow",
}

export enum TimeSeriesTopic {
    CASH_IN_BANK = "cashInBank"
}

export enum TimeSeriesFlow {
    IN_OUT = "inOut",
    IN = "in",
    OUT = "out",
}

export enum TimeSeriesTrim {
    ALL = "all",
    LEFT = "in",
    RIGHT = "right",
}
