import React from "react";
import {useTranslation} from "react-i18next";
import moment from "moment";
import debug from "../../../services/Debug/functions/debug";

import {DatePicker} from "antd";

import {PlanningMode, TransferAccountMode} from "../enums";
import SelectDisbursementAccount from "../../SelectAccount/SelectDisbursementAccount";
import SelectDepositAccount from "../../SelectAccount/SelectDepositAccount";
import SelectCashAccount from "../../SelectAccount/SelectCashAccount";
import TransferAccountSelect, {TransferAccountSelectValue} from "../Input/TransferAccountSelect";
import {PlanningMetaAccounts} from "../PlanningForm";

export type TransferPlanningFormValues = {
    disbursementAccount?: AccountingAccountDraft,
    depositAccount?: AccountingAccountDraft,
    transferAccount?: TransferAccountSelectValue,
    cashAccount?: AccountingAccountDraft,
    invoiceDate: moment.Moment,
    paymentDate?: moment.Moment,
}

type TransferPlanningSubFormProps = SubFormProps<TransferPlanningFormValues> & {
    accountOptions?: AccountRegistry,
    planningMode?: PlanningMode,
    planningAccounts?: PlanningMetaAccounts,
}

const TransferPlanningSubForm: React.VFC<TransferPlanningSubFormProps> = ({
    decorateFormItem,
    accountOptions,
    planningMode = PlanningMode.RECEIVABLE,
    planningAccounts,
}) => {
    debug("TransferPlanningSubForm::render()", decorateFormItem, accountOptions, planningMode, planningAccounts);

    const {t} = useTranslation();

    const receivablePayableLabel = PlanningMode.RECEIVABLE === planningMode ? t("receivable") : t("liability");

    const field: FormFieldSet<TransferPlanningFormValues> = {
        disbursementAccount: decorateFormItem(
            "disbursementAccount",
            <SelectDisbursementAccount accounts={accountOptions}/>,
            {
                label: t("category"),
                rules: [{ required: true, message: t("no category is specified") }],
            }
        ),
        depositAccount: decorateFormItem(
            "depositAccount",
            <SelectDepositAccount accounts={accountOptions}/>,
            {
                label: t("category"),
                rules: [{ required: true, message: t("no category is specified") }],
            }
        ),
        transferAccount: decorateFormItem(
            "transferAccount",
            <TransferAccountSelect
                accountOptions={accountOptions}
                planningMode={planningMode}
                planningAccounts={planningAccounts}
            />,
            {
                label: receivablePayableLabel,
                className: "select-account-type",
                rules: [{
                    required: true,
                    validator: (rule, value: TransferAccountSelectValue) => {
                        return new Promise((resolve, reject) => {
                            const message = value.mode === TransferAccountMode.OTHER ? t(
                                "Es ist keine {{Forderung/Verbindlichkeit}} ausgewählt",
                                {receivablePayable: receivablePayableLabel}
                            ) : (
                                planningMode === PlanningMode.PAYABLE
                                ? t("no vendor is specified")
                                : t("no debtor is specified")
                            );
                            undefined === value.account
                                ? reject(message)
                                : resolve(value);
                        });
                    }
                }],
            }
        ),
        cashAccount: decorateFormItem(
            "cashAccount",
            <SelectCashAccount accounts={accountOptions}/>,
            {
                label: t("cash account"),
                rules: [{required: true, message: t("no cash account")}],
            }
        ),
        invoiceDate: decorateFormItem(
            "invoiceDate",
            <DatePicker
                // @todo localize date format
                format="DD.MM.YYYY"
                placeholder={t("select date")}
                showToday={false}
                // onChange={checkWarning(
                //     "invoiceDate",
                //     isPlanningInThePast,
                //     planningInThePastWarning
                // )}
            />,
            {
                label: t("invoice date"),
                rules: [{required: true, message: t("no invoice date")}],
            }
        ),
        paymentDate: decorateFormItem(
            "paymentDate",
            <DatePicker
                // @todo localize date format
                format="DD.MM.YYYY"
                placeholder={t("select date")}
                showToday={false}
                // onChange={checkWarning(
                //     "paymentDate",
                //     isPlanningInThePast,
                //     planningInThePastWarning
                // )}
            />,
            {
                label: t("payment date"),
                rules: [{required: true, message: t("no payment date")}],
            }
        ),
    };

    return <>{[
        field["disbursementAccount"],
        field["depositAccount"],
        field["transferAccount"],
        field["cashAccount"],
        field["invoiceDate"],
        field["paymentDate"],
    ]}</>;
}

export default TransferPlanningSubForm;
