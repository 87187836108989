import { List, Map } from "immutable";

export default function buildKeyPathMap(rows: List<StatementRow>): Map<string, KeyPath> {
    return Map<string, KeyPath>().withMutations(result => {
        for (let [i, row] of rows.entries()) {
            result.set(row.id, [i]);
            if (row.rows && row.rows.count()) {
                const prefixPath = [i, 'rows'];
                result.concat(
                    buildKeyPathMap(row.rows).map(
                        subPath => [...prefixPath, ...subPath]
                    )
                );
            }
        }
    });
}
