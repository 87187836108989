import {all, spawn, takeEvery} from "redux-saga/effects"
import {BankAction} from "./actions";
import loadBankAccounts from "./saga/loadBankAccounts";
import initAll from "./saga/initAll";
import synchronizeBankAccounts from "./saga/synchronizeBankAccounts";
import {connectBankAccount, removeBankAccount, disconnectBankAccount} from "./saga/bankAccount";
import {cancelWorkflow, connectFinApiBankWorkflow, refreshFinApiConnection, searchBankWorkflow} from "./saga/workflow";
import toggleVisibility from "./saga/toggleVisibility";

export default function* saga() {
    yield all([
        yield spawn(initAll),
        yield takeEvery(BankAction.TOGGLE_BANK_VISIBILITY, toggleVisibility),
        yield takeEvery(BankAction.CANCEL_WORKFLOW, cancelWorkflow),
        yield takeEvery(BankAction.SEARCH_BANK_WORKFLOW, searchBankWorkflow),
        yield takeEvery(BankAction.CONNECT_BANK_WORKFLOW, connectFinApiBankWorkflow),
        yield takeEvery(BankAction.LOAD_BANK_ACCOUNTS, loadBankAccounts),
        yield takeEvery(BankAction.SYNCHRONIZE_BANK_ACCOUNTS, synchronizeBankAccounts),
        yield takeEvery(BankAction.CONNECT_BANK_ACCOUNT, connectBankAccount),
        yield takeEvery(BankAction.DISCONNECT_BANK_ACCOUNT, disconnectBankAccount),
        yield takeEvery(BankAction.DELETE_BANK_ACCOUNT, removeBankAccount),
        yield takeEvery(BankAction.REFRESH_BANK_CONNECTION, refreshFinApiConnection)
    ]);
}
