import {hasRowConstraint} from "./profileCashFlowStatement";
import {StatementRowConstraintMainType, StatementRowSubType, StatementRowType} from "../index";

export type RowQualifier = (s: StatementRow|StatementDeepTimeSeriesRow) => boolean;

export const isQuickPlanningRow: RowQualifier = (s: StatementRow|StatementDeepTimeSeriesRow) => hasRowConstraint(s, {
    mainType: StatementRowType.CATEGORY,
    subType: StatementRowSubType.QUICK_PLANNING
});

export const isDebtorRow: RowQualifier = (s: StatementRow|StatementDeepTimeSeriesRow) => hasRowConstraint(s, {
    mainType: StatementRowConstraintMainType.CASH_FLOW_ROLE,
    subType: 'debtor'
});

export const isCreditorRow: RowQualifier = (s: StatementRow|StatementDeepTimeSeriesRow) => hasRowConstraint(s, {
    mainType: StatementRowConstraintMainType.CASH_FLOW_ROLE,
    subType: 'creditor'
});

export const isParticipantRow: RowQualifier = (s: StatementRow|StatementDeepTimeSeriesRow) => isCreditorRow(s) || isDebtorRow(s);

export const isSystemRow: RowQualifier = (s: StatementRow|StatementDeepTimeSeriesRow) => isParticipantRow(s) || isQuickPlanningRow(s);

export const isStandardRow: RowQualifier = (s: StatementRow|StatementDeepTimeSeriesRow) => !isSystemRow(s);
