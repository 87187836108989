import {RemoteCashFlowBlueprintGroup} from "../actionTypes";

export default function enrichExplanation(
    explanationSeries: ExplanationSeries,
    groups: RemoteCashFlowBlueprintGroup[]
): ExplanationSeries {
    return {
        ...explanationSeries,
        values: explanationSeries.values.map(values => values.map(explanation => {
            const group = groups.find(group => group.id === explanation.blueprintGroupId);
            return group ? {...explanation, label: group.customLabel} : explanation;
        })),
    };
}
