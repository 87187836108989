import {call, spawn} from "redux-saga/effects"
import axios from "../../../axios";
import {errorAlert} from "../../UserAlert";
import loadBankAccounts from "./loadBankAccounts";
import {featureWall} from "../../functions";
import {FlaggedFeature} from "../../../flags";
import tld from "../../../tld";

export default function* synchronizeBankAccounts() {
    yield call(featureWall, FlaggedFeature.finApi);
    try {
        yield axios.post(tld(process.env.REACT_APP_URL_API_INTEGRATION) + "/v1/finApi/sync");
        yield spawn(loadBankAccounts);
    } catch (axiosError) {
        errorAlert("FinApi", axiosError, "start synchronization");
    }
}
