import * as actions from './actionTypes';

export function initializeAccounting() {
    return { type: actions.ACCOUNTING_INIT };
}

export function createAccount(account: AccountingAccountDraft) {
    return {
        type: actions.ACCOUNTING_ACCOUNT_CREATE,
        payload: account
    };
}
