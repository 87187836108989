import * as actions from "./actionTypes";
import {PostingChannel} from "../../model/Posting/enums";

export function postingIsLoading() {
    return { type: actions.REDUCE_POSTINGS_LOADING };
}

export function postingsLoaded(postings: Posting[], defaultChannel: PostingChannel) {
    return {
        type: actions.POSTINGS_LOADED,
        payload: { postings, defaultChannel }
    };
}

export function postingDeleted(channel: PostingChannel, sourceId: string) {
    return {
        type: actions.POSTING_DELETED,
        payload: { channel, sourceId }
    };
}
