export const INITIALIZE_SETTINGS = "INITIALIZE_SETTINGS";
export const REDUCE_COMPANY_SETTINGS = "REDUCE_COMPANY_SETTINGS";
export const REDUCE_CREDIT_LINE = "REDUCE_CREDIT_LINE";

export interface InitializeSettingsAction {
    type: typeof INITIALIZE_SETTINGS;
}

export interface ReduceCompanySettingsAction {
    type: typeof REDUCE_COMPANY_SETTINGS;
    payload: {
        isDemoActive: boolean;
    }
}

export interface ReduceCreditLineAction {
    type: typeof REDUCE_CREDIT_LINE;
    payload: CreditLine|null
}

export type SettingsReducerAction = ReduceCompanySettingsAction | ReduceCreditLineAction;
