import React from "react";

import {Empty} from "antd";

import Stage from "../Stage/Stage";

import styles from "./EmptyStage.module.css"

type EmptyStageProps = Stylable & {
    ghost?: boolean
}

const EmptyStage: React.FC<EmptyStageProps> = ({
    className= "",
    style,
    ghost = false,
    children
}) => {
    const stageProps = {
        className: `${styles.stage} ${className}`,
        style,
        ghost
    };
    const centerStyle = {
        minHeight: 40,
        display: "flex",
        alignItems: "center",
        justifyContent: "center"
    };
    return <Stage {...stageProps}><Empty description><div style={centerStyle}>{children}</div></Empty></Stage>;
}

export default EmptyStage;
