export const QUICK_PLANNING_SAVE = 'QUICK_PLANNING_SAVE';

export interface SaveQuickPlanningActionPayload {
    statementId: string,
    rowId: string,
    amount: number,
    timeFrame: TimeFrame,
    period: number
}

export interface SaveQuickPlanningAction {
    type: typeof QUICK_PLANNING_SAVE,
    payload: SaveQuickPlanningActionPayload
}
