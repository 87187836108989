import * as actionTypes from "./actionTypes";

type StateInitialization = {
    stats: boolean,
    accounts: boolean,
    openingBalances: boolean,
}

let initialized: StateInitialization = {
    stats: false,
    accounts: false,
    openingBalances: false,
};

const asInitialized: (
    state: AccountingState,
    initialization: Partial<StateInitialization>
) => AccountingState = (state, initialization) => {
    initialized = {...initialized, ...initialization};
    if (!state.initialized
        && initialized.accounts
        && initialized.openingBalances
        && initialized.stats
    ) {
        return {
            ...state,
            initialized: true
        };
    } else {
        return state;
    }
}

export default function reducer(state: AccountingState = {
    initialized: false,
}, action: actionTypes.AccountingReducerAction): AccountingState {
    switch (action.type) {
        case actionTypes.REDUCE_ACCOUNTING_STATS_INIT:
            let newState: AccountingState = asInitialized(state, {stats: true});
            if (action.payload.channels) {
                newState = {
                    ...newState,
                    stats: {
                        ...newState.stats,
                        channels: {
                            ...newState.stats?.channels,
                            ...action.payload.channels
                        },
                    }
                };
            }
            if (action.payload.stages) {
                newState = {
                    ...newState,
                    stats: {
                        ...newState.stats,
                        stages: {
                            ...newState.stats?.stages,
                            ...action.payload.stages
                        },
                    }
                };
            }
            return newState;
        case actionTypes.REDUCE_ACCOUNTING_ACCOUNTS_INIT: {
            const newState: AccountingState = asInitialized(state, {accounts: true});
            return {
                ...newState,
                accounts: action.payload,
            };
        }
        case actionTypes.REDUCE_ACCOUNTING_ACCOUNT_LOADED: {
            const account: AccountingAccount = action.payload;
            let newAccounts: AccountRegistry = {
                ...state.accounts,
            };
            newAccounts[account.id] = account;
            return {
                ...state,
                accounts: newAccounts
            };
        }
        case actionTypes.REDUCE_ACCOUNTING_OPENING_BALANCES_INIT: {
            const newState: AccountingState = asInitialized(state, {openingBalances: true});
            if (action.payload.length > 0) {
                return {
                    ...newState,
                    openingBalances: action.payload
                };
            } else {
                return newState;
            }
        }
        default: {
            return state;
        }
    }
}
