import {all, call, put, select} from "redux-saga/effects";
import i18n from "i18next";
import debug from "../../Debug/functions/debug";

import {timeSeriesStatsSelector} from "../selectors";
import loadStatementBankTimeSeries from "./loadStatementBankTimeSeries";
import {notification} from "antd";
import {NOTIFICATION_ID_BANK_SYNC} from "../../../components/FinApi/BankSyncButton";
import {TimeSeriesStatsUpdate} from "../actions";
import {TimeSeriesReducerAction} from "../reducer";
import {initAccountingStats, initAccounts, initOpeningBalances} from "../../Accounting/saga/initAll";

let bankChannelVersion: string|undefined = undefined;

function* refreshStatementBankTimeSeries(formerStats: TimeSeriesStats) {
    const remoteBankChannelVersion = formerStats.channels?.bank?.lastSync?.toISOString();

    debug(
        "refreshStatementBankTimeSeries",
        bankChannelVersion,
        formerStats,
        formerStats.channels?.bank?.lastSync,
        remoteBankChannelVersion,
        bankChannelVersion !== remoteBankChannelVersion
    );

    if (bankChannelVersion !== remoteBankChannelVersion) {
       const initialLoad = undefined === bankChannelVersion;
        bankChannelVersion = remoteBankChannelVersion;
        yield* loadStatementBankTimeSeries();
        if (!initialLoad) {
            notification.info({
                key: NOTIFICATION_ID_BANK_SYNC,
                message: i18n.t("Aktualisierung"),
                description: i18n.t("Es wurden neue Bankdaten geladen."),
                duration: 5
            });
        }
    }
}

export default function* applyTimeSeriesStatsUpdate(action: TimeSeriesStatsUpdate) {
    const formerStats: ReturnType<typeof timeSeriesStatsSelector> = yield select(timeSeriesStatsSelector);
    yield all([
        put({
            type: TimeSeriesReducerAction.REDUCE_TIME_SERIES_STATS_LOADED,
            payload: action.payload,
        }),
        call(initAccountingStats),
        call(initAccounts),
        call(initOpeningBalances),
    ]);
    yield* refreshStatementBankTimeSeries(formerStats);
}
