import { FluxStandardActionWithPayload } from "flux-standard-action";

export enum DrawerReducerActionType {
    REDUCE_DRAWER_OPEN = "REDUCE_DRAWER_OPEN",
    REDUCE_DRAWER_CLOSE = "REDUCE_DRAWER_CLOSE",
}

export type OpenDrawerPayload = Drawer;
export type OpenDrawerAction<Payload extends OpenDrawerPayload = OpenDrawerPayload>
    = FluxStandardActionWithPayload<DrawerReducerActionType.REDUCE_DRAWER_OPEN, Payload>

export type CloseDrawerPayload = { id: string }
export type CloseDrawerAction<Payload extends CloseDrawerPayload = CloseDrawerPayload>
    = FluxStandardActionWithPayload<DrawerReducerActionType.REDUCE_DRAWER_CLOSE, Payload>

export type DrawerAction = CloseDrawerAction | OpenDrawerAction
