import { AppMode } from "../../model/App/enums";
import { AppAction, AppReducerActionType } from "./index";
import getRequestAppMode from "./functions/getRequestAppMode";

export default function reducer(state: AppState = {
    mode: getRequestAppMode(),
}, action: AppAction) {
    if (!action.payload) {
        return state;
    }
    if (action.type === AppReducerActionType.REDUCE_APP_MODE_SET) {
        const { mode } = action.payload;
        if (mode !== state.mode) {
            window.location.replace("?mode=" + mode);
        }
    }
    return state;
}


// -----------------------------------------------------------------------------
// PUBLIC SELECTORS
// -----------------------------------------------------------------------------

export function getAppMode(state): AppMode {
    return state.mode;
}
