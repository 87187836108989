export const AuthRoleEnum: {
    ADMIN: AuthRole,
    UPLOAD: AuthRole,
    DEV: AuthRole,
    OPERATOR_DATA: AuthRole,
    OPERATOR_ACCOUNTING: AuthRole
} = {
    ADMIN: 'admin',
    UPLOAD: 'upload',
    DEV: 'dev',
    OPERATOR_DATA: 'opDat',
    OPERATOR_ACCOUNTING: 'opAcc'
};

