export enum PlanningComplexity {
    DIRECT = "direct",
    TRANSFER = "transfer",
}

export enum TransferAccountMode {
    OTHER = "other",
    PARTICIPANT = "participant",
}

export enum DirectAccountMode {
    CATEGORY = "category",
    RECEIVABLE_PAYABLE = "receivablePayable",
}

export enum PlanningMode {
    RECEIVABLE = "receivable",
    PAYABLE = "payable",
}
