import {PostingType} from "../../../../model/Posting/enums";

export function transformRestV1PostingType(remotePostingType: string): PostingType {
    const key = Object.keys(PostingType).find(key => PostingType[key] === remotePostingType);
    if (key) {
        return PostingType[key];
    } else {
        throw Error(`Posting type not valid: ${remotePostingType}`);
    }
}
