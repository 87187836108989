import tld from "./tld";

const styleRegistry: {
    [key: string]: {
        app: {
            overflow: string
        },
        wrapper: {
            position: string,
            overflow: string
        },
        iframe: {
            position: string,
            top: string,
            left: string,
            width: string,
            height: string,
            zIndex: string
        },
    }
} = {};

function isScsMaximized(scsId: string): boolean {
    return undefined !== styleRegistry[scsId];
}

function getOffset(scs) {
    const scrollRootElement = document.getElementById("appStage");
    if (!scrollRootElement) {
        throw Error("app scroll root element expected");
    }
    // this is the rectangle which contains the scrolled scs
    const containerRect = scrollRootElement.getBoundingClientRect();
    // this is the actual scrolled rectangle of the scs
    const scsRect = scs.getBoundingClientRect();

    const pageXOffset = -1 * containerRect.left;
    const pageYOffset = containerRect.top;
    const scsOffsetY = scsRect.top > pageYOffset ? scsRect.top : pageYOffset;
    const scrolledYOffset = scsRect.top - scsOffsetY;

    const top = scsOffsetY;
    const bottom = containerRect.bottom - scsRect.bottom;

    return {
        left: scsRect.left + pageXOffset,
        top,
        bottom,
        right: containerRect.right - scsRect.right,
        height: scsRect.height + top + bottom,
        scrollTop: scrolledYOffset,
        // maxWidth: 1200,
        // margin: "auto"
    };
}

export default function register() {
    const eventMethod = !!window.addEventListener
        ? "addEventListener"
        : "attachEvent";

    const eventer = window[eventMethod];

    const messageEvent = eventMethod === "attachEvent"
        ? "onmessage"
        : "message";

    eventer(messageEvent, function (e) {
        if (e.origin === tld(process.env.REACT_APP_URL_APP_UPLOAD) ||
            e.origin === tld(process.env.REACT_APP_URL_APP_DASHBOARD) ||
            e.origin === tld(process.env.REACT_APP_URL_APP_STATEMENT)
        ) {
            // "resize" is deprecated in favor of "scsIFrameResize"
            if (e.data.type === "scsIFrameResize" || e.data.type === "resize") {
                const height = parseInt(e.data.height, 10);
                const scsId = e.data.name;
                const scsElement = document.getElementById(scsId);
                if (scsElement) {
                    const scrollbarFix = 0; // 40
                    scsElement.style.height = height + scrollbarFix + "px";
                }
            }
            if (e.data.type === "scsIFrameExpand") {
                const scsId = e.data.name;
                if (!isScsMaximized(scsId)) {
                    const appElement = document.getElementById("app");
                    const scsElement = document.getElementById(scsId);
                    if (appElement && scsElement)  {
                        const scsIFrameElement = scsElement.firstElementChild as HTMLElement|null;
                        if (scsIFrameElement) {
                            const scsOffset = getOffset(scsElement);
                            e.source.postMessage({ type: "scsIFrameBindPadding", ...scsOffset }, "*");
                            styleRegistry[scsId] = {
                                app: { ...appElement.style },
                                wrapper: { ...scsElement.style },
                                iframe: { ...scsIFrameElement.style },
                            };
                            appElement.style.overflow = "hidden"; // disables scrolling when modal is visible
                            scsElement.style.position = "initial";
                            scsElement.style.overflow = "visible";
                            scsIFrameElement.style.position = "absolute";
                            scsIFrameElement.style.top = "0";
                            scsIFrameElement.style.left = "0";
                            scsIFrameElement.style.width = "100%";
                            scsIFrameElement.style.height = scsOffset.height + "px";
                            scsIFrameElement.style.zIndex = "99999999";
                        }
                    }
                }
            }
            if (e.data.type === "scsIFrameCollapse") {
                const scsId = e.data.name;
                if (isScsMaximized(scsId)) {
                    const appElement = document.getElementById("app");
                    const scsElement = document.getElementById(scsId);
                    if (appElement && scsElement)  {
                        const scsIFrameElement = scsElement.children[0] as HTMLElement|null;
                        if (scsIFrameElement) {
                            e.source.postMessage({ type: "scsIFrameFreePadding" }, "*");
                            appElement.style.overflow = undefined === styleRegistry[scsId].app.overflow ? "" : styleRegistry[scsId].app.overflow;
                            scsElement.style.position = undefined === styleRegistry[scsId].wrapper.position ? "" : styleRegistry[scsId].wrapper.position;
                            scsElement.style.overflow = undefined === styleRegistry[scsId].wrapper.overflow ? "" : styleRegistry[scsId].wrapper.overflow;
                            scsIFrameElement.style.position = undefined === styleRegistry[scsId].iframe.position ? "" : styleRegistry[scsId].iframe.position;
                            scsIFrameElement.style.top = undefined === styleRegistry[scsId].iframe.top ? "" : styleRegistry[scsId].iframe.top;
                            scsIFrameElement.style.left = undefined === styleRegistry[scsId].iframe.left ? "" : styleRegistry[scsId].iframe.left;
                            scsIFrameElement.style.width = undefined === styleRegistry[scsId].iframe.width ? "" : styleRegistry[scsId].iframe.width;
                            scsIFrameElement.style.height = undefined === styleRegistry[scsId].iframe.height ? "" : styleRegistry[scsId].iframe.height;
                            scsIFrameElement.style.zIndex = undefined === styleRegistry[scsId].iframe.zIndex ? "" : styleRegistry[scsId].iframe.zIndex;
                            delete styleRegistry[scsId];
                        }
                    }
                }
            }
        }
    });
}
