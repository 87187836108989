import {spawn, take} from "redux-saga/effects";
import * as actionTypes from "../actionTypes";
import loadCompanySettings from "./loadCompanySettings";
import loadCreditLine from "./loadCreditLine";

export default function* initAll() {
    yield take(actionTypes.INITIALIZE_SETTINGS);
    yield spawn(loadCompanySettings);
    yield spawn(loadCreditLine);
}
