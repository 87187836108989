import axios from '../../../axios';
import {put} from "redux-saga/effects";

import * as RestV1 from "./RestV1Types";
import * as actionTypes from "../actionTypes";
import tld from "../../../tld";


function* axiosCreateRemoteRestV1Integration(data) {
    return yield axios.post(tld(process.env.REACT_APP_URL_API_CONNECT) + '/v1/integrations', data);
}

export default function* createIntegration(integrationData) {
    const {data}: RestV1.IntegrationDataResponse = yield axiosCreateRemoteRestV1Integration(integrationData.payload);
    yield put({
        type: actionTypes.REDUCE_INTEGRATION_CREATED,
        payload: data
    });
}

