import React from "react";
import {Button as AntButton} from "antd";

export default class Button extends React.Component {

    render() {
        const {type, className} = this.props;
        let overlayProps = {};
        const isInline = 'inline' === type;
        if (isInline) {
            overlayProps.type = 'ghost';
            overlayProps.className = className ? ('inline ' + className) : 'inline';
        }
        const props = {
            ...this.props,
            ...overlayProps,
        };
        const antButton = <AntButton {...props} />;
        return isInline ? <span> {antButton} </span> : antButton;
    }
}
