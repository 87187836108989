export default function reducer(state = {
    loading: false,
    error: null,
    result: null
}, action) {
    switch (action.type) {
        case 'POST_AUTH_WORKFLOW_INVITATION_PENDING': {
            return {
                ...state,
                loading: true,
                error: null,
                result: null,
            };
        }
        case 'POST_AUTH_WORKFLOW_INVITATION_REJECTED': {
            return {
                ...state,
                loading: false,
                error: action.payload.response.data.message,
                result: null
            };
        }
        case 'POST_AUTH_WORKFLOW_INVITATION_FULFILLED': {
            return {
                ...state,
                loading: false,
                error: null,
                result: action.payload.data
            };
        }
        default: {
            return state;
        }
    }
}
