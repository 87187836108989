export enum PostingChannel {
    ACCOUNTING = 'accounting',
    BANK = 'bank',
    PLANNING = 'planning',
    ADJUSTMENT = 'adjustment',
}

export enum PostingStage {
    HISTORICAL_ACCOUNTING = "historicalAccounting",
    HISTORICAL_BANK = "historicalBank",
    PLANNED = "planned",
}

export enum PostingType {
    DR = 'Dr',
    CR = 'Cr',
}

export enum PostingPlanType {
    Planning = 'planning',
    Budget = 'budget',
}
