import debug from "../../../services/Debug/functions/debug";
import {
    PlanningFormInternalValues,
    PlanningFormMeta,
    PlanningFormState,
    PlanningFormValues,
    PlanningFormWarnings,
} from "../PlanningForm";
import reduceToPlanningMode from "./reduceToPlanningMode";
import {FormInstance} from "antd/lib/form";
import {PaymentTypeEnum} from "../../../model/Planning/Enums";
import refreshWarning from "./refreshWarning";
import calculatePlannedAmount from "./calculatePlannedAmount";
import reduceDirectPlanningAccounts from "./reduceDirectPlanningAccounts";
import reduceTransferPlanningAccounts from "./reduceTransferPlanningAccounts";
import reduceMetaAccounts from "./reduceMetaAccounts";

function reduceAndApplyState(
    form: FormInstance<PlanningFormInternalValues>,
    state: PlanningFormState,
    valueState: PlanningFormValues,
    metaState: PlanningFormMeta,
    warningState: PlanningFormWarnings,
): PlanningFormState {
    form.setFieldsValue(valueState);
    return {
        ...state,
        values: {
            ...state.values,
            ...valueState,
        },
        meta: (state.meta !== metaState)
            ? {...state.meta, ...metaState}
            : state.meta,
        warnings: (state.warnings !== warningState)
            ? {...state.warnings, ...warningState}
            : state.warnings,
    };
}

const reversePaymentType = (paymentType: PaymentType): PaymentType => {
    return paymentType === PaymentTypeEnum.DISBURSEMENT ? PaymentTypeEnum.DEPOSIT : PaymentTypeEnum.DISBURSEMENT;
}

export default function formReduce(
    form: FormInstance<PlanningFormInternalValues>,
    state: PlanningFormState,
    changedValues: Partial<PlanningFormValues>,
    allValues: PlanningFormValues
): PlanningFormState {
    debug("formReduce()", changedValues, allValues);
    // unify values in order to not flooded by null conditions
    Object.keys(changedValues).forEach(fieldName => {
        if (null === changedValues[fieldName]) {
            changedValues[fieldName] = undefined;
        }
    });
    let valueState = {
        ...state.values,
        ...changedValues
    };
    let metaState = state.meta;
    if (changedValues.hasOwnProperty("isReversal") && undefined !== valueState.paymentType) {
        valueState = {
            ...valueState,
            paymentType: reversePaymentType(valueState.paymentType),
        };
    }

    const planningMode = reduceToPlanningMode(valueState.isReversal, valueState.paymentType);
    const planningModeChanged = planningMode !== state.meta.planningMode;
    if (planningModeChanged) {
        metaState = {
            ...metaState,
            planningMode,
        };
    }

    const newMetaAccounts = reduceMetaAccounts(metaState.planningAccounts, changedValues, planningMode);
    if (planningModeChanged || newMetaAccounts !== metaState.planningAccounts) {
        valueState = reduceDirectPlanningAccounts(metaState.planningAccounts, newMetaAccounts, valueState);
        valueState = reduceTransferPlanningAccounts(metaState.planningAccounts, newMetaAccounts, valueState, planningMode);
    }
    if (newMetaAccounts !== metaState.planningAccounts) {
        metaState = {
            ...metaState,
            planningAccounts: newMetaAccounts,
        };
    }

    const plannedAmount = calculatePlannedAmount(valueState.amountTotal?.amount || 0, valueState.probability);
    if (plannedAmount !== valueState.plannedAmount) {
        valueState = {
            ...valueState,
            plannedAmount,
        }
    }

    let warningState = state.warnings;
    Object.keys(changedValues).forEach(fieldName => {
        warningState = refreshWarning(state.warnings, valueState, metaState, fieldName as keyof PlanningFormValues);
    });
    const result = reduceAndApplyState(form, state, valueState, metaState, warningState);
    debug("formReduce() result", result);
    return result;
}
