import {TimeSeriesFlow, TimeSeriesTopic, TimeSeriesType} from "./enums";
import {encodeTimeSeriesKey} from "./functions/encodeTimeSeriesKey";
import {TimeSeriesDensity} from "../../model/TimeSeries/enums";
import {PostingChannel} from "../../model/Posting/enums";

export const timeSeriesStatsSelector: (state: RootState) => TimeSeriesStats
    = state => state.timeSeries.stats;

export const timeSeriesLoadingSelector: (state: RootState, key: TimeSeriesKey) => boolean
    = (state, key) =>
    state.timeSeries.registry.get(encodeTimeSeriesKey(key), {loading: false}).loading;

const cashInBankTotalBalances = encodeTimeSeriesKey({
    type: TimeSeriesType.BALANCES,
    topic: TimeSeriesTopic.CASH_IN_BANK,
    // @todo: #FEATURE-1570
    channels: [PostingChannel.BANK],
    flow: TimeSeriesFlow.IN_OUT,
    resolution: {
        density: TimeSeriesDensity.DAY,
        count: 1,
    }
});

export const cashInBankTotalBalancesSelector: (state: RootState) => SparseTimeSeries|undefined
    = (state) =>
    state.timeSeries.registry.get(cashInBankTotalBalances)?.timeSeries;

export const timeSeriesSelector: (state: RootState, key: TimeSeriesKey) => SparseTimeSeries|undefined
    = (state, key) => state.timeSeries.registry.get(encodeTimeSeriesKey(key))?.timeSeries;
