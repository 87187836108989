import {call, put, select} from "redux-saga/effects"
import axios from "../../../axios";
import * as actionTypes from "../actionTypes";
import {legacyAlert} from "../../UserAlert";
import * as RestV1 from "./RestV1Types";
import {StatementRowRelocateAction} from "../actionTypes";
import {RowLinkRelation} from "../../../model/StatementUri/enums";
import debug from "../../Debug/functions";
import {reduceStatementError} from "../actions";
import tld from "../../../tld";

function* remotePatchStatementRow(
    statementId: string,
    rowId: string,
    rowLink?: StatementRowLink
) {
    debug("remotePatchStatementRow()", statementId, rowId, rowLink);
    let patchPayload: RestV1.StatementRowPatchPayload = {};
    if (rowLink) {
        switch (rowLink.relation) {
            case RowLinkRelation.PREDECESSOR:
                patchPayload.link = {
                    type: RestV1.StatementRowLinkType.Predecessor,
                    rowId: rowLink.id,
                };
                break;
            case RowLinkRelation.PARENT:
                patchPayload.link = {
                    type: RestV1.StatementRowLinkType.Parent,
                    rowId: rowLink.id,
                };
                break;
            default:
                throw new Error("Destination role not known: " + rowLink.relation);
        }
    } else {
        patchPayload.position = "top";
    }
    debug("positionStatementRow() - patchPayload", patchPayload);
    yield axios.patch(
        tld(process.env.REACT_APP_URL_API_STATEMENT) + "/v1/statements/" + statementId + "/rows/" + rowId,
        patchPayload,
        {
            withCredentials: true,
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json"
            }
        }
    );
}

export default function* relocateStatementRow(action: StatementRowRelocateAction) {

    const {statementId, rowId, destination} = action.payload;
    yield put({
        type: actionTypes.REDUCE_STATEMENT_ROW_RELOCATE,
        payload: {
            statementId, rowId, destination
        }
    });
    const error = yield select(state => state.statement.error);
    if(error) return;
    try {
        yield call(remotePatchStatementRow, statementId, rowId, destination);

    } catch (error) {
        if (error.isAxiosError) {
            yield put(reduceStatementError(error.message))
            legacyAlert(error, "relocate statement row");
        } else {
            throw error;
        }
    }
}
