import debug from "../../../services/Debug/functions/debug";
import {isCashFlowReceivablePayable} from "../../../model/Accounting/AccountQualification";
import {DirectAccountMode, PlanningComplexity, TransferAccountMode} from "../enums";
import moment from "moment";
import {PlanningFormValues} from "../PlanningForm";
import findStatementRowByAccountId from "../../../model/Statement/findStatementRowByAccountId";
import findParentRowById from "../../../model/Statement/findParentRowById";
import {PaymentTypeEnum} from "../../../model/Planning/Enums";
import calculatePlannedAmount from "./calculatePlannedAmount";

const recurrenceIntervals: Record<RecurrenceInterval, boolean> = {
    "1 day": true,
    "1 week": true,
    "1 month": true,
    "3 month": true,
    "6 month": true,
    "1 year": true,
};

function getIntervalForRecurrence(recurrence: Recurrence): RecurrenceInterval {
    const interval = recurrence.period + " " + recurrence.unit;
    if (recurrenceIntervals[interval]) {
        return interval as RecurrenceInterval;
    }
    throw Error("Recurrence interval not supported: " + interval);
}

const getFormValuesForPlanning = (
    planning: Planning,
    defaults: PlanningFormValues,
    statement: Statement,
): PlanningFormValues => {
    debug("getFormValuesForPlanning()", planning, defaults);

    let planningGroup: GroupDraft|undefined = undefined;
    const categoryAccount = planning.account;
    const categoryRow = findStatementRowByAccountId(statement, categoryAccount.id);
    if (categoryRow) {
        const row: StatementRow | null | undefined = findParentRowById(statement.rows, categoryRow.id);
        if (row) {
            planningGroup = {id: row.id, label: row.label};
        }
    }

    const paymentType = planning.paymentType;
    const depositAccount = paymentType === PaymentTypeEnum.DEPOSIT && undefined !== planning.account
        ? planning.account
        : undefined;
    const disbursementAccount = paymentType === PaymentTypeEnum.DISBURSEMENT && undefined !== planning.account
        ? planning.account
        : undefined;
    const amount = planning.amount / 100;
    const probability = planning.probability;

    return {
        ...defaults,
        isReversal: planning.isReversal,
        planningType: planning.planningType,
        paymentType,
        planningComplexity: undefined === planning.account || undefined === planning.transferAccount
            ? PlanningComplexity.DIRECT
            : PlanningComplexity.TRANSFER,
        directPlanning: undefined === planning.account ? undefined : {
            mode: isCashFlowReceivablePayable(planning.account)
                ? DirectAccountMode.RECEIVABLE_PAYABLE
                : DirectAccountMode.CATEGORY,
            account: planning.account,
        },
        depositAccount,
        disbursementAccount,
        transferAccount: undefined === planning.transferAccount ? undefined : {
            mode: isCashFlowReceivablePayable(planning.transferAccount)
                ? TransferAccountMode.OTHER
                : TransferAccountMode.PARTICIPANT,
            account: planning.transferAccount,
        },
        probability,
        invoiceDate: moment.utc(planning.invoiceDate),
        paymentDate: moment.utc(planning.paymentDate),
        cashAccount: planning.cashAccount,
        annotation: planning.annotation,
        receiptNumber: planning.receiptNumber,
        amountTotal: {
            amount,
            expression: planning.amountExpression
        },
        plannedAmount: calculatePlannedAmount(amount, probability),
        recurrence: undefined !== planning.recurrence
            ? {
                active: true,
                interval: getIntervalForRecurrence(planning.recurrence)
            } : {active: false},
        recurrenceEnd: undefined !== planning.recurrence?.endDate
            ? {
                active: true,
                date: moment.utc(planning.recurrence.endDate)
            } : {active: false},
        recurrenceIncrease:
            undefined !== planning.recurrence?.gradientAmount || undefined !== planning.recurrence?.growthRate
            ? {
                active: true,
                gradientAmount: planning.recurrence?.gradientAmount,
                growthRate: planning.recurrence?.growthRate
            }
            : {active: false},
        group: planningGroup || defaults.group,
    };
}

export default getFormValuesForPlanning;
