import {createSelector} from "reselect";


export const getTransactionsState: (state: RootState) => TransactionsState = createSelector(
    state => state.transactions,
    (transactions) => {
        return transactions
    }
)

export const getTransactionsSelector: (state: RootState) => Transaction[] = createSelector(
    getTransactionsState,
    (transactionState) => {
        return transactionState.cashFlowTransactions
    }
)

export const getLoadingTransactionsSelector: (state: RootState) => string[] = createSelector(
    getTransactionsState,
    (transactionState) => {
        return transactionState.loadingTransactionIds
    }
)
export const getSelectedTransactionsSelector: (state: RootState) => string[] = createSelector(
    getTransactionsState,
    (transactionState) => {
        return transactionState.selectedTransactionsIds
    })

export const isTransactionLoadingSelector: (state: RootState) => boolean = createSelector(
    getTransactionsState,
    (transactionState) => {
        return transactionState.isLoading
    })
