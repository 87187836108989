import debug from "../../../services/Debug/functions";

/**
 * @deprecated for slim client
 */
export default function sumUpBalances(balances: OpeningBalance[], companyUnitIds?: string[]): number {
    debug("OpeningBalance/reducer::sumUpBalances()", balances, companyUnitIds);

    const balancesFiltered = undefined === companyUnitIds || companyUnitIds.length === 0
        ? balances
        : balances.filter(({companyUnitId}) => companyUnitId && companyUnitIds.includes(companyUnitId));

    return balancesFiltered.reduce((total, item) => total + item.amount, 0);
};
