type RemoteStageStats = {
    startDate: string,
    endDate?: string,
} | {
    startDate?: string,
    endDate: string,
}

type RemoteChannelStats = {
    firstDate: string,
    lastDate: string,
    firstCashDate?: string,
    lastCashDate?: string,
}

export type RemoteStats = {
    channels?: Partial<Record<"accounting"|"bank", RemoteChannelStats>>,
    stages?: Partial<Record<"historicalAccounting"|"historicalBank"|"planned", RemoteStageStats>>,
}
export type RemoteStatsResponse = {
    data: RemoteStats,
}

export enum RemoteCashFlowType {
    OPERATING = 'operating',
    INVESTMENT = 'investment',
    FINANCING = 'financing',
}

export enum RemoteCashFlowRole {
    PAYMENT = 'payment',
    DISBURSEMENT = 'disbursement',
    DEPOSIT = 'deposit',
    PAYABLE = 'payable',
    CREDITOR = 'creditor',
    RECEIVABLE = 'receivable',
    DEBTOR = 'debtor',
    CASH = 'cash',
    TRANSITION = 'transition',
}

export type RemotePostingSource = {
    id: string,
    sequence: number,
}

export type RemotePosting = {
    id?: string,
    source?: RemotePostingSource,
    type: 'Dr'|'Cr',
    amount: number,
    accountId: string,
    contraAccountId: string,
    receiptDate: string,
    receiptNumber?: string,
    annotation?: string,
    taxRate?: number,
    processingInfo?: string,
    companyUnitId?: string,
    scenarioId?: string,
    scenarioBaseSourceId?: string,
}
export type RemotePostingsResponse = {
    data: RemotePosting[],
}

export type RemoteAccountNumber = {
    system: AccountSystem,
    digits: number,
    code: number,
}
export type RemoteAccount = {
    id: string,
    label: string,
    accountNumber?: RemoteAccountNumber,
    cashFlowRole?: RemoteCashFlowRole,
    isOpeningBalance?: boolean,
    postingEffects?: PostingEffectType[],
}
export type RemoteAccountModificationRequest = {
    label: string,
    cashFlowRole?: RemoteCashFlowRole,
}
export type RemoteAccountsResponse = {
    data: RemoteAccount[],
}

export interface RemoteOpeningBalance {
    accountId: string;
    amount: number;
    date: string;
    companyUnitId?: string;
}
