import {put, select} from "redux-saga/effects"
import {TimeSeriesDensity} from "../../../model/TimeSeries/enums";
import {ReduceTimeSeriesLoadedAction, TimeSeriesReducerAction} from "../reducer";
import {TimeSeriesFlow, TimeSeriesTopic, TimeSeriesType} from "../enums";
import getRemoteTimeSeries from "./getRemoteTimeSeries";
import {activeTimeSeriesViewportSelector} from "../../selectors";

export default function* loadCashInBankBalancesTimeSeries() {
    const viewport: ReturnType<typeof activeTimeSeriesViewportSelector> = yield select(activeTimeSeriesViewportSelector);

    if (undefined === viewport) return;
    if (undefined === viewport?.channels) return; // @todo #FEATURE-1570

    const density = TimeSeriesDensity.DAY;
    const type = TimeSeriesType.BALANCES;
    const topic = TimeSeriesTopic.CASH_IN_BANK;
    const flow = TimeSeriesFlow.IN_OUT;
    const timeSeries = yield* getRemoteTimeSeries(
        viewport.startDate,
        viewport.endDate,
        density,
        topic,
        type,
        flow,
        viewport.channels
    );
    const action: ReduceTimeSeriesLoadedAction = {
        type: TimeSeriesReducerAction.REDUCE_TIME_SERIES_LOADED,
        payload: {
            key: {
                type,
                topic,
                flow,
                channels: viewport.channels,
                resolution: {
                    density,
                    count: 1,
                }
            },
            timeSeries: timeSeries,
        }
    };
    yield put(action);
}
