import moment, {Moment} from 'moment';
import {TimeSeriesDensity} from "./TimeSeries/enums";
import getTimeFrameStartDate from "./TimeFrame/functions/getTimeFrameStartDate";
import {timeFrameToTimeCode} from "./TimeFrame/functions";
import dateToTimeFrame from "./TimeFrame/functions/dateToTimeFrame";

export function makeDailyTimeFramesForTimeRange(timeRange: TimeRange, paddingLeftDays: number = 0): TimeFrame[] {
    const date = moment(getTimeRangeStartDate(timeRange)).utc();
    if (paddingLeftDays > 0) {
        date.subtract(paddingLeftDays, 'day');
    }
    const endDate = moment(getTimeRangeEndDate(timeRange)).utc();
    let timeFrames: TimeFrame[] = [];
    while (date <= endDate) {
        timeFrames.push({year: date.year(), month: date.month() + 1, day: date.date()});
        date.add(1, 'day');
    }
    return timeFrames;
}

export function makeWeeklyTimeFramesForTimeRange(timeRange: TimeRange): TimeFrame[] {
    const {start: startTimeFrame, count} = timeRange;
    let timeFrames: TimeFrame[] = [];
    let {year, month, week} = startTimeFrame;
    let endWeek = moment(`${year}-12-31`, "YYYY-MM-DD").isoWeek();
    let numWeeksInYear = endWeek === 1 ? 52 : endWeek
    if (!week) {
        return timeFrames
    } else for (let i = 0; i < count; i++) {
        if (week === endWeek && endWeek === 1 && month && month > 1) {
            year++;
            timeFrames.push({year, week});
            week++
        } else if (week === numWeeksInYear && endWeek === 1) {
            timeFrames.push({year, week});
            week = 1;
        } else if (week === numWeeksInYear) {
            timeFrames.push({year, week});
            week = 1;
            year++
        } else {
            timeFrames.push({year, week});
            week++
        }
    }
    return timeFrames;
}

export function makeHalfYearlyTimeFramesForTimeRange(timeRange: TimeRange): TimeFrame[] {
    const {start: startTimeFrame, count} = timeRange;
    let timeFrames: TimeFrame[] = [];
    let {year, halfYear} = startTimeFrame;
    if (!halfYear) {
        return timeFrames
    } else for (let i = 0; i < count; i++) {
        timeFrames.push({year, halfYear});
        if (2 === halfYear) {
            halfYear = 1;
            year++;
        } else {
            halfYear++;
        }
    }
    return timeFrames;
}

export function makeQuarterlyTimeFramesForTimeRange(timeRange: TimeRange): TimeFrame[] {
    const {start: startTimeFrame, count} = timeRange;
    let timeFrames: TimeFrame[] = [];
    let {year, quarter} = startTimeFrame;
    if (!quarter) {
        return timeFrames
    } else for (let i = 0; i < count; i++) {
        timeFrames.push({year, quarter});
        if (4 === quarter) {
            quarter = 1;
            year++;
        } else {
            quarter++;
        }
    }
    return timeFrames;
}

export function makeMonthlyTimeFramesForTimeRange(timeRange: TimeRange): TimeFrame[] {
    const {start: startTimeFrame, count} = timeRange;
    let timeFrames: TimeFrame[] = [];
    let {year, month, day} = startTimeFrame;
    if (null != day) {
        return timeFrames
    } else if (null == month) {
        return timeFrames
    } else for (let i = 0; i < count; i++) {
        timeFrames.push({year, month});
        if (12 === month) {
            month = 1;
            year++;
        } else {
            month++;
        }
    }
    return timeFrames;
}

export function makeYearlyTimeFramesForTimeRange(timeRange: TimeRange): TimeFrame[] {
    const {start: startTimeFrame, count} = timeRange;
    let timeFrames: TimeFrame[] = [];
    let {year, month, day} = startTimeFrame;
    if (null != day) {
        return timeFrames
    } else if (null != month) {
        return timeFrames
    } else for (let i = 0; i < count; i++) {
        timeFrames.push({year});
        year++;
    }
    return timeFrames;
}

export function makeTimeFramesForTimeRange(timeRange: TimeRange, timeSeriesResolution: TimeSeriesResolution): TimeFrame[] {
    if ('month' === timeSeriesResolution.density && 1 === timeSeriesResolution.count) {
        return makeMonthlyTimeFramesForTimeRange(timeRange);
    } else if ('halfYear' === timeSeriesResolution.density && 1 === timeSeriesResolution.count) {
        return makeHalfYearlyTimeFramesForTimeRange(timeRange);
    } else if ('year' === timeSeriesResolution.density && 1 === timeSeriesResolution.count) {
        return makeYearlyTimeFramesForTimeRange(timeRange);
    } else if ('day' === timeSeriesResolution.density && 1 === timeSeriesResolution.count) {
        return makeDailyTimeFramesForTimeRange(timeRange);
    } else if ('week' === timeSeriesResolution.density && 1 === timeSeriesResolution.count) {
        return makeWeeklyTimeFramesForTimeRange(timeRange);
    } else if ('quarter' === timeSeriesResolution.density && 1 === timeSeriesResolution.count) {
        return makeQuarterlyTimeFramesForTimeRange(timeRange);
    } else {
        throw new Error('Time series resolution not supported yet: ' + JSON.stringify(timeSeriesResolution));
    }
}

export function getTimeRangeStartTimeCode(timeRange: TimeRange, resolution: TimeSeriesResolution): number {
    return timeFrameToTimeCode(dateToTimeFrame(getTimeRangeStartDate(timeRange), resolution));
}

export function getTimeRangeEndTimeCode(timeRange: TimeRange, resolution: TimeSeriesResolution): number {
    return timeFrameToTimeCode(dateToTimeFrame(getTimeRangeEndDate(timeRange), resolution));
}

export function getTimeRangeStartDate(timeRange: TimeRange): Date {
    const timeFrame = timeRange.start;
    return getTimeFrameStartDate(timeFrame);
}

export function getTimeRangeEndDate(timeRange: TimeRange): Date {
    const startDate = getTimeRangeStartDate(timeRange);
    const {start: timeFrame, count} = timeRange;
    let result: Date;
    if (undefined !== timeFrame.week) {
        result = moment(startDate).utc().add(count, 'week').subtract(1, 'days').toDate();
    } else if (undefined !== timeFrame.halfYear) {
        result = moment(startDate).utc().add(count * 6, 'months').subtract(1, 'days').toDate();
    } else if (undefined !== timeFrame.quarter) {
        result = moment(startDate).utc().add(count, 'quarter').subtract(1, 'days').toDate();
    } else if (undefined !== timeFrame.day) {
        result = moment(startDate).utc().add(count - 1, 'days').toDate();
    } else if (undefined !== timeFrame.month) {
        result = moment(startDate).utc().add(count, 'months').subtract(1, 'day').toDate();
    } else {
        result = moment(startDate).utc().add(count, 'years').subtract(1, 'day').toDate();
    }
    return result;
}

export function getTimeRangeStartYear(timeRange: TimeRange): number {
    return timeRange.start.year;
}

export function getTimeRangeStartHalfYear(timeRange: TimeRange): number {
    return timeRange.start.halfYear || 1;
}

export function getTimeRangeStartQuarter(timeRange: TimeRange): number {
    return timeRange.start.quarter || 1;
}

export function getTimeRangeStartMonth(timeRange: TimeRange): number {
    return timeRange.start.month || 1;
}

export function getTimeRangeStartWeek(timeRange: TimeRange): number {
    return timeRange.start.week || 1;
}

export function getTimeRangeStartDay(timeRange: TimeRange): number {
    return timeRange.start.day || 1;
}

export function getTimeRangeEndYear(timeRange: TimeRange): number {
    const endDate = getTimeRangeEndDate(timeRange);
    return endDate.getFullYear();
}

export function getTimeRangeEndHalfYear(timeRange: TimeRange): number {
    return new Date(getTimeRangeEndDate(timeRange)).getMonth() < 6 ? 1 : 2;
}

export function getTimeRangeEndQuarter(timeRange: TimeRange): number {
    return moment(getTimeRangeEndDate(timeRange)).quarter();
}

export function getTimeRangeEndMonth(timeRange: TimeRange): number {
    return new Date(getTimeRangeEndDate(timeRange)).getMonth() + 1;
}

export function getTimeRangeEndWeek(timeRange: TimeRange): number {
    return moment(getTimeRangeEndDate(timeRange)).isoWeek();
}

export function getTimeRangeEndDay(timeRange: TimeRange): number {
    return new Date(getTimeRangeEndDate(timeRange)).getDate() + 1;
}

export function timeRangeToIso8601PeriodString(timeRange: TimeRange): string {
    const startDate = getTimeRangeStartDate(timeRange);
    const endDate = getTimeRangeEndDate(timeRange);
    const {start: timeFrame} = timeRange;
    let format;
    if (undefined !== timeFrame.day) {
        format = 'YYYY-MM-DD';
    } else if (undefined !== timeFrame.month) {
        format = 'YYYY-MM';
    } else {
        format = 'YYYY';
    }
    return moment(startDate).utc().format(format) + '/' + moment(endDate).utc().format(format);
}

function getMinimalDensity(timeRange1: TimeRange, timeRange2: TimeRange): TimeSeriesDensity {
    if (undefined !== timeRange1.start.day || undefined !== timeRange2.start.day) {
        return TimeSeriesDensity.DAY;
    } else if (undefined !== timeRange1.start.month || undefined !== timeRange2.start.month) {
        return TimeSeriesDensity.MONTH;
    } else {
        return TimeSeriesDensity.YEAR;
    }
}

function normalizeTimeRangeDensity(timeRange: TimeRange, density: TimeSeriesDensity): TimeRange {
    switch (density) {
        case TimeSeriesDensity.YEAR:
            if (undefined !== timeRange.start.month) {
                throw new Error("Time range approximation not supported.");
            }
            return timeRange;
        case TimeSeriesDensity.MONTH:
            if (undefined !== timeRange.start.day) {
                throw new Error("Time range approximation not supported.");
            }
            if (undefined !== timeRange.start.month) {
                return timeRange;
            }
            return {
                ...timeRange,
                start: {
                    ...timeRange.start,
                    month: 1,
                },
                count: timeRange.count * 12,
            };
        case TimeSeriesDensity.DAY:
            if (undefined !== timeRange.start.day) {
                return timeRange;
            }
            if (undefined !== timeRange.start.month) {
                return {
                    ...timeRange,
                    start: {
                        ...timeRange.start,
                        day: 1,
                    },
                    count: timeRange.count * 365, // @todo: Schaltjahr berücksichtigen
                };
            }
            return {
                ...timeRange,
                start: {
                    ...timeRange.start,
                    month: 1,
                    day: 1,
                },
                count: timeRange.count * 12 * 365,  // @todo: Schaltjahr berücksichtigen
            };
        default:
            throw new Error("Time series density not known: " + density);
    }
}

export function mergeTimeRanges(timeRange1: TimeRange, timeRange2: TimeRange): TimeRange {
    const minimalDensity = getMinimalDensity(timeRange1, timeRange2);
    const normalizedTimeRange1 = normalizeTimeRangeDensity(timeRange1, minimalDensity);
    const normalizedTimeRange2 = normalizeTimeRangeDensity(timeRange2, minimalDensity);
    const startDate1 = moment(getTimeRangeStartDate(timeRange1)).utc();
    const endDate1 = moment(getTimeRangeEndDate(timeRange1)).utc();
    const startDate2 = moment(getTimeRangeStartDate(timeRange2)).utc();
    const endDate2 = moment(getTimeRangeEndDate(timeRange2)).utc();
    let startDate: Moment;
    let endDate: Moment;
    let timeRange: TimeRange;
    if (startDate1 < startDate2) {
        startDate = startDate1;
        timeRange = normalizedTimeRange1;
    } else {
        startDate = startDate2;
        timeRange = normalizedTimeRange2;
    }
    if (endDate2 > endDate1) {
        endDate = endDate2;
    } else {
        endDate = endDate1;
    }
    if (endDate < startDate) {
        throw new Error("Unexpected date sequence.");
    }
    switch (minimalDensity) {
        case TimeSeriesDensity.DAY:
            if (timeRange.start.week) {
                return {
                    ...timeRange,
                    count: endDate.diff(startDate, 'weeks') + 1,
                };
            } else if (timeRange.start.halfYear) {
                return {
                    ...timeRange,
                    count: Math.floor((endDate.diff(startDate, 'months') + 1) / 6),
                };
            } else if (timeRange.start.quarter) {
                return {
                    ...timeRange,
                    count: endDate.diff(startDate, 'quarters') + 1,
                };
            } else {
                return {
                    ...timeRange,
                    count: endDate.diff(startDate, 'days') + 1,
                };
            }
        case TimeSeriesDensity.MONTH:
            if (timeRange.start.quarter) {
                return {
                    ...timeRange,
                    count: endDate.diff(startDate, 'quarters') + 1,
                };
            } else if (timeRange.start.halfYear) {
                return {
                    ...timeRange,
                    count: Math.floor((endDate.diff(startDate, 'months') + 1) / 6),
                }
            } else {
                return {
                    ...timeRange,
                    count: endDate.diff(startDate, 'months') + 1,
                };
            }
        case TimeSeriesDensity.YEAR:
            return {
                ...timeRange,
                count: endDate.diff(startDate, 'years') + 1,
            };
        default:
            throw new Error("Time series density not known: " + minimalDensity);
    }
}

export function timeRangesEquals(timeRange1: TimeRange, timeRange2: TimeRange): boolean {
    return timeRangeToIso8601PeriodString(timeRange1) === timeRangeToIso8601PeriodString(timeRange2);
}

export function isMonthlyTimeRange(timeRange: TimeRange): boolean {
    return (!!timeRange.start.month && !(timeRange.start.halfYear || timeRange.start.quarter || timeRange.start.week || timeRange.start.day));
}
