import axios from "../../../axios";
import {call} from "redux-saga/effects";

import * as RestV1 from "../RestV1Types";
import experimentRegisterGuidedTour from "./experimentRegisterGuidedTour";
import applyLogin from "./functions/applyLogin";
import tld from "../../../tld";

function* axiosRequestRemoteRestV1Logins() {
    return yield axios.get(tld(process.env.REACT_APP_URL_API_AUTH) + "/v1/logins", {
        withCredentials: true,
        headers: {
            "Accept": "application/json",
            "Content-Type": "application/json"
        }
    });
}

export default function* loadLogins() {
    const {data}: RestV1.LoginResponse = yield axiosRequestRemoteRestV1Logins();

    const login = (undefined === data[0]) ? null : data[0];

    if (login && undefined !== login.userEmail) {
        experimentRegisterGuidedTour(login.userEmail);
    }
    yield call(applyLogin, login);
}
