export const isPlanningChannelVisibleSelector: (state: RootState) => boolean = state =>
    state.planning.visible;

export const planningSelector: (state: RootState) => Planning[] | undefined = state =>
    state.planning.visible ? state.planning.plannings : undefined;

export const withoutQuickPlanningsSelector: (state: RootState) => Planning[] | undefined = state =>
    planningSelector(state)?.filter(planning => {
        return planning.cashAccount.label !== 'quick planning cash'
    })
