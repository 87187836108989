import * as actionTypes from './actionTypes';
import PropTypes from 'prop-types';
import * as actionType from './actionTypes';
import { CashFlowPropType, CashFlowStatsInitializationPropType } from '../../model/CashFlow/PropTypes';

export default function reducer(state: CashFlowState = {
    cashFlows: null,
    stats: null,
}, action) {
    switch (action.type) {
        case actionTypes.REDUCE_CASH_FLOW_STATS_INIT: {
            const newStats: CashFlowStats = action.payload;
            PropTypes.checkPropTypes(
                { newStats: CashFlowStatsInitializationPropType },
                { newStats },
                'args',
                action.type
            );
            const stats = newStats.latestImportDate
                ? { latestImportDate: new Date(newStats.latestImportDate) }
                : {};
            return {
                ...state,
                stats,
            };
        }
        case 'GET_CASH_FLOWS_FULFILLED':
        case actionTypes.REDUCE_CASH_FLOWS_INIT:
        case 'CACHE_CASH_FLOWS': {
            const newCashFlows: CashFlow[] = action.payload;
            PropTypes.checkPropTypes(
                { newCashFlows: PropTypes.arrayOf(CashFlowPropType) },
                { newCashFlows },
                'args',
                action.type
            );
            return {
                ...state,
                cashFlows: [
                    ...(state.cashFlows ? state.cashFlows : []),
                    ...newCashFlows,
                ],
            };
        }
        case 'CLEAR_CACHE_CASH_FLOWS': {
            if (null === state.cashFlows) {
                return state;
            }
            const clearIds = {};
            for (let i = 0, len = action.payload.length; i < len; i++) {
                clearIds[action.payload[i].id] = true;
            }
            const cashFlows = state.cashFlows.filter((cashFlow) => {
                return !clearIds[cashFlow.id];
            });
            return {
                ...state,
                cashFlows
            };
        }
        case 'GET_CASH_FLOW_FULFILLED': {
            if (null === state.cashFlows) {
                state.cashFlows = [];
            }
            const loadedCashFlow = action.payload.data;
            const position = state.cashFlows.findIndex((cashFlow) => cashFlow.id === loadedCashFlow.id);
            if (-1 === position) {
                state.cashFlows.push(loadedCashFlow);
            } else {
                state.cashFlows[position] = loadedCashFlow;
            }
            return {
                ...state,
            };
        }
        // @todo refactor state model that updating cash flow category names is not required
        case actionType.REDUCE_CASH_FLOW_CATEGORY_RENAME: {
            const { oldName, newName } = action.payload;
            PropTypes.checkPropTypes(
                {
                    oldName: PropTypes.string.isRequired,
                    newName: PropTypes.string.isRequired,
                }, {
                    oldName, newName
                },
                'args',
                action.type
            );
            return null === state.cashFlows ? state : {
                ...state,
                cashFlows: state.cashFlows.map(v => {
                    if (v.category && oldName === v.category.name) {
                        return {
                            ...v,
                            category: {
                                ...v.category,
                                name: newName,
                            }
                        }
                    } else {
                        return v;
                    }
                }),
            };
        }
        default: {
            return state;
        }
    }
}
