import React, {useEffect, useState} from "react";
import {Col, InputNumber, Row, Slider} from "antd";

type InputProbabilityProps = {
    value?: number,
    onChange?: (value: number|undefined) => void,
};

const InputProbability: React.VFC<InputProbabilityProps> = ({
    value,
    onChange = () => {},
}) => {
    const [inputValue,setInputValue] = useState<number>(value||100);

    useEffect(() => {
        if (undefined !== value && inputValue !== value) {
            setInputValue(value);
        }
    }, [value, inputValue]);

    const onSliderChange: ((value: number) => void) = (value = 100) => {
        setInputValue(value);
        onChange(value);
    };

    const onInputChange: ((value?: number|string|undefined|null) => void) = (value = 100) => {
        const intValue = parseInt("" + value);
        setInputValue(intValue);
        onChange(intValue);
    };

    return <Row>
        <Col span={12}>
            <Slider
                min={0}
                max={100}
                step={5}
                onChange={onSliderChange}
                value={inputValue}
                tipFormatter={null}
            />
        </Col>
        <Col span={4}>
            <InputNumber
                min={0}
                max={100}
                style={{ marginLeft: 16 }}
                value={inputValue}
                onChange={onInputChange}
                formatter={value => `${value}%`}
                parser={displayValue => parseInt((displayValue||"").replace(/[^0-9]/g, ""))}
            />
        </Col>
    </Row>;
}

export default InputProbability;
