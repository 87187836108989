import {all, call, delay, put, spawn} from "redux-saga/effects"
import axios from "../../../axios";
import * as actionTypes from "../actionTypes";
import {legacyAlert} from "../../UserAlert";
import * as postingActions from "../../Posting/actions";
import { PostingChannel } from "../../../model/Posting/enums";
import { DeletePlanningAction } from "../../../model/Planning";
import {createScenarioExclusion} from "../../Scenarios/actions";
import {featureWall} from "../../functions";
import {FlaggedFeature} from "../../../flags";
import {reloadForecast} from "../../Forecast/saga/loadForecast";
import tld from "../../../tld";

function* axiosDeleteRemoteRestV1Planning(planningId: string) {
    const url = tld(process.env.REACT_APP_URL_API_PLAN) + "/v1/plannings/" + planningId;
    yield axios.delete(url, {
        withCredentials: true,
        headers: {
            "Accept": "application/json",
            "Content-Type": "application/json"
        }
    });
}

export default function* deletePlanning(action: DeletePlanningAction) {
    yield call(featureWall, FlaggedFeature.planning);

    try {
        const planning = action.payload.planning;
        const planningId = planning.id;
        const { onSuccess }  = action.meta;
        const scenario = action.payload.scenario

        let deletedPlanningId: undefined|string;
        if(scenario) {
            const isBasePlanning = undefined === planning.scenarioId;
            if (isBasePlanning) {
                // exclude planning
                yield put(createScenarioExclusion(scenario.id, planning.id));
            } else {
                // exclude base planning
                if (undefined !== planning.scenarioBaseSourceId) {
                    yield put(createScenarioExclusion(scenario.id, planning.scenarioBaseSourceId));
                }
                // delete planning
                yield axiosDeleteRemoteRestV1Planning(planningId);
                deletedPlanningId = planningId;
            }
        } else {
            yield axiosDeleteRemoteRestV1Planning(planningId);
            deletedPlanningId = planningId;
        }

        if (undefined !== deletedPlanningId) {
            let effects = [
                put({type: actionTypes.REDUCE_PLANNING_DELETED, payload: deletedPlanningId}),
                // #STATEMENT-119 required to delete both channels to also support planning type change
                put(postingActions.postingDeleted(PostingChannel.PLANNING, deletedPlanningId)),
                put(postingActions.postingDeleted(PostingChannel.ADJUSTMENT, deletedPlanningId)),
            ];
            yield all(effects);
        }
        if (onSuccess) {
            yield spawn(onSuccess);
            yield delay(5000)
            yield call(reloadForecast)
        }
    } catch (error) {
        if (action.meta.onError) {
            spawn(action.meta.onError, error)
        }
        if (error.isAxiosError) {
            legacyAlert(error, 'delete planning');
        } else {
            throw error;
        }
    }
}



