import { StatementRowType } from "../Statement";
import { StatementUriExtension } from "./enums";

const URI = require('urijs');

function normalizeUri(statementUri: string) {
    return statementUri
        // remove trailing slash
        .replace(/\/+$/, "");
}

function normalizePath(path: string) {
    return path
        // remove double slashes
        .replace(/\/\/+/g, "/")
        // remove leading slash
        .replace(/^\//, "")
    ;
}

function getPathLevel(normalizedPath: string): number {
    return "" === normalizedPath ? 0 : 1 + (normalizedPath.match(/\//g)||[]).length;
}

function getMinLevel(value: string, currentLevel: number): number {
    const matches = value.match(/^(~?)(\d+|\.)$/);
    if (null === matches) {
        return 0;
    } else {
        return '.' === matches[2] ? currentLevel : Number(matches[2]);
    }
}

function getMaxLevel(value: string, currentLevel: number): number|undefined {
    const matches = value.match(/^\d+|\.$/);
    if (null === matches) {
        return undefined;
    } else {
        return '.' === matches[0] ? currentLevel : Number(matches[0]);
    }
}

function isRootBound(userInfo: string): boolean {
    return "~" === userInfo.substr(0, 1);
}

export function parseStatementUri(statementUri: string): StatementUriLocationReference
{
    const normalizedStatementUri = normalizeUri(statementUri);
    const uri = URI(normalizedStatementUri);
    if ("statement" !== uri.protocol()) {
        throw Error("Bad URI scheme: " +  uri.protocol());
    }
    const rowSpec = uri.filename();
    const statementId = uri.host();
    if ('' === rowSpec) {
        return {
            statement: {
                uri: "statement://" + statementId,
                id: statementId,
            },
        };
    } else {
        const normalizedPath = normalizePath(uri.directory());
        const [rowTypeSymbol, rowExtendedIdPart] = rowSpec.split(":");
        if (undefined === rowExtendedIdPart) {
            throw Error("Bad row format: " + rowSpec);
        }
        const [rowIdPart, rowExtension] = rowExtendedIdPart.split(".");
        const rowId: string = rowIdPart;
        const rowTypeMapping = {
            g: StatementRowType.GROUP,
            c: StatementRowType.CATEGORY,
        };
        if (undefined === rowTypeMapping[rowTypeSymbol]) {
            throw Error("Bad row type: " + rowTypeSymbol);
        }
        if (undefined !== rowExtension && !Object.values(StatementUriExtension).includes(rowExtension)) {
            throw Error("Bad row extension: " + rowExtension);
        }
        const level = getPathLevel(normalizedPath);
        const username = uri.username();
        const minLevel = getMinLevel(username, level);
        const maxLevel = getMaxLevel(uri.password(), level);
        const rootBound = isRootBound(username);
        const rowType: string = rowTypeMapping[rowTypeSymbol];
        return {
            statement: {
                uri: "statement://" + statementId,
                id: statementId,
            },
            row: {
                uri: statementUri,
                id: rowId,
                statementId: statementId,
                path: ("" === normalizedPath ? "" : "/" + normalizedPath) + "/" + rowTypeSymbol + ":" + rowId,
                type: rowType,
                level,
                minLevel,
                maxLevel,
                rootBound,
                extension: rowExtension
            }
        };
    }
}
