import {DirectAccountMode} from "../enums";
import {PaymentTypeEnum} from "../../../model/Planning/Enums";
import {PlanningFormInternalValues, PlanningMetaAccounts} from "../PlanningForm";

export default function reduceDirectPlanningAccounts(
    oldPlanningAccounts: PlanningMetaAccounts,
    newPlanningAccounts: PlanningMetaAccounts,
    valueState: PlanningFormInternalValues
): PlanningFormInternalValues {
    if (oldPlanningAccounts.depositAccount !== newPlanningAccounts.depositAccount) {
        if (valueState.directPlanning?.mode === DirectAccountMode.CATEGORY &&
            valueState.paymentType === PaymentTypeEnum.DEPOSIT
        ) {
            valueState = {
                ...valueState,
                directPlanning: {
                    ...valueState.directPlanning,
                    account: newPlanningAccounts.depositAccount
                },
            };
        }
    }
    if (oldPlanningAccounts.disbursementAccount !== newPlanningAccounts.disbursementAccount) {
        if (valueState.directPlanning?.mode === DirectAccountMode.CATEGORY &&
            valueState.paymentType === PaymentTypeEnum.DISBURSEMENT
        ) {
            valueState = {
                ...valueState,
                directPlanning: {
                    ...valueState.directPlanning,
                    account: newPlanningAccounts.disbursementAccount
                },
            };
        }
    }
    if (oldPlanningAccounts.receivableAccount !== newPlanningAccounts.receivableAccount) {
        if (valueState.directPlanning?.mode === DirectAccountMode.RECEIVABLE_PAYABLE &&
            valueState.paymentType === PaymentTypeEnum.DEPOSIT
        ) {
            valueState = {
                ...valueState,
                directPlanning: {
                    ...valueState.directPlanning,
                    account: newPlanningAccounts.receivableAccount
                },
            };
        }
    }
    if (oldPlanningAccounts.payableAccount !== newPlanningAccounts.payableAccount) {
        if (valueState.directPlanning?.mode === DirectAccountMode.RECEIVABLE_PAYABLE &&
            valueState.paymentType === PaymentTypeEnum.DISBURSEMENT
        ) {
            valueState = {
                ...valueState,
                directPlanning: {
                    ...valueState.directPlanning,
                    account: newPlanningAccounts.payableAccount
                },
            };
        }
    }
    return valueState;
}
