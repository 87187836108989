import debug from "../../../services/Debug/functions";
import {
    findPaymentTypeForAccounts,
    isCashFlowCreditor,
    isCashFlowDebtor,
    isCashFlowDeposit,
    isCashFlowDisbursement, isCashFlowParticipant, isCashFlowPayable, isCashFlowPayment,
    isCashFlowReceivable, isCashFlowReceivablePayable
} from "../../../model/Accounting/AccountQualification";
import {PaymentTypeEnum, PlanningTypeEnum} from "../../../model/Planning/Enums";
import {DirectAccountMode, PlanningComplexity, TransferAccountMode} from "../enums";
import moment from "moment";
import {PlanningFormValues} from "../PlanningForm";
import getStatementRowById from "../../../model/Statement/getStatementRowById";
import {getAccountsByAccountIds} from "../../../model/Accounting/AccountRegistry";
import findParentRowById from "../../../model/Statement/findParentRowById";

interface RowBasedDefaults {
    group?: GroupDraft,
    paymentAccount?: AccountingAccount,
    participantAccount?: AccountingAccount,
    receivablePayableAccount?: AccountingAccount,
    paymentType?: PaymentType,
    selectedCompanyUnit?: CompanyUnit,
}

const reduceToPaymentType = (defaults: RowBasedDefaults): PaymentType|undefined => {
    let defaultPaymentType: PaymentType|undefined;
    if (undefined !== defaults.paymentType) {
        defaultPaymentType = defaults.paymentType;
    } else if (
        undefined !== defaults.paymentAccount && isCashFlowDeposit(defaults.paymentAccount)
        ||
        undefined !== defaults.participantAccount && isCashFlowDebtor(defaults.participantAccount)
        ||
        undefined !== defaults.receivablePayableAccount && isCashFlowReceivable(defaults.receivablePayableAccount)
    ) {
        defaultPaymentType = PaymentTypeEnum.DEPOSIT;
    } else if (
        undefined !== defaults.paymentAccount && isCashFlowDisbursement(defaults.paymentAccount)
        ||
        undefined !== defaults.participantAccount && isCashFlowCreditor(defaults.participantAccount)
        ||
        undefined !== defaults.receivablePayableAccount && isCashFlowPayable(defaults.receivablePayableAccount)
    ) {
        defaultPaymentType = PaymentTypeEnum.DISBURSEMENT;
    }
    return defaultPaymentType;
}

const getFormDefaults = (
    statement: Statement,
    accounts: AccountRegistry,
    timeFrame?: TimeFrame,
    statementRowId?: string,
): PlanningFormValues => {
    debug('getFormDefaults()');

    const rowBasedDefaults: RowBasedDefaults = {};
    if (undefined !== statementRowId) {
        const statementRow = getStatementRowById(statement, statementRowId);
        const statementRowAccountIds: string[] = statementRow.accountIds ? statementRow.accountIds : [];
        const planningAccounts = getAccountsByAccountIds(accounts, statementRowAccountIds);
        rowBasedDefaults.paymentType = findPaymentTypeForAccounts(planningAccounts);
        if (statementRowAccountIds.length === 1) {
            const accountId = statementRowAccountIds[0];
            const account = accounts[accountId];
            if (undefined !== account) {
                if (isCashFlowPayment(account)) {
                    rowBasedDefaults.paymentAccount = account;
                } else if (isCashFlowParticipant(account)) {
                    rowBasedDefaults.participantAccount = account;
                } else if (isCashFlowReceivablePayable(account)) {
                    rowBasedDefaults.receivablePayableAccount = account;
                }
            }
        }
        // the related row's parent is the default group for new plannings
        const parentRow: StatementRow | null | undefined = findParentRowById(statement.rows, statementRowId);
        if (parentRow) {
            rowBasedDefaults.group = {id: parentRow.id, label: parentRow.label};
        }
    }

    const directPlanningMode = undefined !== rowBasedDefaults.receivablePayableAccount
        ? DirectAccountMode.RECEIVABLE_PAYABLE
        : DirectAccountMode.CATEGORY;
    const directPlanningAccount = undefined !== rowBasedDefaults.receivablePayableAccount
        ? rowBasedDefaults.receivablePayableAccount
        : rowBasedDefaults.paymentAccount;

    const transferAccountMode = undefined !== rowBasedDefaults.receivablePayableAccount
        ? TransferAccountMode.OTHER
        : TransferAccountMode.PARTICIPANT;
    const transferAccountAccount = undefined !== rowBasedDefaults.receivablePayableAccount
        ? rowBasedDefaults.receivablePayableAccount
        : rowBasedDefaults.participantAccount;

    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();
    const currentMonth = currentDate.getMonth(); // 0 based
    const year = timeFrame?.year || currentYear;
    const month = timeFrame?.month ? timeFrame.month - 1 : currentMonth;

    return {
        group: rowBasedDefaults.group,
        isReversal: false,
        planningType: PlanningTypeEnum.FORECAST,
        paymentType: reduceToPaymentType(rowBasedDefaults),
        planningComplexity: PlanningComplexity.DIRECT,
        directPlanning: {
            mode: directPlanningMode,
            account: directPlanningAccount,
        },
        transferAccount: {
            mode: transferAccountMode,
            account: transferAccountAccount,
        },
        probability: 100,
        plannedAmount: 0,
        invoiceDate: moment.utc({year, month: month, day: 1}),
        paymentDate: moment.utc({year, month: month, day: 15}),
        cashAccount: undefined,
        annotation: undefined,
        receiptNumber: undefined,
        amountTotal: {amount: undefined, expression: undefined},
        recurrence: {active: false},
        recurrenceEnd: {active: false},
        recurrenceIncrease: {active: false},
    };
}

export default getFormDefaults;
