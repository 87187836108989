import {timeFrameToIsoString} from "../../../model/TimeFrame/functions";

/**
 * @deprecated for slim client
 */

export default function calculateTotalDataPoints(
    rows: StatementDeepTimeSeriesRow[],
    data: TimeSeriesValueMap = {}
): TimeSeriesDataPoint[] {
    const dataPointsByDate = {};
    let mergedDataPoints: TimeSeriesDataPoint[] = [];

    mergedDataPoints.push(...Object.values(data))

    for (let subRow of rows) {
        if (subRow && subRow.dataPoints) {
            mergedDataPoints = mergedDataPoints.concat( ...subRow.dataPoints)
        }
    }

    for (let dataPoint of mergedDataPoints) {
        const dateKey = timeFrameToIsoString(dataPoint.timeFrame);

        if (undefined === dataPointsByDate[dateKey]) {
            dataPointsByDate[dateKey] = {...dataPoint};
        }else{
            dataPointsByDate[dateKey].value += dataPoint.value
        }
    }
    const dataPoints: TimeSeriesDataPoint[] = [];
    Object.keys(dataPointsByDate).sort().forEach(function (key) {
        dataPoints.push(dataPointsByDate[key]);
    });
    return dataPoints;
}
