import {put} from "redux-saga/effects";
import {enablePostingFilter} from "../../Filter/actions";
import {PostingChannel} from "../../../model/Posting/enums";
import {REDUCE_PLANNING_VISIBILITY_TOGGLED, TogglePlanningVisibilityAction} from "../actionTypes";

export default function* togglePlanningVisibility(action: TogglePlanningVisibilityAction) {
    const displayPlanningChannel = action.payload;
    yield put({
        type: REDUCE_PLANNING_VISIBILITY_TOGGLED,
        payload: displayPlanningChannel,
    });
    const planningFilter: PostingFilter = {
        id: "planningExclusion",
        priority: 1,
        filterPosting: posting => displayPlanningChannel || (
            posting.channel !== PostingChannel.PLANNING && posting.channel !== PostingChannel.ADJUSTMENT
        ),
    };
    yield put(enablePostingFilter(planningFilter));
}
