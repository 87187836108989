import React from "react";

interface ConditionalProps<C> {
    context: C,
    visible?: (context: C) => boolean,
}

const Conditional = <C extends object>(props: ConditionalProps<C> & { children: React.ReactNode }) => {
    return (undefined === props.visible || props.visible(props.context))
        ? <>{props.children}</>
        : null;
}

export default Conditional;
