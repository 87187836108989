import React, {useMemo} from "react";
import debug from "../../../services/Debug/functions/debug";

import AutoNumericInput from "../AutoNumericInput";
import {Options as AutoNumericOptions} from "autonumeric";
import {CurrencyMode} from "./enums";

type CurrencyNumberInputProps = AntdFormInputProps<number> & {
    currencyMode?: CurrencyMode,
    onPressEsc?: () => void,
    onEnterFormulaMode?: () => void,
};

const CurrencyNumberInput: React.VFC<CurrencyNumberInputProps> = ({
    currencyMode = CurrencyMode.FULL,
    ...inputProps
}) => {
    debug("CurrencyNumberInput::render()", inputProps);

    const autoNumericProps: AutoNumericOptions = useMemo(
        () => {
            switch (currencyMode) {
                case CurrencyMode.SLIM:
                    return {
                        currencySymbol: "",
                        decimalCharacter: ",",
                        decimalPlaces: 0,
                        digitGroupSeparator: ".",
                    };
                case CurrencyMode.FULL:
                default:
                    return {
                    };
            }
        },
        [currencyMode]
    );

    return <AutoNumericInput {...inputProps} autoNumericOptions={autoNumericProps}/>;
};

export default CurrencyNumberInput;
