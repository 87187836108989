export enum FLOW {
    ANY = 'any',
    IN = 'in',
    OUT = 'out',
}

export enum PATH {
    DASHBOARD = "/dashboard",
    LIQUIDITY = "/liquidität",
    BANK = "/bank/:sub",
    ACCOUNTING = "/buchhaltung",
    CASH_FLOW = "/cashflow",
    STATEMENT = "/statement",
    PLANNINGS = "/plannings"
}
