import {put, select} from "redux-saga/effects"
import {waitForStateReady} from "../../functions";
import {findFirstPlanningPostingDate} from "../../../reducer";
import {initializeAccounting} from "../../Accounting/actions";
import {historicalEndDateSelector} from "../../Accounting/selectors";

export function* getForecastStartDate(defaultStartDate: Date) {
    yield put(initializeAccounting());
    yield* waitForStateReady("accounting");
    let result: Date|undefined;
    const latestAccountingPostingDate: Date|undefined = yield select(historicalEndDateSelector);
    if (latestAccountingPostingDate) {
        result = new Date(latestAccountingPostingDate);
        result.setDate(result.getDate() + 1);
    }
    const firstPlanningPostingDate: Date|null = yield select(findFirstPlanningPostingDate);
    if (firstPlanningPostingDate) {
        result = new Date(firstPlanningPostingDate);
    }
    return result ? result : defaultStartDate;
}
