import moment from "moment";
import {TimeSeriesDensity} from "../../TimeSeries/enums";

export default function dateToTimeFrame(date: Date, resolution: TimeSeriesResolution): TimeFrame {
    const year = date.getFullYear();
    const month = date.getMonth() + 1;
    const day = date.getDate();
    if (TimeSeriesDensity.MONTH === resolution.density && 1 === resolution.count) {
        return {year, month};
    } else if (TimeSeriesDensity.HALFYEAR === resolution.density && 1 === resolution.count) {
        return {year, halfYear: month <= 6 ? 1 : 2};
    } else if (TimeSeriesDensity.YEAR === resolution.density && 1 === resolution.count) {
        return {year};
    } else if (TimeSeriesDensity.DAY === resolution.density && 1 === resolution.count) {
        return {year, month, day};
    } else if (TimeSeriesDensity.WEEK === resolution.density && 1 === resolution.count) {
        return {year, week: moment(date).isoWeek()};
    } else if (TimeSeriesDensity.QUARTER === resolution.density && 1 === resolution.count) {
        return {year, quarter: moment(date).quarter()};
    } else {
        throw new Error("Time series resolution not supported yet: " + JSON.stringify(resolution));
    }
}
