import getStatementRowsMap from "./getStatementRowsMap";

export default function getStatementRowById(statement: Statement, rowId: string): StatementRow {
    const rowsById = getStatementRowsMap(statement);
    const row: StatementRow|undefined = rowsById.get(rowId);
    if (undefined === row) {
        throw Error("Statement row not found: " + rowId);
    } else {
        return row;
    }
}
