import {TimeSeriesDensity} from "../enums";

export default function dateToTimeCode(
    date: Date,
    resolution: TimeSeriesResolution = {density: TimeSeriesDensity.DAY, count: 1}
): number {
    if (TimeSeriesDensity.DAY !== resolution.density || 1 !== resolution.count) {
        throw new Error("Resolution support not implemented yet for time code handling: " + JSON.stringify(resolution));
    }
    const dateParts = [
        date.getFullYear().toString(),
        (date.getMonth() + 1).toString().padStart(2, "0"),
        date.getDate().toString().padStart(2, "0")
    ];
    return parseInt(dateParts.join(""));
}
