import axios from "axios";
import moment from "moment";
import {activeDensitySelector, activeTimeRangeSelector} from "../../TimeTravel/selectors";
import {all, call, put, select} from "redux-saga/effects";
import {waitForStateReady} from "../../functions";
import {
    activeCashInBankTotalBalancesSelector,
    getAccountIdsSelector
} from "../../selectors";
import {setChartData} from "../reducer";
import {getTimeRangeEndDate, getTimeRangeStartDate} from "../../../model/TimeRange";
import fitIntoRange from "../../../model/TimeSeries/functions/fitIntoRange";
import {PostingChannel} from "../../../model/Posting/enums";
import {historicalEndDateSelector} from "../../Accounting/selectors";
import {makeDailyTimeSeriesByRange} from "../../../model/TimeSeries/functions";
import tld from "../../../tld";


const parseParams = (params) => {
    return Object.keys(params).map(function (key) {
        return key + '=' + params[key];
    }).join('&');
}

function* transformBarsData(data, params) {
    const updatedData = {...data};
    const timeRange = yield select(activeTimeRangeSelector)
    const timeSeries = makeDailyTimeSeriesByRange(timeRange, []);
    if (timeSeries !== undefined && timeSeries.resolution) {
        const fittedBaseTimeSeries = fitIntoRange(timeSeries, timeRange);

        const dates = params.flow === 'in' ? [12, 13, 14, 15, 16, 17] : [20, 21, 22, 23, 24, 25]; //dates for positive and negative values in chart library

        updatedData.isoCodes = fittedBaseTimeSeries.isoCodes;
        updatedData.timeCodes = fittedBaseTimeSeries.timeCodes;

        updatedData.values = updatedData.isoCodes.map((code) => {
            const valueIndex = updatedData.timeFrames.findIndex(timeFrame => code.includes(timeFrame));
            if (dates.includes(Number(code.slice(-2)))) {
                return Math.abs(Number(updatedData.values[valueIndex]))
            }
            return null
        })
        return updatedData;
    }
}

function* request(params) {
    const endPoint = '/v1/cashInBank/cashFlows?'
    const stringParams = parseParams(params);
    const data = yield axios.get(tld(process.env.REACT_APP_URL_API_TIME_SERIES) + endPoint + stringParams).then(res => res.data);
    const transformedData = yield transformBarsData(data, params)
    return {...transformedData, density: params.density}
}

function* requestBarsData({start, end, density, channel, accountIds}) {
    const params = {
        start,
        end,
        density,
        flow: 'in',
        channels: channel,
        trim: 'all',
        accountIds
    }
    return yield all([call(request, params), call(request, {...params, flow: 'out'})])
}

export default function* combineBarsData() {
    yield* waitForStateReady("accounting");
    yield* waitForStateReady("statement");
    yield* waitForStateReady("timeTravel");

    const density: ReturnType<typeof activeDensitySelector> = yield select(activeDensitySelector);
    const stats: AccountingStats = yield select(state => state.accounting.stats);
    const accountIds = yield select(getAccountIdsSelector)
    const requiredChannels = ['bank', 'planning']
    const timeRange = yield select(activeTimeRangeSelector);
    const start = getTimeRangeStartDate(timeRange)
    const end = getTimeRangeEndDate(timeRange);
    const historicalEndDate = yield select(historicalEndDateSelector);
    const timeSeries = yield select(activeCashInBankTotalBalancesSelector);

    if(!timeSeries) return;
    if (stats.channels && density) {
        const pipe = requiredChannels.map(channel => ({
            method: requestBarsData,
            params: {
                channel,
                start: channel === PostingChannel.PLANNING ?
                    moment(historicalEndDate).format('YYYY-MM-DD') : moment(start).subtract(1, 'month').format('YYYY-MM-DD'),
                end: moment(end).format('YYYY-MM-DD'),
                density,
                accountIds,
            }
        }))

        const data = yield all(pipe.map(request => call(request.method, request.params)))
        const barsData = {
            actual: data[0],
            planning: data[1]
        }
        yield put(setChartData(barsData))
    }


}
