import React, {useCallback, useMemo, useRef, useState} from "react";
import debug from "../../services/Debug/functions/debug";

import {FontAwesomeIcon} from "@fortawesome/react-fontawesome"
import {faFunction} from "@fortawesome/pro-light-svg-icons"

import Button from "../Button";
import "../Button.css";

import CurrencyNumberInput from "./CurrencyNumberInput";
import FormulaInput from "./FormulaInput";

type CurrencyFormulaInputProps = HtmlInputProps & AntdFormControlProps<ExpressionAmount>;

const isExpressionAmountEqual = (amount1?: ExpressionAmount, amount2?: ExpressionAmount): boolean => {
    if (undefined === amount1 && undefined !== amount2 || undefined !== amount1 && undefined === amount2) {
        return false;
    }
    return amount1?.amount === amount2?.amount && amount1?.expression === amount2?.expression;
}

const CurrencyFormulaInput: React.VFC<CurrencyFormulaInputProps> = ({
    defaultValue,
    value,
    onChange = () => {},
    ...inputProps
}) => {

    const renderValue = value || defaultValue;

    const [formulaMode, setFormulaMode] = useState<boolean>(undefined !== renderValue?.expression);

    const expressionRef = useRef<ExpressionAmount|undefined>(renderValue);

    debug("CurrencyFormulaInput::render()", renderValue, inputProps, expressionRef, formulaMode);

    const onChangeNumberInput = useCallback(
        (amount?: number) => {
            if (amount !== expressionRef.current?.amount || undefined !== expressionRef.current?.expression) {
                debug("CurrencyFormulaInput::onChangeNumberInput()", amount);
                expressionRef.current = {amount};
                onChange(expressionRef.current);
            }
        },
        [onChange]
    );

    const onChangeFormulaInput = useCallback(
        (newExpressionAmount?: ExpressionAmount) => {
            if (!isExpressionAmountEqual(newExpressionAmount, expressionRef.current)) {
                debug("CurrencyFormulaInput::onChangeFormulaInput()", newExpressionAmount);
                expressionRef.current = newExpressionAmount;
                onChange(expressionRef.current);
            }
        },
        [onChange]
    );

    const enterFormulaMode = useCallback(
        () => {
            if (formulaMode) {
                debug("CurrencyFormulaInput::enterFormulaMode() skipped");
            } else {
                debug("CurrencyFormulaInput::enterFormulaMode()");
                setFormulaMode(true);
            }
        },
        [formulaMode, setFormulaMode]
    );

    const dismissFormula = () => {
        if (formulaMode) {
            debug("CurrencyFormulaInput::dismissFormula()");
            onChangeFormulaInput({amount: expressionRef.current?.amount});
            setFormulaMode(false);
        } else {
            debug("CurrencyFormulaInput::dismissFormula() skipped");
        }
    };

    const formulaTrigger = useMemo(
        () => <Button
            type="inline"
            onClick={enterFormulaMode}
            style={{color: "#666"}}
        ><FontAwesomeIcon
            icon={faFunction}
            style={{verticalAlign: "middle"}}
        /></Button>,
        [enterFormulaMode]
    );

    return formulaMode ? <FormulaInput
        {...inputProps}
        value={expressionRef.current}
        onChange={onChangeFormulaInput}
        onCancel={dismissFormula}
        addonBefore={formulaTrigger}
        style={{width:"171px"}}
    /> : <CurrencyNumberInput
        {...inputProps}
        value={expressionRef.current?.amount}
        onChange={onChangeNumberInput}
        onEnterFormulaMode={enterFormulaMode}
        // onCancel={onCancel}
        addonBefore={formulaTrigger}
        style={{width:"171px"}}
    />;
}

export default CurrencyFormulaInput;
