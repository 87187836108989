import {put, select} from "redux-saga/effects";

import createStatementGroupRow from "../../Statement/saga/createStatementGroupRow";
import {
    STATEMENT_GROUP_ROW_CREATE,
    StatementGroupRowCreateAction,
} from "../../Statement/actionTypes";
import {cashFlowStatementSelector} from "../../selectors";
import {notification} from "antd";
import {updateBankTransactions} from "./saga";
import {
    resetTransactionLoading,
    setLoadingTransactions, unselectTransactions,
    updateCashFlowTransactions
} from "../actions";
import {closeDrawerAction} from "../../Drawer/actions";

export function* createAndUpdateTransactionCategory(action) {
    const {transactionIds, category, parentGroup, modalId} = action.payload;
    const cashFlowStatement = yield select(cashFlowStatementSelector);
    if (cashFlowStatement) {
        const createGroupAction: StatementGroupRowCreateAction = {
            type: STATEMENT_GROUP_ROW_CREATE,
            payload: {statementId: cashFlowStatement.id, label: category.label, parentGroup}
        }
        yield put(setLoadingTransactions([...transactionIds]))
        const groupRow = yield createStatementGroupRow(createGroupAction);
        yield updateBankTransactions(updateCashFlowTransactions({
            transactionIds: [...transactionIds],
            category: {id: groupRow.id, label: groupRow.label}
        }))

        if(transactionIds.length > 1){
            yield put(unselectTransactions())
        }
        yield put(resetTransactionLoading())

        notification.success({message: ("Die Transaktion wurde der neuen Gruppe zugewiesen"), duration: 5});
        if(modalId){
            yield put(closeDrawerAction(modalId))
        }
    }
}
