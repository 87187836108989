import * as actionTypes from "./actionTypes";

const defaultState: SettingsState = {
    loading: true,
    isDemoActive: undefined,
    latestCreditLine: undefined,
}

function withLoading(state: SettingsState): SettingsState {
    const loading = undefined === state.isDemoActive || undefined === state.latestCreditLine;
    return {...state, loading};
}

export default function reducer(state: SettingsState = defaultState, action: actionTypes.SettingsReducerAction) {
    switch (action.type) {
        case actionTypes.REDUCE_COMPANY_SETTINGS:
            return withLoading({
                ...state,
                isDemoActive: action.payload.isDemoActive
            });
        case actionTypes.REDUCE_CREDIT_LINE:
            return withLoading({
                ...state,
                latestCreditLine: action.payload
            });
        default:
            return state;
    }
}
