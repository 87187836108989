import i18n from "i18next";
import store from "../../../store";
import debug from "../../../services/Debug/functions/debug";
import {
    PlanningFormMeta,
    PlanningFormValues,
    PlanningFormWarnings,
} from "../PlanningForm";
import {PlanningTypeEnum} from "../../../model/Planning/Enums";
import {standardAccountsSelector} from "../../../services/selectors";
import {historicalEndDateSelector} from "../../../services/Accounting/selectors";
import {findAccountByLabel} from "../../../model/Accounting/AccountRegistry";
import {dateToIso8601, iso8601ToDate} from "../../../model/date";

const getAccountWarning = (account?: AccountingAccountDraft): string|undefined => {
    const accounts = standardAccountsSelector(store.getState());
    if (undefined !== account && undefined === account.id && accounts) {
        const existingAccount = findAccountByLabel(
            accounts,
            account.label,
            account.cashFlowRole
        );
        if (existingAccount) {
            let errorMessageSubject: string;
            switch (account.cashFlowRole) {
                case "deposit":
                case "disbursement":
                    errorMessageSubject = i18n.t("this category");
                    break;
                case "receivable":
                    errorMessageSubject = i18n.t("this receivable");
                    break;
                case "payable":
                    errorMessageSubject = i18n.t("this payable");
                    break;
                default:
                    errorMessageSubject = i18n.t("this account");
            }
            return `${errorMessageSubject} ${i18n.t("is already used")}`;
        }
    }
    return undefined;
}

const getDateWarning = (
    meta: PlanningFormMeta,
    valueState: PlanningFormValues,
    fieldName: "invoiceDate" | "paymentDate",
    message: string | undefined
) => {
    const latestPostingDate = historicalEndDateSelector(store.getState());
    if (latestPostingDate) {
        const value = valueState[fieldName];
        if (undefined !== value) {
            const planningType = valueState["planningType"];
            const valueDate = iso8601ToDate(dateToIso8601(value.toDate()));
            if (planningType === PlanningTypeEnum.FORECAST && valueDate <= latestPostingDate) {
                message = i18n.t("Das ausgewählte Datum liegt ganz oder teilweise im Zeitraum Ihrer historischen Cashflow-Daten")
                    + ". " + i18n.t("In diesem Zeitraum wird die Planung nicht sichtbar sein") + ".";
            }
        }
    }
    return message;
};

export default function refreshWarning(
    warnings: PlanningFormWarnings,
    valueState: PlanningFormValues,
    meta: PlanningFormMeta,
    fieldName: keyof PlanningFormValues,
): PlanningFormWarnings {
    debug("refreshWarning()", warnings, valueState, fieldName);
    let message: string|undefined = undefined;
    switch (fieldName) {
        case "directPlanning":
            message = getAccountWarning(valueState[fieldName]?.account);
            break;
        case "disbursementAccount":
        case "depositAccount":
            message = getAccountWarning(valueState[fieldName]);
            break;
        case "invoiceDate":
        case "paymentDate":
            message = getDateWarning(meta, valueState, fieldName, message);
            break;
        default:
    }
    return (warnings[fieldName] !== message)
        ? {...warnings, [fieldName]: message}
        : warnings;
}
