import _ from "lodash";
import {detailedDiff} from "deep-object-diff";

const COLORS = {
    DEFAULT: "#9E9E9E",
    NEXT: "#4CAF50",
    DIFF: "#f68f41",
    PREV: "#9E9E9E",
    SELECT: "#03A9F4",
};

const getFormattedTime = () => {
    const date = new Date();
    return `${date.toLocaleTimeString().split(" ")[0]}.${date.getMilliseconds()}`;
};

type DiffNativeResult = {
    added: object,
    deleted: object,
    updated: object,
};

type DiffResult = {
    added?: object,
    deleted?: object,
    updated?: object,
};

function diff(arg1, arg2): DiffResult | undefined {
    if (null === arg1 || undefined === arg1 || null === arg2 || undefined === arg2) {
        return arg2 !== arg1 ? {updated: arg2} : undefined
    }
    const nativeResult = detailedDiff(arg1, arg2) as DiffNativeResult;
    const result = {
        added: Object.keys(nativeResult.added).length ? nativeResult.added : undefined,
        deleted: Object.keys(nativeResult.deleted).length ? nativeResult.deleted : undefined,
        updated: Object.keys(nativeResult.updated).length ? nativeResult.updated : undefined,
    };
    const hasDiff = result.added || result.deleted || result.updated;
    return hasDiff ? result : undefined;
}

function logDiff(console: Console, name: string, diff: DiffResult | undefined) {
    if (diff) {
        if (diff.added) {
            console.log(`%c[Select] ${name} diff (added) %O`, `color:${COLORS.DIFF}`, diff.added);
        }
        if (diff.updated) {
            console.log(`%c[Select] ${name} diff (updated) %O`, `color:${COLORS.DIFF}`, diff.updated);
        }
        if (diff.deleted) {
            console.log(`%c[Select] ${name} diff (deleted) %O`, `color:${COLORS.DIFF}`, diff.deleted);
        }
    } else {
        console.log(`%c[Select] ${name} diff (${name} are equal)`, `color:${COLORS.DIFF}`);
    }
}

const selectorArgs: {
    [selector: string]: any[]
} = {};
const selectorResults: {
    [selector: string]: any
} = {};

const log = (selector: string, result: any, ...args: any[]) => {
    const lastArgs = selectorArgs[selector];
    const lastResult = selectorResults[selector];
    const argDiff = diff(lastArgs, args);
    const resultDiff = diff(lastResult, result);

    console.group(
        `%c[Select] %c${selector} %c@ ${getFormattedTime()}`,
        `color:${COLORS.DEFAULT}`,
        "font-weight:bold;",
        `color:${COLORS.DEFAULT}`
    );

    console.group("%c[Select] args", `color:${COLORS.DEFAULT}`);
    console.log("%c[Select] prev args %O", `color:${COLORS.PREV}`, lastArgs);
    console.log("%c[Select] next args %O", `color:${COLORS.NEXT}`, args);
    logDiff(console, "args", argDiff);
    console.groupEnd();

    console.group("%c[Select]  result", `color:${COLORS.DEFAULT}`);
    console.log("%c[Select] prev result %O", `color:${COLORS.PREV}`, lastResult);
    console.log("%c[Select] next result %O", `color:${COLORS.NEXT}`, result);
    logDiff(console, "results", resultDiff);
    console.groupEnd();

    console.groupEnd();

    selectorArgs[selector] = _.cloneDeep(args);
    selectorResults[selector] = _.cloneDeep(result);
}

const debugSelect = (process.env.REACT_APP_DEBUG_SELECT === "true") ? log : () => {};

export default debugSelect;
