import * as PropTypes from 'prop-types';
import {
    AccountingAccountPropType
} from '../Accounting/PropTypes';
import { PlanningTypeEnum } from './Enums';

export const PlanningTypePropType = PropTypes.oneOf([
    PlanningTypeEnum.FORECAST,
    PlanningTypeEnum.ADJUSTMENT,
    PlanningTypeEnum.BUDGET,
]);

export const RecurrencePropType = PropTypes.shape({
    period: PropTypes.number.isRequired,
    unit: PropTypes.oneOf(['day', 'week', 'month', 'year']).isRequired,
    endDate: PropTypes.instanceOf(Date),
});

export const PlanningPropType = PropTypes.shape({
    id: PropTypes.string.isRequired,
    isEnabled: PropTypes.bool.isRequired,
    isReversal: PropTypes.bool.isRequired,
    planningType: PlanningTypePropType.isRequired,
    recurrence: RecurrencePropType,
    invoiceDate: PropTypes.instanceOf(Date).isRequired,
    paymentDate: PropTypes.instanceOf(Date).isRequired,
    account: AccountingAccountPropType.isRequired,
    transferAccount: AccountingAccountPropType, // optional
    cashAccount: AccountingAccountPropType.isRequired,
    amount: PropTypes.number.isRequired,
    amountExpression: PropTypes.string, // optional
    probability: PropTypes.number.isRequired,
    annotation: PropTypes.string, // optional
    receiptNumber: PropTypes.string, // optional
    companyUnitId: PropTypes.string // optional
});
