import {PlanningFormValues, PlanningMetaAccounts} from "../PlanningForm";
import {DirectAccountMode, PlanningMode, TransferAccountMode} from "../enums";
import debug from "../../../services/Debug/functions/debug";

const reduceMetaAccount = (
    planningAccounts: PlanningMetaAccounts|undefined,
    accountType: keyof PlanningMetaAccounts,
    account: AccountingAccountDraft | undefined
): PlanningMetaAccounts => {
    if (!planningAccounts || planningAccounts[accountType] !== account) {
        return {
            ...planningAccounts,
            [accountType]: account,
        };
    } else {
        return planningAccounts;
    }
}

export default function reduceMetaAccounts(
    metaAccounts: PlanningMetaAccounts,
    changedValues: Partial<PlanningFormValues>,
    planningMode: PlanningMode|undefined
): PlanningMetaAccounts {
    debug("reduceMetaAccounts()", metaAccounts, changedValues, planningMode);
    let newMetaAccounts = metaAccounts;
    if (changedValues.hasOwnProperty("directPlanning") && undefined !== changedValues.directPlanning) {
        const {mode, account} = changedValues.directPlanning;
        switch (mode) {
            case DirectAccountMode.RECEIVABLE_PAYABLE:
                switch (planningMode) {
                    case PlanningMode.PAYABLE:
                        newMetaAccounts = reduceMetaAccount(newMetaAccounts, "payableAccount", account);
                        break;
                    case PlanningMode.RECEIVABLE:
                    default:
                        newMetaAccounts = reduceMetaAccount(newMetaAccounts, "receivableAccount", account);
                }
                break;
            case DirectAccountMode.CATEGORY:
            default:
                switch (planningMode) {
                    case PlanningMode.PAYABLE:
                        newMetaAccounts = reduceMetaAccount(newMetaAccounts, "disbursementAccount", account);
                        break;
                    case PlanningMode.RECEIVABLE:
                    default:
                        newMetaAccounts = reduceMetaAccount(newMetaAccounts, "depositAccount", account);
                }
        }
    }
    if (changedValues.hasOwnProperty("depositAccount")) {
        newMetaAccounts = reduceMetaAccount(newMetaAccounts, "depositAccount", changedValues["depositAccount"]);
    }
    if (changedValues.hasOwnProperty("disbursementAccount")) {
        newMetaAccounts = reduceMetaAccount(newMetaAccounts, "disbursementAccount", changedValues["disbursementAccount"]);
    }
    if (changedValues.hasOwnProperty("transferAccount") && undefined !== changedValues.transferAccount) {
        const {mode, account} = changedValues.transferAccount;
        switch (mode) {
            case TransferAccountMode.OTHER:
                switch (planningMode) {
                    case PlanningMode.PAYABLE:
                        newMetaAccounts = reduceMetaAccount(newMetaAccounts, "payableAccount", account);
                        break;
                    case PlanningMode.RECEIVABLE:
                    default:
                        newMetaAccounts = reduceMetaAccount(newMetaAccounts, "receivableAccount", account);
                }
                break;
            case TransferAccountMode.PARTICIPANT:
            default:
                switch (planningMode) {
                    case PlanningMode.PAYABLE:
                        newMetaAccounts = reduceMetaAccount(newMetaAccounts, "creditorAccount", account);
                        break;
                    case PlanningMode.RECEIVABLE:
                    default:
                        newMetaAccounts = reduceMetaAccount(newMetaAccounts, "debtorAccount", account);
                }
        }
    }
    debug("reduceMetaAccounts() result", newMetaAccounts);
    return newMetaAccounts;
}
