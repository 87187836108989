export enum ConnectionStatus {
    CONNECTED = "connected",
    DISCONNECTED = "disconnected",
    DISCONNECTED_AUTO = "disconnectedAuto",
    DISCONNECTED_GHOST = "disconnectedGhost",
    DISABLED = "disabled",
}

export enum SyncStatus {
    SUCCESS = "success",
    FAILURE = "failure",
    RUNNING = "running",
    NEVER = "never",
}
