import debug from "../../../services/Debug/functions";
import {PlanningMetaAccounts, PlanningFormProps, PlanningFormState, PlanningFormValues} from "../PlanningForm";
import reduceToPlanningMode from "./reduceToPlanningMode";
import reduceToPaymentTypes from "./reduceToPaymentTypes";
import {DirectAccountMode, PlanningMode, TransferAccountMode} from "../enums";
import refreshWarning from "./refreshWarning";

const getInitialFormState = (props: PlanningFormProps): PlanningFormState => {
    debug('getInitialFormState()', props);

    const planningMode = reduceToPlanningMode(props.initialValues.isReversal, props.initialValues.paymentType);

    const planningAccounts: PlanningMetaAccounts = {};

    const directPlanning = props.initialValues.directPlanning;
    if (directPlanning && directPlanning.account) {
        const account = directPlanning.account;
        switch (directPlanning.mode) {
            case DirectAccountMode.RECEIVABLE_PAYABLE:
                switch (planningMode) {
                    case PlanningMode.PAYABLE:
                        planningAccounts.payableAccount = account;
                        break;
                    case PlanningMode.RECEIVABLE:
                    default:
                        planningAccounts.receivableAccount = account;
                }
                break;
            case DirectAccountMode.CATEGORY:
            default:
                switch (planningMode) {
                    case PlanningMode.PAYABLE:
                        planningAccounts.disbursementAccount = account;
                        break;
                    case PlanningMode.RECEIVABLE:
                    default:
                        planningAccounts.depositAccount = account;
                }
        }
    }

    const transferAccount = props.initialValues.transferAccount;
    if (transferAccount && transferAccount.account) {
        const account = transferAccount.account;
        switch (transferAccount.mode) {
            case TransferAccountMode.OTHER:
                switch (planningMode) {
                    case PlanningMode.PAYABLE:
                        planningAccounts.payableAccount = account;
                        break;
                    case PlanningMode.RECEIVABLE:
                    default:
                        planningAccounts.receivableAccount = account;
                }
                break;
            case TransferAccountMode.PARTICIPANT:
            default:
                switch (planningMode) {
                    case PlanningMode.PAYABLE:
                        planningAccounts.creditorAccount = account;
                        break;
                    case PlanningMode.RECEIVABLE:
                    default:
                        planningAccounts.debtorAccount = account;
                }
        }
    }

    const values = props.initialValues;
    const meta = {
        planningMode,
        planningAccounts,
    };

    const preCheckFields = ["invoiceDate", "paymentDate"];
    let warnings = {};
    preCheckFields.forEach(fieldName => {
        warnings = refreshWarning(
            warnings,
            values,
            meta,
            fieldName as keyof PlanningFormValues
        );
    });

    return {
        values,
        controls: {
            next: {},
            submit: {},
        },
        options: {
            groups: props.groups,
            paymentTypes: reduceToPaymentTypes(props.paymentCashFlowRole, props.initialValues.isReversal),
        },
        meta,
        warnings
    };
}

export default getInitialFormState;
