import moment from "moment";
import makeUtcDate from "./makeUtcDate";

export default function getTimeFrameStartDate(timeFrame: TimeFrame): Date {
    if (timeFrame.halfYear === 2) {
        timeFrame = {month: 7, ...timeFrame};
    } else if (undefined !== timeFrame.quarter) {
        timeFrame = {month: (timeFrame.quarter * 3)-2, ...timeFrame};
    } else if (undefined !== timeFrame.week) {
        const momentDate = moment().year(timeFrame.year).isoWeek(timeFrame.week).startOf("isoWeek");
        timeFrame = {month: momentDate.month() + 1, day: momentDate.date(), ...timeFrame};
    } else {
        timeFrame = {month: 1, day: 1, ...timeFrame};
    }
    return makeUtcDate(timeFrame.year, timeFrame.month, timeFrame.day);
}
