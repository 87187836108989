export function decodeRestTimeFrame(restValue: string): TimeFrame {
    const [year, period, day] = restValue.split("-");
    if (undefined !== period) {
        switch (period[0]) {
            case 'W':
                return {
                    year: parseInt(year),
                    week: parseInt(period.substr(1)),
                };
            case 'Q':
                return {
                    year: parseInt(year),
                    quarter: parseInt(period.substr(1)),
                };
            case 'H':
                return {
                    year: parseInt(year),
                    halfYear: parseInt(period.substr(1)),
                };
        }
    }
    if(undefined === day){
        return {
            year: parseInt(year),
            month: undefined === period ? undefined : parseInt(period),
        };
    }
    return {
        year: parseInt(year),
        month: undefined === period ? undefined : parseInt(period),
        day: parseInt(day),
    };
}
