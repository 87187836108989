export enum StatementRowType {
    GROUP = "group",
    CATEGORY = "category"
}

export enum StatementRowSubType {
    QUICK_PLANNING = 'quickPlanning'
}

export enum StatementProfileType {
    CASH_FLOW = "cashFlow",
    CASH_IN_BANK = "cashInBank",
    LIQUIDITY = "liquidity",
}

export enum StatementRowConstraintMainType {
    CASH_FLOW_TYPE = "cashFlowType",
    CASH_FLOW_ROLE = "cashFlowRole",
}
