import {AxiosError} from "axios";
import {generateShortId} from "../../../model/shortId";
import track from "../../UserAnalytics/track";
import notifyBugsnag from "./notifyBugsnag";
import showError from "./showError";

/**
 * @deprecated in favor of alert()
 */
export default function legacyAlert(error: Error|AxiosError, action?: string, subject?: any) {
    const errorId = generateShortId();
    notifyBugsnag(errorId, action, subject, error);
    track("User Alert", "Error Alert", {errorId, action});
    showError(errorId);
}
