import {notification} from "antd";
import track from "../../UserAnalytics/track";

export default function warningAlert(
    featureName: string,
    userMessage: string,
    description: string|undefined,
    actionName: string,
    actionContext: object = {},
    componentName?: string,
) {
    track(
        featureName,
        "Warning Alert",
        {message: userMessage, triggerAction: actionName, description },
        componentName
    );
    notification.warning({message: userMessage, description, duration: 5});
}
