import axios from "../../../axios";
import {AxiosResponse} from "axios";
import {SaveQuickPlanningAction} from "../actionTypes";
import {all, call, delay, put, select} from "redux-saga/effects";
import {REDUCE_ACCOUNTING_ACCOUNT_LOADED, ReduceAccountingAccountLoaded} from "../../Accounting/actionTypes";
import {REDUCE_STATEMENT_ROW_CREATE, ReduceStatementRowCreateAction} from "../../Statement/actionTypes";
import {RowLinkRelation} from "../../../model/StatementUri/enums";
import {transformStatementRowResource} from "../../Statement/saga/functions";
import {postingsLoaded} from "../../Posting/actions";
import {PostingChannel} from "../../../model/Posting/enums";
import {transformRestV1PostingResource} from "../../Accounting/saga/functions";
import {getStatementRow} from "../../../reducer";
import {yearMonthFromDate} from "../../../model/date";
import {notification} from "antd";
import i18n from "../../../i18n";
import {accountsSelector} from "../../Accounting/selectors";
import * as RestV1Posting from "../../Accounting/saga/RestV1Types";
import * as RestV1Statement from "../../Statement/saga/RestV1Types";
import {cashFlowStatementDeepRowSetSelector, selectedCompanyUnitSelector} from "../../selectors";
import {selectedScenarioSelector} from "../../Scenarios/selectors";
import {amountsForPeriod} from "../functions";
import {findQuickPlanningRow} from "../../../model/Statement/findChildRow";
import {reloadForecast} from "../../Forecast/saga/loadForecast";
import tld from "../../../tld";

type RestV1QuickPlanningResult = {
    postings: RestV1Posting.RemotePosting[],
    accounts: AccountingAccount[],
    row: RestV1Statement.StatementRow
}

function* postQuickPlannings(
    groupId: string,
    amounts: number[],
    month: number,
    year: number,
    companyUnit?: CompanyUnit,
    scenario?: Scenario
): Generator<
    Promise<AxiosResponse<RestV1QuickPlanningResult>>,
    RestV1QuickPlanningResult,
    AxiosResponse<RestV1QuickPlanningResult>
> {
    const url = tld(process.env.REACT_APP_URL_API_PLAN) + '/v1/quickPlannings';
    const data = {
        groupId,
        amounts,
        startMonth: yearMonthFromDate(new Date(year, month, 0)),
        companyUnitId: companyUnit?.id,
        scenarioId: scenario?.id
    }
    const response = yield axios.post<RestV1QuickPlanningResult>(url, data);
    return response.data;
}

function calculateAmounts(
    amount: number,
    timeFrame: TimeFrame,
    period: number,
    dataPoints?: TimeSeriesDataPoint[],
    quickPlanningDataPoints?: TimeSeriesDataPoint[],
): number[]|undefined {
    const existingAmounts = dataPoints || [];
    const existingQuickPlanningAmounts = quickPlanningDataPoints || [];
    return amountsForPeriod(timeFrame, existingAmounts, existingQuickPlanningAmounts, amount, period);
}

function findDeepSubRow(rowId: string, deepRow: StatementDeepTimeSeriesRow): StatementDeepTimeSeriesRow|undefined {
    if (undefined !== deepRow.rows) {
        for (let row of deepRow.rows) {
            if (rowId === row.id) {
                return row;
            }
            const subRow = findDeepSubRow(rowId, row);
            if (subRow) {
                return subRow;
            }
        }
    }
    return undefined;
}

function findDeepRow(rowId: string, deepRowSet?: StatementDeepTimeSeriesRowSet): StatementDeepTimeSeriesRow|undefined {
    if (deepRowSet) {
        for (let row of deepRowSet.rows) {
            if (rowId === row.id) {
                return row;
            }
            const subRow = findDeepSubRow(rowId, row);
            if (subRow) {
                return subRow;
            }
        }
    }
    return undefined;
}

export default function* saveQuickPlanning(action: SaveQuickPlanningAction) {
    const {payload: {statementId, rowId, amount, timeFrame, period}} = action;
    const {month, year} = timeFrame;
    if (month && year) {
        const [companyUnit, scenario, deepRowSet] = yield all([
            select(selectedCompanyUnitSelector),
            select(selectedScenarioSelector),
            select(cashFlowStatementDeepRowSetSelector),
        ]);
        const row = findDeepRow(rowId, deepRowSet);
        if (row) {
            const isTopLevelGroup = row.isGroup && row.level === 1;
            const quickPlanningSubRow = isTopLevelGroup ? findQuickPlanningRow(row.rows || []) : undefined;
            const amounts = calculateAmounts(amount, timeFrame, period, row.dataPoints, quickPlanningSubRow?.dataPoints);
            if (undefined !== amounts) {
                const {postings: remotePostings, accounts, row: remoteRow}: RestV1QuickPlanningResult
                    = yield* postQuickPlannings(rowId, amounts, month, year, companyUnit, scenario);

                const channel = PostingChannel.PLANNING;
                const postings = remotePostings.map(p => transformRestV1PostingResource(p, channel));

                const accountsRegistry = yield select(accountsSelector) || {};
                const accountsToCreate = accounts
                    .filter(a => !accountsRegistry[a.id])
                    .map(a => put<ReduceAccountingAccountLoaded>({
                        type: REDUCE_ACCOUNTING_ACCOUNT_LOADED,
                        payload: a
                    }));

                const rowWithWrongLevel = transformStatementRowResource(remoteRow, statementId);
                let existingRow = false;
                try {
                    existingRow = yield select(getStatementRow, statementId, rowWithWrongLevel.id);
                } catch (e) {
                    // do nothing
                }
                const rowToCreate = existingRow ?
                    [] : [ put<ReduceStatementRowCreateAction>({
                        type: REDUCE_STATEMENT_ROW_CREATE, payload: {
                            statementId,
                            row: rowWithWrongLevel,
                            destination: {
                                relation: RowLinkRelation.PARENT,
                                id: rowId
                            }
                        }
                    }) ];
                notification.success({message: i18n.t("Planung gespeichert"), duration: 5});
                yield all([
                    ...accountsToCreate,
                    ...rowToCreate,
                    put(postingsLoaded(postings, channel)),
                ]);
                yield delay(5000)
                yield call(reloadForecast)
            }
        }
    }
}
