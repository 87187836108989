import {subAxios as axios} from "../../../axios";
import {put} from "redux-saga/effects";

import * as actionTypes from "../actionTypes";
import {LogoutAction, ReduceLogoutAction} from "../actionTypes";
import {UserAnalytics} from "../../UserAnalytics/functions";
import unsetAxiosAuthorization from "../../../axios/functions/unsetAxiosAuthorization";
import {LogoutTrigger} from "../enums";
import AuthBroadcast from "../AuthBroadcast";
import redirectToSignIn from "./functions/redirectToSignIn";
import tld from "../../../tld";

function* axiosRequestRemoteRestV1Logins() {
    return yield axios.delete(tld(process.env.REACT_APP_URL_API_AUTH) + "/v1/logins")
        .then(() => undefined)
        // make it never fail
        .catch(() => undefined);
}

export default function* logout(action: LogoutAction) {
    if (LogoutTrigger.BROADCAST !== action.payload) {
        const timeout = action.payload === LogoutTrigger.TIMEOUT;
        const trackName = timeout ? "Timeout" : "Logout";
        UserAnalytics.track(trackName, {trigger: action.payload});

        yield axiosRequestRemoteRestV1Logins();

        AuthBroadcast.broadcastLogout(action.payload);
    }

    unsetAxiosAuthorization();

    const reducerAction: ReduceLogoutAction = {
        type: actionTypes.REDUCE_LOGOUT,
        payload: action.payload,
    };
    yield put(reducerAction);

    redirectToSignIn(action.payload);
}
