const shallowequal = require('shallowequal');

const dragDropViewRegistry: DragDropViewRegistry = {};

export function getDragDropListView(dragDropViewId: string): DragDropView {
    if (undefined === dragDropViewRegistry[dragDropViewId]) {
        throw new Error('Drag and drop view not found: ' + dragDropViewId);
    }
    return dragDropViewRegistry[dragDropViewId];
}

export function registerDragDropListView(dragDropViewId: string, dragDropIds: string[], dragDropUris: string[]): DragDropView {
    if (undefined === dragDropViewRegistry[dragDropViewId] ||
        !shallowequal(dragDropViewRegistry[dragDropViewId].dragDropIds, dragDropIds)
    ) {
        dragDropViewRegistry[dragDropViewId] = {
            id: dragDropViewId,
            dragDropIds: dragDropIds,
            dragDropUris: dragDropUris
        };
    }
    return dragDropViewRegistry[dragDropViewId];
}
