import {PlanningMode} from "../enums";
import {PaymentTypeEnum} from "../../../model/Planning/Enums";

const reduceToPlanningMode = (isReversal: boolean, paymentType?: PaymentType): PlanningMode|undefined => {
    switch (paymentType) {
        case PaymentTypeEnum.DEPOSIT:
            return isReversal ? PlanningMode.PAYABLE : PlanningMode.RECEIVABLE;
        case PaymentTypeEnum.DISBURSEMENT:
            return isReversal ? PlanningMode.RECEIVABLE : PlanningMode.PAYABLE;
        default:
            return undefined;
    }
};

export default reduceToPlanningMode;
