import React from "react";
import {useTranslation} from "react-i18next";
import moment from "moment";
import debug from "../../../services/Debug/functions/debug";

import {DatePicker} from "antd";

import {PlanningMode} from "../enums";
import DirectPlanningAccountSelect, {DirectPlanningAccountSelectValue} from "../Input/DirectPlanningAccountSelect";
import SelectCashAccount from "../../SelectAccount/SelectCashAccount";
import {PlanningMetaAccounts} from "../PlanningForm";

export type DirectPlanningFormValues = {
    directPlanning?: DirectPlanningAccountSelectValue,
    cashAccount?: AccountingAccountDraft,
    invoiceDate: moment.Moment,
}

type DirectPlanningSubFormProps = SubFormProps<DirectPlanningFormValues> & {
    accountOptions?: AccountRegistry,
    planningMode?: PlanningMode,
    planningAccounts?: PlanningMetaAccounts,
}

const DirectPlanningSubForm: React.VFC<DirectPlanningSubFormProps> = ({
    decorateFormItem,
    accountOptions,
    planningMode = PlanningMode.RECEIVABLE,
    planningAccounts,
}) => {
    debug("BaseSubForm::render()", decorateFormItem, accountOptions, planningMode, planningAccounts);

    const {t} = useTranslation();

    const field: FormFieldSet<DirectPlanningFormValues> = {
        directPlanning: decorateFormItem(
            "directPlanning",
            <DirectPlanningAccountSelect
                accountOptions={accountOptions}
                planningMode={planningMode}
                planningAccounts={planningAccounts}
            />,
            {
                label: t("direct planning of"),
                className: "select-account-type",
                rules: [{
                    required: true,
                    validator: (rule, value: DirectPlanningAccountSelectValue) => {
                        return new Promise((resolve, reject) => {
                            undefined === value.account
                                ? reject(t("no planning account"))
                                : resolve(value);
                        });
                    }
                }],
            }
        ),
        cashAccount: decorateFormItem(
            "cashAccount",
            <SelectCashAccount accounts={accountOptions}/>,
            {
                label: t("cash account"),
                rules: [{required: true, message: t("no cash account")}],
            }
        ),
        invoiceDate: decorateFormItem(
            "invoiceDate",
            <DatePicker
                // @todo localize date format
                format="DD.MM.YYYY"
                placeholder={t("select date")}
                showToday={false}
                // onChange={checkWarning(
                //     "invoiceDate",
                //     isPlanningInThePast,
                //     planningInThePastWarning
                // )}
            />,
            {
                label: t("billing and payment date"),
                rules: [{required: true, message: t("no billing and payment date")}],
            }
        ),
    };

    return <>{[
        field["directPlanning"],
        field["cashAccount"],
        field["invoiceDate"],
    ]}</>;
}

export default DirectPlanningSubForm;
