import { PaymentTypeEnum } from '../Planning/Enums';
import {CashFlowRole, ResourceOrigin} from '../CashFlow/enums';

export type AccountingAccountFilter = (account: AccountingAccount) => boolean;

export function isCashFlowPayable(account: AccountingAccount): boolean {
    return undefined !== account.cashFlowRole && CashFlowRole.PAYABLE === account.cashFlowRole;
}

export function isCashFlowCreditor(account: AccountingAccount): boolean {
    return undefined !== account.cashFlowRole && CashFlowRole.CREDITOR === account.cashFlowRole;
}

export function isCashFlowReceivable(account: AccountingAccount): boolean {
    return undefined !== account.cashFlowRole && CashFlowRole.RECEIVABLE === account.cashFlowRole;
}

export function isCashFlowDebtor(account: AccountingAccount): boolean {
    return undefined !== account.cashFlowRole && CashFlowRole.DEBTOR === account.cashFlowRole;
}

export function isCashFlowReceivablePayable(account: AccountingAccount): boolean {
    return isCashFlowPayable(account) || isCashFlowReceivable(account);
}

export function isCashFlowParticipant(account: AccountingAccount): boolean {
    return isCashFlowCreditor(account) || isCashFlowDebtor(account);
}

export function isCashFlowDisbursement(account: AccountingAccount): boolean {
    return undefined !== account.cashFlowRole && (
        CashFlowRole.DISBURSEMENT === account.cashFlowRole ||
        CashFlowRole.PAYMENT === account.cashFlowRole
    );
}

export function isCashFlowDeposit(account: AccountingAccount): boolean {
    return undefined !== account.cashFlowRole && (
        CashFlowRole.DEPOSIT === account.cashFlowRole ||
        CashFlowRole.PAYMENT === account.cashFlowRole
    );
}

export function isCashFlowPayment(account: AccountingAccount): boolean {
    return undefined !== account.cashFlowRole && (
        CashFlowRole.DISBURSEMENT === account.cashFlowRole ||
        CashFlowRole.DEPOSIT === account.cashFlowRole ||
        CashFlowRole.PAYMENT === account.cashFlowRole
    );
}

export function isCashFlowCash(account: AccountingAccount): boolean {
    return undefined !== account.cashFlowRole && CashFlowRole.CASH === account.cashFlowRole;
}

export function isCashFlowTransition(account: AccountingAccount): boolean {
    return undefined !== account.cashFlowRole && CashFlowRole.TRANSITION === account.cashFlowRole;
}

export const isQuickPlanning = (a: AccountingAccount) => a.resourceOrigin === ResourceOrigin.QuickPlanning;

export const isSystemAccount = (account: AccountingAccount) => isQuickPlanning(account);

export function hasCashFlowRole(account: AccountingAccount, cashFlowRole: CashFlowRole): boolean {
    switch (cashFlowRole) {
        case undefined:
            return false;
        case CashFlowRole.PAYMENT:
            return isCashFlowPayment(account);
        case CashFlowRole.DISBURSEMENT:
            return isCashFlowDisbursement(account);
        case CashFlowRole.DEPOSIT:
            return isCashFlowDeposit(account);
        case CashFlowRole.PAYABLE:
            return isCashFlowPayable(account);
        case CashFlowRole.CREDITOR:
            return isCashFlowCreditor(account);
        case CashFlowRole.RECEIVABLE:
            return isCashFlowReceivable(account);
        case CashFlowRole.DEBTOR:
            return isCashFlowDebtor(account);
        case CashFlowRole.CASH:
            return isCashFlowCash(account);
        case CashFlowRole.TRANSITION:
            return isCashFlowTransition(account);
        default:
            throw new Error('Cash flow role not known: ' + cashFlowRole);
    }
}


export function findPaymentTypeForAccounts(accounts: AccountingAccount[]): PaymentType|undefined {
    let disbursementCount = 0;
    let depositCount = 0;
    let payableCount = 0;
    let receivableCount = 0;
    let creditorCount = 0;
    let debtorCount = 0;
    accounts.forEach(account => {
        if (CashFlowRole.DISBURSEMENT === account.cashFlowRole) {
            disbursementCount++;
        } else if (CashFlowRole.DEPOSIT === account.cashFlowRole) {
            depositCount++;
        } else if (CashFlowRole.DEBTOR === account.cashFlowRole) {
            debtorCount++;
        }
        if (CashFlowRole.PAYABLE === account.cashFlowRole) {
            payableCount++;
        } else if (CashFlowRole.RECEIVABLE === account.cashFlowRole) {
            receivableCount++;
        } else if (CashFlowRole.CREDITOR === account.cashFlowRole) {
            creditorCount++;
        }
    });
    if (depositCount > disbursementCount || receivableCount > payableCount || debtorCount > creditorCount) {
        return PaymentTypeEnum.DEPOSIT;
    } else if (depositCount < disbursementCount || receivableCount < payableCount || debtorCount < creditorCount) {
        return PaymentTypeEnum.DISBURSEMENT;
    } else {
        return undefined;
    }
}

export function isDraft(account: AccountingAccount): boolean {
    return undefined === account.accountNumber;
}
