import { StatementRowType, StatementProfileType } from "../../../model/Statement";

export type IdAccountFilter = {
    type: 'accountId',
    accountIds?: string[],
}
export type CashFlowRoleAccountFilter = {
    type: 'cashFlowRole',
    whitelist?: CashFlowRole[],
    blacklist?: CashFlowRole[],
}
//
export type Statement = {
    id: string,
    label: string,
    profileType: StatementProfileType,
}
export type StatementResponse = {
    data: Statement,
}
export type StatementsResponse = {
    data: Statement[],
}
export type StatementRow = {
    id: string,
    type: StatementRowType,
    label: string,
    accountIds?: string[],
    rows?: StatementRow[],
    constraintType?: string,
    link?:  StatementRowLinkResponse
}
export enum StatementRowLinkType {
    Parent = 'parent',
    Predecessor = 'predecessor',
}
export type StatementRowLink = {
    type: StatementRowLinkType,
    rowId: string,
}

export type StatementRowLinkResponse = {
    type: StatementRowLinkType,
    itemId: string,
}

export type StatementRowPostPayload = {
    type: StatementRowType,
    label: string,
    link?: StatementRowLink,
    accountIds?: string[],
    position?: 'top',
    constraintType?: string,
}
export type StatementRowPatchPayload = {
    type?: StatementRowType,
    label?: string,
    link?: StatementRowLink|null,
    accountIds?: string[]|null,
    position?: 'top'|null,
    constraintType?: string|null,
}
export type StatementRowsResponse = {
    data: StatementRow[],
}

export type CompanyUnit = {
    label: string,
    values: {
        companyUnitId: string
    }
}

export type CompanyUnitControl = {
    label: string,
    options: CompanyUnit[]
}

export type CompanyUnitControlResponse = {
    data: CompanyUnitControl[] // TODO: change me
}
