import {call, put} from "redux-saga/effects";
import axios from "../../../axios";
import * as actionTypes from "../actionTypes";
import * as RestV1 from "./RestV1Types";
import {legacyAlert} from "../../UserAlert";
import {getAccounts} from "./function-getAccounts";
import {loadAccount} from "./loadAccount";
import {findAccountsByLabel} from "../../../model/Accounting/AccountRegistry";
import {PostingChannel} from "../../../model/Posting/enums";
import {transformRestV1PostingType} from "./functions/transformRestV1PostingType";
import tld from "../../../tld";

export function* getUniqueLabeledAccountByDraft(account: AccountingAccountDraft) {
    if (undefined !== account.id) {
        return account;
    }
    const accounts: AccountRegistry = yield call(getAccounts);
    const loadedAccounts: AccountingAccount[] = findAccountsByLabel(accounts, account.label, account.cashFlowRole);
    if (loadedAccounts.length > 0) {
        return loadedAccounts[0];
    }
    return yield call(getRemoteAccountByDraft, account);
}

export function* getAmbiguousLabeledAccountByDraft(account: AccountingAccountDraft) {
    if (undefined !== account.id) {
        return account;
    }
    return yield call(getRemoteAccountByDraft, account);
}

function* getRemoteAccountByDraft(account: AccountingAccountDraft) {
    let requestParams: RestV1.RemoteAccountModificationRequest = {
        label: account.label,
        cashFlowRole:account.cashFlowRole
    };
    try {
        const restV1AccountingAccount: RestV1.RemoteAccount =
            yield axios.post(
                tld(process.env.REACT_APP_URL_API_CONNECT) + "/v1/accounting/accounts",
                requestParams,
                {
                    withCredentials: true,
                    headers: {
                        "Accept": "application/json",
                        "Content-Type": "application/json"
                    },
                }
            ).then(response => response.data);
        const accountingAccount: AccountingAccount = {
            ...restV1AccountingAccount
        };
        yield put({type: actionTypes.REDUCE_ACCOUNTING_ACCOUNT_LOADED, payload: accountingAccount});
        return accountingAccount;
    } catch (axiosError) {
        if (409 === axiosError.response.status) {
            return yield call(loadAccount, axiosError.response.headers["resource-id"]);
        } else {
            legacyAlert(axiosError, "init cash flows");
            throw axiosError;
        }
    }
}

export function transformRestV1PostingResource(postingResource: RestV1.RemotePosting, channel: PostingChannel): Posting {
    let postingIdParts: string[] = [ channel ];
    let resourceId: string|undefined = postingResource.id;
    if (undefined === postingResource.id) {
        if (undefined === postingResource.source) {
            throw new Error("Posting resource has neither an id nor a source.");
        }
        postingIdParts.push(postingResource.source.id);
        postingIdParts.push(postingResource.source.sequence.toString());
    } else {
        postingIdParts.push(postingResource.id);
    }
    return {
        ...postingResource,
        id: postingIdParts.join("."),
        type: transformRestV1PostingType(postingResource.type),
        channel,
        resourceId,
        receiptDate: new Date(postingResource.receiptDate),
    };
}


export function transformRestV1OpeningBalanceResource(
    openingBalanceResource: RestV1.RemoteOpeningBalance
): OpeningBalance {
    return {
        ...openingBalanceResource
    };
}
