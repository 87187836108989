import React, {Suspense} from 'react';
import ReactDOM from 'react-dom';
import reportWebVitals from './reportWebVitals';
import registerScsIFrameResizer from './registerScsIFrameResizer';
import {Provider} from 'react-redux';
import {Helmet} from "react-helmet";
import moment from 'moment';
import 'moment/locale/de';

import store from './store';

import App from './scenes/App';
import {ConnectedFlagsProvider} from "./flags";

import Bugsnag from '@bugsnag/js';
import BugsnagPluginReact, {BugsnagErrorBoundary} from '@bugsnag/plugin-react';

import './i18n';

import {UserAnalytics} from "./services/UserAnalytics/functions";

// explicit css imports are required to get rid of CI breaking warnings about conflicting order
import 'antd/es/avatar/style/index.less';
import 'antd/es/modal/style/index.less';
import 'antd/es/alert/style/index.less';
import 'antd/es/pagination/style/index.less';
import 'antd/es/table/style/index.less'
import './index.less';
import 'c3/c3.min.css';
import './c3.css';
import useBrand from "./hooks/useBrand";

moment.locale('de')

UserAnalytics.init();
UserAnalytics.track('Page Load');

const ErrorBoundary: React.FC = (props) => {
    if (process.env.REACT_APP_BUGSNAG_KEY) {
        Bugsnag.start({
            apiKey: process.env.REACT_APP_BUGSNAG_KEY,
            collectUserIp: false,
            plugins: [new BugsnagPluginReact()],
            logger: null, // make Bugsnag silent in console
            appVersion: process.env.REACT_APP_REVISION?.substring(0, 8),
        });
        const ErrorBoundary: BugsnagErrorBoundary | undefined
            = Bugsnag.getPlugin('react')?.createErrorBoundary(React) || undefined;
        return ErrorBoundary ? <ErrorBoundary>{props.children}</ErrorBoundary> : <>{props.children}</>;
    } else {
        return <>{props.children}</>;
    }
}

const Meta: React.FC = ({children}) => {
    const brand = useBrand();
    return <>
        <Helmet>
            <title>{brand.name}</title>
            <meta name="apple-mobile-web-app-title" content="{brand.name}"/>
        </Helmet>
        {children}
    </>;
}

// push also console errors to bugsnag
if (process.env.REACT_APP_BUGSNAG_KEY) {
    window.console = ((console => {
        const isError = v => toString.call(v) === '[object Error]';
        return {
            ...console,
            log: msg => {
                console.log(msg);
                isError(msg) && Bugsnag.notify(msg);
            },
            warn: msg => {
                console.warn(msg);
                isError(msg) && Bugsnag.notify(msg);
            },
            error: msg => {
                console.error(msg);
                Bugsnag.notify(isError(msg) ? msg : new Error("console: " + msg));
            }
        };
    })(window.console));
}

ReactDOM.render(
    <Provider store={store}>
        <ConnectedFlagsProvider>
            <Suspense fallback="">
                <ErrorBoundary>
                    <Meta>
                        <App/>
                    </Meta>
                </ErrorBoundary>
            </Suspense>
        </ConnectedFlagsProvider>
    </Provider>,
    document.getElementById('app'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

registerScsIFrameResizer();
