import { FluxStandardActionWithPayload } from "flux-standard-action";
import { AppMode } from "../../model/App/enums";

export enum AppReducerActionType {
    REDUCE_APP_MODE_SET = "REDUCE_APP_MODE_SET",
}

export type SetAppModePayload = { mode: AppMode }
export type SetAppModeAction<Payload extends SetAppModePayload = SetAppModePayload>
    = FluxStandardActionWithPayload<AppReducerActionType.REDUCE_APP_MODE_SET, Payload>

export type AppAction = SetAppModeAction
