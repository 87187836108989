import React, {useCallback} from "react";
import {useTranslation} from "react-i18next";

import {Col, Row, Select, Switch} from "antd";

const Option = Select.Option;

const SelectRecurrence: React.VFC<AntdFormControlProps<RecurrenceValue>> = ({
    defaultValue = {active: false, interval: "1 month"},
    value,
    onChange = () => {},
}) => {
    const {t} = useTranslation();

    const active = value ? value.active : defaultValue.active;
    const interval = value ? value.interval : defaultValue.interval;

    const onSwitchChange = useCallback(
    (inputActive) => {
        if (inputActive !== active) {
            onChange({active: inputActive, interval: interval || "1 month"});
        }
    }, [active, interval, onChange]);

    const onIntervalChange = useCallback(
    (inputDate) => {
        if (inputDate !== interval) {
            onChange({active, interval: inputDate});
        }
    }, [active, interval, onChange]);

    const intervalSelect = active ? <Col span={21}>
        <Select
            defaultValue={interval}
            style={{width: 126}}
            // optionFilterProp="children"
            // filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
            onChange={onIntervalChange}
        >
            <Option value="1 day">{t("täglich")}</Option>
            <Option value="1 week">{t("wöchentlich")}</Option>
            <Option value="1 month">{t("monatlich")}</Option>
            <Option value="3 month">{t("quartalsweise")}</Option>
            <Option value="6 month">{t("halbjährlich")}</Option>
            <Option value="1 year">{t("jährlich")}</Option>
        </Select>
    </Col> : null;

    return <Row>
        <Col span={3}>
            <Switch defaultChecked={active} onChange={onSwitchChange}/>
        </Col>
        {intervalSelect}
    </Row>;
}

export default SelectRecurrence;
