import {AxiosInstance, AxiosRequestConfig} from "axios";
import localforage from "localforage"
import store from "../store";
import * as selectors from "../reducer";

let localForageInitialized = false;

function initLocalforage(): void {
    if (!localForageInitialized) {
        localForageInitialized = true;
        const appMode = selectors.getAppMode(store.getState());
        localforage.config({
            // driver: localforage.WEBSQL, // Force WebSQL; same as using setDriver()
            name: "app.plusfix.finance",
            version: 1.0,
            // size: 4980736, // Size of database, in bytes. WebSQL-only for now.
            storeName: "apiCache_" + appMode, // Should be alphanumeric, with underscores.
            // description: "some description"
        });
        // drop legacy database
        localforage.dropInstance({
            name: "app.plusfix.finance",
            storeName: "apiCache",
        });
    }
}

function getStorageKey(axiosConfig: AxiosRequestConfig): string|null {
    const loginNamespace = selectors.findLoginNamespace(store.getState());
    return loginNamespace ? [
        loginNamespace,
        axiosConfig.url,
        JSON.stringify(axiosConfig.params)
    ].join(";") : null;
}

interface ApiCacheEntryResponse {
    data: any;
    status: number;
    statusText: string;
    headers: any;
}

interface ApiCacheEntry {
    lastModified?: string,
    eTag?: string,
    response: ApiCacheEntryResponse
}

export default function useCacheInterceptor(axios: AxiosInstance) {
    axios.interceptors.request.use(async (config) => {
        if ("get" === config.method?.toLowerCase()) {
            const storageKey = getStorageKey(config);
            if (storageKey) {
                initLocalforage();
                const storageValuePromise: Promise<ApiCacheEntry|null> = localforage.getItem<ApiCacheEntry>(storageKey);
                const storageValue: ApiCacheEntry|null = storageValuePromise ? await storageValuePromise : null;
                if (storageValue) {
                    if (storageValue.lastModified) {
                        config.headers["If-Modified-Since"] = storageValue.lastModified;
                    }
                    if (storageValue.eTag) {
                        config.headers["If-None-Match"] = storageValue.eTag;
                    }
                    config.validateStatus = (status) => {
                        return (status >= 200 && status < 300) // default
                            || 304 === status;
                    }
                }
            }
        }
        return config;
    }, (error) => {
        return Promise.reject(error);
    });

    axios.interceptors.response.use(async function (response) {
        switch (response.status) {
            case 200:
                if (response.headers?.["last-modified"] || response.headers?.["e-tag"]) {
                    const storageKey = getStorageKey(response.config);
                    if (storageKey) {
                        initLocalforage();
                        localforage.setItem(
                            storageKey,
                            {
                                lastModified: response.headers["last-modified"],
                                eTag: response.headers["e-tag"],
                                response: {
                                    data: response.data,
                                    status: response.status,
                                    statusText: response.statusText,
                                    headers: response.headers
                                },
                            }
                        );
                    }
                }
                break;
            case 304:
                const lastModified: string = response.headers["last-modified"];
                const eTag: string = response.headers["e-tag"];
                const storageKey = getStorageKey(response.config);
                if (!storageKey) {
                    throw new Error("ApiCache storage key not found.");
                }
                initLocalforage();
                const storageValuePromise: Promise<ApiCacheEntry|null> = localforage.getItem<ApiCacheEntry>(storageKey);
                const storageValue: ApiCacheEntry|null = storageValuePromise ? await storageValuePromise : null;
                if (storageValue) {
                    let update = false;
                    if (undefined !== eTag && storageValue.eTag !== eTag) {
                        storageValue.eTag = eTag;
                        update = true;
                    }
                    if (undefined !== lastModified && storageValue.lastModified !== lastModified) {
                        storageValue.lastModified = lastModified;
                        update = true;
                    }
                    if (update) {
                        localforage.setItem(storageKey, storageValue);
                    }
                    response.data = storageValue.response.data;
                }
                break;
            default:
                // do intentionally nothing
        }
        return response;
    }, (error) => {
        return Promise.reject(error);
    });
}




