import {delay, put} from "redux-saga/effects";
import AuthBroadcast from "../AuthBroadcast";
import {renewLogin} from "../actions";

const renewIntervalSeconds = 1800;

export default function* renewLoginLoop() {
    while (true) {
        yield delay(renewIntervalSeconds * 1000);
        if (AuthBroadcast.isLeader()) {
            yield put(renewLogin());
        }
    }
}
