import * as actionTypes from "./actionTypes";

export function reduceCreditLine(creditLine: CreditLine|null): actionTypes.ReduceCreditLineAction {
    return {
        type: actionTypes.REDUCE_CREDIT_LINE,
        payload: creditLine
    }
}
export function initializeSettings(): actionTypes.InitializeSettingsAction {
    return {
        type: actionTypes.INITIALIZE_SETTINGS
    }
}
