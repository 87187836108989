import {PostingChannel} from "../../model/Posting/enums";

export const POSTINGS_LOADED = 'POSTINGS_LOADED';
export const POSTING_DELETED = 'POSTING_DELETED';
export const REDUCE_POSTINGS_LOADING = 'REDUCE_POSTINGS_LOADING';
export const REDUCE_POSTINGS_LOADED = 'REDUCE_POSTINGS_LOADED';
export const REDUCE_POSTING_DELETED = 'REDUCE_POSTING_DELETED';

export interface PostingsLoaded {
    type: typeof POSTINGS_LOADED,
    payload: {
        postings: Posting[],
        defaultChannel: PostingChannel,
    }
}

export interface PostingDeleted {
    type: typeof POSTING_DELETED,
    payload: {
        channel: PostingChannel,
        sourceId: string,
    }
}

export interface ReducePostingsLoading {
    type: typeof REDUCE_POSTINGS_LOADING
}

export interface ReducePostingsLoaded {
    type: typeof REDUCE_POSTINGS_LOADED,
    payload: {
        postings: Posting[],
        defaultChannel: PostingChannel,
    }
}

export interface ReducePostingDeleted {
    type: typeof REDUCE_POSTING_DELETED,
    payload: {
        channel: PostingChannel,
        sourceId: string,
    }
}

export type PostingReducerAction =
    ReducePostingsLoading |
    ReducePostingsLoaded |
    ReducePostingDeleted;
