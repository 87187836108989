import PropTypes from 'prop-types';

export const CategorySummaryPropType = PropTypes.shape({
    name: PropTypes.string.isRequired,
    accountNumbers: PropTypes.arrayOf(PropTypes.number),
});

export const CashFlowPropType = PropTypes.shape({
    id: PropTypes.string.isRequired,
    amount: PropTypes.number.isRequired,
    paymentDate: PropTypes.string.isRequired,
    category: CategorySummaryPropType,
    actualPaymentDate: PropTypes.string,
    plannedPaymentDate: PropTypes.string,
    annotation: PropTypes.string,
    receiptNumber: PropTypes.string,
    participant: PropTypes.shape({
        name: PropTypes.string.isRequired,
    }),
    paymentAccount: PropTypes.shape({
        accountNumber: PropTypes.number.isRequired,
        name: PropTypes.string.isRequired,
    }),
});

export const CashFlowStatsInitializationPropType = PropTypes.shape({
    latestImportDate: PropTypes.string,
});

export const CashFlowStatsPropType = PropTypes.shape({
    latestImportDate: PropTypes.instanceOf(Date),
});
