export enum BankAction {
    TOGGLE_BANK_VISIBILITY = "TOGGLE_BANK_VISIBILITY",
    INITIALIZE_BANK = "INITIALIZE_BANK",
    LOAD_BANK_ACCOUNTS = "LOAD_BANK_ACCOUNTS",
    SYNCHRONIZE_BANK_ACCOUNTS = "SYNCHRONIZE_BANK_ACCOUNTS",
    CONNECT_BANK_ACCOUNT = "CONNECT_BANK_ACCOUNT",
    DISCONNECT_BANK_ACCOUNT = "DISCONNECT_BANK_ACCOUNT",
    DELETE_BANK_ACCOUNT = "DELETE_BANK_ACCOUNT",
    CANCEL_WORKFLOW = "CANCEL_WORKFLOW",
    SEARCH_BANK_WORKFLOW = "SEARCH_BANK_WORKFLOW",
    CONNECT_BANK_WORKFLOW = "CONNECT_BANK_WORKFLOW",
    REFRESH_BANK_CONNECTION = "REFRESH_BANK_CONNECTION "
}

export type ToggleBankVisibilityAction = {type: BankAction.TOGGLE_BANK_VISIBILITY, payload: boolean};
export function toggleBankVisibility(checked: boolean): ToggleBankVisibilityAction {
    return {
        type: BankAction.TOGGLE_BANK_VISIBILITY,
        payload: checked,
    }
}

export type InitializeBankAction = {type: BankAction.INITIALIZE_BANK};
export function initializeBank(): InitializeBankAction {
    return {
        type: BankAction.INITIALIZE_BANK
    };
}

export type LoadBankAccountsAction = {type: BankAction.LOAD_BANK_ACCOUNTS};
export function loadBankAccounts(): LoadBankAccountsAction {
    return {
        type: BankAction.LOAD_BANK_ACCOUNTS,
    };
}

export type SynchronizeBankAccountAction = {type: BankAction.SYNCHRONIZE_BANK_ACCOUNTS};
export function synchronizeBankAccounts(): SynchronizeBankAccountAction {
    return {
        type: BankAction.SYNCHRONIZE_BANK_ACCOUNTS,
    };
}

export type ConnectBankAccountAction = {
    type: BankAction.CONNECT_BANK_ACCOUNT,
    payload: BankAccount,
};
export function connectBankAccount(bankAccount: BankAccount): ConnectBankAccountAction {
    return {
        type: BankAction.CONNECT_BANK_ACCOUNT,
        payload: bankAccount
    };
}

export type DisconnectBankAccountAction = {
    type: BankAction.DISCONNECT_BANK_ACCOUNT,
    payload: BankAccount,
};
export function disconnectBankAccount(bankAccount: BankAccount): DisconnectBankAccountAction {
    return {
        type: BankAction.DISCONNECT_BANK_ACCOUNT,
        payload: bankAccount
    };
}

export type DeleteBankAccountAction = {
    type: BankAction.DELETE_BANK_ACCOUNT,
    payload: BankAccount,
};
export function deleteBankAccount(bankAccount: BankAccount): DeleteBankAccountAction {
    return {
        type: BankAction.DELETE_BANK_ACCOUNT,
        payload: bankAccount
    };
}

export type CancelWorkflowAction = {type: BankAction.CANCEL_WORKFLOW};
export function cancelWorkflow(): CancelWorkflowAction {
    return {
        type: BankAction.CANCEL_WORKFLOW
    };
}

export type SearchBankWorkflowAction = {type: BankAction.SEARCH_BANK_WORKFLOW};
export function searchBankWorkflow(): SearchBankWorkflowAction {
    return {
        type: BankAction.SEARCH_BANK_WORKFLOW
    };
}

export type ConnectBankWorkflowAction = {type: BankAction.CONNECT_BANK_WORKFLOW, payload: number};
export function connectBankWorkflow(connectionId: number): ConnectBankWorkflowAction {
    return {
        type: BankAction.CONNECT_BANK_WORKFLOW,
        payload: connectionId
    };
}
export type RefreshFinApiConnectionAction = {type: BankAction.REFRESH_BANK_CONNECTION, payload: string}

export function refreshFinApiConnection(integrationId: string): RefreshFinApiConnectionAction {
    return {
        type: BankAction.REFRESH_BANK_CONNECTION,
        payload: integrationId
    };
}
