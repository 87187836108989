import {put, select, StrictEffect} from "redux-saga/effects";
import {BankReducerAction} from "../reducer";
import axios from "../../../axios";
import history from "../../../history";
import {BankWorkflowType} from "../enums";
import {bankWorkflowSelector} from "../../selectors";
import {ConnectBankWorkflowAction, RefreshFinApiConnectionAction} from "../actions";
import {AxiosResponse} from "axios";
import tld from "../../../tld";

type RestV1FinApiRequest = {
    externalId: number,
    redirectUrl: string,
};

type RestV1FinApiResponseData = {
    connectionId: string,
};

export function* cancelWorkflow(): Generator<StrictEffect, void, void> {
    yield put({
        type: BankReducerAction.REDUCE_WORKFLOW,
    });
}

export function* loadWorkflow(): Generator<StrictEffect, void, void> {
    const urlParam = "finApi";
    const activeConnectionId: string|undefined = (new URL(window.location.href)).searchParams.get(urlParam) || undefined;
    if (undefined !== activeConnectionId) {
        const url = new URL(window.location.href);
        url.searchParams.delete(urlParam);
        history.push(url);
        yield put({
            type: BankReducerAction.REDUCE_WORKFLOW,
            payload: {
                type: BankWorkflowType.CONNECT_BANK,
                connectionId: activeConnectionId,
            }
        });
    }
}

export function* searchBankWorkflow(): Generator<StrictEffect, void, void> {
    yield put({
        type: BankReducerAction.REDUCE_WORKFLOW,
        payload: {
            type: BankWorkflowType.SELECT_BANK
        }
    });
}

function handle451(response) {
    if (response.headers.location) {
        window.location.href = response.headers.location;
    } else {
        throw Error("No location found in response for finAPI integration.");
    }
}

export function* connectFinApiBankWorkflow(action: ConnectBankWorkflowAction): Generator<StrictEffect | Promise<AxiosResponse<RestV1FinApiResponseData>>,
    void,
    any> {
    const currentWorkflow = yield select(bankWorkflowSelector);
    if (BankWorkflowType.SELECT_BANK === currentWorkflow?.type && true !== currentWorkflow?.loading) {
        yield put({
            type: BankReducerAction.REDUCE_WORKFLOW,
            payload: {
                type: BankWorkflowType.SELECT_BANK,
                loading: true
            }
        });
    }

    const externalId = action.payload;
    const redirectUrl = new URL(window.location.href);
    redirectUrl.searchParams.set("finApi", "_connectionId_")
    const data: RestV1FinApiRequest = {
        externalId,
        redirectUrl: redirectUrl.toString(),
    };
    const response = yield axios.post<RestV1FinApiResponseData>(tld(process.env.REACT_APP_URL_API_INTEGRATION) + "/v1/finApi", data, {
        withCredentials: true,
        headers: {
            "Accept": "application/json",
            "Content-Type": "application/json"
        },
        validateStatus: status => [409, 451, 500].indexOf(status) > -1,
    });


    switch (response.status)
    {
        case 500:
            yield put({
                type: BankReducerAction.REDUCE_WORKFLOW,
                payload: {
                    type: BankWorkflowType.SELECT_BANK,
                    errors: [ response.data.errors.map(e => e.message).join(', ') ]
                }
            });
            break;
        case 409:
            const workflow: ConnectBankWorkflow = {
                type: BankWorkflowType.CONNECT_BANK,
                connectionId: response.headers["resource-id"],
            };
            yield put({
                type: BankReducerAction.REDUCE_WORKFLOW,
                payload: workflow
            });
            break;
        case 451:
            handle451(response);
            break;
        default:
            throw Error("Response status not expected: " + response.status);
    }
}

export function* refreshFinApiConnection(action: RefreshFinApiConnectionAction) {
    const integrationId = action.payload;
    const redirectUrl = new URL(window.location.href);
    redirectUrl.searchParams.set("finApi", "_connectionId_");
    const data = {
        redirectUrl
    };
    yield axios.post(`${tld(process.env.REACT_APP_URL_API_INTEGRATION)}/v1/finApi/${integrationId}/refresh`, data, {
        validateStatus: function (status) {
            return status === 451 || status === 200
        },
    }).then(res => {
        if (res.status === 451) {
            handle451(res)
        }
    })
}
