import {BroadcastChannel} from "broadcast-channel";
import {LogoutTrigger} from "./enums";
import {AuthBroadcastMessage} from "./AuthBroadcast";

export const INITIALIZE_AUTH = "INITIALIZE_AUTH";
export const LOAD_LOGINS = "LOAD_LOGINS";
export const LOGOUT = "LOGOUT";
export const RENEW_LOGIN = "RENEW_LOGIN";
export const RECEIVE_AUTH_BROADCAST = "RECEIVE_BROADCAST";
export const REDUCE_LOGIN = "REDUCE_LOGIN";
export const REDUCE_LOGOUT = "REDUCE_LOGOUT";
export const REDUCE_REGISTER_BROADCAST = "REDUCE_REGISTER_BROADCAST";
export const REDUCE_LEAD_BROADCAST = "REDUCE_LEAD_BROADCAST";
export const X_AUTH_CHANGED = "X_AUTH_CHANGED";

export interface InitializeAuthAction {
    type: typeof INITIALIZE_AUTH,
}

export interface LoadLoginsAction {
    type: typeof LOAD_LOGINS,
}

export interface LogoutAction {
    type: typeof LOGOUT,
    payload: LogoutTrigger,
}

export interface RenewLoginAction {
    type: typeof RENEW_LOGIN,
}

export interface ReceiveAuthBroadcastAction {
    type: typeof RECEIVE_AUTH_BROADCAST,
    payload: AuthBroadcastMessage,
}

export interface ReduceLoginAction {
    type: typeof REDUCE_LOGIN,
    payload: AuthLogin|undefined,
}

export interface ReduceLogoutAction {
    type: typeof REDUCE_LOGOUT,
    payload: LogoutTrigger,
}

export interface ReduceRegisterBroadcastAction {
    type: typeof REDUCE_REGISTER_BROADCAST,
    payload: BroadcastChannel,
}

export interface ReduceLeadBroadcastAction {
    type: typeof REDUCE_LEAD_BROADCAST,
}

export type AuthReducerAction =
    ReduceLoginAction |
    ReduceLogoutAction |
    ReduceRegisterBroadcastAction |
    ReduceLeadBroadcastAction;
