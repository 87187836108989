import { isCashFlowPayment } from "../Accounting/AccountQualification";
import { CashFlowRole } from "../CashFlow/enums";
import {isStandardRow} from "./Profile/rowQualifier";

export default function getPaymentCashFlowRoleForStatementRow(
    row: StatementRowContainer,
    accountRegistry: AccountRegistry
): PaymentCashFlowRole {
    const cashFlowRoleFlags: {[cashFlowRole: string]: boolean} = {};

    const rows = row?.rows?.toArray() || [];
    rows.filter(subRow => isStandardRow(subRow)).forEach(subRow => {
        const accountIds = subRow.accountIds || [];
        accountIds.filter(i => undefined !== accountRegistry[i]).forEach(accountId => {
            const account = accountRegistry[accountId];
            if (undefined !== account.cashFlowRole && isCashFlowPayment(account)) {
                cashFlowRoleFlags[account.cashFlowRole] = true;
            }
        });
    });

    return (cashFlowRoleFlags[CashFlowRole.PAYMENT]
            || 0 === Object.keys(cashFlowRoleFlags).length
            || 2 === Object.keys(cashFlowRoleFlags).length
        )
        ? CashFlowRole.PAYMENT
        : (cashFlowRoleFlags[CashFlowRole.DEPOSIT] ? CashFlowRole.DEPOSIT : CashFlowRole.DISBURSEMENT);
}
