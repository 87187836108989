import React from "react";

import styles from "./Stage.module.css"

type StageProps = Stylable & {
    ghost?: boolean
}

const Stage: React.FC<StageProps> = ({
    className= "",
    style,
    ghost = false,
    children
}) => {
    const ghostClassName = ghost ? styles.ghost : '';
    return <div className={`${styles.stage} ${ghostClassName} ${className}`} style={style}>{children}</div>;
}

export default Stage;
