import {greyText} from "../../../components/colors";
import i18n from "i18next";
import {notification} from "antd";
import React from "react";

export default function showError(errorId: string) {
    const alertIdContent = <span style={{color: greyText, fontSize: "smaller"}}>{errorId}</span>;
    const alertContent = <span>{i18n.t("Leider ist ein Fehler aufgetreten.")}<br/>{i18n.t("Wir arbeiten daran.")} {alertIdContent}</span>;
    notification.error({key: "UserAlert", duration: 5, message: alertContent});
}
