import { timeFrameToString } from "../TimeFrame/functions";
import { makeTimeFramesForTimeRange } from "../TimeRange";

export default function getTimeAxisOfTimeRange(
    timeRange: TimeRange,
    timeSeriesResolution: TimeSeriesResolution
): TimeAxis {
    const timeFrames = makeTimeFramesForTimeRange(timeRange, timeSeriesResolution);
    const labels = timeFrames.map(timeFrame => timeFrameToString(timeFrame));
    return { timeFrames, labels, resolution: timeSeriesResolution }
}
