import {AxiosResponse} from "axios";

export default function arrayStream<T = any>(axiosResponse: AxiosResponse<(T|StreamControlItem)[]>): AxiosResponse<T[]> {
    if (Array.isArray(axiosResponse.data) && axiosResponse.data.length) {
        const streamControls: StreamControl[] = [];
        const newData = axiosResponse.data.filter(item => {
            const streamControl = (item as StreamControlItem)._streamControl;
            if (streamControl) {
                if (Object.keys(item).length > 1) {
                    throw new Error(`Unexpected stream control structure: ${Object.keys(item)}`);
                }
                streamControls.push(streamControl);
            }
            return !streamControl;
        });
        if (streamControls.length) {
            axiosResponse = {
                ...axiosResponse,
                data: newData as T[],
            };
            const lastControl = streamControls[streamControls.length - 1];
            if ("cancel" === lastControl.handle) {
                const error = lastControl.error;
                throw new Error(
                    `${axiosResponse.config.method} ${axiosResponse.config.url} failed: ${error.id} ${error.message}`
                );
            }
        }
    }
    return axiosResponse as AxiosResponse<T[]>;
}
