import debug from "../../services/Debug/functions/debug";

export default function filterObject<T extends Object | undefined>(obj: T, predicate:
(value: any, key: string) => boolean): Record<string, any>|undefined {
    if (undefined === obj) {
        return undefined;
    }
    let result = {}, key;
    let filtered = false;
    for (key in obj) {
        if (obj.hasOwnProperty(key)) {
            if (predicate(obj[key], key)) {
                result[key] = obj[key];
            } else {
                filtered = true;
            }
        }
    }
    const result2 = filtered ? result : obj;
    debug("filterObject", obj, result);
    return result2;
};
