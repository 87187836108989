import { call, select } from "redux-saga/effects";
import { getStatements } from "../../../reducer";
import { StatementProfileType } from "../../../model/Statement";
import {PlanningCreatedAction, PlanningUpdatedAction} from "../../Planning/actionTypes";
import updateCashFlowStatementForPlanning from "./CashFlowStatement/updateCashFlowStatementForPlanning";

export default function* applyPlanning(action: PlanningCreatedAction | PlanningUpdatedAction) {
    const {planning, statementAssignments} = action.payload;
    const statements: Statement[] = yield select(getStatements);
    for (const statement of statements) {
        if (StatementProfileType.CASH_FLOW === statement.profileType) {
            const statementId = statement.id;
            if (undefined === statementAssignments[statementId]) {
                console.error(`Missing planning assignment of planning ${planning.id} for statement ${statementId}`);
            } else {
                yield call(
                    updateCashFlowStatementForPlanning,
                    statement,
                    statementAssignments[statementId],
                    planning.account,
                    planning.cashAccount,
                    planning.transferAccount
                );
            }
        }
    }
}
