import React, {useMemo} from "react";
import {useTranslation} from "react-i18next";

import {CashFlowRole} from "../../model/CashFlow/enums";
import SelectAccount from "./SelectAccount";
import {isCashFlowDeposit} from "../../model/Accounting/AccountQualification";
import filterObject from "../../model/Object/filterObject";

const SelectDepositAccount: React.VFC<SelectCashAccountProps> = (props) => {
    const {t} = useTranslation();

    const accounts = useMemo(
        () => filterObject(props.accounts, isCashFlowDeposit),
        [props.accounts]
    );

    return <SelectAccount
        {...props}
        placeholder={props.placeholder || t("select category or reassign")}
        accounts={accounts}
        cashFlowRole={CashFlowRole.DEPOSIT}
    />;
}

export default SelectDepositAccount;
