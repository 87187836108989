import {useMemo} from "react";
import {AxiosError} from "axios";
import errorAlert from "./errorAlert";
import infoAlert from "./infoAlert";
import warningAlert from "./warningAlert";

export default function useUserAlert(featureName: string, componentName?: string): UserAlert {
    return useMemo(
        () => ({
            error: (error: Error|AxiosError, actionName: string, actionContext: object = {}) => {
                errorAlert(featureName, error, actionName, actionContext, componentName);
            },
            info: (userMessage: string, actionName: string, actionContext: object = {}) => {
                infoAlert(featureName, userMessage, actionName, actionContext, componentName);
            },
            warning: (userMessage: string, description: string|undefined, actionName: string, actionContext: object = {}) => {
                warningAlert(featureName, userMessage, description, actionName, actionContext, componentName);
            },

        }),
        [featureName, componentName],
    );
}
