import { call } from "redux-saga/effects";
import { createRemoteStatementRow, updateRemoteStatementRow } from "../functions";
import { CashFlowRole } from "../../../../model/CashFlow/enums";
import { statementRowIsCategory } from "../../../../model/Statement/statementRowIsGroup";
import { StatementProfileType, StatementRowConstraintMainType } from "../../../../model/Statement";
import { RowLinkRelation } from "../../../../model/StatementUri/enums";
import getStatementRowById from "../../../../model/Statement/getStatementRowById";
import composeMimeType from "../../../../model/Mime/composeMimeType";
import profileCashFlowStatement from "../../../../model/Statement/Profile/profileCashFlowStatement";
import findStatementRowByAccountId from "../../../../model/Statement/findStatementRowByAccountId";
import findCreditorRowIdForSubGroupRowId from "../../../../model/Statement/Profile/findCreditorRowIdForSubGroupRowId";
import findDebtorRowIdForSubGroupRowId from "../../../../model/Statement/Profile/findDebtorRowIdForSubGroupRowId";
import resolveGroupRowId from "../../../../model/Statement/Profile/resolveGroupRowId";
import { mutateWithParticipantRow } from "../../../../model/Statement/Profile/mutator.functions";
import debug from "../../../Debug/functions";

function* createCategoryStatementRowIfMissing(
    statement: Statement,
    cashFlowStatementProfile: CashFlowStatementProfile,
    account: AccountingAccount,
    parentRow: StatementRow
) {
    debug('createCategoryStatementRowIfMissing()', statement, account, parentRow);
    let categoryRow = findStatementRowByAccountId(statement, account.id);
    if (undefined === categoryRow) {
        const createdCategoryRow: StatementRow = yield createRemoteStatementRow(
            statement.id,
            account.label,
            [ account.id ],
            undefined,
            parentRow ? {
                id: parentRow.id,
                relation: RowLinkRelation.PARENT,
            } : undefined,
        );
        if(createdCategoryRow){
            cashFlowStatementProfile.groupRowIdByCategoryRowId[createdCategoryRow.id]
                = resolveGroupRowId(cashFlowStatementProfile, parentRow.id);
                 categoryRow = createdCategoryRow;
        }

    }
    return categoryRow;
}

/**
 * @todo localization
 */
function getDefaultGroupRowLabel(cashFlowRole: CashFlowRole, defaultLabel: string): string {
    switch (cashFlowRole) {
        case CashFlowRole.CREDITOR:
            return 'Kreditoren';
        case CashFlowRole.DEBTOR:
            return 'Debitoren';
        default:
            return defaultLabel;
    }
}

function statementRowContainerMountsAccount(statementRowContainer: StatementRowContainer, accountId: string): boolean {
    if (statementRowContainer.rows) {
        for (let row of statementRowContainer.rows) {
            if (row.accountIds && row.accountIds.indexOf(accountId) !== -1) {
                return true;
            }
            if (statementRowContainerMountsAccount(row, accountId)) {
                return true;
            }
        }
    }
    return false;
}

function statementMountsAccount(statement: Statement, accountId: string): boolean {
    return statementRowContainerMountsAccount(statement, accountId);
}

function* createOrUpdateCashFlowParticipantStatementRow(
    statement: Statement,
    cashFlowStatementProfile: CashFlowStatementProfile,
    participantAccount: AccountingAccount,
    plannedGroupRow: StatementRow,
    plannedCategoryAccount: AccountingAccount
) {
    debug('createOrUpdateCashFlowParticipantStatementRow()', participantAccount, plannedGroupRow);
    if (CashFlowRole.CREDITOR !== participantAccount.cashFlowRole && CashFlowRole.DEBTOR !== participantAccount.cashFlowRole) {
        throw new Error(`Account "${participantAccount.label}" has no participant cash flow role assigned.`);
    }
    const accountId = participantAccount.id;
    if (!findStatementRowByAccountId(statement, accountId)) {
        let participantRowId: string|null;
        switch (participantAccount.cashFlowRole) {
            case CashFlowRole.CREDITOR:
                participantRowId = findCreditorRowIdForSubGroupRowId(cashFlowStatementProfile, plannedGroupRow.id);
                break;
            case CashFlowRole.DEBTOR:
                participantRowId = findDebtorRowIdForSubGroupRowId(cashFlowStatementProfile, plannedGroupRow.id);
                break;
            default:
                throw Error('Participant cash flow role not known: ' + participantAccount.cashFlowRole);
        }
        debug('createOrUpdateCashFlowParticipantStatementRow() - participantRowId', participantRowId, accountId);
        if (null === participantRowId) {
            const cashFlowGroupRowId = resolveGroupRowId(cashFlowStatementProfile, plannedGroupRow.id);
            const createdRow: StatementRow = yield createRemoteStatementRow(
                statement.id,
                getDefaultGroupRowLabel(participantAccount.cashFlowRole, participantAccount.label),
                [ participantAccount.id ],
                composeMimeType(StatementRowConstraintMainType.CASH_FLOW_ROLE, participantAccount.cashFlowRole),
                 { id: cashFlowGroupRowId, relation: RowLinkRelation.PARENT }
            );
            mutateWithParticipantRow(
                cashFlowStatementProfile,
                createdRow,
                cashFlowGroupRowId,
                plannedCategoryAccount.id
            );
        } else {
            const participantRow = getStatementRowById(statement, participantRowId);
            if (!statementRowIsCategory(participantRow)) {
                throw new Error('Not implemented yet: transform a group to a category');
            }
            if (!statementMountsAccount(statement, accountId)) { // STATEMENT-77
                if (undefined === participantRow.accountIds || -1 === participantRow.accountIds.indexOf(accountId)) {
                    let accountIds: string[] = (undefined === participantRow.accountIds)
                        ? [accountId]
                        : [...participantRow.accountIds, accountId];
                    yield call (
                        updateRemoteStatementRow,
                        statement.id,
                        participantRow,
                        undefined,
                        undefined,
                        accountIds
                    );
                }
            }
        }
    }
}

/**
 * @deprecated
 * @todo move this to backend
 */
export default function* updateCashFlowStatementForPlanning(
    statement: Statement,
    groupDraft: GroupDraft,
    account: AccountingAccount,
    cashAccount: AccountingAccount,
    transferAccount?: AccountingAccount,
) {
    if (StatementProfileType.CASH_FLOW !== statement.profileType) {
        throw Error("The statement is not a cash flow statement.");
    }
    const cashFlowStatementProfile = profileCashFlowStatement(statement);
    // persist group draft
    let groupId: string;
    let plannedGroup: StatementRow;
    if (undefined === groupDraft.id) {
        if (undefined === cashFlowStatementProfile.operatingCashFlowRowId) {
            throw Error("Operating cash flow row not found.");
        }
        const parentLink: StatementRowLink = {
            id: cashFlowStatementProfile.operatingCashFlowRowId,
            relation: RowLinkRelation.PARENT,
        };
        plannedGroup = yield call(
            createRemoteStatementRow, statement.id, groupDraft.label, undefined, undefined, parentLink
        );
    } else {
        groupId = groupDraft.id;
        plannedGroup = getStatementRowById(statement, groupId);
    }
    yield call(createCategoryStatementRowIfMissing, statement, cashFlowStatementProfile, account, plannedGroup);
    if (undefined !== transferAccount) {
        switch (transferAccount.cashFlowRole) {
            case CashFlowRole.CREDITOR:
            case CashFlowRole.DEBTOR:
                yield call(
                    createOrUpdateCashFlowParticipantStatementRow,
                    statement,
                    cashFlowStatementProfile,
                    transferAccount,
                    plannedGroup,
                    account
                );
                break;
            case CashFlowRole.RECEIVABLE:
            case CashFlowRole.PAYABLE:
                yield call(
                    createCategoryStatementRowIfMissing,
                    statement,
                    cashFlowStatementProfile,
                    transferAccount,
                    plannedGroup
                );
                break;
            default:
                throw new Error(`Unexpected transfer cash flow role: "${transferAccount.cashFlowRole}"`);
        }
    }
}
