import {List} from "immutable";
import {statementRowIsGroup} from "../../../model/Statement/statementRowIsGroup";
import {StatementRowConstraintMainType} from "../../../model/Statement";

function getGroupsForStatementRows(statementRows: List<StatementRow>): FormGroup[] {
    let result: FormGroup[] = [];
    statementRows
        .filter(row => statementRowIsGroup(row))
        .forEach(row => result.push(getGroupForStatementRow(row)));
    return result;
}

function getGroupForStatementRow(statementRow: StatementRow): FormGroup {
    const isCashFlowTypeRow = undefined !== statementRow.constraintType
        && StatementRowConstraintMainType.CASH_FLOW_TYPE === statementRow.constraintType.mainType;
    let result: FormGroup = {
        id: statementRow.id,
        label: statementRow.label,
        isSelectable: !isCashFlowTypeRow,
    };
    if (statementRow.rows) {
        result.groups = getGroupsForStatementRows(statementRow.rows);
    }
    return result;
}

export function getPlanningGroupsForStatement(statement: Statement): FormGroup[] {
    let result: FormGroup[] = [];
    if(statement && statement.rows) {
        statement.rows
            .filter(row => statementRowIsGroup(row) && !row.isVirtual)
            .forEach(row => result.push(getGroupForStatementRow(row)));
    }
    return result;
}

export default getPlanningGroupsForStatement;
