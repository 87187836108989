import { applyMiddleware, createStore, compose } from 'redux';
import thunk from 'redux-thunk';
import promise from 'redux-promise-middleware';
import createSagaMiddleware from 'redux-saga';
import reducer from './reducer';
import saga from './saga';
import {AppMode} from "./model/App/enums";
import isAppInMode from "./services/App/functions/isAppInMode";

const sagaMiddleware = createSagaMiddleware();

const middlewares: any[] = [];
middlewares.push(sagaMiddleware);
middlewares.push(promise);
middlewares.push(thunk);
const enforce = true;
const useDevTool = enforce || process.env.NODE_ENV === 'development' || isAppInMode(AppMode.PREFLIGHT);
if (useDevTool && process.env.REACT_APP_DEBUG_REDUX === 'true') {
    const { logger } = require('redux-logger');
    middlewares.push(logger);
}

const composeEnhancers = useDevTool ? (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose : compose;

export default createStore(
    reducer,
    composeEnhancers(
        applyMiddleware(...middlewares)
    )
);

sagaMiddleware.run(saga);
