import * as actions from './actionTypes';
import {notification} from "antd";
import i18n from "../../i18n";

enum DataExplorerFilterNotification {
    GENERALFILTERSELECTED = 'GENERALFILTERSELECTED'
}

export function setHiddenCashFlowRoles(cashFlowRoles: CashFlowRole[]) {
    return {
        type: actions.REDUCE_CASH_FLOW_ROLES_BLACKLIST,
        payload: cashFlowRoles
    };
}

export function setCompanyUnitFilterAction(companyUnit?: CompanyUnit): actions.FilterCompanyUnitAction {
    return {
        type: actions.FILTER_COMPANY_UNIT,
        payload: {companyUnit}
    };
}

export function reduceCompanyUnit(companyUnit?: CompanyUnit): actions.ReduceCompanyUnitFilterAction {
    return {
        type: actions.REDUCE_COMPANY_UNIT_FILTER,
        payload: {companyUnit}
    };
}

export function enablePostingFilter(postingFilter: PostingFilter): actions.ReduceEnablePostingFilterAction {
    if (postingFilter.isFiltering) {
        notification.warning({message: i18n.t("Filter aktiv (Datenexplorer)"), key: DataExplorerFilterNotification.GENERALFILTERSELECTED, duration: 5})
    } else {
        notification.close(DataExplorerFilterNotification.GENERALFILTERSELECTED);
    }
    return {
        type: actions.REDUCE_ENABLE_POSTING_FILTER,
        payload: postingFilter
    };
}
