import * as actionTypes from './actionTypes';

const defaultState: IntegrationsState = {
    initialized: false,
    integrations: [],
}

export default function reducer(state: IntegrationsState = defaultState, action: actionTypes.IntegrationReducerAction) {
    switch (action.type) {
        case actionTypes.REDUCE_INTEGRATIONS_LOADED:
            return ({...state, initialized: true, integrations: action.payload})

        case actionTypes.REDUCE_INTEGRATION_CREATED:
            return ({...state, integrations: [...state.integrations, action.payload] })

        case actionTypes.REDUCE_INTEGRATION_DELETED:
            return ({...state, integrations: state.integrations.filter(integration => integration.id !== action.payload)})

        case actionTypes.REDUCE_INTEGRATION_UPDATED:
            return ({...state, integrations: state.integrations.map(integration => integration.id === action.payload.id ? action.payload : integration)})

        default:
            return state;

    }
}

