import tld from "../tld";

const brands: {[domain: string]: Brand} = {
    "flowpilot.finance": {
        domain: "flowpilot.finance",
        name: "FlowPilot",
        imprintUrl: "https://flowpilots.de/impressum/",
        privacyUrl: "https://flowpilots.de/datenschutzerklaerung/",
        upgradeUrl: "https://flowpilots.de/abo-upgrade-buchen/",
        helpUrlPostbank: "https://flowpilots.de/postbank-anbinden/",
        supportEmail: "info@flowpilots.de",
        supportPhone: "+49 172 971 92 95",
    },
    "plusfix.finance": {
        domain: "flowpilot.finance",
        name: "PlusFix",
        imprintUrl: "https://plusfix.de/impressum/",
        privacyUrl: "https://plusfix.de/datenschutz/",
        upgradeUrl: "https://plusfix.de/abo-upgrade-buchen/",
        helpUrlPostbank: "https://plusfix.de/postbank-anbinden/",
        supportEmail: "info@plusfix.de",
        supportPhone: "+49 172 971 92 95",
    },
};

const defaultBrand: Brand = {
    domain: "localhost",
    name: "App",
    imprintUrl: "#imprint",
    privacyUrl: "#privacy",
    upgradeUrl: "#upgrade",
    helpUrlPostbank: "#postbank-anbinden",
    supportEmail: "info@localhost",
    supportPhone: "",
}

export default function useBrand(): Brand {
    return brands[tld()] ?? defaultBrand;
}
