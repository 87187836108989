import {select, take} from "redux-saga/effects"
import {isFeatureActive, isStateInitialized, isStateLoaded, isStateReady} from "./selectors";
import {FlaggedFeature} from "../flags";

export function* featureWall(feature: FlaggedFeature) {
    if (!(yield select(isFeatureActive, feature))) {
        throw Error("Feature not active: " + feature);
    }
}

export function* waitForSelector(selector: (state: RootState) => any, waitValue: any = null) {
    while (waitValue === (yield select(selector))) {
        yield take("*");
    }
}

export function* waitForStateLoaded(lookupState: keyof RootState) {
    yield* waitForSelector(
        state => isStateLoaded(state, lookupState),
        false
    );
}

export function* waitForStateInitialized(lookupState: keyof RootState) {
    yield* waitForSelector(
        state => isStateInitialized(state, lookupState),
        false
    );
}

export function* waitForStateReady(lookupState: keyof RootState) {
    yield* waitForSelector(
        state => isStateReady(state, lookupState),
        false
    );
}
