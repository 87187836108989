import {TimeSeriesDensity} from "../../model/TimeSeries/enums";

export const REDUCE_DENSITY_SELECTED = 'REDUCE_DENSITY_SELECTED';
export const REDUCE_TIMEFRAME_SELECTED = 'REDUCE_TIMEFRAME_SELECTED';

export interface ReduceSelectDensity {
    type: typeof REDUCE_DENSITY_SELECTED,
    payload: TimeSeriesDensity,
}

export interface ReduceSelectTimeFrame {
    type: typeof REDUCE_TIMEFRAME_SELECTED,
    payload: {
        selectedDate: Date,
        offset?: number,
    }
}

export type TimeTravelReducerAction =
    ReduceSelectDensity |
    ReduceSelectTimeFrame;
