import {Scenario, ScenarioDraft} from "./saga/RestV1Types";

export const SCENARIOS_LOAD = 'SCENARIOS_LOAD';
export const SCENARIO_UPDATE = 'SCENARIO_UPDATE';
export const SCENARIO_CREATE = 'SCENARIO_CREATE';
export const SCENARIO_DELETE = 'SCENARIO_DELETE';
export const SCENARIO_EXCLUSIONS_LOAD = 'SCENARIO_EXCLUSIONS_LOAD';
export const SCENARIO_EXCLUSIONS_CREATE = 'SCENARIO_EXCLUSIONS_CREATE';
export const SCENARIO_EXCLUSIONS_DELETE = 'SCENARIO_EXCLUSIONS_DELETE';
export const REDUCE_SCENARIOS_LOADED = 'REDUCE_SCENARIOS_LOADED';
export const REDUCE_SCENARIO_UPDATED = 'REDUCE_SCENARIO_UPDATED';
export const REDUCE_SCENARIO_CREATED = 'REDUCE_SCENARIO_CREATED';
export const REDUCE_SCENARIO_DELETED = 'REDUCE_SCENARIO_DELETED';
export const REDUCE_SCENARIO_SELECTED = 'REDUCE_SCENARIO_SELECTED';
export const REDUCE_SCENARIO_DESELECTED = 'REDUCE_SCENARIO_DESELECTED';
export const REDUCE_SCENARIO_EXCLUSIONS_LOAD = 'REDUCE_SCENARIO_EXCLUSIONS_LOAD';
export const REDUCE_SCENARIO_EXCLUSIONS_CREATED = 'REDUCE_SCENARIO_EXCLUSIONS_CREATE';

export interface CreateScenarioAction {
    type: typeof SCENARIO_CREATE,
    payload: ScenarioDraft
}

export interface GetScenariosAction {
    type: typeof SCENARIOS_LOAD
}

export interface DeleteScenarioAction {
    type: typeof SCENARIO_DELETE,
    payload: string
}

export interface UpdateScenarioAction {
    type: typeof SCENARIO_UPDATE,
    payload: Scenario
}

export interface LoadScenarioExclusionsAction {
    type: typeof SCENARIO_EXCLUSIONS_LOAD,
    payload: string
}

export interface CreateScenarioExclusionAction {
    type: typeof SCENARIO_EXCLUSIONS_CREATE,
    payload: {
        scenarioId: string,
        planningId: string
    }
}

export interface DeleteScenarioExclusionAction {
    type: typeof SCENARIO_EXCLUSIONS_DELETE,
    payload: {
        scenarioId: string,
        id: string
    }
}

export interface ReduceGetScenarios {
    type: typeof REDUCE_SCENARIOS_LOADED
    payload: Scenario[]
}

export interface ReduceSelectScenario {
    type: typeof REDUCE_SCENARIO_SELECTED
    payload: Scenario
}

export interface ReduceCreateScenario {
    type: typeof REDUCE_SCENARIO_CREATED
    payload: Scenario
}

export interface ReduceUpdateScenario {
    type: typeof REDUCE_SCENARIO_UPDATED
    payload: Scenario
}

export interface ReduceDeleteScenario {
    type: typeof REDUCE_SCENARIO_DELETED
    payload: string
}

export interface ReduceDeselectScenario {
    type: typeof REDUCE_SCENARIO_DESELECTED
}

export interface ReduceGetScenarioExclusions {
    type: typeof REDUCE_SCENARIO_EXCLUSIONS_LOAD
    payload: {
        scenarioId: string,
        exclusions: ScenarioExclusion[]
    }
}

export interface ReduceCreateScenarioExclusions {
    type: typeof REDUCE_SCENARIO_EXCLUSIONS_CREATED
    payload: ScenarioExclusion
}

export type ScenarioReducerAction =
    ReduceGetScenarios |
    ReduceSelectScenario |
    ReduceDeselectScenario |
    ReduceCreateScenario |
    ReduceUpdateScenario |
    ReduceDeleteScenario |
    ReduceGetScenarioExclusions |
    ReduceCreateScenarioExclusions;
