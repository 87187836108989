import React, {useCallback, useMemo, useState} from "react";
import PlanningForm, {PlanningFormProps, PlanningFormValues} from "./PlanningForm";
import getFormValuesForPlanning from "./functions/getFormValuesForPlanning";
import debug from "../../services/Debug/functions/debug";
import * as actions from "../../services/Planning/actions";
import {notification} from "antd";
import {useDispatch, useSelector} from "react-redux";
import {useTranslation} from "react-i18next";
import getFormDefaults from "./functions/getFormDefaults";
import {standardAccountsSelector, cashFlowStatementSelector} from "../../services/selectors";
import {selectedScenarioSelector} from "../../services/Scenarios/selectors";
import getFormGroups from "./functions/getPlanningGroupsForStatement";
import getPaymentCashFlowRole from "./functions/getPaymentCashFlowRole";
import filterAccountsForPlanning from "./functions/filterAccountsForPlanning";
import filterObject from "../../model/Object/filterObject";
import {
    isCashFlowCash,
    isCashFlowParticipant,
    isCashFlowReceivablePayable
} from "../../model/Accounting/AccountQualification";
import makePlanningDraftByFormValues from "./functions/makePlanningDraftByFormValues";

type PlanningFormConnectedProps = PlanningScope & {
    planning?: Planning,
    onFinish?: () => void,
}

const PlanningFormConnected: React.VFC<PlanningFormConnectedProps> = ({
    planning,
    onFinish = () => {},
    timeFrame,
    statementRowId,
}) => {
    debug("PlanningFormConnected::render()", planning, timeFrame, statementRowId);

    const {t} = useTranslation();
    const dispatch = useDispatch();
    const statement = useSelector(cashFlowStatementSelector);
    const accounts = useSelector(standardAccountsSelector);
    const scenario = useSelector(selectedScenarioSelector);

    const nonCategoryAccounts = useMemo(
        () => filterObject(accounts, account =>
               isCashFlowCash(account)
            || isCashFlowParticipant(account)
            || isCashFlowReceivablePayable(account)
        ),
        [accounts]
    );

    if (undefined === statement || undefined === accounts) {
        throw Error("App not initialized");
    }

    const onSave = useCallback(
        (values: PlanningFormValues) => {
            debug("PlanningFormConnected::onSave()", values);
            if (!values.group) {
                throw Error("Planning group not set when saving a planning.");
            }
            const statementAssignments: StatementAssignmentDirectory = {
                [statement.id]: values.group,
            };
            const planningDraft = makePlanningDraftByFormValues(values, planning, scenario);
            dispatch(actions.savePlanning(planningDraft, statementAssignments, onFinish));
        },
        [scenario, dispatch, onFinish, planning, statement.id]
    );

    const onDelete = useCallback(
        () => {
            debug("PlanningFormConnected::onDelete()", planning, scenario);
            if (planning) {
                dispatch(actions.deletePlanning(
                    planning,
                    scenario,
                    () => {
                        onFinish && onFinish();
                        notification.success({message: t("Planung gelöscht"), duration: 5})
                    },
                ));
            } else {
                console.warn("onDelete() called but no planning is set");
            }
        },
        [dispatch, t, planning, scenario, onFinish]
    );

    const formDefaults = getFormDefaults(
        statement,
        accounts,
        timeFrame,
        statementRowId,
    );

    const initialValues = planning ? getFormValuesForPlanning(planning, formDefaults, statement) : formDefaults;

    const [group, setGroup] = useState<GroupDraft|undefined>(initialValues.group);

    const paymentCashFlowRole = useMemo(
        () => getPaymentCashFlowRole(statement, accounts, group),
        [statement, accounts, group]
    );

    const planningAccounts = useMemo(
        () => filterAccountsForPlanning(statement, accounts, group, nonCategoryAccounts),
        [statement, accounts, group, nonCategoryAccounts]
    );

    const onSelectGroup = useCallback(
        (group?: GroupDraft) => {
            debug("PlanningFormConnected::onSelectGroup()", group);
            setGroup(group);
        },
        []
    );

    const planningFormProps: PlanningFormProps = {
        initialValues,
        onSave,
        onDelete,
        deletable: undefined !== planning?.id,
        groups: useMemo(() => getFormGroups(statement), [statement]),
        onSelectGroup,
        accounts: planningAccounts,
        paymentCashFlowRole,
    };
    return <PlanningForm {...planningFormProps}/>;
}

export default PlanningFormConnected;
