import devLog from "../Debug/functions/devLog";
import mixpanel from 'mixpanel-browser';

const isUserAnalyticsEnabled = !!process.env.REACT_APP_MIXPANEL_PROJECT_TOKEN;

let sessionLoginId = undefined;
let isActor:boolean|undefined = undefined;

const analyticsActions = {
    init: () => {
        if (isUserAnalyticsEnabled) {
            mixpanel.init(process.env.REACT_APP_MIXPANEL_PROJECT_TOKEN, {
                host: 'api-eu.mixpanel.com',
            });
            mixpanel.set_config({
                disable_persistence: true,
                ip: false,
            });
        }
    },
    identify: (actorId, loginId) => {
        isActor = !!actorId;
        if (isUserAnalyticsEnabled && isActor) {
            mixpanel.identify(actorId);
            sessionLoginId = loginId;
        }
    },
    alias: (id) => {
        if (isUserAnalyticsEnabled) {
            mixpanel.alias(id);
        }
    },
    track: (name: string, props: object = {}) => {
        if (isUserAnalyticsEnabled && isActor) {
            props = {
                ...props,
                loginId: sessionLoginId,
                app: "app",
                appVersion: process.env.REACT_APP_REVISION?.substring(0, 8),
            };
            mixpanel.track(name, props);
        }
        if (process.env.REACT_APP_DEBUG_TRACK === "true") {
            devLog("[Track] " + name, props);
        }
    },
    people: {
        set: (props) => {
            if (isUserAnalyticsEnabled) mixpanel.people.set(props);
        },
    },
};

export const UserAnalytics = analyticsActions;
