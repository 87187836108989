export enum RestV1FinApiConnectionStatus {
    PENDING = 'pending',
    ENABLED_ACTIVE = 'enabledActive',
    ENABLED_PAUSED = 'enabledPaused',
    ENABLED_PERMISSION_EXPIRED = 'enabledPermissionExpired',
    DEAD = 'dead',
    DISABLED = 'disabled'
}

export enum RestV1FinApiSyncStatus {
    PENDING = "pending",
    PROGRESS = "progress",
    SUCCESS = "success",
    FAILURE = "failure",
}

export type RestV1FinApiBank = {
    externalId: string,
    name: string,
    bic: string,
}

export type RestV1FinApiConnectionResponse = {
    data: {
        id: string,
        connectionStatus: RestV1FinApiConnectionStatus,
        bank: RestV1FinApiBank,
    }
};

export type RestV1FinApiBankAccount = {
    id: string,
    isOwner: boolean,
    integrationId: string,
    accountName: string,
    iban: string,
    latestSyncDateTime: string|null,
    syncStatus: RestV1FinApiSyncStatus,
    connectionStatus: RestV1FinApiConnectionStatus,
    bank: RestV1FinApiBank,
}

export type RestV1FinApiBankAccountsResponse = {
    data: RestV1FinApiBankAccount[]
};
