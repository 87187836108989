import { all, takeEvery } from 'redux-saga/effects'
import * as actionTypes from './actionTypes';
import * as scenarioActionTypes from '../Scenarios/actionTypes';
import loadForecast, {loadForecastForScenario, reloadForecast} from "./saga/loadForecast";

export default function* saga() {
    yield all([
        yield takeEvery(actionTypes.FORECAST_LOAD, loadForecast),
        yield takeEvery(scenarioActionTypes.REDUCE_SCENARIO_SELECTED, loadForecastForScenario),
        yield takeEvery(scenarioActionTypes.REDUCE_SCENARIO_DESELECTED, loadForecastForScenario),
        yield takeEvery(actionTypes.FORECAST_BASE_PLANNING_TOGGLE, reloadForecast )
    ]);
}
