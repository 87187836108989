import { all, spawn, call } from 'redux-saga/effects'

import auth from './services/Auth/saga';
import cashFlow from './services/CashFlow/saga';
import accounting from './services/Accounting/saga';
import statement from './services/Statement/saga';
import planning from './services/Planning/saga';
import posting from './services/Posting/saga';
import quickPlanning from './services/QuickPlanning/saga';
import scenarios from './services/Scenarios/saga';
import filter from './services/Filter/saga';
import integrations from './services/Integrations/saga';
import settings from './services/Settings/saga';
import forecast from './services/Forecast/saga';
import statementValidation from "./services/CashFlow/Validator/saga";
import bank from "./services/Bank/saga";
import timSeries from "./services/TimeSeries/saga";
import transactions from './services/Transactions/saga.ts'
import chart from './services/Chart/saga'

export default function* saga() {
    const sagas = [
        auth,
        cashFlow,
        accounting,
        statement,
        planning,
        posting,
        quickPlanning,
        scenarios,
        filter,
        integrations,
        settings,
        forecast,
        statementValidation,
        bank,
        timSeries,
        transactions,
        chart
    ];
    yield all(sagas.map(saga =>
        spawn(function* () {
            while (true) {
                try {
                    yield call(saga);
                    break;
                } catch (e) {
                    console.error(e);
                }
            }
        })
    ));
}
