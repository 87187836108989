import getStatementRowById from "../../../model/Statement/getStatementRowById";
import getPaymentCashFlowRoleForStatementRow from "../../../model/Statement/getPaymentCashFlowRoleForStatementRow";
import {CashFlowRole} from "../../../model/CashFlow/enums";
import debug from "../../../services/Debug/functions/debug";

const getPaymentCashFlowRole = (
    statement: Statement,
    accounts: AccountRegistry,
    group?: GroupDraft
): PaymentCashFlowRole => {
    debug("getPaymentCashFlowRole()", statement, accounts, group);

    const rowId = group?.id;
    if (undefined === rowId) {
        const result: PaymentCashFlowRole = CashFlowRole.PAYMENT;
        debug("getPaymentCashFlowRole() result", result);
        return result;
    } else {
        const row: StatementRowContainer | undefined = null === rowId
            ? statement
            : getStatementRowById(statement, rowId);
        if (undefined === row) {
            throw new Error('Statement row not found: ' + rowId);
        } else {
            const result = getPaymentCashFlowRoleForStatementRow(row, accounts);
            debug("getPaymentCashFlowRole() result", result);
            return result;
        }
    }
}

export default getPaymentCashFlowRole;
