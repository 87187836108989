import * as actionTypes from "./actionTypes";
import {subtractMonths} from "./TimeTravel.functions";
import {TimeSeriesDensity} from "../../model/TimeSeries/enums";

const defaultDensity = TimeSeriesDensity.MONTH;
const defaultDate = new Date((new Date()).getFullYear(), 0, 1);

const defaultState: TimeTravelState = {
    isDefault: true,
    date: defaultDate,
    density: defaultDensity,
    densityControl: Object.values(TimeSeriesDensity),
    timeSeriesResolution: {density: defaultDensity, count: 1},
}

export default function reducer(
    state: TimeTravelState = defaultState,
    action: actionTypes.TimeTravelReducerAction
): TimeTravelState {
    switch (action.type) {
        case actionTypes.REDUCE_DENSITY_SELECTED: {
            return {
                ...state,
                isDefault: false,
                density: action.payload,
                timeSeriesResolution: {density: action.payload, count: 1},
            };
        }
        case actionTypes.REDUCE_TIMEFRAME_SELECTED: {
            const offsetDate = action.payload.offset ? subtractMonths(action.payload.selectedDate, action.payload.offset) : action.payload.selectedDate;
            return {
                ...state,
                isDefault: false,
                date: offsetDate
            };
        }
        default: {
            return state;
        }
    }
}
