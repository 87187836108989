import axios from '../../../axios';
import {CreateScenarioExclusionAction} from "../actionTypes";
import {put} from "redux-saga/effects";
import * as actionTypes from "../actionTypes";
import * as RestV1 from "./RestV1Types";
import tld from "../../../tld";

function* axiosCreateRemoteRestV1Exclusion(scenarioId: string, planningId: string) {
    const url = tld(process.env.REACT_APP_URL_API_PLAN) + "/v1/scenarios/" + scenarioId + '/exclusions';
    return yield axios.post(url,
        {planningId},
        {
            withCredentials: true,
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json"
            }
        });
}

export default function* createScenarioExclusion(action: CreateScenarioExclusionAction) {
    const {data}: RestV1.ScenarioExclusionResponse = yield axiosCreateRemoteRestV1Exclusion(action.payload.scenarioId, action.payload.planningId);
    yield put({
        type: actionTypes.REDUCE_SCENARIO_EXCLUSIONS_CREATED,
        payload: data
    });
}

