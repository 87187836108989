import * as actionTypes from './actionTypes';
import {ForecastReducerAction} from "./actionTypes";
import {iso8601ToDate} from "../../model/date";
import {makeDailyTimeSeriesByDates} from "../../model/TimeSeries/functions";
import transformRestV1ExplanationSeries from "./saga/functions/transformRestV1ExplanationSeries";
import enrichExplanation from "./functions/enrichExplanation";

const defaultState: ForecastState = {
    loading: false,
    visible: true,
    forecast: {'base': undefined},
    explanation: undefined,
    basePlanning: 'delta',
}

export default function reducer(state: ForecastState = defaultState, action: ForecastReducerAction): ForecastState {
    switch (action.type) {
        case actionTypes.FORECAST_LOADING_STARTED:
            return {
                ...state,
                loading: true
            }
        case actionTypes.FORECAST_LOADED:
            if (!action.payload.date.length) {
                return {
                    ...state,
                    loading: false,
                };
            }
            const payload = action.payload;
            const scenarioId = payload.scenario?.id || 'base';
            const stateForecast = state.forecast[scenarioId];
            const payloadStartDate = iso8601ToDate(payload.date[0]);
            const payloadEndDate = iso8601ToDate(payload.date[payload.date.length - 1]);

            const newForecastDirectory = {...state.forecast};
            if (!!stateForecast) {
                const stateUpperTimeSeries = stateForecast.upperTimeSeries;
                const startDate = !!stateUpperTimeSeries ? iso8601ToDate(stateUpperTimeSeries.isoCodes[0]) : payloadStartDate;

                newForecastDirectory[scenarioId] = {
                    upperTimeSeries: makeDailyTimeSeriesByDates(
                        startDate,
                        payloadEndDate,
                        [
                            // ...stateForecast.upperTimeSeries.values,
                            ...payload.upper,
                        ]
                    ),
                    lowerTimeSeries: makeDailyTimeSeriesByDates(
                        startDate,
                        payloadEndDate,
                        [
                            // ...stateForecast.lowerTimeSeries.values,
                            ...payload.lower,
                        ]
                    ),
                }
            } else {
                newForecastDirectory[scenarioId] = {
                    upperTimeSeries: makeDailyTimeSeriesByDates(
                        payloadStartDate, payloadEndDate, payload.upper
                    ),
                    lowerTimeSeries: makeDailyTimeSeriesByDates(
                        payloadStartDate, payloadEndDate, payload.lower
                    ),
                }
            }

            return {
                ...state,
                loading: false,
                forecast: newForecastDirectory,
            }
        case actionTypes.FORECAST_TOGGLED:
            return {...state, visible: !state.visible}

        case actionTypes.FORECAST_EXPLANATION_LOADED:
            const {groups} = action;
            const explanationData = transformRestV1ExplanationSeries(action.payload);
            const enrichedExplanationData = enrichExplanation(explanationData, groups);
            return {...state, explanation: enrichedExplanationData}

        case actionTypes.FORECAST_EXPLANATION_UPDATED:
            return {...state, explanation: action.payload}

        case actionTypes.FORECAST_BASE_PLANNING_TOGGLE:
            return {...state, basePlanning: action.payload}

        default:
            return state;
    }

}

