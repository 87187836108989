import {Map} from "immutable";
import {encodeTimeSeriesKey} from "./functions/encodeTimeSeriesKey";

export enum TimeSeriesReducerAction {
    REDUCE_TIME_SERIES_STATS_LOADED = "REDUCE_TIME_SERIES_STATS_LOADED",
    REDUCE_TIME_SERIES_LOADING = "REDUCE_TIME_SERIES_LOADING",
    REDUCE_TIME_SERIES_LOADED = "REDUCE_TIME_SERIES_LOADED",
    REDUCE_TRANSACTION_DATA_LOADED = 'REDUCE_TRANSACTION_DATA_LOADED'

}

export type ReduceTimeSeriesStatsLoadedAction = {
    type: TimeSeriesReducerAction.REDUCE_TIME_SERIES_STATS_LOADED,
    payload: TimeSeriesStats,
}

export type ReduceTimeSeriesLoadingAction = {
    type: TimeSeriesReducerAction.REDUCE_TIME_SERIES_LOADING,
    payload: {
        key: TimeSeriesKey
    },
}

export type ReduceTimeSeriesLoadedAction = {
    type: TimeSeriesReducerAction.REDUCE_TIME_SERIES_LOADED,
    payload: {
        key: TimeSeriesKey,
        timeSeries: SparseTimeSeries
    },
}
export type TransactionDataLoaded = {
    type: TimeSeriesReducerAction.REDUCE_TRANSACTION_DATA_LOADED,
    payload: TimeSeriesValueNestedMap,
}

type TimeSeriesReducerActionType =
    ReduceTimeSeriesStatsLoadedAction |
    ReduceTimeSeriesLoadingAction |
    ReduceTimeSeriesLoadedAction |
    TransactionDataLoaded

const defaultState: TimeSeriesState = {
    initialized: false,
    stats: {},
    registry: Map(),
}

export default function reducer(
    state: TimeSeriesState = defaultState,
    action: TimeSeriesReducerActionType
): TimeSeriesState {
    switch (action.type) {
        case TimeSeriesReducerAction.REDUCE_TIME_SERIES_STATS_LOADED: {
            return {
                ...state,
                initialized: true,
                stats: action.payload
            };
        }
        case TimeSeriesReducerAction.REDUCE_TIME_SERIES_LOADING: {
            const key = encodeTimeSeriesKey(action.payload.key);
            const entry = state.registry.get(key, {loading: false});
            if (entry.loading) {
                return state;
            } else {
                return {
                    ...state,
                    registry: state.registry.set(key, {...entry, loading: true}),
                }
            }
        }
        case TimeSeriesReducerAction.REDUCE_TIME_SERIES_LOADED: {
            const key = encodeTimeSeriesKey(action.payload.key);

            const entry: TimeSeriesRegistryEntry = {
                loading: false,
                timeSeries: action.payload.timeSeries
            };
            return {
                ...state,
                registry: state.registry.set(key, entry),
            };
        }
        case TimeSeriesReducerAction.REDUCE_TRANSACTION_DATA_LOADED: {
            return {...state, catchAllBankCashFlow: action.payload};

        }
        default:
            return state;
    }
}
