export const FILTER_COMPANY_UNIT = 'FILTER_COMPANY_UNIT';
export const REDUCE_CASH_FLOW_ROLES_BLACKLIST = 'REDUCE_HIDDEN_CASH_FLOW_ROLES';
export const REDUCE_COMPANY_UNIT_FILTER = 'REDUCE_COMPANY_UNIT_FILTER';
export const REDUCE_ENABLE_POSTING_FILTER = 'REDUCE_ENABLE_POSTING_FILTER';

export interface FilterCompanyUnitAction {
    type: typeof FILTER_COMPANY_UNIT
    payload: {
        companyUnit?: CompanyUnit
    }
}
export interface ReduceCashFlowRolesBlacklist {
    type: typeof REDUCE_CASH_FLOW_ROLES_BLACKLIST
    payload: CashFlowRole[]
}
export interface ReduceCompanyUnitFilterAction {
    type: typeof REDUCE_COMPANY_UNIT_FILTER
    payload: {
        companyUnit?: CompanyUnit
    }
}
export interface ReduceEnablePostingFilterAction {
    type: typeof REDUCE_ENABLE_POSTING_FILTER
    payload: PostingFilter
}

export type FilterReducerAction =
    ReduceEnablePostingFilterAction |
    ReduceCompanyUnitFilterAction |
    ReduceCashFlowRolesBlacklist;
