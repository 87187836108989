import { all, takeEvery } from 'redux-saga/effects'
import * as actionTypes from './actionTypes';
import getScenarios from './saga/getScenarios';
import updateScenario from './saga/updateScenario';
import deleteScenario from './saga/deleteScenario';
import createScenario from './saga/createScenario';
import createScenarioExclusion from "./saga/createScenarioExclusion";
import getScenarioExclusions from "./saga/getScenarioExclusions";
import deleteScenarioExclusion from "./saga/deleteScenarioExclusion";

export default function* saga() {
    yield all([
        yield takeEvery(actionTypes.SCENARIO_CREATE, createScenario),
        yield takeEvery(actionTypes.SCENARIO_UPDATE, updateScenario),
        yield takeEvery(actionTypes.SCENARIO_DELETE, deleteScenario),
        yield takeEvery(actionTypes.SCENARIOS_LOAD, getScenarios),
        yield takeEvery(actionTypes.SCENARIO_EXCLUSIONS_CREATE, createScenarioExclusion),
        yield takeEvery(actionTypes.SCENARIO_EXCLUSIONS_DELETE, deleteScenarioExclusion),
        yield takeEvery(actionTypes.SCENARIO_EXCLUSIONS_LOAD, getScenarioExclusions),
    ]);
}
