import {makeTimeFramesForTimeRange} from "../../TimeRange";
import {timeFrameToIsoString, timeFrameToTimeCode} from "../../TimeFrame/functions";

export default function makeTimeSeriesForTimeRange(timeRange: TimeRange, resolution: TimeSeriesResolution): TimeSeries
{
    const fillingTimeFrames = makeTimeFramesForTimeRange(timeRange, resolution);
    return {
        resolution,
        timeCodes: fillingTimeFrames.map(timeFrame => timeFrameToTimeCode(timeFrame)),
        isoCodes: fillingTimeFrames.map(timeFrame => timeFrameToIsoString(timeFrame)),
        values: Array(fillingTimeFrames.length).fill(null),
    };
}
