import { List, Map } from "immutable";
import getKeyPathByRowId from "../../model/Statement/getKeyPathByRowId";
import buildKeyPathMap from "../../model/Statement/buildKeyPathMap";
import getStatementRowsMap from "../../model/Statement/getStatementRowsMap";

export function mutateStatementWithRow(statement: Statement, row: StatementRow): Statement {
    const rowKeyPath = getKeyPathByRowId(statement, row.id);
    return {
        ...statement,
        rows: statement.rows.setIn(rowKeyPath, row),
    };
}

export function mutateStatementWithRows(statement: Statement, rows: Iterable<StatementRow>): Statement {
    const keyMap: Map<string, KeyPath> = buildKeyPathMap(statement.rows);
    let mutated = false;
    const newRows = statement.rows.withMutations(mutableRows => {
        for (let row of rows) {
            const statementRowKeyPath = keyMap.get(row.id, null);
            if (!statementRowKeyPath) {
                throw new Error('Statement row index not found for row ' + row.id + ' of statement ' + statement.id);
            }
            mutableRows = mutableRows.setIn(statementRowKeyPath, row);
            mutated = true;
        }
    });
    return mutated ? {
        ...statement,
        rows: newRows,
    } : statement;
}

export function mutateStatementRow(statementRow: StatementRow, rowMutation: Partial<StatementRow>): StatementRow {
    return {
        ...statementRow,
        ...rowMutation
    };
}

export function *yieldMutateStatementRows(rows: Iterable<StatementRow>, rowMutation: Partial<StatementRow>): Iterable<StatementRow> {
    for (let row of rows) {
        yield mutateStatementRow(row, rowMutation);
    }
}

export function mutateStateWithStatement(state: StatementState, statement: Statement): StatementState {
    let newStatements: StatementDirectory = {
        ...state.statements,
    };
    newStatements[statement.id] = statement;
    return {
        ...state,
        statements: newStatements
    };
}

function mutateStatementRowsWithCollapsing(rows: List<StatementRow>, originRows: Map<string, StatementRow>): List<StatementRow> {
    const result = rows.asMutable();
    for (let [i, row] of rows.entries()) {
        const originRow = originRows.get(row.id);
        if (undefined !== originRow) {
            const subRowsWithCollapsing = (undefined !== row.rows)
                ? mutateStatementRowsWithCollapsing(row.rows, originRows)
                : undefined;
            if (originRow.isCollapsed !== row.isCollapsed ||
                subRowsWithCollapsing !== row.rows
            ) {
                result.set(i, mutateStatementRow(
                    row, {
                        isCollapsed: originRow.isCollapsed,
                        rows: subRowsWithCollapsing,
                    }
                ));
            }
        }
    }
    return result.wasAltered() ? result : rows;
}

export function mutateStatementWithCollapsing(statement: Statement, origin: Statement): Statement {
    const originRowsById = getStatementRowsMap(origin);
    const rowsWithCollapsing = mutateStatementRowsWithCollapsing(statement.rows, originRowsById);
    return rowsWithCollapsing === statement.rows ? statement : { ...statement, rows: rowsWithCollapsing };
}
