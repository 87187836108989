export default function parseMimeType(mimeType: string): MimeTypeStruct & StatementRowConstraint {
    const parts = mimeType.split('/');
    if (2 !== parts.length) {
        throw new Error("MIME type not valid: " + mimeType);
    }
    return {
        mainType: parts[0],
        subType: parts[1],
    } as StatementRowConstraint;
}
