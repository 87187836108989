import React, {useRef} from "react";
import {useTranslation} from "react-i18next";
import {Button, ButtonProps, Popconfirm} from "antd";

const DeleteButton: React.FC<ButtonProps> = ({
    hidden,
    children,
    onClick = () => {},
    ...props
}) => {
    const {t} = useTranslation();

    const lastClickEvent = useRef<React.MouseEvent<HTMLElement>|undefined>(undefined);

    return hidden ? null : <Popconfirm
        key="delete"
        title={t("cannot undo delete")}
        okText={t("delete")}
        cancelText={t("cancel")}
        onConfirm={() => onClick(lastClickEvent.current!)}
    ><Button
        {...props}
        danger
        ghost
        onClick={e => lastClickEvent.current = e}
    >{children}</Button></Popconfirm>;
}

export default DeleteButton;
