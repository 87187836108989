import {all, takeEvery} from 'redux-saga/effects'
import * as actionTypes from './actionTypes';
import loadPlannings, {
    loadPlanningsForDensity,
    loadPlanningsForTimeFrame,
} from './saga/loadPlannings';
import savePlanning from './saga/savePlanning';
import deletePlanning from './saga/deletePlanning';
import {openCreatePlanningForm, openEditPlanningModal} from './saga/openPlanningForm';
import togglePlanningVisibility from "./saga/togglePlanningVisibility";
import * as TimeTravelActions from "../TimeTravel/actionTypes";

export default function* saga() {
    yield all([
        yield takeEvery(actionTypes.PLANNING_LOAD, loadPlannings),
        yield takeEvery(actionTypes.PLANNING_SAVE, savePlanning),
        yield takeEvery(actionTypes.PLANNING_DELETE, deletePlanning),
        yield takeEvery(actionTypes.PLANNING_CREATE, openCreatePlanningForm),
        yield takeEvery(actionTypes.PLANNING_EDIT, openEditPlanningModal),
        yield takeEvery(actionTypes.PLANNING_VISIBILITY_TOGGLED, togglePlanningVisibility),
        yield takeEvery(TimeTravelActions.REDUCE_TIMEFRAME_SELECTED, loadPlanningsForTimeFrame),
        yield takeEvery(TimeTravelActions.REDUCE_DENSITY_SELECTED, loadPlanningsForDensity),
    ]);
}
