
export const black = "#262626";
export const grey = "#ccc";
export const greyText = "#aaa";
export const blue = '#00b0f0';
export const blueLight = '#00beff';
export const green = '#2fcc7b';
export const greenLight = '#55d990';
export const yellow = '#faad14';

export const hoverBlue = "#e6f7ff";

/*
https://ant.design/docs/spec/colors
blue
*/
export const color1 = '#e6fcff';
export const color2 = '#a3f3ff';
export const color3 = '#7ae9ff';
export const color4 = '#52dcff';
export const color5 = '#28cbfc';
export const color6 = '#00b0f0'; // base
export const color7 = '#008dc9';
export const color8 = '#006da3';
export const color9 = '#004f7d';
export const color10 = '#003457';


/*
https://ant.design/docs/spec/colors
green #2fcc7b

#f0fff5
#deffe9
#acf2c7
#7ee6a9
#55d990
#2fcc7b // base
#1ea664
#11804e
#075937
#043321
*/

// http://colormind.io/
// 00B0F0
// export const green  = '#23C59C';
// export const yellow = '#C5B352';
// export const orange = '#BE7445';
// export const red    = '#BF4957';
// export const green  = '#23C59C';
// export const yellow = '#E0DA77';
// export const orange = '#D9812E';
// export const red    = '#BF3117';

// numbers
export const numberNegative = '#e33';

const colors = {
    black,
    grey,
    blue,
    blueLight,
    green,
    greenLight,
    yellow,
    hoverBlue,
    numberNegative,
};
export default colors;
