import {RemoteStats} from "../RestV1Types";
import {iso8601ToDate} from "../../../../model/date";
import {PostingChannel, PostingStage} from "../../../../model/Posting/enums";

const validPostingChannels: string[] = Object.values(PostingChannel);
const validPostingStages: string[] = Object.values(PostingStage);

function transformRequiredDate(dateValue: string): Date {
    return iso8601ToDate(dateValue);
}

function transformOptionalDate(dateValue: string|undefined): Date|undefined {
    return undefined === dateValue ? undefined : iso8601ToDate(dateValue);
}

function transformChannels(channels: {} & RemoteStats["channels"]) {
    let result: {} & AccountingStats["channels"] = {};
    Object.keys(channels).forEach(channelKey => {
        if (validPostingChannels.indexOf(channelKey) > -1) {
            result[channelKey] = {
                firstDate: transformRequiredDate(channels[channelKey].firstDate),
                lastDate: transformRequiredDate(channels[channelKey].lastDate),
                firstCashDate: transformOptionalDate(channels[channelKey].firstCashDate),
                lastCashDate: transformOptionalDate(channels[channelKey].lastCashDate),
            };
        } else {
            console.error(`Unexpected stats channel skipped: ${channelKey}`);
        }
    });
    return result;
}

function transformStages(stages: {} & RemoteStats["stages"]) {
    let result: {} & AccountingStats["stages"] = {};
    Object.keys(stages).forEach(stageKey => {
        if (validPostingStages.indexOf(stageKey) > -1) {
            if (undefined === stages[stageKey].startDate && undefined === stages[stageKey].endDate) {
                console.error(`Empty stats stage skipped: ${stageKey}`);
            } else {
                result[stageKey] = {
                    startDate: transformOptionalDate(stages[stageKey].startDate),
                    endDate: transformOptionalDate(stages[stageKey].endDate),
                };
            }
        } else {
            console.error(`Unexpected stats stage skipped: ${stageKey}`);
        }
    });
    return result;
}

export function transformRestV1Stats(data: RemoteStats): AccountingStats {
    let result: AccountingStats = {};
    if (data.channels) {
        result.channels = transformChannels(data.channels);
    }
    if (data.stages) {
        result.stages = transformStages(data.stages);
    }
    return result;
}
