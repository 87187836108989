import {dateToIso8601, yearFromDate, yearMonthFromDate} from "../../../model/date";
import moment from "moment";
import debug from "../../../services/Debug/functions";

function getDailyPostingTotalsByAccountId(postings: Posting[]): TimeSeriesValueNestedMap {
    debug('getDailyPostingTotalsByAccountId()', postings);
    let totals: TimeSeriesValueNestedMap = {};
    for (let posting of postings) {
        const dateKey = dateToIso8601(posting.receiptDate);
        const year = posting.receiptDate.getUTCFullYear();
        const month = posting.receiptDate.getUTCMonth() + 1; // (!) ISO 1-based is required
        const day = posting.receiptDate.getUTCDate();
        let accountId;
        let flowFactor;
        // account
        accountId = posting.accountId;
        flowFactor = 'Cr' === posting.type ? 1 : -1;
        if (undefined === totals[accountId]) {
            totals[accountId] = {};
        }
        if (undefined === totals[accountId][dateKey]) {
            totals[accountId][dateKey] = {
                timeFrame: { year, month, day },
                value: 0
            };
        }
        totals[accountId][dateKey].value += (flowFactor * posting.amount);
        // contra account
        accountId = posting.contraAccountId;
        flowFactor = 'Cr' === posting.type ? -1 : 1;
        if (undefined === totals[accountId]) {
            totals[accountId] = {};
        }
        if (undefined === totals[accountId][dateKey]) {
            totals[accountId][dateKey] = {
                timeFrame: { year, month, day },
                value: 0
            };
        }
        totals[accountId][dateKey].value += (flowFactor * posting.amount);
    }
    return totals;
}

function getWeeklyPostingTotalsByAccountId(postings: Posting[]): TimeSeriesValueNestedMap {
    debug('getWeeklyPostingTotalsByAccountId()', postings);
    let totals: TimeSeriesValueNestedMap = {};
    for (let posting of postings) {
        const year = posting.receiptDate.getUTCFullYear();
        const week = moment(posting.receiptDate, "DDMMYYY").isoWeek();
        const dateKey = `${year}-${week}`;
        let accountId;
        let flowFactor;
        // account
        accountId = posting.accountId;
        flowFactor = 'Cr' === posting.type ? 1 : -1;
        if (undefined === totals[accountId]) {
            totals[accountId] = {};
        }
        if (undefined === totals[accountId][dateKey]) {
            totals[accountId][dateKey] = {
                timeFrame: { year, week },
                value: 0
            };
        }
        totals[accountId][dateKey].value += (flowFactor * posting.amount);
        // contra account
        accountId = posting.contraAccountId;
        flowFactor = 'Cr' === posting.type ? -1 : 1;
        if (undefined === totals[accountId]) {
            totals[accountId] = {};
        }
        if (undefined === totals[accountId][dateKey]) {
            totals[accountId][dateKey] = {
                timeFrame: { year, week },
                value: 0
            };
        }
        totals[accountId][dateKey].value += (flowFactor * posting.amount);
    }
    return totals;
}

function getQuarterlyPostingTotalsByAccountId(postings: Posting[]): TimeSeriesValueNestedMap {
    debug('getMonthlyPostingTotalsByAccountId()', postings);
    let totals: TimeSeriesValueNestedMap = {};
    for (let posting of postings) {
        const year = posting.receiptDate.getUTCFullYear();
        const quarter = moment(posting.receiptDate, "DDMMYYY").quarter();
        const dateKey = `${year}-${quarter}`;
        let accountId;
        let flowFactor;
        // account
        accountId = posting.accountId;
        flowFactor = 'Cr' === posting.type ? 1 : -1;
        if (undefined === totals[accountId]) {
            totals[accountId] = {};
        }
        if (undefined === totals[accountId][dateKey]) {
            totals[accountId][dateKey] = {
                timeFrame: { year, quarter },
                value: 0
            };
        }
        totals[accountId][dateKey].value += (flowFactor * posting.amount);
        // contra account
        accountId = posting.contraAccountId;
        flowFactor = 'Cr' === posting.type ? -1 : 1;
        if (undefined === totals[accountId]) {
            totals[accountId] = {};
        }
        if (undefined === totals[accountId][dateKey]) {
            totals[accountId][dateKey] = {
                timeFrame: { year, quarter },
                value: 0
            };
        }
        totals[accountId][dateKey].value += (flowFactor * posting.amount);
    }
    return totals;
}

function getHalfYearlyPostingTotalsByAccountId(postings: Posting[]): TimeSeriesValueNestedMap {
    debug('getHalfYearlyPostingTotalsByAccountId()', postings);
    let totals: TimeSeriesValueNestedMap = {};
    for (let posting of postings) {
        const year = posting.receiptDate.getUTCFullYear();
        const halfYear = posting.receiptDate.getMonth() < 6 ? 1 : 2;
        const dateKey = `${year}-${halfYear}`;
        let accountId;
        let flowFactor;
        // account
        accountId = posting.accountId;
        flowFactor = 'Cr' === posting.type ? 1 : -1;
        if (undefined === totals[accountId]) {
            totals[accountId] = {};
        }
        if (undefined === totals[accountId][dateKey]) {
            totals[accountId][dateKey] = {
                timeFrame: { year, halfYear },
                value: 0
            };
        }
        totals[accountId][dateKey].value += (flowFactor * posting.amount);
        // contra account
        accountId = posting.contraAccountId;
        flowFactor = 'Cr' === posting.type ? -1 : 1;
        if (undefined === totals[accountId]) {
            totals[accountId] = {};
        }
        if (undefined === totals[accountId][dateKey]) {
            totals[accountId][dateKey] = {
                timeFrame: { year, halfYear },
                value: 0
            };
        }
        totals[accountId][dateKey].value += (flowFactor * posting.amount);
    }
    return totals;
}

function getMonthlyPostingTotalsByAccountId(postings: Posting[]): TimeSeriesValueNestedMap {
    debug('getMonthlyPostingTotalsByAccountId()', postings);
    let totals: TimeSeriesValueNestedMap = {};
    for (let posting of postings) {
        const dateKey = yearMonthFromDate(posting.receiptDate);
        const year = posting.receiptDate.getUTCFullYear();
        const month = posting.receiptDate.getUTCMonth() + 1; // (!) ISO 1-based is required
        let accountId;
        let flowFactor;
        // account
        accountId = posting.accountId;
        flowFactor = 'Cr' === posting.type ? 1 : -1;
        if (undefined === totals[accountId]) {
            totals[accountId] = {};
        }
        if (undefined === totals[accountId][dateKey]) {
            totals[accountId][dateKey] = {
                timeFrame: { year, month },
                value: 0
            };
        }
        totals[accountId][dateKey].value += (flowFactor * posting.amount);
        // contra account
        accountId = posting.contraAccountId;
        flowFactor = 'Cr' === posting.type ? -1 : 1;
        if (undefined === totals[accountId]) {
            totals[accountId] = {};
        }
        if (undefined === totals[accountId][dateKey]) {
            totals[accountId][dateKey] = {
                timeFrame: { year, month },
                value: 0
            };
        }
        totals[accountId][dateKey].value += (flowFactor * posting.amount);
    }
    return totals;
}

function getYearlyPostingTotalsByAccountId(postings: Posting[]): TimeSeriesValueNestedMap {
    debug('getYearlyPostingTotalsByAccountId()', postings);
    let totals: TimeSeriesValueNestedMap = {};
    for (let posting of postings) {
        const dateKey = yearFromDate(posting.receiptDate);
        const year = posting.receiptDate.getUTCFullYear();
        let accountId;
        let flowFactor;
        // account
        accountId = posting.accountId;
        flowFactor = 'Cr' === posting.type ? 1 : -1;
        if (undefined === totals[accountId]) {
            totals[accountId] = {};
        }
        if (undefined === totals[accountId][dateKey]) {
            totals[accountId][dateKey] = {
                timeFrame: { year },
                value: 0
            };
        }
        totals[accountId][dateKey].value += (flowFactor * posting.amount);
        // contra account
        accountId = posting.contraAccountId;
        flowFactor = 'Cr' === posting.type ? -1 : 1;
        if (undefined === totals[accountId]) {
            totals[accountId] = {};
        }
        if (undefined === totals[accountId][dateKey]) {
            totals[accountId][dateKey] = {
                timeFrame: { year },
                value: 0
            };
        }
        totals[accountId][dateKey].value += (flowFactor * posting.amount);
    }
    return totals;
}
/**
 * @deprecated for slim client
 */
export default function getPostingTotalsByAccountId(
    postings: Posting[],
    timeSeriesResolution: TimeSeriesResolution
): TimeSeriesValueNestedMap {
    debug('getPostingTotalsByAccountId()', postings, timeSeriesResolution);
    if ('year' === timeSeriesResolution.density && 1 === timeSeriesResolution.count) {
        return getYearlyPostingTotalsByAccountId(postings);
    } else if ('halfYear' === timeSeriesResolution.density && 1 === timeSeriesResolution.count) {
        return getHalfYearlyPostingTotalsByAccountId(postings);
    } else if ('quarter' === timeSeriesResolution.density && 1 === timeSeriesResolution.count) {
        return getQuarterlyPostingTotalsByAccountId(postings);
    } else if ('month' === timeSeriesResolution.density && 1 === timeSeriesResolution.count) {
        return getMonthlyPostingTotalsByAccountId(postings);
    } else if ('week' === timeSeriesResolution.density && 1 === timeSeriesResolution.count) {
        return getWeeklyPostingTotalsByAccountId(postings);
    } else if ('day' === timeSeriesResolution.density && 1 === timeSeriesResolution.count) {
        return getDailyPostingTotalsByAccountId(postings);
    } else {
        throw new Error('Time series resolution not supported yet: ' + JSON.stringify(timeSeriesResolution));
    }
}
