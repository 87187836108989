import {all, call, select} from "redux-saga/effects";
import {accountsSelector, historicalEndDateSelector} from "../../../Accounting/selectors";
import {cashFlowStatementSelector} from "../../../selectors";
import {postingSelector} from "../../../Posting/selectors";
import {filterPostingsForCashFlowStatement} from "../../../../model/Posting/Posting.filter";
import buildStatementDeepRowSet from "../../../../legacy/fatClient/functions/buildStatementDeepRowSet";
import {ReduceValidateStatementCategorizationAction} from "../actionTypes";
import {TimeSeriesDensity} from "../../../../model/TimeSeries/enums";
import CashFlowValidator from "../actions";

export function* validateStatementCategorization(action: ReduceValidateStatementCategorizationAction) {
    const postingFilter = action.payload;
    const filter = {[postingFilter.id]: postingFilter};

    const [postings, latestPostingDate, statement, accounts] = yield all([
        select(postingSelector),
        select(historicalEndDateSelector),
        select(cashFlowStatementSelector),
        select(accountsSelector)
    ]);
    if (undefined !== postings
        && undefined !== cashFlowStatementSelector
        && undefined !== accountsSelector
    ) {
        const basePostings = filterPostingsForCashFlowStatement(
            postings,
            latestPostingDate,
            accounts,
            undefined,
            filter
        );
        const deepRowSet = buildStatementDeepRowSet(
            statement,
            accounts,
            basePostings,
            {count: 1, density: TimeSeriesDensity.MONTH}
        );

        yield call(CashFlowValidator.validateCategorization, statement.id, deepRowSet.rows[0]);
    }
}
