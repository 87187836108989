import URLSearchParams from "@ungap/url-search-params";
import {AppMode} from "../../../model/App/enums";

let appMode: AppMode|undefined = undefined;

export default function getRequestAppMode(): AppMode {
    if (undefined === appMode) {
        const url = new URL(window.location.href);
        const params = new URLSearchParams(url.search);
        const appModeQueryParam = params.get('mode');
        switch (appModeQueryParam) {
            case AppMode.PREFLIGHT:
                appMode = appModeQueryParam;
                break;
            default:
                appMode = AppMode.MASTER;
        }
    }
    return appMode;
};
