import {ConnectBankAccountAction} from "../actions";
import {call} from "redux-saga/effects";
import loadBankAccounts from "./loadBankAccounts";
import {errorAlert} from "../../UserAlert";
import axios from "../../../axios";
import {RestV1FinApiConnectionStatus} from "../../../scenes/Bank/components/FinApi/RestV1.types";
import {featureWall} from "../../functions";
import {FlaggedFeature} from "../../../flags";
import tld from "../../../tld";

function* updateBankAccount(id: string, connectionStatus: RestV1FinApiConnectionStatus) {
    try {
        const url = tld(process.env.REACT_APP_URL_API_INTEGRATION) + "/v1/finApi/bankAccounts/" + id;
        yield axios.patch(url, {connectionStatus});
    } catch (axiosError) {
        const actionNames = {
            [RestV1FinApiConnectionStatus.ENABLED_ACTIVE]: "connect bank account",
            [RestV1FinApiConnectionStatus.ENABLED_PAUSED]: "disconnect bank account"
        };
        errorAlert(
            "FinApi",
            axiosError,
            actionNames[connectionStatus] || "invalid bank account update "
        );
    }
}

function* deleteBankAccount(id: string) {
    try {
        const url = tld(process.env.REACT_APP_URL_API_INTEGRATION) + "/v1/finApi/bankAccounts/" + id;
        yield axios.delete(url);
    } catch (axiosError) {
        errorAlert(
            'FinApi',
            axiosError,
            'An error has occurred during the deletion'
        )
    }
}

export function* connectBankAccount({payload: bankAccount}: ConnectBankAccountAction) {
    yield call(featureWall, FlaggedFeature.finApi);
    yield call(updateBankAccount, bankAccount.id, RestV1FinApiConnectionStatus.ENABLED_ACTIVE);
    yield call(loadBankAccounts);
}

export function* disconnectBankAccount({payload: bankAccount}: ConnectBankAccountAction) {
    yield call(featureWall, FlaggedFeature.finApi);
    yield call(updateBankAccount, bankAccount.id, RestV1FinApiConnectionStatus.ENABLED_PAUSED);
    yield call(loadBankAccounts);
}

export function* removeBankAccount({payload: bankAccount}: ConnectBankAccountAction) {
    yield call(featureWall, FlaggedFeature.finApi);
    yield call(deleteBankAccount, bankAccount.id);
}
