import { all, put, take, call } from 'redux-saga/effects'
import axios from '../../axios';
import * as actionTypes from './actionTypes';
import {legacyAlert} from "../UserAlert";
import tld from "../../tld";

// -------------
// REST V1 types
type RemoteRestV1Account = {
    id: string,
    name: string,
    accountNumber?: number,
}
type RemoteRestV1Category = {
    id: string,
    name: string,
    displayOrder: number,
    accountNumber?: number,
}
type RemoteRestV1Participant = {
    id: string,
    name: string,
    accountNumber?: number,
    dueDays?: number,
}
type RemoteRestV1CashFlow = {
    id: string,
    isEnabled: boolean,
    expirationDate?: string,
    amount: number,
    annotation?: string,
    receiptNumber?: string,
    category?: RemoteRestV1Category,
    participant?: RemoteRestV1Participant,
    paymentDate?: string,
    actualPaymentDate?: string,
    plannedPaymentDate?: string,
    dueDate?: string,
    paymentAccount?: RemoteRestV1Account,
}
type RemoteRestV1CashFlowResponse = {
    data: RemoteRestV1CashFlow[],
}
type RemoteRestV1CashFlowStats = {
    latestImportDate: string,
}
type RemoteRestV1CashFlowStatsResponse = {
    data: RemoteRestV1CashFlowStats,
}
// -------------

function axiosRequestRemoteRestV1CashFlows(): Promise<RemoteRestV1CashFlowResponse> {
    return axios.get(tld(process.env.REACT_APP_URL_API_CASHFLOW) + '/v1/cashflows');
}

function axiosRequestRemoteRestV1CashFlowStats(): Promise<RemoteRestV1CashFlowStatsResponse> {
    return axios.get(tld(process.env.REACT_APP_URL_API_CASHFLOW) + '/v1/stats');
}

function* loadRemoteRestV1CashFlows() {
    try {
        return yield axiosRequestRemoteRestV1CashFlows();
    } catch (axiosError) {
        legacyAlert(axiosError, 'init cash flows');
        return { data: [] };
    }
}

function* loadRemoteRestV1CashFlowStats() {
    try {
        return yield axiosRequestRemoteRestV1CashFlowStats();
    } catch (axiosError) {
        legacyAlert(axiosError, 'init cash flow stats');
        return { data: {} };
    }
}

function* initCashFlows() {
    const { data: cashFlows } = yield loadRemoteRestV1CashFlows();
    yield put({type: actionTypes.REDUCE_CASH_FLOWS_INIT, payload: cashFlows});
}

function* initCashFlowStats() {
    const { data } = yield loadRemoteRestV1CashFlowStats();
    const cashFlowStats = '' === data ? {} : data;
    yield put({type: actionTypes.REDUCE_CASH_FLOW_STATS_INIT, payload: cashFlowStats});
}

function* init() {
    yield take(actionTypes.CASH_FLOWS_INIT);
    yield all([
        yield call(initCashFlowStats),
        yield call(initCashFlows),
    ]);
}

export default function* saga() {
    yield all([
        yield call(init),
    ]);
}
