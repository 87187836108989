export default function reducer(state: BalanceState = {
    initialized: false,
    error: undefined,
    balances: undefined
}, action) {
    switch (action.type) {
        case "GET_BALANCES_PENDING": {
            return {
                ...state,
                loading: true,
            };
        }
        case "GET_BALANCES_FULFILLED": {
            const newState = {
                ...state,
                initialized: true,
                error: undefined,
                balances: {...state.balances},
            };
            if (action.payload.data.length) {
                const date = action.payload.data[0].date;
                newState.balances[date] = action.payload.data;
            } else {
                delete newState.balances[action.payload.config.params.date];
            }
            return newState;
        }
        case "GET_BALANCES_REJECTED": {
            if (undefined !== action.payload.response && 404 === action.payload.response.status) {
                let newState = {
                    ...state,
                    initialized: true,
                    error: undefined,
                    balances: {...state.balances},
                };
                delete newState.balances[action.payload.config.params.date];
                return newState;
            } else {
                return {
                    ...state,
                    initialized: true,
                    error: action.payload?.response.data.message || "GET_BALANCES_REJECTED",
                    balances: undefined,
                };
            }
        }
        default: {
            return state;
        }
    }
}
