import {all, call, delay, put, select, spawn} from "redux-saga/effects";
import axios from "../../../axios";
import i18n from "i18next";
import * as actionTypes from "../actionTypes";
import {legacyAlert} from "../../UserAlert";
import * as RestV1 from "./RestV1Types";
import { getAmbiguousLabeledAccountByDraft, getUniqueLabeledAccountByDraft } from "../../Accounting/saga/functions";
import { PlanningTypeEnum } from "../../../model/Planning/Enums";
import { dateToIso8601 } from "../../../model/date";
import { loadPlanningPostings } from "./posting-functions";
import { SavePlanningAction } from "../../../model/Planning";
import { transformPlanningResource } from "./planning-functions";
import { getAccounts } from "../../Accounting/saga/function-getAccounts";
import {notification} from "antd";
import {featureWall} from "../../functions";
import {FlaggedFeature} from "../../../flags";
import {reloadForecast} from "../../Forecast/saga/loadForecast";
import {isDevSelector} from "../../selectors";
import tld from "../../../tld";

function* createOrUpdatePlanning(planningDraft: PlanningDraft) {
    let account: AccountingAccount;
    let transferAccount: AccountingAccount|undefined;
    let cashAccount: AccountingAccount;
    [ account, transferAccount, cashAccount ] = yield all([
        call(getAmbiguousLabeledAccountByDraft, planningDraft.account),
        undefined === planningDraft.transferAccount
            ? undefined
            : call(getUniqueLabeledAccountByDraft, planningDraft.transferAccount),
        call(getUniqueLabeledAccountByDraft, planningDraft.cashAccount)
    ]);
    const isForecast = undefined === planningDraft.planningType
        ? undefined
        : PlanningTypeEnum.FORECAST === planningDraft.planningType;
    const apiRequest: RestV1.PutPostPlanningRequest = {
        isEnabled: planningDraft.isEnabled,
        paymentType: planningDraft.paymentType,
        isReversal: planningDraft.isReversal,
        isForecast,
        amount: planningDraft.amount,
        amountExpression: planningDraft.amountExpression,
        invoiceDate: dateToIso8601(planningDraft.invoiceDate),
        paymentDate: dateToIso8601(planningDraft.paymentDate),
        accountId: account.id,
        transferAccountId: transferAccount ? transferAccount.id : undefined,
        cashAccountId: cashAccount.id,
        probability: planningDraft.probability,
        recurrence: planningDraft.recurrence
            ? {
                unit: planningDraft.recurrence.unit,
                period: planningDraft.recurrence.period,
                endDate: planningDraft.recurrence && planningDraft.recurrence.endDate
                    ? dateToIso8601(planningDraft.recurrence.endDate)
                    : undefined,
                gradientAmount: planningDraft.recurrence.gradientAmount,
                growthRate: planningDraft.recurrence.growthRate
            }
            : undefined,
        annotation: planningDraft.annotation,
        receiptNumber: planningDraft.receiptNumber,
        companyUnitId: planningDraft.companyUnitId,
        scenarioId: planningDraft.scenarioId,
        scenarioBaseSourceId: planningDraft.scenarioBaseSourceId,
    };

    const urlIdSuffix = undefined === planningDraft.id ? "" : "/" + planningDraft.id;
    const url = tld(process.env.REACT_APP_URL_API_PLAN) + "/v1/plannings" + urlIdSuffix;
    const method = undefined === planningDraft.id ? "post" : "put";
    const planningResource: RestV1.Planning =
        yield axios({
            url,
            method,
            data: apiRequest,
            withCredentials: true,
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json"
            }
        }).then(response => response.data);
    const accounts: AccountRegistry = yield call(getAccounts);
    const planning: Planning = yield call(transformPlanningResource, planningResource, accounts);
    yield put({ type: actionTypes.REDUCE_PLANNING_LOADED, payload: planning });
    yield spawn(loadPlanningPostings, planning);
    return planning;
}

function* createPlanning(planningDraft: PlanningDraft, statementAssignments: StatementAssignmentDirectory) {
    if (undefined !== planningDraft.id) {
        throw new Error("An id is already assigned to the planning in creation: " + planningDraft.id);
    }
    const planning = yield call(createOrUpdatePlanning, planningDraft);
    notification.success({message: i18n.t("Planung gespeichert"), duration: 5})
    yield put({type: actionTypes.PLANNING_CREATED, payload: { planning, statementAssignments } });
    return planning;
}

function* updatePlanning(planningDraft: PlanningDraft, statementAssignments: StatementAssignmentDirectory) {
    if (undefined === planningDraft.id) {
        throw new Error("Id not found for the planning.");
    }
    const planning = yield call(createOrUpdatePlanning, planningDraft);
    notification.success({message: i18n.t("Planung gespeichert"), duration: 5})
    yield put({type: actionTypes.PLANNING_UPDATED, payload: { planning, statementAssignments } });
    return planning;
}

export default function* savePlanning(action: SavePlanningAction) {
    yield call(featureWall, FlaggedFeature.planning);
    const isDev = yield select(isDevSelector);
    const {planningDraft, statementAssignments} = action.payload;
    try {
        const { onSuccess }  = action.meta;
        if (undefined === planningDraft.id) {
            const planning: Planning | undefined = yield call(createPlanning, planningDraft, statementAssignments);
            if (onSuccess) {
                yield spawn(onSuccess)
                if(isDev) {
                    yield delay(5000)
                    yield call(reloadForecast)
                }
            }
            return planning;
        } else {
            const planning: Planning | undefined = yield call(updatePlanning, planningDraft, statementAssignments);
            if (onSuccess) {
                yield spawn(onSuccess)
                if(isDev) {
                    yield delay(5000)
                    yield call(reloadForecast)
                }
            }
            return planning;
        }
    } catch (error) {
        if (action.meta.onError) {
            yield spawn(action.meta.onError, error)
        }
        if (error.isAxiosError || error.isPlanningError) {
            legacyAlert(error, "save planning", planningDraft);
        } else {
            throw error;
        }
    }
}
