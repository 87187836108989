import { isCashFlowCash } from "../../../model/Accounting/AccountQualification";

/**
 * @deprecated for slim client
 */
export default function getOpeningCashBalanceOfDate(accounts: AccountRegistry, postings: Posting[], date: Date): number {
    return postings.reduce((result, posting) => {
        if (posting.receiptDate < date) {
            const account = accounts[posting.accountId];
            const contraAccount = accounts[posting.contraAccountId];
            if (!account.isOpeningBalance && !contraAccount.isOpeningBalance) {
                if (isCashFlowCash(account)) {
                    const flowFactor = "Cr" === posting.type ? -1 : 1;
                    const amount = flowFactor * posting.amount;
                    result += amount;
                }
                if (isCashFlowCash(contraAccount)) {
                    const flowFactor = "Cr" === posting.type ? 1 : -1;
                    const amount = flowFactor * posting.amount;
                    result += amount;
                }
            }
        }
        return result;
    }, 0)
}
