import React, {useCallback, useEffect, useRef, useState} from "react";
import {useTranslation} from "react-i18next";
import debug from "../../services/Debug/functions/debug";

import {Button, Collapse, Form, Space} from "antd";
import {FormItemProps, FormProps} from "antd/lib/form";

import BaseSubForm, {BaseFormValues} from "./FormItem/BaseSubForm";
import Conditional from "../Conditional/Conditional";
import ExpertSubForm, {ExpertFormValues, filterExpertSubFormValue} from "./FormItem/ExpertSubForm";
import DeleteButton from "../Button/DeleteButton";

import "./style.css";
import getInitialFormState from "./functions/getInitialFormState";
import formReduce from "./functions/formReduce";
import VisibilityRules from "./VisibilityRules";
import {PlanningMode} from "./enums";
import reduceToPaymentTypes from "./functions/reduceToPaymentTypes";

const formLayout: FormProps = {
    labelCol: { span: 10 },
    wrapperCol: { span: 14 },
    size: "small",
};
const formTailLayout: FormItemProps = {
    wrapperCol: { offset: 10, span: 14 },
};

export type PlanningFormValues = BaseFormValues & ExpertFormValues;

type FormControlValues = {
    _next?: any,
    _submit?: any,
    _expertForm?: any,
};

export type PlanningFormInternalValues = PlanningFormValues & FormControlValues;

type PlanningFormControlState = {
    next: ControlState
    submit: ControlState
};

export type PlanningFormOptions = {
    groups?: FormGroup[],
    paymentTypes?: PaymentType[]
};

export type PlanningMetaAccounts = {
    depositAccount?: AccountingAccountDraft,
    disbursementAccount?: AccountingAccountDraft,
    payableAccount?: AccountingAccountDraft,
    receivableAccount?: AccountingAccountDraft,
    creditorAccount?: AccountingAccountDraft,
    debtorAccount?: AccountingAccountDraft,
};

export type PlanningFormMeta = {
    planningMode?: PlanningMode,
    planningAccounts: PlanningMetaAccounts,
};

export type PlanningFormWarnings = Partial<Record<keyof PlanningFormValues, string>>;

export type PlanningFormState = {
    values: PlanningFormInternalValues,
    controls: PlanningFormControlState,
    options: PlanningFormOptions,
    meta: PlanningFormMeta,
    warnings: PlanningFormWarnings,
};

export type PlanningFormProps = {
    initialValues: PlanningFormValues,
    onSave: (values: PlanningFormValues) => void,
    onDelete: () => void,
    deletable: boolean,
    groups?: FormGroup[],
    onSelectGroup: (group?: GroupDraft) => void,
    accounts: AccountRegistry,
    paymentCashFlowRole: PaymentCashFlowRole,
};

const PlanningForm: React.VFC<PlanningFormProps> = (
    props
) => {
    const initialState = () => getInitialFormState(props);
    const {onSelectGroup} = props;

    const {t} = useTranslation();
    const [form] = Form.useForm<PlanningFormInternalValues>();
    const [formState, setFormState] = useState<PlanningFormState>(initialState);
    const [paymentCashFlowRole, setPaymentCashFlowRole] = useState<PaymentCashFlowRole>(props.paymentCashFlowRole);
    const isExpertSubFormExpanded = useRef<boolean>(false);

    debug("PlanningForm::render()", props, formState, form.getFieldsValue());

    const onFinish = useCallback(
        (values: PlanningFormValues) => {
            debug("PlanningForm::onFinish()", values, formState);
            setFormState({
                ...formState,
                controls: {
                    ...formState.controls,
                    submit: {
                        disabled: true,
                        loading: true,
                    }
                }
            });
            if (isExpertSubFormExpanded.current) {
                props.onSave(values);
            } else {
                const expertSubFormValues = filterExpertSubFormValue(formState.values);
                props.onSave({...values, ...expertSubFormValues});
            }
        },
        [formState, props]
    );

    const onValuesChange = useCallback(
        (changedValues: Partial<PlanningFormValues>, allValues: PlanningFormValues) => {
            debug("PlanningForm::onValuesChange()", changedValues, allValues, formState);
            if (changedValues.hasOwnProperty('group')) {
                onSelectGroup(changedValues.group);
            }
            setFormState(formReduce(form, formState, changedValues, allValues));
        },
        [form, setFormState, formState, onSelectGroup]
    );

    useEffect(() => {
        if (props.paymentCashFlowRole !== paymentCashFlowRole) {
            const newPaymentTypes = reduceToPaymentTypes(
                props.paymentCashFlowRole,
                form.getFieldValue("isReversal")
            );
            const newState = {
                ...formState,
                options: {
                    ...formState.options,
                    paymentTypes: newPaymentTypes,
                }
            };
            if (1 === newPaymentTypes.length) {
                const newPaymentType = newPaymentTypes[0];
                if (formState.values.paymentType !== newPaymentType) {
                    newState.values = {
                        ...newState.values,
                        paymentType: newPaymentType,
                    };
                    form.setFieldsValue({
                        paymentType: newPaymentType,
                    });
                }
            }
            debug("PlanningForm::useEffect()", newState);
            setFormState(newState);
            setPaymentCashFlowRole(props.paymentCashFlowRole);
        }
    }, [props.paymentCashFlowRole, paymentCashFlowRole, formState, form]);

    const onDelete = useCallback(
        (e) => {
            if (e) {
                e.preventDefault();
            }
            if (props.deletable) {
                setFormState({
                    ...formState,
                    controls: {
                        ...formState.controls,
                        submit: {
                            disabled: true,
                        }
                    }
                });
                props.onDelete();
            }
        },
        [formState, props]
    );

    const decorateFormItem: FormFieldDecorator<PlanningFormInternalValues> = useCallback(
        (
            name,
            children,
            formItemProps
        ) => {
            debug("PlanningForm::decorateFormItem()", name, formState.values, formState.warnings, formItemProps);
            return <Conditional
                key={name}
                context={formState.values}
                visible={VisibilityRules[name]}
            ><Form.Item
                name={name}
                validateStatus={formState.warnings[name] ? "warning" : undefined}
                help={formState.warnings[name]}
                {...formItemProps}
            >{children}</Form.Item></Conditional>
        },
        [formState.values, formState.warnings]
    );

    const fields: FormFieldSet<PlanningFormControlState> = {
        next: decorateFormItem(
            "_next",
            <Space><Button type="primary" htmlType="submit">{t("continue")}</Button></Space>,
            {...formTailLayout}
        ),
        // https://blog.seibert-media.net/blog/2007/09/14/usability-richtlinien-fuer-submit-buttons-in-formularen
        // https://uxplanet.org/designing-more-efficient-forms-structure-inputs-labels-and-actions-e3a47007114f
        submit: decorateFormItem(
            "_submit",
            <Space>
                <Button
                    type="primary"
                    htmlType="submit"
                    disabled={formState.controls.submit.disabled}
                    loading={formState.controls.submit.loading}
                >{t("save planning")}</Button>
                <DeleteButton
                    hidden={!props.deletable}
                    onClick={onDelete}
                    disabled={formState.controls.submit.disabled}
                >{t("delete planning")}</DeleteButton>
            </Space>,
            {...formTailLayout}
        ),
    };

    return <div style={{width:780}}><Form
        {...formLayout}
        form={form}
        onFinish={onFinish}
        onValuesChange={onValuesChange}
        initialValues={formState.values}
    >
        <BaseSubForm
            formValues={formState.values}
            decorateFormItem={decorateFormItem}
            groupOptions={formState.options.groups}
            paymentTypeOptions={formState.options.paymentTypes}
            accountOptions={props.accounts}
            planningMode={formState.meta.planningMode}
            planningAccounts={formState.meta.planningAccounts}
            planningComplexity={formState.values.planningComplexity}
            paymentType={formState.values.paymentType}
        />
        <Conditional
            key="expertForm"
            context={formState.values}
            visible={VisibilityRules["_expertForm"]}
        ><Collapse ghost onChange={v => isExpertSubFormExpanded.current = '1' === v[0]}>
            <Collapse.Panel header="Erweitert" key="1">
                <ExpertSubForm decorateFormItem={decorateFormItem}/>
            </Collapse.Panel>
        </Collapse></Conditional>
        {fields["next"]}
        {fields["submit"]}
    </Form></div>;
}

export default PlanningForm;
