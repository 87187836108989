import PropTypes from 'prop-types';

export const TimeFramePropType = PropTypes.shape({
    year: PropTypes.number.isRequired,
    month: PropTypes.number,
    day: PropTypes.number,
});

export const TimeRangePropType = PropTypes.shape({
    start: TimeFramePropType.isRequired,
    count: PropTypes.number.isRequired,
});
