import {call, spawn, StrictEffect, take} from "redux-saga/effects"
import * as actionTypes from "../actionTypes";
import loadLogins from "./loadLogins";
import renewLoginLoop from "./renewLoginLoop";

export default function* initAll(): Generator<StrictEffect, void, any> {
    yield take(actionTypes.INITIALIZE_AUTH);
    yield call(loadLogins);
    yield spawn(renewLoginLoop);
}
