import {notification} from "antd";
import track from "../../UserAnalytics/track";

export default function infoAlert(
    featureName: string,
    userMessage: string,
    actionName: string,
    actionContext: object = {},
    componentName?: string,
) {
    track(
        featureName,
        "Info Alert",
        {message: userMessage, triggerAction: actionName},
        componentName
    );
    notification.info({message: userMessage, duration: 5});
}
