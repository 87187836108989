import { List } from "immutable";

export default function insertRow(
    rows: List<StatementRow>,
    row: StatementRow,
    destinationParentKeyPath: KeyPath|null,
    destinationIndex: number,
): List<StatementRow> {
    if (destinationParentKeyPath && destinationParentKeyPath.length) {
        const destinationRowListKeyPath = [...destinationParentKeyPath, "rows"];
        const destinationLevel = (destinationRowListKeyPath.length)/2;
        if (row.level !== destinationLevel) {
            row = {...row, level: destinationLevel};
        }
        let destinationRowList = rows.getIn(destinationRowListKeyPath) as List<StatementRow>|null;
        if (destinationRowList) {
            destinationRowList = destinationRowList.insert(destinationIndex, row);
        } else {
            destinationRowList = List<StatementRow>([row]);
        }
        return rows.setIn(destinationRowListKeyPath, destinationRowList);
    } else {
        if (row.level !== 0) {
            row = {...row, level: 0};
        }
        return rows.insert(destinationIndex, row);
    }
}
