import {List} from "immutable";
import {statementRowIsCategory} from "../../../model/Statement/statementRowIsGroup";
import calculateTotalDataPoints from "./calculateTotalDataPoints";
import debug from "../../../services/Debug/functions";

let assignedAccountIds: string[] = [];

/**
 * @deprecated for slim client
 */
export default function buildRows(
    statement: Statement,
    statementRows: List<StatementRow>,
    totalsByAccountId: TimeSeriesValueNestedMap,
    accounts: AccountRegistry,
    mergeValuesByRowId: TimeSeriesValueNestedMap = {}
): StatementDeepTimeSeriesRow[] {
    assignedAccountIds = [];
    const result = deepBuildRows(
        statement,
        statementRows,
        totalsByAccountId,
        accounts,
        mergeValuesByRowId
    );
    debug(
        "model/Statement/buildRows() - assignedAccountIds",
        assignedAccountIds,
        Object.keys(accounts).filter(accountId => -1 === assignedAccountIds.indexOf(accountId)),
    );
    return result;
}

function deepBuildRows(
    statement: Statement,
    statementRows: List<StatementRow>,
    totalsByAccountId: TimeSeriesValueNestedMap,
    accounts: AccountRegistry,
    mergeValuesByRowId: TimeSeriesValueNestedMap
): StatementDeepTimeSeriesRow[] {
    const statementId = statement.id;
    const draggingItemId: string|null|undefined = statement.draggingRowId;
    debug("model/Statement/deepBuildRows()", statementRows, totalsByAccountId, draggingItemId);
    let result: StatementDeepTimeSeriesRow[] = [];
    for (let [, statementRow] of statementRows.entries()) {
        const { id, label, level, isDraggable, accountIds, constraintType, isCollapsed, rows: subRows } = statementRow;
        let row: StatementDeepTimeSeriesRow|null = null;

        if (statementRowIsCategory(statementRow)) {
            if (subRows) {
                throw Error(`No sub rows expected for category row "${statementRow.id}"`);
            }
            if (undefined !== accountIds && accountIds.length > 0) {
                assignedAccountIds = [...assignedAccountIds, ...accountIds];
                const accountNumberRows: StatementDeepTimeSeriesRow[] = [];
                for (let accountId of accountIds) {
                    if (totalsByAccountId[accountId]) {
                        const dataPoints: TimeSeriesDataPoint[] = Object.values(totalsByAccountId[accountId]);

                        accountNumberRows.push({
                            statementId,
                            id,
                            label,
                            level,
                            isDraggable,
                            accountIds: [accountId],
                            dataPoints,
                            constraintType,
                        });
                    }
                }
                if (accountNumberRows.length > 1) {
                    row = {
                        statementId,
                        id,
                        label,
                        level,
                        isDraggable: true,
                        accountIds,
                        dataPoints: calculateTotalDataPoints(accountNumberRows),
                        constraintType: constraintType,
                    };
                } else if (undefined !== accountNumberRows[0]) {
                    row = accountNumberRows[0];
                }
            }
            if(undefined !== mergeValuesByRowId[id]){
                if(Object.keys(mergeValuesByRowId[id]).length > 0) { //to avoid creation for empty rows
                    row = {
                        statementId,
                        id,
                        label,
                        level,
                        isDraggable,
                        accountIds,
                        dataPoints: calculateTotalDataPoints([], mergeValuesByRowId[id]),
                        constraintType: constraintType,
                    }
                }
            }
        } else {
            row = {
                statementId,
                id,
                label,
                level,
                isGroup: true,
                isDraggable: true,
                constraintType: constraintType,
            };

            if (subRows) {
                row.rows = deepBuildRows(
                    statement,
                    subRows,
                    totalsByAccountId,
                    accounts,
                    mergeValuesByRowId
                );
                row.dataPoints = calculateTotalDataPoints(row.rows);
            }

        }
        if (null !== row) {
            if (undefined !== isCollapsed) {
                row.isCollapsed = isCollapsed;
            }
            if (draggingItemId === row.id) {
                row.isCollapsed = true;
                row.isDragging = true;
            }
            result.push(row);
        }
    }
    return result;
}
