import { List } from "immutable";
import getStatementRowById from "../../model/Statement/getStatementRowById";
import {StatementProfileType} from "../../model/Statement";

export function statementSelector(state: RootState, statementId: string): Statement {
    const statement = state.statement.statements[statementId];
    if (undefined === statement) {
        throw Error(`Statement not found: ${statementId}`);
    }
    return statement;
}

export function standardStatementSelector(state: RootState, profileType: StatementProfileType): Statement|undefined {
    for (const statementId of Object.keys(state.statement.statements)) {
        if (state.statement.statements[statementId].profileType === profileType) {
            return state.statement.statements[statementId];
        }
    }
    return undefined;
}

export function hasStatement(state: StatementState, statementId: string): boolean {
    return undefined !== state.statements[statementId];
}

export function getStatement(state: StatementState, statementId: string): Statement {
    if (undefined === state.statements[statementId]) {
        throw new Error('Statement not found: ' + statementId);
    }
    return state.statements[statementId];
}

export function getStatements(state: StatementState): Statement[] {
    return Object.values(state.statements);
}
export function statementsLoadingSelector(state: RootState): boolean {
    return state.statement.loading;
}

/**
 * @deprecated in favor of getStatementRowById()
 */
export function getStatementRow(state: StatementState, statementId: string, rowId: string): StatementRow {
    const statement: Statement = getStatement(state, statementId);
    return getStatementRowById(statement, rowId);
}

function* deepYieldStatementRows(
    rows: List<StatementRow>,
    level: number,
    filter?: (row: FlatStatementRow) => boolean
): Iterable<StatementRow> {
    for (let [, row] of rows.entries()) {
        const leveledRow = { ...row, level };
        if (undefined === filter || filter(leveledRow)) {
            yield row;
        }
        if (row.rows && row.rows.count()) {
            yield* deepYieldStatementRows(row.rows, level + 1, filter);
        }
    }
}

export function* yieldStatementRows(
    state: StatementState,
    statementId: string,
    filter?: (row: FlatStatementRow) => boolean
): Iterable<StatementRow> {
    const statement: Statement = getStatement(state, statementId);
    yield* deepYieldStatementRows(statement.rows, 0, filter);
}

