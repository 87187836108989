import { RemoteCashFlowRole, RemoteCashFlowType } from "../../services/Accounting/saga/RestV1Types";

export const CashFlowType = {
    ...RemoteCashFlowType
}

export const CashFlowRole = {
    ...RemoteCashFlowRole
}

export const ResourceOrigin = {
    QuickPlanning: 'feature:quickPlanning'
}
