import * as PropTypes from 'prop-types';

export const PostingPlanPropType = PropTypes.shape({
    id: PropTypes.string.isRequired,
    type: PropTypes.oneOf(['planning', 'budget']).isRequired,
});

// -------------

export const AccountSystemPropType = PropTypes.oneOf([
    'skr03', 'skr04'
]);

export const CashFlowRolePropType = PropTypes.oneOf([
    'payment', 'disbursement', 'deposit', 'payable', 'creditor', 'receivable', 'debtor', 'cash', 'transition'
]);

export const AccountingNumberPropType = PropTypes.shape({
    system: AccountSystemPropType.isRequired,
    digits: PropTypes.number.isRequired,
    code: PropTypes.number.isRequired,
});

export const AccountingAccountPropType = PropTypes.shape({
    id: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    accountNumber: AccountingNumberPropType,
    cashFlowRole: CashFlowRolePropType,
    types: PropTypes.arrayOf(PropTypes.string),
    clientName: PropTypes.string
});

export const AccountRegistryPropType = PropTypes.objectOf(AccountingAccountPropType);

export const AccountAliasesRegistryPropType = PropTypes.objectOf(PropTypes.string);
