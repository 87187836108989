export const ACCOUNTING_INIT = 'ACCOUNTING_INIT';
export const ACCOUNTING_ACCOUNT_CREATE = 'ACCOUNTING_ACCOUNT_CREATE';
export const REDUCE_ACCOUNTING_STATS_INIT = 'REDUCE_ACCOUNTING_STATS_INIT';
export const REDUCE_ACCOUNTING_ACCOUNTS_INIT = 'REDUCE_ACCOUNTING_ACCOUNTS_INIT';
export const REDUCE_ACCOUNTING_OPENING_BALANCES_INIT = 'REDUCE_ACCOUNTING_OPENING_BALANCES_INIT';
export const REDUCE_ACCOUNTING_ACCOUNT_LOADED = 'REDUCE_ACCOUNTING_ACCOUNT_LOADED';

export interface ReduceAccountingStatsInit {
    type: typeof REDUCE_ACCOUNTING_STATS_INIT
    payload: AccountingStats
}
export interface ReduceAccountingAccountsInit {
    type: typeof REDUCE_ACCOUNTING_ACCOUNTS_INIT
    payload: AccountRegistry
}
export interface ReduceAccountingOpeningBalancesInit {
    type: typeof REDUCE_ACCOUNTING_OPENING_BALANCES_INIT
    payload: OpeningBalance[]
}
export interface ReduceAccountingAccountLoaded {
    type: typeof REDUCE_ACCOUNTING_ACCOUNT_LOADED
    payload: AccountingAccount
}

export type AccountingReducerAction =
    ReduceAccountingStatsInit |
    ReduceAccountingAccountsInit |
    ReduceAccountingOpeningBalancesInit |
    ReduceAccountingAccountLoaded;
