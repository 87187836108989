export const PLANNING_LOAD = 'PLANNING_LOAD';
export const PLANNING_SAVE = 'PLANNING_SAVE';
export const PLANNING_DELETE = 'PLANNING_DELETE';
export const PLANNING_CREATE = 'PLANNING_CREATE';
export const PLANNING_EDIT = 'PLANNING_EDIT';
export const PLANNING_CREATED = 'PLANNING_CREATED';
export const PLANNING_UPDATED = 'PLANNING_UPDATED';
export const PLANNING_VISIBILITY_TOGGLED = 'PLANNING_VISIBILITY_TOGGLED';
export const REDUCE_PLANNINGS_LOADED = 'REDUCE_PLANNINGS_LOADED';
export const REDUCE_PLANNING_LOADED = 'REDUCE_PLANNING_LOADED';
export const REDUCE_PLANNING_DELETED = 'REDUCE_PLANNING_DELETED';
export const REDUCE_PLANNING_VISIBILITY_TOGGLED = 'REDUCE_PLANNING_VISIBILITY_TOGGLED';

export interface CreatePlanningAction {
    type: typeof PLANNING_CREATE
    payload: {scope?: PlanningScope}
}

export interface EditPlanningAction {
    type: typeof PLANNING_EDIT
    payload: {planningId: string}
}

export interface PlanningCreatedAction {
    type: typeof PLANNING_CREATED
    payload: {
        planning: Planning,
        statementAssignments: StatementAssignmentDirectory
    }
}

export interface PlanningUpdatedAction {
    type: typeof PLANNING_UPDATED
    payload: {
        planning: Planning,
        statementAssignments: StatementAssignmentDirectory
    }
}

export interface TogglePlanningVisibilityAction {
    type: typeof PLANNING_VISIBILITY_TOGGLED,
    payload: boolean
}
