import React from "react";
import {useTranslation} from "react-i18next";
import debug from "../../../services/Debug/functions/debug";

import {PlanningComplexity, PlanningMode} from "../enums";
import SelectGroup from "../../FormItem/GroupFormItem/SelectGroup/SelectGroup";
import PaymentTypeSwitch from "../Input/PaymentTypeSwitch";
import Radio from "antd/lib/radio";
import CurrencyFormulaInput from "../../FormInput/CurrencyFormulaInput";
import {PaymentTypeEnum} from "../../../model/Planning/Enums";
import SelectRecurrence from "../Input/SelectRecurrence";
import SelectRecurrenceEnd from "../Input/SelectRecurrenceEnd";
import SelectRecurrenceIncrease, {SelectRecurrenceIncreaseValue} from "../Input/SelectRecurrenceIncrease";
import DirectPlanningSubForm, {DirectPlanningFormValues} from "./DirectPlanningSubForm";
import TransferPlanningSubForm, {TransferPlanningFormValues} from "./TransferPlanningSubForm";
import {PlanningFormInternalValues, PlanningMetaAccounts} from "../PlanningForm";
import {FormInstance} from "antd/lib/form";
import moment from "moment";

export type BaseFormInternalValues = {
    group?: GroupDraft,
    paymentType?: PaymentType,
    planningComplexity: PlanningComplexity,
    // aggregated values
    amountTotal: ExpressionAmount,
    recurrence: RecurrenceValue,
    recurrenceEnd: RecurrenceEndValue,
    recurrenceIncrease: RecurrenceIncreaseValue;
}

export type BaseFormValues = BaseFormInternalValues & DirectPlanningFormValues & TransferPlanningFormValues;

type BaseFormProps = SubFormProps<BaseFormValues> & {
    groupOptions?: FormGroup[],
    paymentTypeOptions?: PaymentType[],
    accountOptions?: AccountRegistry,
    paymentType?: PaymentType,
    planningMode?: PlanningMode,
    planningAccounts?: PlanningMetaAccounts,
    planningComplexity: PlanningComplexity,
    formValues?: PlanningFormInternalValues
}

const BaseSubForm: React.VFC<BaseFormProps> = ({
    decorateFormItem,
    groupOptions,
    paymentTypeOptions,
    accountOptions,
    paymentType,
    planningMode = PlanningMode.RECEIVABLE,
    planningAccounts,
    planningComplexity,
    formValues
}) => {
    debug(
        "BaseSubForm::render()",
        decorateFormItem,
        groupOptions,
        paymentTypeOptions,
        accountOptions,
        paymentType,
        planningMode,
        planningAccounts,
        planningComplexity
    );

    const {t} = useTranslation();

    const field: FormFieldSet<BaseFormInternalValues> = {
        group: decorateFormItem(
            "group",
            <SelectGroup groups={groupOptions} draftEnabled={true} />,
            {
                label: t("group"),
                rules: [{required: true, message: t("no group selected")}],
            }
        ),
        paymentType: decorateFormItem(
            "paymentType",
            <PaymentTypeSwitch options={paymentTypeOptions}/>,
            {
                label:t("payment method"),
                rules: [{required: true, message: t("no payment type selected")}],
            }
        ),
        planningComplexity: decorateFormItem(
            "planningComplexity",
            <Radio.Group buttonStyle="solid" size="small">
                <Radio.Button value={PlanningComplexity.DIRECT}>{t("direct")}</Radio.Button>
                <Radio.Button value={PlanningComplexity.TRANSFER}>{t("transfer")}</Radio.Button>
            </Radio.Group>,
            {
                label: t("payment"),
                rules: [{required: true, message: t("no payment selected")}]
            }
        ),
        amountTotal: decorateFormItem(
            "amountTotal",
            <CurrencyFormulaInput />,
            {
                label: t("total amount") + " (" + (
                    paymentType === PaymentTypeEnum.DISBURSEMENT
                    ? t("negativ")
                    : t("positiv")
                ) + ")",
                dependencies: ["paymentType"],
                rules: [(form: FormInstance) => ({
                    validator(rule, value: ExpressionAmount) {
                        const paymentType = form.getFieldValue("paymentType");
                        debug("BaseSubForm amountTotal validator", rule, value, form, paymentType);
                        return new Promise((resolve, reject) => {
                            const errors: string[] = [];
                            if (undefined === value.amount && undefined !== value.expression) {
                                errors.push(t("expression not valid"));
                            } else if (!value.amount) {
                                errors.push(t("no amount is specified"));
                            } else {
                                switch (paymentType) {
                                    case PaymentTypeEnum.DISBURSEMENT:
                                        if (value.amount > 0) {
                                            errors.push(t("payout amount is not negative"));
                                        }
                                        break;
                                    case PaymentTypeEnum.DEPOSIT:
                                        if (value.amount < 0) {
                                            errors.push(t("payout amount is not positive"));
                                        }
                                        break;
                                    default:
                                }
                            }
                            errors.length > 0
                                ? reject(errors.join(" "))
                                : resolve(value);
                        });
                    },
                })],
            }
        ),
        recurrence: decorateFormItem(
            "recurrence",
            <SelectRecurrence />,
            {
                label:t("recurrence"),
                className:"input-toggle",
                rules: [{
                    validator: (rule, value: RecurrenceValue) => {
                        return new Promise((resolve, reject) => {
                            if (!value.active || value.interval) {
                                resolve(value);
                            } else {
                                reject(t("Es ist kein Interval angegeben") + ".");
                            }
                        });
                    }
                }]
            }
        ),
        recurrenceEnd: decorateFormItem(
            "recurrenceEnd",
            <SelectRecurrenceEnd />,
            {
                label: t("ends"),
                className: "input-toggle",
                rules: [{
                    validator: (rule, value) => {
                        return new Promise((resolve, reject) => {
                            if (!value.active || value.date) {
                                resolve(value);
                            } else {
                                reject(t("Es ist kein Datum angegeben") + ".");
                            }
                        });
                    }
                },
                    {
                        validator: (rule, value) => {
                            return new Promise((resolve, reject) => {
                                const paymentDate = formValues?.planningComplexity === PlanningComplexity.TRANSFER? formValues?.paymentDate : formValues?.invoiceDate;
                                if(!value.date) return resolve(value)
                                if (moment(value.date).isSameOrAfter(paymentDate)){
                                    resolve(value);
                                } else {
                                    reject(t("Das Enddatum kann nicht vor dem ersten Zahlungsdatum liegen. Bitte korrigieren") + ".");
                                }
                            });
                        }
                    },]
            }
        ),
        recurrenceIncrease: decorateFormItem(
            "recurrenceIncrease",
            <SelectRecurrenceIncrease />,
            {
                label:t("increase"),
                className:"input-toggle",
                rules: [{
                    validator: (rule, value: SelectRecurrenceIncreaseValue) => {
                        return new Promise((resolve, reject) => {
                            if (!value.active || undefined !== value.growthRate || undefined !== value.gradientAmount) {
                                resolve(value);
                            } else {
                                reject(t("Es ist keine Steigerung angegeben") + ".");
                            }
                        });
                    }
                }]
            }
        ),
    };

    const renderPlanningSubform = () => {
        switch (planningComplexity) {
            case PlanningComplexity.TRANSFER:
                return <TransferPlanningSubForm
                    key="planningSubForm"
                    decorateFormItem={decorateFormItem}
                    accountOptions={accountOptions}
                    planningMode={planningMode}
                    planningAccounts={planningAccounts}
                />;
            case PlanningComplexity.DIRECT:
            default:
                return <DirectPlanningSubForm
                    key="planningSubForm"
                    decorateFormItem={decorateFormItem}
                    accountOptions={accountOptions}
                    planningMode={planningMode}
                    planningAccounts={planningAccounts}
                />;
        }
    };

    return <>{[
        field["group"],
        field["paymentType"],
        field["planningComplexity"],
        renderPlanningSubform(),
        field["cashAccount"],
        field["amountTotal"],
        field["recurrence"],
        field["recurrenceEnd"],
        field["recurrenceIncrease"],
    ]}</>;
}

export default BaseSubForm;
