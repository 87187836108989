import {put} from "redux-saga/effects"
import {BankReducerAction} from "../reducer";
import {ToggleBankVisibilityAction} from "../actions";

export default function* toggleVisibility(action: ToggleBankVisibilityAction) {
    yield put({
        type: BankReducerAction.REDUCE_VISIBILITY,
        payload: action.payload,
    });
}
