import React, {CSSProperties, useCallback, useRef, useState} from "react";
import {useTranslation} from "react-i18next";

import {Button, notification, Tooltip} from "antd";
import {SyncOutlined} from "@ant-design/icons";
import {useDispatch, useSelector} from "react-redux";
import history from "../../history";
import {latestBankSyncSelector, getExpiredBankAccountsSelector, shouldSyncSelector} from "../../services/selectors";
import {synchronizeBankAccounts} from "../../services/Bank/actions";

import styles from "./BankSyncButton.module.css";

export type BankSyncButtonProps = {
    style?: CSSProperties,
    className?: string,
    type?: "secondary"|"inline"
};

export const NOTIFICATION_ID_BANK_SYNC = "BankSyncButton";

const BankSyncButton: React.VFC<BankSyncButtonProps> = ({
    style,
    className = "",
    type= "secondary"
}) => {
    const {t} = useTranslation();
    const dispatch = useDispatch();
    const latestBankSync = useSelector(latestBankSyncSelector);
    const expiredBankAccounts = useSelector(getExpiredBankAccountsSelector)
    const shouldSync = useSelector(shouldSyncSelector);

    const loadingBankSync = useRef<Date|undefined>(undefined);
    const [loading, setLoading] = useState<boolean>(false);

    if (loading && loadingBankSync.current?.getTime() !== latestBankSync?.getTime()) {
        loadingBankSync.current = latestBankSync;
        setLoading(false);
        notification.info({
            key: NOTIFICATION_ID_BANK_SYNC,
            message: t("Synchronisierung läuft") + "…",
            description: t("Wir benachrichtigen, sobald neue Bankdaten geladen wurden."),
            duration: 5
        });
    }

    const onClick = useCallback(
         () => {
             if (shouldSync) {
                 setLoading(true)
                 dispatch(synchronizeBankAccounts());
             }
             const someExpiredAccountsExist = (expiredBankAccounts?.length || 0) > 0;
             if (someExpiredAccountsExist) {
                 history.push('/bank/bank');
                 notification.info({
                     key: NOTIFICATION_ID_BANK_SYNC,
                     message: t("Verbinden Sie die getrennten Bankkonten."),
                     duration: 5
                 });
             }
        },
        [dispatch, expiredBankAccounts, shouldSync, t]
    );

    switch (type) {
        case "inline":
            return <Tooltip
                trigger={["hover", "click"]}
                placement="bottom"
                title={t("Synchronize now")}
            ><Button
                type="link"
                icon={<SyncOutlined/>}
                loading={loading}
                onClick={onClick}
                className={(styles.inline + " " + className).trim()}
                style={style}
            /></Tooltip>;
        default:
            return <Button
                icon={<SyncOutlined/>}
                loading={loading}
                onClick={onClick}
                className={className}
                style={style}
            >{t("Synchronize now")}</Button>;
    }
}

export default BankSyncButton;
