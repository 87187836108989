import { RowLinkRelation } from "./enums";
import { parseStatementUri } from "./parseStatementUri";
import { rebuildStatementRowUri } from "./rebuildStatementRowUri";
import { StatementRowType } from "../Statement";

function getTargetLevel(
    destinationLocation: StatementUriLocationReference,
    destinationRole: RowLinkRelation
): number {
    if (destinationLocation.row) {
        switch (destinationRole) {
            case RowLinkRelation.PARENT:
                return destinationLocation.row.level + 1;
            case RowLinkRelation.PREDECESSOR:
                return destinationLocation.row.level;
            default:
                throw Error("Destination role not known: " + destinationRole);
        }
    } else {
        return 0;
    }
}

function getBoundRootUri(sourceRowLocation: StatementUriRowLocation): string
{
    if (!sourceRowLocation.rootBound) {
        throw new Error("Logic error. Not bound to root.");
    }
    const depth = Math.min(sourceRowLocation.minLevel, sourceRowLocation.level);
    const regEx = new RegExp("^(?<rootUrl>statement:\\/\\/[^/]+((\\/)[^/]+){" + depth + "})");
    const uriWithAbsoluteConstraints = rebuildStatementRowUri(sourceRowLocation);
    const matches = uriWithAbsoluteConstraints.match(regEx);
    if (null === matches || undefined === matches.groups) {
        throw new Error("Logic error. No root url found.");
    }
    return matches.groups["rootUrl"];
}

function findConstraint(
    sourceRowLocation: StatementUriRowLocation,
    targetRowLocation: StatementUriRowLocation,
    destinationRowLocation: StatementUriRowLocation|undefined,
    destinationRole: RowLinkRelation
): string|null {
    if (undefined !== destinationRowLocation &&
        StatementRowType.CATEGORY === destinationRowLocation.type &&
        RowLinkRelation.PARENT === destinationRole
    ) {
        return "Destination is a category."
    }
    if (sourceRowLocation.minLevel > targetRowLocation.level) {
        return "Target level is less than minimum level constraint."
    }
    if (undefined !== sourceRowLocation.maxLevel && sourceRowLocation.maxLevel < targetRowLocation.level) {
        return "Target level is greater than maximum level constraint."
    }
    if (sourceRowLocation.rootBound) {
        const boundRootUri = getBoundRootUri(sourceRowLocation);
        if (!targetRowLocation.uri.startsWith(boundRootUri)) {
            return "Target root is different to source root."
        }
    }
    return null;
}

export function verifyTransition(
    sourceUri: string,
    destinationUri: string,
    destinationRole: RowLinkRelation,
    onConstraint?: (message: string) =>  void
): boolean {
    const sourceLocation = parseStatementUri(sourceUri);
    const destinationLocation = parseStatementUri(destinationUri);
    if (!sourceLocation.row) {
        throw Error("Source row not found: " + sourceUri);
    }
    const targetRowLocation: StatementUriRowLocation = {
        ...sourceLocation.row,
        statementId: destinationLocation.statement.id,
        path: destinationLocation.row ? destinationLocation.row.path : "/",
        uri: "",
        level: getTargetLevel(destinationLocation, destinationRole),
    };
    targetRowLocation.uri = rebuildStatementRowUri(targetRowLocation);
    const targetLocation: StatementUriLocationReference = {
        statement: {
            ...destinationLocation.statement,
        },
        row: targetRowLocation
    };
    if (sourceLocation.row && targetLocation.row) {
        const constraintMessage = findConstraint(sourceLocation.row, targetLocation.row, destinationLocation.row, destinationRole);
        if (null !== constraintMessage) {
            if (onConstraint) onConstraint(constraintMessage);
        }
        return null === constraintMessage;
    } else {
        throw Error("Logic error");
    }
}
