import axios from '../../../axios';
import {put} from "redux-saga/effects";

import * as actionTypes from "../actionTypes";
import tld from "../../../tld";


function* axiosDeleteRemoteRestV1Integration(id) {
    return yield axios.delete(tld(process.env.REACT_APP_URL_API_INTEGRATION) + '/integrations/' + id);
}

export default function* deleteIntegration(integrationId) {
    yield axiosDeleteRemoteRestV1Integration(integrationId.payload);

    yield put({
        type: actionTypes.REDUCE_INTEGRATION_DELETED,
        payload: integrationId.payload
    });
}
