export default class DateRange {
    constructor(fromYear, toYear,
                fromMonth, toMonth,
                fromDay, toDay) {
        if (toYear < fromYear) {
            throw new Error('toYear is smaller than fromYear');
        }
        if (toYear === fromYear && toMonth < fromMonth) {
            throw new Error('toMonth is smaller than fromMonth for the same year');
        }
        if (toYear === fromYear && toMonth === fromMonth && toDay < fromDay) {
            throw new Error('toDay is smaller than fromDay for the same month');
        }
        this._fromYear = fromYear;
        this._fromMonth = fromMonth;
        this._toYear = toYear;
        this._toMonth = toMonth;
        
        const fromDate = fromDay ? new Date(fromYear + '-' + fromMonth + '-' + fromDay) : undefined; 
        const toDate = toDay ? new Date(toYear + '-' + toMonth + '-' + toDay) : undefined;

        this._yearCount = toYear - fromYear + 1;
        this._monthCount = (toMonth - fromMonth + 1) + ((this._yearCount - 1) * 12);

        this._iso8601YearMonths = [];
        this._iso8601YearMonthTimeCodes = [];
        this._iso8601Dates = [];
        this._iso8601DateTimeCodes = [];
        for (let year = this._fromYear; year <= this._toYear; year++) {
            let firstMonth = (year === this._fromYear) ? this._fromMonth : 1;
            let lastMonth = (year === this._toYear) ? this._toMonth : 12;
            for (let month = firstMonth; month <= lastMonth; month++) {
                this._iso8601YearMonths.push(year + '-' + (month < 10 ? '0' : '') + month);
                this._iso8601YearMonthTimeCodes.push(Number(year + '' + (month < 10 ? '0' : '') + month));
                let lastDay = new Date(year, month, 0).getDate();
                for (let day = 1; day <= lastDay; day++) {
                    const isoDate = year + '-' + (month < 10 ? '0' : '') + month + '-' + (day < 10 ? '0' : '') + day;
                    const date = new Date(isoDate);
                    if (undefined !== fromDate && date < fromDate) continue;
                    if (undefined !== toDate && date > toDate) continue;
                    this._iso8601Dates.push(isoDate);
                    this._iso8601DateTimeCodes.push(Number(year + '' + (month < 10 ? '0' : '') + month + '' + (day < 10 ? '0' : '') + day));
                }
            }
        }
    }

    equals(dateRange) {
        return this.fromYear === dateRange.fromYear
            && this.fromMonth === dateRange.fromMonth
            && this.toYear === dateRange.toYear
            && this.toMonth === dateRange.toMonth;
    };

    get fromYear() {
        return this._fromYear;
    }

    get fromMonth() {
        return this._fromMonth;
    }

    get toYear() {
        return this._toYear;
    }

    get toMonth() {
        return this._toMonth;
    }

    get yearCount() {
        return this._yearCount;
    }

    get monthCount() {
        return this._monthCount;
    }

    get iso8601Dates() {
        return this._iso8601Dates;
    }

    get iso8601DateTimeCodes() {
        return this._iso8601DateTimeCodes;
    }

    get iso8601YearMonths() {
        return this._iso8601YearMonths;
    }

    get iso8601YearMonthTimeCodes() {
        return this._iso8601YearMonthTimeCodes;
    }

    get iso8601StartDate() {
        return this._iso8601Dates[0];
    }

    get iso8601EndDate() {
        return this._iso8601Dates[this._iso8601Dates.length - 1];
    }

    get iso8601DateRange() {
        return this._iso8601Dates[0] + '/' + this._iso8601Dates[this._iso8601Dates.length - 1];
    }

    get iso8601MonthRange() {
        return this._iso8601YearMonths[0] + '/' + this._iso8601YearMonths[this._iso8601YearMonths.length - 1];
    }
}
