import { List } from 'immutable';

function deepFindSubRowWithId(statementRow: StatementRow, rowId: string): StatementRow|undefined {
    if (undefined !== statementRow.rows) {
        for (let subRow of statementRow.rows) {
            if (rowId === subRow.id) {
                return statementRow;
            } else {
                const subResult = deepFindSubRowWithId(subRow, rowId);
                if (undefined !== subResult) {
                    return subResult;
                }
            }
        }
    }
    return undefined;
}

export default function findParentRowById(rows: List<StatementRow>, rowId: string): StatementRow|null|undefined {
    for (let row of rows) {
        if (rowId === row.id) {
            return null;
        }
        const subResult = deepFindSubRowWithId(row, rowId);
        if (undefined !== subResult) {
            return subResult;
        }
    }
    return undefined;
}
