/**
 * Fast and flexible string trimming.
 * @see https://stackoverflow.com/questions/26156292/trim-specific-character-from-a-string
 */

export enum Trim {
    ALL = "ALL",
    START = "START",
    END = "END",
}

function trimChars(value: string, chars: string|string[], mode: Trim) {
    let start = 0, end = value.length;

    if (Trim.END !== mode) {
        while (start < end && chars.indexOf(value[start]) >= 0) {
            ++start;
        }
    }

    if (Trim.START !== mode) {
        while(end > start && chars.indexOf(value[end - 1]) >= 0) {
            --end;
        }
    }

    return (start > 0 || end < value.length) ? value.substring(start, end) : value;
}

function trimChar(value: string, char: string, mode: Trim) {
    let start = 0, end = value.length;

    if (Trim.END !== mode) {
        while (start < end && value[start] === char) {
            ++start;
        }
    }

    if (Trim.START !== mode) {
        while (end > start && value[end - 1] === char) {
            --end;
        }
    }

    return (start > 0 || end < value.length) ? value.substring(start, end) : value;
}

export default function trim(value: string, chars: string|string[], mode: Trim = Trim.ALL) {
    return Array.isArray(chars) || chars.length > 1 ? trimChars(value, chars, mode) : trimChar(value, chars, mode);
}
