import {isCashFlowCash} from "../../../model/Accounting/AccountQualification";
import {getTimeRangeStartDate} from "../../../model/TimeRange";
import getOpeningCashBalanceOfDate from "./getOpeningCashBalanceOfDate";
import getCumulatedTimeSeries from "./getCumulatedTimeSeries";

/**
 * @deprecated for slim client
 */
const calculateCashInBankTimeSeries = (
    accounts: AccountRegistry,
    postings: Posting[],
    timeRange: TimeRange,
    totalOpeningBalance: number,
): TimeSeries => {
    let postingChartAccountIdMap: {} = {};
    postings.forEach(posting => {
        const account = accounts[posting.accountId];
        const contraAccount = accounts[posting.contraAccountId];
        if (isCashFlowCash(account)) {
            postingChartAccountIdMap[account.id] = true;
        }
        if (isCashFlowCash(contraAccount)) {
            postingChartAccountIdMap[contraAccount.id] = true;
        }
    });
    const timeRangeStartDate = getTimeRangeStartDate(timeRange);
    const openingCashBalance = getOpeningCashBalanceOfDate(
        accounts,
        postings,
        timeRangeStartDate,
    ) + totalOpeningBalance;
    return getCumulatedTimeSeries(
        timeRange,
        postings,
        Object.keys(postingChartAccountIdMap),
        openingCashBalance || 0
    );
};

export default calculateCashInBankTimeSeries;
