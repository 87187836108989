import {DrawerAction, DrawerReducerActionType} from "./index";


const defaultState = {
    drawers: []
}

export default function reducer(state: DrawerState = defaultState, action: DrawerAction): DrawerState {
    switch (action.type) {
        case DrawerReducerActionType.REDUCE_DRAWER_OPEN: {
            const drawer = action.payload;
            const filteredDrawers = state.drawers.filter(({id}) => id !== drawer.id);

            return {...state, drawers: [drawer, ...filteredDrawers]};
        }

        case DrawerReducerActionType.REDUCE_DRAWER_CLOSE: {
            const payload = action.payload;
            const filteredDrawers = state.drawers.filter(({id}) => id !== payload.id);

            return {...state, drawers: filteredDrawers}
        }

        default:
            return state;
    }
}
