import {CashFlowRole as CashFlowRoleEnum} from "../../../model/CashFlow/enums";
import {PaymentTypeEnum} from "../../../model/Planning/Enums";

const filterPaymentTypes = (
    paymentTypes: PaymentType[],
    forReversalPlanning: boolean,
    paymentCashFlowRole: CashFlowRole
): PaymentType[] => {
    switch (paymentCashFlowRole) {
        case CashFlowRoleEnum.DEPOSIT:
            return [forReversalPlanning ? PaymentTypeEnum.DISBURSEMENT : PaymentTypeEnum.DEPOSIT];
        case CashFlowRoleEnum.DISBURSEMENT:
            return [forReversalPlanning ? PaymentTypeEnum.DEPOSIT : PaymentTypeEnum.DISBURSEMENT];
        default:
            return paymentTypes;
    }
};

export default filterPaymentTypes;
