import * as actions from "./actionTypes";

export default function reducer(state: FilterState = {
    postingFilters: undefined,
    accountFilter: undefined,
    selectedCompanyUnit: undefined
}, action: actions.FilterReducerAction): FilterState {
    switch (action.type) {
        case actions.REDUCE_CASH_FLOW_ROLES_BLACKLIST: {
            const cashFlowRoles = action.payload;
            const accountFilter: AccountFilter = state.accountFilter || {};
            return {
                ...state,
                accountFilter: {
                    ...accountFilter,
                    cashFlowRoleBlacklist: cashFlowRoles,
                }
            };
        }
        case actions.REDUCE_COMPANY_UNIT_FILTER: {
            const companyUnit = action.payload.companyUnit;
            const postingFilters: PostingFilterSet = state.postingFilters || {};
            return {
                ...state,
                postingFilters: {
                    ...postingFilters,
                    // @deprecated #FEATURE-624
                    companyUnitIds: companyUnit ? [companyUnit.id] : [],
                },
                selectedCompanyUnit: companyUnit
            };
        }
        case actions.REDUCE_ENABLE_POSTING_FILTER: {
            const postingFilter = action.payload;
            const postingFilters: PostingFilterSet = state.postingFilters || {};
            return {
                ...state,
                postingFilters: {
                    ...postingFilters,
                    [postingFilter.id]: postingFilter,
                },
            };
        }
        default: {
            return state;
        }
    }
}
