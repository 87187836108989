import { call, put } from 'redux-saga/effects';
import axios from '../../../axios';
import * as actionTypes from '../actionTypes';
import {legacyAlert} from "../../UserAlert";
import * as RestV1 from './RestV1Types';
import { PlanningTypeEnum } from '../../../model/Planning/Enums';
import { getAccounts } from '../../Accounting/saga/function-getAccounts';
import tld from "../../../tld";

export function transformPlanningResource(planningResource: RestV1.Planning, accounts: AccountRegistry) {
    let account: AccountingAccount = accounts[planningResource.accountId];
    let transferAccount: AccountingAccount|undefined = undefined === planningResource.transferAccountId
        ? undefined
        : accounts[planningResource.transferAccountId];
    let cashAccount: AccountingAccount = accounts[planningResource.cashAccountId];
    let recurrence: Recurrence|undefined;
    if (undefined !== planningResource.recurrence) {
        recurrence = {
            unit: planningResource.recurrence.unit,
            period: planningResource.recurrence.period,
            gradientAmount: planningResource.recurrence.gradientAmount,
            growthRate: planningResource.recurrence.growthRate
        };
        if (undefined !== planningResource.recurrence.endDate) {
            recurrence.endDate = new Date(planningResource.recurrence.endDate);
        }
    }
    const planningType = (undefined === planningResource.isForecast || planningResource.isForecast)
        ? PlanningTypeEnum.FORECAST
        : PlanningTypeEnum.ADJUSTMENT;
    const planning: Planning = {
        ...planningResource,
        isReversal: !!planningResource.isReversal,
        planningType,
        recurrence,
        invoiceDate: new Date(planningResource.invoiceDate),
        paymentDate: new Date(planningResource.paymentDate),
        account,
        transferAccount,
        cashAccount
    };
    return planning;
}

function* loadRemotePlanning(planningId: string) {
    const url = tld(process.env.REACT_APP_URL_API_PLAN) + '/v1/plannings/' + planningId;
    const planningResource: RestV1.Planning =
        yield axios.get(url).then(response => response.data);
    const accounts: AccountRegistry = yield call(getAccounts);
    return transformPlanningResource(planningResource, accounts);
}

export function* loadPlanning(planningId: string) {
    try {
        const planning: Planning = yield call(loadRemotePlanning, planningId);
        yield put({ type: actionTypes.REDUCE_PLANNING_LOADED, payload: planning });
        return planning;
    } catch (axiosError) {
        legacyAlert(axiosError, 'create planning');
    }
}
