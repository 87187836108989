import axios from '../../../axios';
import {put} from "redux-saga/effects";

import * as actionTypes from "../actionTypes";
import tld from "../../../tld";


function* axiosDeleteRemoteRestV1Filters(id) {
    return yield axios.delete(tld(process.env.REACT_APP_URL_API_PLAN) + '/v1/scenarios/' + id);
}

export default function* deleteScenario(scenarioId) {
    yield axiosDeleteRemoteRestV1Filters(scenarioId.payload);

    yield put({
        type: actionTypes.REDUCE_SCENARIO_DELETED,
        payload: scenarioId.payload
    });
}
