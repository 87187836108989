import debug from "../../../services/Debug/functions/debug";
import getStatementRowById from "../../../model/Statement/getStatementRowById";
import {isStandardRow} from "../../../model/Statement/Profile/rowQualifier";

function getPlanningAccountsForStatementRow(
    row: StatementRowContainer,
    accountRegistry: AccountRegistry,
    baseAccounts: AccountRegistry,
): AccountRegistry {
    const result: AccountRegistry = {...baseAccounts};
    const rows = row?.rows?.toArray() || [];
    rows.filter(subRow => isStandardRow(subRow)).forEach(subRow => {
        const accountIds = subRow.accountIds || [];
        accountIds.filter(i => undefined !== accountRegistry[i]).forEach(accountId => {
            result[accountId] = accountRegistry[accountId];
        });
    });
    return result;
}

const filterAccountsForPlanning = (
    statement: Statement,
    accounts: AccountRegistry,
    group?: GroupDraft,
    baseAccounts: AccountRegistry = {},
): AccountRegistry => {
    debug("filterAccountsForPlanning()", statement, accounts, group, baseAccounts);

    const rowId = group?.id;
    if (undefined === rowId) {
        debug("filterAccountsForPlanning() result", baseAccounts);
        return baseAccounts;
    } else {
        const row: StatementRowContainer | undefined = null === rowId
            ? statement
            : getStatementRowById(statement, rowId);
        if (undefined === row) {
            throw new Error('Statement row not found: ' + rowId);
        } else {
            const result = getPlanningAccountsForStatementRow(row, accounts, baseAccounts);
            debug("filterAccountsForPlanning() result", result);
            return result;
        }
    }
}

export default filterAccountsForPlanning;
