import axios from "axios";
import applyApplicationModeDefaultHeader from "./applyApplicationModeDefaultHeader";
import applyRetryInterceptor from "./applyRetryInterceptor";
import applyCacheInterceptor from "./applyCacheInterceptor";
import applyStreamInterceptor from "./applyStreamInterceptor";

axios.defaults.headers.common["Cache-Control"] = "no-cache, no-store";
axios.defaults.headers.common["Accept"] = "application/json";
axios.defaults.headers.common["Content-Type"] = "application/json";
axios.defaults.withCredentials = true;
applyApplicationModeDefaultHeader();

const mainAxios = axios.create();
applyRetryInterceptor(mainAxios);
applyStreamInterceptor(mainAxios);
applyCacheInterceptor(mainAxios);

export const subAxios = axios.create();
applyStreamInterceptor(subAxios);
applyCacheInterceptor(subAxios);

export const axiosInstances = [
    mainAxios,
    subAxios,
];

export default mainAxios;
