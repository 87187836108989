import {getTimeRangeEndTimeCode, getTimeRangeStartTimeCode} from "../../TimeRange";
import getStartIndexForTimeCode from "./getStartIndexForTimeCode";
import getEndIndexForTimeCode from "./getEndIndexForTimeCode";
import makeTimeSeriesForTimeRange from "./makeTimeSeriesForTimeRange";
import overloadTimeSeries from "./overloadTimeSeries";

export default function fitIntoRange(timeSeries: AbstractTimeSeries, timeRange: TimeRange): TimeSeries
{
    const rangeStartTimeCode = getTimeRangeStartTimeCode(timeRange, timeSeries.resolution);
    const rangeEndTimeCode = getTimeRangeEndTimeCode(timeRange, timeSeries.resolution);
    const startIndex = getStartIndexForTimeCode(timeSeries, rangeStartTimeCode);
    const endIndex = getEndIndexForTimeCode(timeSeries, rangeEndTimeCode);
    const result = {...timeSeries};
    if (undefined !== startIndex || undefined !== endIndex) {
        const sliceEnd = undefined === endIndex ? undefined : endIndex + 1;
        result.timeCodes = result.timeCodes.slice(startIndex, sliceEnd);
        result.isoCodes = result.isoCodes.slice(startIndex, sliceEnd);
        result.values = result.values.slice(startIndex, sliceEnd);
    }
    if (undefined === startIndex || undefined === endIndex) {
        const fillingTimeSeries = makeTimeSeriesForTimeRange(timeRange, timeSeries.resolution);
        return overloadTimeSeries(fillingTimeSeries, result);
    } else {
        return result;
    }
}
