import {CashFlowTransactionsTypes} from "./actions";
import * as actions from './actions';

const defaultState: TransactionsState = {
    cashFlowTransactions: [],
    isLoading: false,
    selectedTransactionsIds: [],
    loadingTransactionIds: [],

}

export default function reducer(
    state: TransactionsState = defaultState,
    action: CashFlowTransactionsTypes
): TransactionsState {
    switch (action.type) {
        case actions.REDUCE_TRANSACTIONS_REQUEST: {
            return {
                ...state,
                isLoading: true
            };
        }
        case actions.REDUCE_TRANSACTIONS_LOADED: {
            return {
                ...state,
                isLoading: false,
                cashFlowTransactions: action.payload
            };
        }
        case actions.TRANSACTIONS_LOADING: {
            return {
                ...state,
                isLoading: true,
                loadingTransactionIds: state.loadingTransactionIds.concat(action.payload)
            }
        }
        case actions.RESET_TRANSACTIONS_LOADING: {
            return {
                ...state,
                loadingTransactionIds: [],
                isLoading: false,
            }
        }
        case actions.SELECT_TRANSACTION: {
            return {
                ...state,
                selectedTransactionsIds: action.payload
            }
        }
        case actions.UNSELECT_TRANSACTIONS: {
            return {
                ...state,
                selectedTransactionsIds: [],
            }
        }
        case actions.UNSELECT_TRANSACTION: {
            return {
                ...state,
                selectedTransactionsIds: state.selectedTransactionsIds.filter(t => t !== action.payload)
            }
        }
        case actions.UNLOAD_TRANSACTIONS: {
            return {
                ...state,
                cashFlowTransactions: []
            }
        }

        default: {
            return state;
        }
    }
}
