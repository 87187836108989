import * as actionTypes from "./actionTypes";
import {LogoutTrigger} from "./enums";

export function initializeAuth(): actionTypes.InitializeAuthAction {
    return {
        type: actionTypes.INITIALIZE_AUTH
    }
}

export function loadLogins(): actionTypes.LoadLoginsAction {
    return {
        type: actionTypes.LOAD_LOGINS
    }
}

export function logout(trigger: LogoutTrigger = LogoutTrigger.USER): actionTypes.LogoutAction {
    return {
        type: actionTypes.LOGOUT,
        payload: trigger,
    }
}

export function renewLogin(): actionTypes.RenewLoginAction {
    return {
        type: actionTypes.RENEW_LOGIN
    }
}

export function timeoutLogout(): actionTypes.LogoutAction {
    return {
        type: actionTypes.LOGOUT,
        payload: LogoutTrigger.TIMEOUT,
    }
}
