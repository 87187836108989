import moment from "moment";
import makeUtcDate from "./makeUtcDate";

export default function getTimeFrameEndDate(timeFrame: TimeFrame): Date {
    if (timeFrame.halfYear === 1) {
        timeFrame = {month: 6, day: 0, ...timeFrame};
    } else if (undefined !== timeFrame.quarter) {
        timeFrame = {month: timeFrame.quarter * 3, day: 0, ...timeFrame};
    } else if (undefined !== timeFrame.week) {
        const momentDate = moment().year(timeFrame.year).isoWeek(timeFrame.week).endOf("isoWeek");
        timeFrame = {month: momentDate.month() + 1, day: momentDate.date(), ...timeFrame};
    } else {
        timeFrame = {month: 12, day: 0, ...timeFrame};
    }
    return makeUtcDate(timeFrame.year, timeFrame.month, timeFrame.day);
}
