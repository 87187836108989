import {PostingChannel} from "../../model/Posting/enums";

export const REDUCE_TRANSACTIONS_LOADED = 'REDUCE_TRANSACTIONS_LOADED';
export const REDUCE_TRANSACTIONS_REQUEST = 'REDUCE_TRANSACTIONS_REQUEST';
// export const REDUCE_TRANSACTIONS_FAILED = 'REDUCE_TRANSACTIONS_FAILED';
export const UPDATE_CASHFLOW_TRANSACTIONS = 'UPDATE_CASHFLOW_TRANSACTIONS'
export const CREATE_AND_UPDATE_TRANSACTION_CATEGORY = 'CREATE_AND_UPDATE_TRANSACTION_CATEGORY'
export const TRANSACTIONS_LOADING = 'TRANSACTIONS_LOADING'
export const RESET_TRANSACTIONS_LOADING = 'RESET_TRANSACTIONS_LOADING'
export const SELECT_TRANSACTION = 'SELECT_TRANSACTION'
export const UNSELECT_TRANSACTION = 'UNSELECT_TRANSACTION'
export const UNLOAD_TRANSACTIONS = 'UNLOAD_TRANSACTIONS'
export const UNSELECT_TRANSACTIONS = 'UNSELECT_TRANSACTIONS'

export type RequestCashFlowTransactionsAction = {
    type: typeof REDUCE_TRANSACTIONS_REQUEST,
    payload: { startDate: TimeFrame, endDate: TimeFrame, resolution?: TimeSeriesResolution, accountIds: string[], channels: PostingChannel[] }
}
export const requestCashFlowTransactions = (params): RequestCashFlowTransactionsAction => {
    return {
        type: REDUCE_TRANSACTIONS_REQUEST,
        payload: params
    }
}
export type UnloadTransactions = {
        type: typeof UNLOAD_TRANSACTIONS,
}

export const unloadTransactions = () => {
    return {
        type: UNLOAD_TRANSACTIONS,
    }
}

export type LoadCashFlowTransactionsAction = {
    type: typeof REDUCE_TRANSACTIONS_LOADED,
    payload: Transaction[]
}
export const loadCashFlowTransactions = (payload: Transaction[]) => {
    return {
        type: REDUCE_TRANSACTIONS_LOADED,
        payload
    }
}
export type UpdateCashFlowTransactions = {
    type: typeof UPDATE_CASHFLOW_TRANSACTIONS,
    payload: Transaction
}

export const updateCashFlowTransactions = (payload: { category: GroupDraft, transactionIds: string[]}) => {
    return {
        type: UPDATE_CASHFLOW_TRANSACTIONS,
        payload
    }
}
export type CreateNewCategoryAndUpdate = {
    type: typeof CREATE_AND_UPDATE_TRANSACTION_CATEGORY,
    payload: Transaction[]
}
export const createNewCategoryAndUpdate = (payload: { category: GroupDraft, transactionIds: string[], parentGroup: GroupDraft, modalId? }) => {
    return {
        type: CREATE_AND_UPDATE_TRANSACTION_CATEGORY,
        payload
    }
}
export type UpdateTransaction = {
    type: typeof TRANSACTIONS_LOADING,
    payload: string[]
}
export const setLoadingTransactions = (transactionIds: string[]) => (
    {
        type: TRANSACTIONS_LOADING,
        payload: transactionIds
    }
)
export type ResetTransactionLoading = {
    type: typeof RESET_TRANSACTIONS_LOADING,
}
export const resetTransactionLoading = () => ({
        type: RESET_TRANSACTIONS_LOADING,
    }
)
export type SelectTransaction = {
    type: typeof SELECT_TRANSACTION,
    payload: string[]
}

export const selectTransactions = (ids:string[]) => ({
        type: SELECT_TRANSACTION,
        payload: ids
    }
)
export type UnSelectTransaction = {
    type: typeof UNSELECT_TRANSACTION,
    payload: string
}

export const unselectTransaction = (id:string) => ({
        type: UNSELECT_TRANSACTION,
        payload: id
    }
)
export type UnSelectTransactions = {
    type: typeof UNSELECT_TRANSACTIONS,
    payload: string
}
export const unselectTransactions = () => ({
    type: UNSELECT_TRANSACTIONS
})
export type CashFlowTransactionsTypes =
    RequestCashFlowTransactionsAction |
    LoadCashFlowTransactionsAction |
    CreateNewCategoryAndUpdate |
    UpdateTransaction |
    ResetTransactionLoading|
    SelectTransaction|
    UnSelectTransaction|
    UnloadTransactions|
    UnSelectTransactions
