export const accountFilterSelector: (state: RootState) => AccountFilter | undefined
    = (state: RootState) => state.filter.accountFilter;

export const postingFiltersSelector: (state: RootState) => PostingFilterSet | undefined
    = (state: RootState) => state.filter.postingFilters;

export function getSelectedCompanyUnitId(state: FilterState): string {
    if (undefined === state.selectedCompanyUnit) {
        throw new Error("No company unit selected.");
    }
    return state.selectedCompanyUnit.id;
}

export function getSelectedCompanyUnit(state: FilterState): CompanyUnit {
    if (undefined === state.selectedCompanyUnit) {
        throw new Error("No company unit selected.");
    }
    return state.selectedCompanyUnit;
}
