import { StatementRowType } from "../Statement";
import { StatementUriExtension } from "./enums";

export function encodeStatementRowType(rowType: StatementRowType): string
{
    switch (rowType) {
        case StatementRowType.GROUP:
            return "g";
        case StatementRowType.CATEGORY:
            return "c";
        default:
            throw Error("Statement row type not supported: " +  rowType);
    }
}

export function encodeStatementUriPathName(rowId: string, rowType: StatementRowType): string {
    return encodeStatementRowType(rowType) + ":" + rowId;
}

export function encodeStatementUri(statementId: string): string {
    return "statement://" + statementId;
}

export function encodeStatementRowUri(
    statementId: string,
    rowId: string,
    rowType: StatementRowType,
    basePath: string = "",
    minLevel: number|"." = 0,
    maxLevel: number|"."|undefined = undefined,
    rootBound: boolean = false,
    extension?: StatementUriExtension
): string
{
    let result = "statement://";
    //
    let constraint = "";
    if (rootBound) {
        constraint += "~";
    }
    if (minLevel === "." || minLevel > 0) {
        constraint += minLevel;
    }
    if (undefined !== maxLevel) {
        constraint += ":" + maxLevel;
    }
    //
    if ("" !== constraint) {
        result += constraint + "@";
    }
    basePath = basePath.replace(/^\/+/, "");
    basePath = basePath.replace(/\/+$/, "");
    result += statementId + "/" + basePath + "/" + encodeStatementRowType(rowType) + ":" + rowId;
    if (undefined !== extension) {
        result += "." + extension;
    }
    return result;
}
