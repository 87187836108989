import PropTypes from 'prop-types';
import {all, call, put, select, spawn} from 'redux-saga/effects'
import axios from '../../../axios';
import * as actionTypes from '../actionTypes';
import {legacyAlert} from "../../UserAlert";
import * as postingActions from '../../Posting/actions';
import {transformRestV1PostingResource} from '../../Accounting/saga/functions';
import * as selectors from '../../../reducer';
import * as RestV1Accounting from '../../Accounting/saga/RestV1Types';
import {PostingChannel} from '../../../model/Posting/enums';
import {TimeRangePropType} from '../../../model/TimeSeries/PropTypes';
import {getTimeRangeEndDate, getTimeRangeStartDate, mergeTimeRanges} from '../../../model/TimeRange';
import {dateToIso8601} from '../../../model/date';
import {getForecastStartDate} from './function-getForecastStartDate';
import {getAccounts} from '../../Accounting/saga/function-getAccounts';
import * as RestV1 from './RestV1Types';
import {transformPlanningResource} from './planning-functions';
import {PlanningTypeEnum} from '../../../model/Planning/Enums';
import {activeDateSelector, activeDensitySelector, activeTimeRangeSelector} from "../../TimeTravel/selectors";
import tld from "../../../tld";
import {ReduceSelectDensity, ReduceSelectTimeFrame} from "../../TimeTravel/actionTypes";
import {makeTimeRange} from "../../TimeTravel/TimeTravel.functions";

// ------------- init postings

function axiosRequestRemoteRestV1Postings(
    startDate: Date | null,
    endDate: Date,
    planningType?: PlanningType
): Promise<RestV1Accounting.RemotePostingsResponse> {
    if (!startDate || endDate >= startDate) {
        let params: RestV1.GetPostingsParams = {
            endDate: dateToIso8601(endDate),
        };
        if (startDate) {
            params.startDate = dateToIso8601(startDate);
        }
        if (planningType) {
            params.planningType = planningType;
        }
        return axios.get(tld(process.env.REACT_APP_URL_API_PLAN) + '/v1/accounting/postings', {params});
    } else {
        return new Promise<RestV1Accounting.RemotePostingsResponse>(resolve => resolve({data: []}));
    }
}

function* loadRemoteRestV1Postings(startDate: Date | null, endDate: Date, planningType?: PlanningType) {
    try {
        return yield axiosRequestRemoteRestV1Postings(startDate, endDate, planningType);
    } catch (axiosError) {
        legacyAlert(axiosError, 'init postings');
        return {data: []};
    }
}

function* loadPostingsInRange(actionRange?: TimeRange) {
    yield put(postingActions.postingIsLoading());
    const range = actionRange?.start ? actionRange : yield select(activeTimeRangeSelector)

    const rangeEndDate = getTimeRangeEndDate(range);
    const forecastStartDate: Date = yield call(getForecastStartDate, getTimeRangeStartDate(range));
    const [adjustmentPostingsResponse, forecastPostingsResponse] = yield all([
        loadRemoteRestV1Postings(null, rangeEndDate, PlanningTypeEnum.ADJUSTMENT),
        loadRemoteRestV1Postings(forecastStartDate, rangeEndDate, PlanningTypeEnum.FORECAST)
    ]);
    const adjustmentPostings: Posting[] = adjustmentPostingsResponse.data.map(
        posting => transformRestV1PostingResource(posting, PostingChannel.ADJUSTMENT)
    );
    yield put(postingActions.postingsLoaded(adjustmentPostings, PostingChannel.ADJUSTMENT));
    const forecastPostings: Posting[] = forecastPostingsResponse.data.map(
        posting => transformRestV1PostingResource(posting, PostingChannel.PLANNING)
    );
    yield put(postingActions.postingsLoaded(forecastPostings, PostingChannel.PLANNING));
}

function* loadRemotePlannings() {
    const url = tld(process.env.REACT_APP_URL_API_PLAN) + '/v1/plannings';
    const planningResources: RestV1.Planning[] =
        yield axios.get(url).then(response => response.data);
    const responseType = typeof planningResources;
    if ("object" === responseType) {
        const accounts: AccountRegistry = yield call(getAccounts);
        return planningResources.map(planningResource => transformPlanningResource(planningResource, accounts));
    } else {
        // #FEATURE-1178
        const error = Error(`Unable to parse API response.`);
        legacyAlert(error, "load plannings", {url});
        return [];
    }
}

let planningsLoaded: boolean = false;

function* loadPlanningsInRange(range: TimeRange) {
    try {
        const loadedRange = planningsLoaded ? yield select(selectors.findPlanningRange) : null;
        const newRange = null === loadedRange ? range : mergeTimeRanges(loadedRange, range);
        let plannings: Planning[];
        if (planningsLoaded) {
            plannings = yield select(selectors.getPlannings);
        } else {
            planningsLoaded = true;
            plannings = yield call(loadRemotePlannings);
        }
        yield all([
            put({type: actionTypes.REDUCE_PLANNINGS_LOADED, payload: {plannings, range: newRange}}),
            spawn(loadPostingsInRange, newRange),
        ]);
    } catch (e) {
        if (e.isAxiosError) {
            legacyAlert(e, 'init planning');
        } else {
            throw e;
        }
    }
}

// -------------

export default function* loadPlannings(action) {
    PropTypes.checkPropTypes(
        {
            range: TimeRangePropType.isRequired,
        },
        action.payload,
        'saga',
        'loadPlannings'
    );
    yield* loadPlanningsInRange(action.payload.range);
}

// #FEATURE-1384 fix to load missing plannings
export function* loadPlanningsForTimeFrame(action: ReduceSelectTimeFrame) {
    const date = action.payload.selectedDate;
    const selectedDensity = yield select(activeDensitySelector)
    const quickFixDate = new Date(new Date().setFullYear(date.getFullYear() + 1));
    const timeRange = makeTimeRange(selectedDensity, quickFixDate);
    yield* loadPlanningsInRange(timeRange);
}

// #FEATURE-1384 fix to load missing plannings
export function* loadPlanningsForDensity(action: ReduceSelectDensity) {
    const date = yield select(activeDateSelector)
    const density = action.payload;
    const timeRange = makeTimeRange(density, date);
    yield* loadPlanningsInRange(timeRange);
}
