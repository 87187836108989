import {BankAction, ConnectBankAccountAction, DeleteBankAccountAction, DisconnectBankAccountAction} from "./actions";
import {ConnectionStatus} from "../../scenes/Bank/components/enums";

export enum BankReducerAction {
    REDUCE_VISIBILITY = "REDUCE_VISIBILITY",
    REDUCE_BANK_ACCOUNTS = "REDUCE_BANK_ACCOUNTS",
    REDUCE_WORKFLOW = "REDUCE_WORKFLOW",
}

export type ReduceVisibilityAction = {
    type: BankReducerAction.REDUCE_VISIBILITY,
    payload: boolean
}

export type ReduceBankAccountsAction = {
    type: BankReducerAction.REDUCE_BANK_ACCOUNTS,
    payload: {
        bankAccounts: BankAccount[],
    }
}

export type WorkflowAction = {
    type: BankReducerAction.REDUCE_WORKFLOW,
    payload?: BankWorkflow
}

type BankReducerActionType =
    ReduceVisibilityAction |
    ConnectBankAccountAction |
    DisconnectBankAccountAction |
    DeleteBankAccountAction |
    ReduceBankAccountsAction |
    WorkflowAction;

const defaultState = {
    initialized: false,
    bankAccounts: [],
}

export default function reducer(
    state: BankState = defaultState,
    action: BankReducerActionType
): BankState {
    switch (action.type) {
        case BankReducerAction.REDUCE_VISIBILITY: {
            return action.payload !== state.visible ? {
                ...state,
                visible: action.payload,
            } : state;
        }
        case BankReducerAction.REDUCE_BANK_ACCOUNTS: {
            return {
                ...state,
                initialized: true,
                bankAccounts: action.payload.bankAccounts,
            };
        }
        case BankAction.CONNECT_BANK_ACCOUNT: {
            return {
                ...state,
                initialized: true,
                bankAccounts: state.bankAccounts.map(
                    bankAccount => bankAccount.id === action.payload.id
                        ? {...bankAccount, connectionStatus: ConnectionStatus.CONNECTED}
                        : bankAccount
                ),
            };
        }
        case BankAction.DISCONNECT_BANK_ACCOUNT: {
            return {
                ...state,
                initialized: true,
                bankAccounts: state.bankAccounts.map(
                    bankAccount => bankAccount.id === action.payload.id
                        ? {...bankAccount, connectionStatus: ConnectionStatus.DISCONNECTED}
                        : bankAccount
                ),
            };
        }
        case BankAction.DELETE_BANK_ACCOUNT: {
            const {id} = action.payload;
            return {
                ...state,
                initialized: true,
                bankAccounts: state.bankAccounts.filter(bankAccount => bankAccount.id !== id),
            };
        }
        case BankReducerAction.REDUCE_WORKFLOW: {
            return {
                ...state,
                workflow: action.payload,
            };
        }
        default:
            return state;
    }
}
