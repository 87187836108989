import {RemoteAbstractExplanationSeries} from "./saga/RestV1Types";

export const FORECAST_LOAD = 'FORECAST_LOAD';
export const FORECAST_LOADING_STARTED = 'FORECAST_LOADING_STARTED';
export const FORECAST_LOADED = 'FORECAST_LOADED';
export const FORECAST_TOGGLED = 'FORECAST_TOGGLED';
export const FORECAST_EXPLANATION_LOADED = 'FORECAST_EXPLANATION_LOADED'
export const FORECAST_EXPLANATION_UPDATED = 'FORECAST_EXPLANATION_UPDATED';
export const FORECAST_BASE_PLANNING_TOGGLE = 'FORECAST_BASE_PLANNING_TOGGLE'

export type ForecastToggledAction = {
    type: typeof FORECAST_TOGGLED,
    payload: {
        visible: boolean
    }
}

export type ForecastLoadAction = {
    type: typeof FORECAST_LOAD,
}

export type ForecastLoadingStartedAction = {
    type: typeof FORECAST_LOADING_STARTED,
}
export type RemoteCashFlowBlueprintGroup = {
    id: string,
    customGroupId: string,
    customLabel: string,
}
export type ForecastExplanationLoaded = {
    type: typeof FORECAST_EXPLANATION_LOADED,
    payload: RemoteAbstractExplanationSeries,
    groups: RemoteCashFlowBlueprintGroup[]
}
export type ForecastExplanationUpdated = {
    type: typeof FORECAST_EXPLANATION_UPDATED,
    payload: ExplanationSeries
}

export type ForecastLoadedAction = {
    type: typeof FORECAST_LOADED,
    payload: {
        upper: number[],
        lower: number[],
        date: string[]
        scenario?: Scenario
    }
}
export type ForecastBasePlanningToggle = {
    type: typeof FORECAST_BASE_PLANNING_TOGGLE,
    payload: string
}

export type ForecastReducerAction =
    ForecastToggledAction
    | ForecastLoadedAction
    | ForecastLoadingStartedAction
    | ForecastExplanationLoaded
    | ForecastExplanationUpdated
    | ForecastBasePlanningToggle
