import React from 'react';
import memoizeOne from 'memoize-one';
import {connect} from 'react-redux';

import {DragDropContext} from 'react-beautiful-dnd';

import {PATH} from "../../model/enums";
import {ConfigProvider, Layout, Spin} from "antd";
import LoadableSpin from '../../components/LoadableSpin';

import {initializeAuth} from '../../services/Auth/actions';
import {onBeforeCapture, onDragEnd, onDragUpdate} from "../../model/DragAndDrop/DragDropManager";

import {getStatementById} from "../../services/Statement/reducer";
import {getAppMode} from "../../reducer";
import {AppMode} from "../../model/App/enums";

import i18n from '../../i18n';
import {Locale} from "antd/lib/locale-provider";
import enUS from 'antd/lib/locale/en_US';
import deDE from 'antd/lib/locale/de_DE';
import AppFooter from "../../components/AppFooter/AppFooter";
import DrawerContainer from "../../components/Drawer/DrawerContainer";
import {closeDrawerAction} from "../../services/Drawer/actions";
import {isAuthStateInitialized, isLoggedInSelector, isMasterUserSelector} from "../../services/Auth/selectors";
import {isMasterUserLogin} from "../../model/Auth/functions";
import {isFeatureActive} from "../../services/selectors";
import {FlaggedFeature} from "../../flags";
import {
    AuditOutlined,
    BankOutlined,
    BarChartOutlined,
    DashboardOutlined,
    LineChartOutlined,
    FileSearchOutlined
} from "@ant-design/icons";
import EmptyStage from "../../components/EmptyStage/EmptyStage";


const {Content} = Layout;
const Loading = () => <EmptyStage ghost style={{margin: 0}}><LoadableSpin/></EmptyStage>;
const AppLayoutUploadLazy = React.lazy(() => import("../../components/AppLayout/AppLayoutUpload"));
const AppLayoutUpload = props => (
    <React.Suspense fallback={<Loading/>}><AppLayoutUploadLazy {...props}/></React.Suspense>
);
const AppLayoutAdminLazy = React.lazy(() => import("../../components/AppLayout/AppLayoutAdmin"));
const AppLayoutAdmin = props => (
    <React.Suspense fallback={<Loading/>}><AppLayoutAdminLazy {...props}/></React.Suspense>
);
const AppLayoutUnknownLazy = React.lazy(() => import("../../components/AppLayout/AppLayoutUnknown"));
const AppLayoutUnknown = props => (
    <React.Suspense fallback={<Loading/>}><AppLayoutUnknownLazy {...props}/></React.Suspense>
);
const BankSceneLazy = React.lazy(() => import("../../scenes/Bank/BankScene"));
const BankScene = props => (
    <React.Suspense fallback={<Loading/>}><BankSceneLazy {...props}/></React.Suspense>
);
const AccountingSceneLazy = React.lazy(() => import("../../scenes/Accounting/AccountingScene"));
const AccountingScene = props => (
    <React.Suspense fallback={<Loading/>}><AccountingSceneLazy {...props}/></React.Suspense>
);
const LiquiditySceneLazy = React.lazy(() => import("../../scenes/Liquidity"));
const LiquidityScene = props => (
    <React.Suspense fallback={<Loading/>}><LiquiditySceneLazy {...props}/></React.Suspense>
);
const CashFlowSceneLazy = React.lazy(() => import("../../scenes/CashFlow"));
const CashFlowScene = props => (
    <React.Suspense fallback={<Loading/>}><CashFlowSceneLazy {...props}/></React.Suspense>
);
const DashboardSceneLazy = React.lazy(() => import("../../scenes/Dashboard"));
const DashboardScene = props => (
    <React.Suspense fallback={<Loading/>}><DashboardSceneLazy {...props}/></React.Suspense>
);
const StatementSceneLazy = React.lazy(() => import("../../scenes/Statement"));
const PlanningsSceneLazy = React.lazy(() => import("../Plannings"))
const StatementScene = props => (
    <React.Suspense fallback={<Loading/>}><StatementSceneLazy {...props}/></React.Suspense>
);
const Plannings = () => (
    <React.Suspense fallback={<Loading/>}><PlanningsSceneLazy/></React.Suspense>
)
const navComponents = {
    dashboard: DashboardScene,
    liquidity: LiquidityScene,
    bank: BankScene,
    accounting: AccountingScene,
    cashFlow: CashFlowScene,
    statement: StatementScene,
    plannings: Plannings
};

const navPaths = {
    de: {
        dashboard: PATH.DASHBOARD,
        liquidity: PATH.LIQUIDITY,
        bank: PATH.BANK,
        accounting: PATH.ACCOUNTING,
        cashFlow: PATH.CASH_FLOW,
        statement: PATH.STATEMENT,
        plannings: PATH.PLANNINGS
    }
};
const navTitles = {
    de: {
        dashboard: "Dashboard",
        liquidity: "Liquidität",
        bank: "Bank",
        accounting: "Buchhaltung",
        cashFlow: "Cashflow",
        statement: "Statement",
        plannings: 'Planungen'
    }
};
const navIcons = {
    dashboard: <DashboardOutlined/>,
    liquidity: <BarChartOutlined/>,
    bank: <BankOutlined/>,
    accounting: <AuditOutlined/>,
    cashFlow: <LineChartOutlined/>,
    statement: <LineChartOutlined/>,
    plannings: <FileSearchOutlined/>
};
const navLayouts = {
    dashboard: "standard",
    liquidity: "standard",
    bank: "standard",
    accounting: "standard",
    cashFlow: "sticky",
    statement: "standard",
    plannings: 'standard'
};
const navDefault = 'cashFlow';

const getMainNavigation_raw = (
    state: RootState,
    isBankFeatureActive: boolean,
    isSevDeskActive: boolean,
    isBuchhaltungsButlerActive: boolean,
    isUploadActive: boolean,
    isDashboardActive: boolean
): HeaderNavItem[] => {
    // const authState = state.auth;
    const isLoggedIn = isLoggedInSelector(state);
    // const companyName = authState.login?.companyName;
    // const dashboardViewIsEnabled = isLoggedIn && (
    //     companyName === 'ACME' ||
    //     companyName === 'ACME 2' ||
    //     companyName === 'ACME 3' ||
    //     companyName === 'Anytime Fitness Germany, Eighty8 Health & Fitness GmbH' ||
    //     companyName === 'Tim Lorijn Demo Account'
    // );
    // const statementSCSEnabled = isLoggedIn && (
    //     companyName === 'Dev'
    // );
    const statementSCSEnabled = false;
    const liquidityViewsEnabled = false;
    const dataManagementIsEnabled = isMasterUserSelector(state);
    let navConfig: string[] = [];
    if (isLoggedIn) {
        if (isDashboardActive) {
            navConfig.push('dashboard');
        }

        navConfig.push('cashFlow');

        navConfig.push('plannings');

        if (dataManagementIsEnabled) {
            if (isBankFeatureActive) {
                navConfig.push('bank');
            }
            if (isUploadActive || isSevDeskActive || isBuchhaltungsButlerActive) {
                navConfig.push('accounting');
            }
        }

        if (statementSCSEnabled) {
            navConfig.push('statement');
        }
        if (liquidityViewsEnabled) {
            navConfig.push('liquidity');
        }

    }
    const mainNavigation: HeaderNavItem[] = [];
    for (let navConfigItem of navConfig) {
        mainNavigation.push({
            key: navConfigItem + 'Key',
            path: navPaths.de[navConfigItem],
            title: navTitles.de[navConfigItem],
            layout: navLayouts[navConfigItem],
            component: navComponents[navConfigItem],
            isDefault: navConfigItem === navDefault,
            icon: navIcons[navConfigItem],
        });
    }
    return mainNavigation;
}
const getMainNavigation = memoizeOne(getMainNavigation_raw);

export interface AppBaseProps {
}

interface AppProps extends AppBaseProps {
    dispatch: (action: object) => void, // redux connect
    appMode: AppMode,
    mainNavigation: HeaderNavItem[],
    login?: AuthLogin | null,
    loginIsInitialized: boolean,
    loginIsActive: boolean,
    modals: Modal[],
    drawers: Drawer[],
    statementState: StatementState,
}

interface AppState {
    locale: Locale
}

class App extends React.PureComponent<AppProps, AppState> {

    constructor(props) {
        super(props);
        this.state = {
            locale: deDE
        };
    };

    componentDidMount() {
        this.props.dispatch(initializeAuth());
    };

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (i18n.language?.substring(0, 2) !== this.state.locale.locale) {
            this.setState({locale: this.matchLocale(i18n.language)})
        }
    };

    matchLocale(locale) {
        switch (locale) {
            case "en_US":
                return enUS
            case "de_DE":
            default:
                return deDE
        }
    }

    getStatement = (statementId) => {
        return getStatementById(this.props.statementState, statementId);
    };

    renderLayout() {
        if (this.props.loginIsInitialized) {
            const login = this.props.login!;
            if (this.props.loginIsActive) {
                if (login!.roles.indexOf("admin") > -1) {
                    return <AppLayoutAdmin
                        userName={login.userName}
                        companyName={login.companyName}
                        userRoles={login.roles}
                        isMasterUser={isMasterUserLogin(login)}
                        mainNavigation={this.props.mainNavigation}
                        appMode={this.props.appMode}
                    />;
                } else if (login.roles.indexOf("upload") > -1) {
                    return <AppLayoutUpload
                        userName={login.userName}
                        companyName={login.companyName}
                    />;
                } else {
                    return <AppLayoutUnknown/>;
                }
            } else {
                return null;
            }
        } else {
            return (
                <Layout style={{height: '100%'}}>
                    <Content style={{padding: '25px', display: 'flex', justifyContent: 'center', alignItems: 'center'}}><Spin
                        size="large"/>
                    </Content>
                    <AppFooter/>
                </Layout>
            );
        }
    }

    renderDrawer() {
        const [firstDrawer,] = this.props.drawers;
        const onClose = (id) => this.props.dispatch(closeDrawerAction(id))

        return (firstDrawer && <DrawerContainer
            drawer={firstDrawer}
            onClose={onClose}
        />)
    }

    render() {
        return <DragDropContext
            onBeforeCapture={onBeforeCapture}
            onDragUpdate={onDragUpdate}
            onDragEnd={onDragEnd}
        >
            <ConfigProvider locale={this.state.locale}>
                {this.renderDrawer()}
                {this.renderLayout()}
            </ConfigProvider>
        </DragDropContext>;
    }
}

function mapStateToProps(state: RootState) {
    const isFinApiActive = isFeatureActive(state, FlaggedFeature.finApi);
    const isUploadActive = isFeatureActive(state, FlaggedFeature.upload);
    const isSevDeskActive = isFeatureActive(state, FlaggedFeature.sevDesk);
    const isBuchhaltungsButlerActive = isFeatureActive(state, FlaggedFeature.buchhaltungsButler);
    const isDashboardActive = isFeatureActive(state, FlaggedFeature.dashboard);
    const mainNavigation = getMainNavigation(state, isFinApiActive, isSevDeskActive, isBuchhaltungsButlerActive, isUploadActive, isDashboardActive);
    return {
        login: state.auth.login,
        loginIsInitialized: isAuthStateInitialized(state),
        loginIsActive: isLoggedInSelector(state),
        mainNavigation,
        drawers: state.drawer.drawers,
        statementState: state.statement,
        appMode: getAppMode(state),
    };
}

export default connect<ReturnType<typeof mapStateToProps>,
    undefined,
    AppBaseProps,
    RootState>(mapStateToProps)(App as any);
