import {all, put} from "redux-saga/effects";
import {reduceCompanyUnit} from "../actions";
import {FilterCompanyUnitAction} from "../actionTypes";
import {deselectScenario} from "../../Scenarios/actions";

export default function* filterCompanyUnit(action: FilterCompanyUnitAction) {
    yield all([
        put(reduceCompanyUnit(action.payload.companyUnit)),
        put(deselectScenario()),
    ]);
}
