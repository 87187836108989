import {TimeSeriesDensity} from "../../model/TimeSeries/enums";
import {createSelector} from "reselect";
import {debugSelect} from "../Debug/functions";
import {makeTimeFramesForTimeRange} from "../../model/TimeRange";
import getTimeAxisOfTimeRange from "../../model/TimeAxis/getTimeAxisOfTimeRange";
import {makeTimeRange, subtractMonths} from "./TimeTravel.functions";
import {historicalEndDateSelector} from "../Accounting/selectors";

export const timeSeriesResolutionSelector: (state: RootState) => TimeSeriesResolution
    = (state: RootState) => state.timeTravel.timeSeriesResolution;

export const isTimeTravelDefault: (state: RootState) => boolean
    = (state: RootState) => state.timeTravel.isDefault;

export const densityControlSelector: (state: RootState) => TimeSeriesDensity[]
    = (state: RootState) => state.timeTravel.densityControl;

export const activeDensitySelector: (state: RootState) => TimeSeriesDensity
    = (state: RootState) => state.timeTravel.density;

export const activeDateSelector: (state: RootState) => Date
= createSelector<
    // State
    RootState,
    // Parameter
    boolean,
    Date|undefined,
    Date,
    // Result
    Date
>(
    // selectors
    isTimeTravelDefault,
    historicalEndDateSelector,
    (state: RootState) => state.timeTravel.date,
    // combiner
    (isTimeTravelDefault, historicalEndDate, date) => {
        const adjustDynamically = isTimeTravelDefault && historicalEndDate;
        const result = adjustDynamically ? subtractMonths(historicalEndDate, 6) : date;
        debugSelect("activeDateSelector", result);
        return result;
    }
);

export const activeTimeRangeSelector: (state: RootState) => TimeRange
= createSelector<
    // State
    RootState,
    // Parameter
    TimeSeriesDensity,
    Date,
    // Result
    TimeRange
>(
    // selectors
    activeDensitySelector,
    activeDateSelector,
    // combiner
    (density, date) => makeTimeRange(density, date)
);

export const timeFramesSelector: (state: RootState) => TimeFrame[]
    = createSelector<
    // State
    RootState,
    // Parameter
    TimeRange,
    TimeSeriesResolution,
    // Result
    TimeFrame[]
>(
    // selectors
    activeTimeRangeSelector,
    timeSeriesResolutionSelector,
    // combiner
    (timeRange, timeSeriesResolution) => {
        const result = makeTimeFramesForTimeRange(timeRange, timeSeriesResolution);
        debugSelect("timeFramesSelector", result);
        return result;
    }
);

export const timeAxisSelector: (state: RootState) => TimeAxis
    = createSelector<
    // State
    RootState,
    // Parameter
    TimeRange,
    TimeSeriesResolution,
    // Result
    TimeAxis
    >(
    // selectors
    activeTimeRangeSelector,
    timeSeriesResolutionSelector,
    // combiner
    (timeRange, timeSeriesResolution) => {
        const result = getTimeAxisOfTimeRange(timeRange, timeSeriesResolution);
        debugSelect("timeAxisSelector", result);
        return result;
    }
);
export const basePlanningSelector = (state: RootState): string => {
    return state.forecast.basePlanning
}
