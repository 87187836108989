import moment from "moment";

export function timeFrameToIsoString(timeFrame: TimeFrame): string {
    let result: string = timeFrame.year.toString();
    if (undefined !== timeFrame.month) {
        result += '-' + timeFrame.month.toString().padStart(2, '0');
        if (undefined !== timeFrame.day) {
            result += '-' + timeFrame.day.toString().padStart(2, '0');
        }
    }
    if (timeFrame.week) {
        result += '-' + timeFrame.week.toString().padStart(2, '0');
    }
    if (timeFrame.quarter) {
        result += '-' + timeFrame.quarter.toString().padStart(2, '0');
    }
    if (timeFrame.halfYear) {
        result += '-' + timeFrame.halfYear.toString().padStart(2, '0');
    }
    return result;
}

export function timeFrameToSysString(timeFrame: TimeFrame): string {
    let result: string = timeFrame.year.toString();
    if (undefined !== timeFrame.month) {
        result += '-' + timeFrame.month.toString().padStart(2, '0');
        if (undefined !== timeFrame.day) {
            result += '-' + timeFrame.day.toString().padStart(2, '0');
        }
    } else if (timeFrame.week) {
        result += '-W' + timeFrame.week.toString().padStart(2, '0');
    } else if (timeFrame.quarter) {
        result += '-Q' + timeFrame.quarter.toString();
    } else if (timeFrame.halfYear) {
        result += '-H' + timeFrame.halfYear.toString();
    }
    return result;
}

export function timeFrameToTimeCode(timeFrame: TimeFrame): number {
    let result: string = timeFrame.year.toString();
    if (undefined !== timeFrame.month) {
        result += '' + timeFrame.month.toString().padStart(2, '0');
        if (undefined !== timeFrame.day) {
            result += '' + timeFrame.day.toString().padStart(2, '0');
        }
    }
    if (timeFrame.week) {
        result += '' + timeFrame.week.toString().padStart(2, '0');
    }
    if (timeFrame.quarter) {
        result += '' + timeFrame.quarter.toString().padStart(2, '0');
    }
    if (timeFrame.halfYear) {
        result += '' + timeFrame.halfYear.toString().padStart(2, '0');
    }
    return Number(result);
}

/**
 * @todo localization
 */
export function timeFrameToString(timeFrame: TimeFrame): string {
    const { year, halfYear, quarter, month, week, day } = timeFrame;
    let result = year.toString();
    if (month && day) {
        const dayResult = day.toString().padStart(2, '0');
        const monthResult = month.toString().padStart(2, '0');
        result = dayResult + '.' + monthResult + '.' + result;
    } else if (week) {
        result = week.toString().padStart(2, '0') + '/' + result;
    } else if (month) {
        result = month.toString().padStart(2, '0') + '/' + result;
    } else if (quarter) {
        result = quarter.toString().padStart(2, '0') + '/' + result;
    } else if (halfYear) {
        result = halfYear.toString().padStart(2, '0') + '/' + result;
    }
    return result;
}

export function compareTimeFrames(timeFrame1: TimeFrame, timeFrame2: TimeFrame) {
    if (undefined === timeFrame1.day && undefined !== timeFrame2.day ||
        undefined !== timeFrame1.day && undefined === timeFrame2.day ||
        undefined === timeFrame1.month && undefined !== timeFrame2.month ||
        undefined !== timeFrame1.month && undefined === timeFrame2.month
    ) {
        throw new Error("Same time frame resolution required to compare.");
    }
    if (timeFrame1.year > timeFrame2.year) {
        return -1;
    } else if (timeFrame1.year < timeFrame2.year) {
        return 1;
    } else {
        if (undefined === timeFrame1.month || undefined === timeFrame2.month) {
            return 0;
        } else if (timeFrame1.month > timeFrame2.month) {
            return -1;
        } if (timeFrame1.month < timeFrame2.month) {
            return 1;
        } else {
            if (undefined === timeFrame1.day || undefined === timeFrame2.day) {
                return 0;
            } else if (timeFrame1.day > timeFrame2.day) {
                return -1;
            } if (timeFrame1.day < timeFrame2.day) {
                return 1;
            } else {
                return 0;
            }
        }
    }
}

export function getDailyTimeFrames(startDate: Date, endDate: Date, paddingLeftDays: number = 0): TimeFrame[] {
    const start = moment(startDate).utc();
    if (paddingLeftDays > 0) {
        start.subtract(paddingLeftDays, 'day');
    }
    const end = moment(endDate).utc();
    let timeFrames: TimeFrame[] = [];
    while (start <= end) {
        timeFrames.push({year: start.year(), month: start.month() + 1, day: start.date()});
        start.add(1, 'day');
    }
    return timeFrames;
}
