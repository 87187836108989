import getStartTimeCode from "./getStartTimeCode";
import getEndTimeCode from "./getEndTimeCode";

export enum OverloadMode {
    REPLACE = "replace",
    ADJUST = "adjust",
}

type TimeSeriesType = TimeSeries | SparseTimeSeries;

export default function overloadTimeSeries<T extends TimeSeriesType>(
    base: T, replace: T,
    mode: OverloadMode = OverloadMode.REPLACE
): T {
    if (!replace.timeCodes.length) {
        return base;
    }

    const baseStart = getStartTimeCode(base);
    const baseEnd = getEndTimeCode(base);
    const replaceStart = getStartTimeCode(replace);
    const replaceEnd = getEndTimeCode(replace);

    type ValueArrayType = typeof base.values;
    const adjustTail: (values: ValueArrayType, adjustValue: number|undefined) => ValueArrayType = (
        values,
        adjustValue
    ) => {
        if (OverloadMode.ADJUST === mode && undefined !== adjustValue) {
            return values.map(v => null === v ? v : v + adjustValue);
        } else {
            return values;
        }
    }

    if (replaceStart >= baseEnd + 1) {
        return {
            ...base,
            values: [...base.values, ...replace.values],
            isoCodes: [...base.isoCodes, ...replace.isoCodes],
            timeCodes: [...base.timeCodes, ...replace.timeCodes],
        }
    }

    if (replaceEnd <= baseStart - 1) {
        const adjustValue = [...replace.values].reverse().find(v => null !== v) as number|undefined;
        return {
            ...base,
            values: [...replace.values, ...adjustTail(base.values, adjustValue)],
            isoCodes: [...replace.isoCodes, ...base.isoCodes],
            timeCodes: [...replace.timeCodes, ...base.timeCodes],
        }
    }

    const baseStartIndex: number = base.timeCodes.findIndex(
        value => replaceStart <= value
    );
    const baseEndIndex: number = base.timeCodes.findIndex(
        value => value >= replaceEnd
    );

    const baseHead: T = -1 === baseStartIndex
        ? {
            ...base,
            values: [],
            isoCodes: [],
            timeCodes: [],
        }
        : {
            ...base,
            values: base.values.slice(0, baseStartIndex),
            isoCodes: base.isoCodes.slice(0, baseStartIndex),
            timeCodes: base.timeCodes.slice(0, baseStartIndex),
        };

    const isEndSliceStrict = base.timeCodes[baseEndIndex] === replaceEnd;
    const baseEndIndexOffset = isEndSliceStrict ? 1 : 0;
    const baseTail: T = -1 === baseEndIndex
        ? {
            ...base,
            values: [],
            isoCodes: [],
            timeCodes: [],
        }
        : {
            ...base,
            values: base.values.slice(baseEndIndex + baseEndIndexOffset),
            isoCodes: base.isoCodes.slice(baseEndIndex + baseEndIndexOffset),
            timeCodes: base.timeCodes.slice(baseEndIndex + baseEndIndexOffset),
        };

    const newTailLevel = [...replace.values].reverse().find(v => null !== v) as number|undefined;
    const formerTailLevel = undefined === newTailLevel
        ? undefined
        : [...base.values.slice(0, baseEndIndex + baseEndIndexOffset)].reverse().find(v => null !== v) as number|undefined;
    const adjustValue = undefined === newTailLevel
        ? undefined
        : newTailLevel - (formerTailLevel || 0 );

    return {
        ...base,
        values: [...baseHead.values, ...replace.values, ...adjustTail(baseTail.values, adjustValue)],
        isoCodes: [...baseHead.isoCodes, ...replace.isoCodes, ...baseTail.isoCodes],
        timeCodes: [...baseHead.timeCodes, ...replace.timeCodes, ...baseTail.timeCodes],
    }
}
