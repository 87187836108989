import { all, takeEvery } from 'redux-saga/effects'
import * as actionTypes from './actionTypes';
import getIntegrations from './saga/getIntegrations';
import updateIntegration from './saga/updateIntegration';
import deleteIntegration from './saga/deleteIntegration';
import createIntegration from './saga/createIntegration';
import deleteIntegrationDemo from "./saga/deleteDemoData";

export default function* saga() {
    yield all([
        yield takeEvery(actionTypes.INTEGRATION_CREATE, createIntegration),
        yield takeEvery(actionTypes.INTEGRATION_UPDATE, updateIntegration),
        yield takeEvery(actionTypes.INTEGRATION_DELETE, deleteIntegration),
        yield takeEvery(actionTypes.INTEGRATIONS_LOAD, getIntegrations),
        yield takeEvery(actionTypes.INTEGRATION_DEMO_DELETE, deleteIntegrationDemo)
    ]);
}
