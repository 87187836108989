import React, {useMemo} from "react";
import debug from "../../services/Debug/functions/debug";

import AutoNumericInput from "./AutoNumericInput";
import {Options as AutoNumericOptions} from "autonumeric";

const PercentageNumberInput: React.VFC<AntdFormInputProps<number>> = (props) => {
    debug("PercentageNumberInput::render()", props);

    const autoNumericProps: AutoNumericOptions = useMemo(
        () => ({
            currencySymbol: " %"
        }),
        []
    );

    return <AutoNumericInput {...props} autoNumericOptions={autoNumericProps}/>;
};

export default PercentageNumberInput;
