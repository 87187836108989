import {call, put, select} from "redux-saga/effects"
import axios from "../../../axios";
import {AxiosResponse} from "axios";
import * as actionTypes from "../actionTypes";
import {legacyAlert} from "../../UserAlert";
import * as RestV1 from "./RestV1Types";
import {StatementRowLinkResponse} from "./RestV1Types";
import {List} from "immutable";
import {StatementRowSubType, StatementRowType} from "../../../model/Statement";
import parseMimeType from "../../../model/Mime/parseMimeType";
import {RowLinkRelation} from "../../../model/StatementUri/enums";
import * as selectors from "../../../reducer";
import findStatementRowById from "../../../model/Statement/findStatementRowById";
import debug from "../../Debug/functions";
import tld from "../../../tld";

export function* updateRemoteStatementRow(
    statementId: string,
    statementRow: StatementRow,
    type?: StatementRowType,
    label?: string,
    accountIds?: string[],
) {
    debug('updateRemoteStatementRow()', statementId, statementRow.id, label, accountIds);
    const statementRowId = statementRow.id;
    const patchPayload: RestV1.StatementRowPatchPayload = {};
    const newStatementRow: StatementRow = { ...statementRow };
    let hasChanges = false;
    if (undefined !== type) {
        patchPayload.type = type;
        newStatementRow.type = type;
        hasChanges = true;
    }
    if (undefined !== label) {
        patchPayload.label = label;
        newStatementRow.label = label;
        hasChanges = true;
    }
    if (undefined !== accountIds) {
        patchPayload.accountIds = accountIds;
        newStatementRow.accountIds = accountIds;
        hasChanges = true;
    }
    if (hasChanges) {
        try {
            yield put({
                type: actionTypes.REDUCE_STATEMENT_ROW_UPDATE,
                payload: { statementId, row: newStatementRow },
            });
            const statementRowResource: RestV1.StatementRow = yield axios.patch(
                tld(process.env.REACT_APP_URL_API_STATEMENT) + '/v1/statements/' + statementId + '/rows/' + statementRowId,
                patchPayload
            ).then(response => response.data);
            const row = transformStatementRowResource(
                statementRowResource,
                statementId,
                null,
                newStatementRow.level
            );
            yield put({
                type: actionTypes.REDUCE_STATEMENT_ROW_UPDATE,
                payload: { statementId, row }
            });
        } catch (error) {
            yield put({
                type: actionTypes.REDUCE_STATEMENT_ROW_UPDATE,
                payload: { statementId, row: statementRow },
            });
            if (error.isAxiosError) {
                legacyAlert(error, 'update statement row');
            } else {
                throw error;
            }
        }
    }
}

export function* createRemoteStatementRow(
    statementId: string,
    label: string,
    accountIds?: string[],
    constraintType?: string,
    destination?: StatementRowLink
) {
    const type: StatementRowType = undefined === accountIds ? StatementRowType.GROUP : StatementRowType.CATEGORY;
    try {
        const url = tld(process.env.REACT_APP_URL_API_STATEMENT) + "/v1/statements/" + statementId + "/rows";
        let requestData: RestV1.StatementRowPostPayload = { type, label };
        if (undefined !== accountIds) {
            requestData.accountIds = accountIds;
        }
        if (undefined !== constraintType) {
            requestData.constraintType = constraintType;
        }
        if (undefined === destination) {
            // statement top
            requestData.position = 'top';
        } else {
            switch (destination.relation) {
                case RowLinkRelation.PREDECESSOR:
                    requestData.link = {
                        type: RestV1.StatementRowLinkType.Predecessor,
                        rowId: destination.id,
                    };
                    break;
                case RowLinkRelation.PARENT:
                    requestData.link = {
                        type: RestV1.StatementRowLinkType.Parent,
                        rowId: destination.id,
                    };
                    break;
                default:
                    // noinspection ExceptionCaughtLocallyJS
                    throw Error("Destination group role not known: " + destination.relation);
            }
        }
        const postResponse: AxiosResponse<RestV1.StatementRow> =
            yield axios.post(url, requestData, {
                withCredentials: true,
                headers: {
                    "Accept": "application/json",
                    "Content-Type": "application/json"
                },
                validateStatus: (status: number) => {
                    return (status >= 200 && status < 300) ||409 === status; // default
                },
            });
        if (409 === postResponse.status) {
            const rowId = postResponse.headers["resource-id"];
            if (undefined === rowId) {
                // noinspection ExceptionCaughtLocallyJS
                throw Error("Statement row creation failed.");
            } else {
                const statement: Statement = yield select(selectors.getStatementById, statementId);
                let row = findStatementRowById(statement, rowId);
                if (row) {
                    const newAccountIds = accountIds ? [...(row.accountIds || []), ...accountIds] : [...(row.accountIds || [])];
                    yield call(updateRemoteStatementRow, statementId, row, undefined, undefined, newAccountIds);
                    row = {...row, accountIds: newAccountIds}
                } else {
                    const statementRowResource = yield axios.get(url + "/" + rowId, {
                        withCredentials: true,
                        headers: {
                            "Accept": "application/json",
                            "Content-Type": "application/json"
                        },
                    }).then(response => response.data);
                    const rowWithWrongLevel = transformStatementRowResource(statementRowResource, statementId);
                    const responseDestination = statementRowResource.link ? transformStatementRowLink(statementRowResource.link) : destination;
                    yield put({
                        type: actionTypes.REDUCE_STATEMENT_ROW_CREATE,
                        payload: { statementId, row: rowWithWrongLevel, destination: responseDestination }
                    });
                    // required to get the row with correct level
                    row = findStatementRowById(statement, rowId);
                    if (!row) {
                        // if this is occurs we need to deliver the level per row via backend
                        // or this function must not return a row directly
                        // noinspection ExceptionCaughtLocallyJS
                        throw Error("Accessing post-loaded existing statement row with correct level failed.");
                    }
                }
                return row;
            }
        } else {
            const statementRowResource = postResponse.data;
            const rowWithWrongLevel = transformStatementRowResource(statementRowResource, statementId);
            const responseDestination = statementRowResource.link ? transformStatementRowLink(statementRowResource.link) : destination;
            yield put({
                type: actionTypes.REDUCE_STATEMENT_ROW_CREATE,
                payload: { statementId, row: rowWithWrongLevel, destination: responseDestination }
            });
            const statement: Statement = yield select(selectors.getStatementById, statementId);
            // required to get the row with correct level
            const row = findStatementRowById(statement, rowWithWrongLevel.id);
            if (!row) {
                // if this is occurs we need to deliver the level per row via backend
                // or this function must not return a row directly
                // noinspection ExceptionCaughtLocallyJS
                throw Error("Accessing created statement row with correct level failed.");
            }
            return row;
        }
    } catch (error) {
        if (error.isAxiosError) {
            legacyAlert(error, "create statement row");
        } else {
            throw error;
        }
    }
}
const transformStatementRowLink = (l: StatementRowLinkResponse): StatementRowLink => ({
    id: l.itemId,
    relation: l.type
});

export function transformStatementRowResource(
    statementRowResource: RestV1.StatementRow,
    statementId: string,
    autoCollapseFromLevel: number|null = null,
    level: number = 0
): StatementRow {
    let result: StatementRow = {
        statementId,
        id: statementRowResource.id,
        type: statementRowResource.type,
        label: statementRowResource.label,
        level,
        isCollapsed: null !== autoCollapseFromLevel && autoCollapseFromLevel <= level,
        isGroup: StatementRowType.GROUP === statementRowResource.type,
        isTitle: false,
        isVirtual: false,
        content: undefined,
    };
    if (undefined !== statementRowResource.accountIds && statementRowResource.accountIds.length > 0) {
        result.accountIds = statementRowResource.accountIds;
    }
    if (undefined !== statementRowResource.constraintType) {
        result.constraintType = parseMimeType(statementRowResource.constraintType);
    }
    if (statementRowResource.constraintType === 'category/bank') {
        result.isDraggable = false;
    } else {
        result.isDraggable = result.constraintType?.subType !== StatementRowSubType.QUICK_PLANNING;
    }


    if (statementRowResource.rows) {
        const subRows = List<StatementRow>().asMutable();
        statementRowResource.rows.forEach(statementSubRowResource => {
            subRows.push(transformStatementRowResource(statementSubRowResource, statementId, autoCollapseFromLevel, level + 1));
        });
        result.rows = subRows.asImmutable();
    }
    return result;
}
