import moment from "moment";

// NOTE: Currently only requires months to be offset. Some future feature request may require offsets by days/weeks/years/etc
export const subtractMonths = (date, months) => {
    const subtractedMonths = new Date(date).setMonth((new Date(date).getMonth() - months));
    return new Date(subtractedMonths);
}

const numYears = 5;
const numHalfYears = 10;
const numQuarters = 12;
const numMonths = 13;
const numWeeks = 12;
const numDays = 14;

export const makeTimeRange = (resolution, date) => {
    switch (resolution) {
        case "year": {
            return {
                start: {
                    year: new Date(date).getFullYear()
                },
                count: numYears
            }
        }
        case "day": {
            return {
                start: {
                    year: new Date(date).getFullYear(),
                    month: new Date(date).getMonth()+1,
                    day: new Date(date).getDate()
                },
                count: numDays
            }
        }
        case "week": {
            const startOfWeek = moment(date).startOf('week');
            return {
                start: {
                    year: startOfWeek.year(),
                    month: startOfWeek.month()+1,
                    week: startOfWeek.isoWeek(),
                    day: startOfWeek.date()
                },
                count: numWeeks
            }
        }
        case "quarter": {
            const startOfQuarter = moment(date).startOf('quarter');
            return {
                start: {
                    year: startOfQuarter.year(),
                    quarter: startOfQuarter.quarter(),
                    month: startOfQuarter.month()+1,
                },
                count: numQuarters
            }
        }
        case "halfYear": {
            return {
                start: {
                    year: new Date(date).getFullYear(),
                    halfYear: new Date(date).getMonth() < 6 ? 1 : 2,
                    month: new Date(date).getMonth() < 6 ? 1 : 7,
                },
                count: numHalfYears
            }
        }
        case "month":
        default: {
            return {
                start: {
                    year: new Date(date).getFullYear(),
                    month: new Date(date).getMonth()+1
                },
                count: numMonths
            }
        }
    }
}
