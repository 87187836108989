import React from "react";
import debug from "../../services/Debug/functions";

import CurrencyAmount from "../CurrencyAmount";

type CurrencyAmountInfoProps = {
    value?: number
};

const CurrencyAmountInfo:React.VFC<CurrencyAmountInfoProps> = (props) => {
    debug("CurrencyAmountInfo::render()", props);
    return undefined === props.value ? null : <span
        className="ant-form-text"
        style={{fontWeight:"bold"}}
    ><CurrencyAmount>{props.value}</CurrencyAmount></span>;
}

export default CurrencyAmountInfo;
