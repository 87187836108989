import React, {useMemo} from "react";
import {useTranslation} from "react-i18next";
import debug from "../../services/Debug/functions/debug";

import {CashFlowRole} from "../../model/CashFlow/enums";
import SelectAccount from "./SelectAccount";
import {isCashFlowCash} from "../../model/Accounting/AccountQualification";
import filterObject from "../../model/Object/filterObject";

const SelectCashAccount: React.VFC<SelectCashAccountProps> = (props) => {
    debug("SelectCashAccount::render()", props);

    const {t} = useTranslation();

    const accounts = useMemo(
        () => filterObject(props.accounts, isCashFlowCash),
        [props.accounts]
    );

    return <SelectAccount
        {...props}
        placeholder={props.placeholder || t("select or reassign a cash account")}
        accounts={accounts}
        cashFlowRole={CashFlowRole.CASH}
    />;
}

export default SelectCashAccount;
