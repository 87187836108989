import React, {useMemo} from "react";
import {useTranslation} from "react-i18next";
import debug from "../../services/Debug/functions/debug";

import {AutoComplete, Spin} from "antd";

import {OptionData, OptionGroupData} from "rc-select/lib/interface";

const SelectAccount: React.VFC<SelectAccountProps> = ({
    onChange = () => {},
    accounts: accountRegistry,
    cashFlowRole,
    placeholder,
    value: account,
    width = "100%",
}) => {
    debug("SelectAccount::render()", accountRegistry, cashFlowRole, placeholder, account, width);

    const getValueForAccount = (account: AccountingAccountDraft): string => {
        return undefined === account.id ? account.label : "id://" + account.id;
    };

    const accounts: AccountingAccount[]|undefined = useMemo(
        () => accountRegistry ? Object.values(accountRegistry) : undefined,
        [accountRegistry]
    );

    if (undefined !== accounts) {
        // #STATEMENT-79 sort account numbers
        accounts.sort((a: AccountingAccount, b: AccountingAccount) => {
            if (undefined === a.accountNumber) {
                if (undefined === b.accountNumber) {
                    return a.label.localeCompare(b.label);
                } else {
                    return -1;
                }
            } else {
                if (undefined === b.accountNumber) {
                    return 1;
                } else {
                    return a.label.localeCompare(b.label);
                }
            }
        });
    }

    const {t} = useTranslation();

    // natSort = (a, b) => {
    //     const aNumMatch = a.match(/^\d+$/g);
    //     const bNumMatch = b.match(/^\d+$/g);
    //     if (null !== aNumMatch && null !== bNumMatch) {
    //         return Number(aNumMatch) - Number(bNumMatch);
    //     }
    //     if (null !== aNumMatch) {
    //         return 1;
    //     }
    //     if (null !== bNumMatch) {
    //         return -1;
    //     }
    //     return a.localeCompare(b);
    // };

    const onInput = (value) => {
        let inputAccount: AccountingAccountDraft|undefined = undefined;
        if ("id://" === value.substr(0, 5)) {
            const accountId = value.substr(5);
            if (undefined !== accounts) {
                for (let i = 0; i < accounts.length; i++) {
                    if (accountId === accounts[i].id) {
                        inputAccount = accounts[i];
                        break;
                    }
                }
            }
        }
        if (undefined === inputAccount) {
            const valueCompressed = value.replace(/\s+/g, " ");
            const valueTrimmed = valueCompressed.trim();
            const customValue: string = ("" === valueTrimmed) ? valueTrimmed : valueCompressed;
            if ("" !== customValue) {
                inputAccount = { label: customValue, cashFlowRole: cashFlowRole };
            }
        }
        debug("SelectAccount::onInput()", value, inputAccount);
        onChange(inputAccount);
    };

    const renderAccountOptionLabel = (account: AccountingAccountDraft) => {
        if (undefined === account.accountNumber) {
            return account.label;
        } else {
            const accountNumber = account.accountNumber.code.toString();
            return accountNumber === account.label ? account.label : accountNumber + " " + account.label;
        }
    };

    let accountOptions: OptionData[] = [];
    let draftAccountOptions: OptionData[] = [];
    if (undefined !== accounts) {
        accounts.forEach(account => {
            const value = getValueForAccount(account);
            if (undefined !== value) {
                if (undefined === account.accountNumber) {
                    draftAccountOptions.push({
                        label: account.label,
                        value
                    });
                } else {
                    accountOptions.push({
                        label: renderAccountOptionLabel(account),
                        value
                    });
                }
            }
        });
    }

    let options: OptionGroupData[] = [];
    if (accountOptions.length > 0) {
        options.push({
            label: t("Konten"),
            options: accountOptions,
        });
    }
    if (draftAccountOptions.length > 0) {
        options.push({
            label: t("Entwurfskonten"),
            options: draftAccountOptions,
        });
    }

    return <div style={{width}}>
        <Spin spinning={ undefined === accounts }>
            <AutoComplete
                placeholder={placeholder || t("Konto angeben")}
                value={account ? renderAccountOptionLabel(account) : undefined}
                onChange={onInput}
                optionFilterProp="label"
                filterOption={true}
                options={options}
                style={{width:"100%"}}
            />
        </Spin>
    </div>;
}

export default SelectAccount;
