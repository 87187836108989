export const PaymentTypeEnum: {
    DISBURSEMENT: PaymentType,
    DEPOSIT: PaymentType,
} = {
    DISBURSEMENT: 'disbursement',
    DEPOSIT: 'deposit',
};

export const PlanningTypeEnum: {
    FORECAST: PlanningType,
    ADJUSTMENT: PlanningType,
    BUDGET: PlanningType,
} = {
    FORECAST: 'forecast',
    ADJUSTMENT: 'adjustment',
    BUDGET: 'budget',
};
