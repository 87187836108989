import {AxiosInstance} from "axios";
import arrayStream from "./AxiosStreamAdapter/functions/arrayStream";

export default function applyStreamInterceptor(axios: AxiosInstance) {
    axios.interceptors.response.use(function (response) {
        return (200 === response.status) ? arrayStream(response) : response;
    }, (error) => {
        return Promise.reject(error);
    });
}
