import React from "react";
import {Spin} from "antd";

export default class LoadableSpin extends React.Component {
    render() {
        let spinProps = {...this.props};
        let display = 'block';
        if ('inline' in spinProps) {
            display = 'inline-block';
            delete spinProps.inline;
        }
        let lineHeight = 'inherit';
        if ('height' in spinProps) {
            lineHeight = spinProps.height;
            delete spinProps.height;
        }
        return (
            <div style={{display,textAlign:'center',lineHeight}}><Spin {...spinProps}/></div>
        );
    }
}
