import axios from '../../../axios';
import {all, call, put, select, StrictEffect} from "redux-saga/effects";
import moment from "moment";
import {dateToIso8601} from "../../../model/date";
import {legacyAlert} from "../../UserAlert";
import * as RestV1 from './RestV1Types';
import * as actionTypes from '../actionTypes';
import {getTimeRangeEndDate} from "../../../model/TimeRange";
import {ReduceSelectScenario} from "../../Scenarios/actionTypes";
import {isFeatureActive} from "../../selectors";
import {historicalEndDateSelector} from "../../Accounting/selectors";
import {activeTimeRangeSelector, basePlanningSelector} from "../../TimeTravel/selectors";
import {FlaggedFeature} from "../../../flags";
import {waitForStateReady} from "../../functions";
import {Scenario} from "../../Scenarios/saga/RestV1Types";
import tld from "../../../tld";

function* axiosRequestRemoteRestV1Forecast(startDate: Date, endDate: Date, scenario?: Scenario) {
    const basePlanning = yield select(basePlanningSelector);
    if (endDate >= startDate) {
        let params: RestV1.GetForecastParams = {
            from: dateToIso8601(startDate),
            to: dateToIso8601(endDate),
            basePlanning,
            ...(scenario) && {scenarioId: scenario.id}
        };
        // uncomment this to use the statistical prototype algorithm
        params.algorithm = "TFTModel";
        return yield axios.get(tld(process.env.REACT_APP_URL_API_FORECAST) + '/forecast_postings', {params});
    }
}

function* axiosRequestRemoteRestV1Explanation(startDate: Date, endDate: Date, scenario?: Scenario) {
    const basePlanning = yield select(basePlanningSelector);
    if (endDate >= startDate) {
        let params: RestV1.GetForecastParams = {
            from: dateToIso8601(startDate),
            to: dateToIso8601(endDate),
            basePlanning,
            ...(scenario) && {scenarioId: scenario.id}
        };
        params.algorithm = "TFTModel";
        //to mock data - change url from env to REACT_APP_URL_API_MOCK>
        return yield axios.get(tld(process.env.REACT_APP_URL_API_FORECAST) + '/forecast_explanation', {params});
    }
}

// -------------

async function axiosRequestCashBlueprintGroupId() {
    return await axios.get(tld(process.env.REACT_APP_URL_API_STATEMENT) + '/v1/cashFlowBlueprintGroups')
}
interface LoadForecastIntend {
    scenario?: Scenario;
}

let loadedEndDates: Record<string, Date> = {};

function* loadQueuedForecast(forecastLoad: LoadForecastIntend) {
    const scenario = forecastLoad.scenario;
    yield* waitForStateReady("timeTravel");
    const timeRange = yield select(activeTimeRangeSelector);
    yield* waitForStateReady("accounting");
    const scenarioId: string = scenario?.id || 'base';

    try {
        let endDate = getTimeRangeEndDate(timeRange);
        if (!loadedEndDates[scenarioId] || loadedEndDates[scenarioId] < endDate) {
            const loadedEndDate = loadedEndDates[scenarioId];
            yield put({type: actionTypes.FORECAST_LOADING_STARTED});
            // @todo consider changed forecastStartDate
            const startDate = (!loadedEndDate || !!scenario)
                ? yield select(historicalEndDateSelector)
                : moment(loadedEndDate).add(1, 'days').toDate();
            const pipe = [
                [axiosRequestRemoteRestV1Forecast, startDate, endDate, scenario],
                [axiosRequestRemoteRestV1Explanation, startDate, endDate, scenario],
                [axiosRequestCashBlueprintGroupId]
            ]
            const [forecast, explanation, groupsByBlueprintGroupId] = yield all([
                //if one fails, we still get a result and error for the failed one
                ...pipe.map((arr) =>
                    (function* () {
                        const fn = arr[0];
                        const args = arr.splice(1)
                        try {
                            return yield call(fn, ...args);
                        } catch (e) {
                            return e; // **
                        }
                    })()
                )
            ]);
            loadedEndDates[scenarioId] = endDate;
            if (forecast.data && !!forecast.data.charts) {
                yield all([
                    put({
                        type: actionTypes.FORECAST_EXPLANATION_LOADED,
                        payload: explanation.data,
                        groups: groupsByBlueprintGroupId.data
                    }),
                    put({type: actionTypes.FORECAST_LOADED, payload: {...forecast.data.charts, scenario}}),
                ])
            }


        }
    } catch (e) {
        if (e.isAxiosError) {
            legacyAlert(e, 'init forecast');
        } else {
            throw e;
        }
    }
}

let loadingForecast: LoadForecastIntend | undefined = undefined;

const lastInFirstOutLoadingQueue: LoadForecastIntend[] = [];

function* loadNextQueuedForecast() {
    if (undefined === loadingForecast) {
        loadingForecast = lastInFirstOutLoadingQueue[lastInFirstOutLoadingQueue.length - 1];
        if (undefined !== loadingForecast) {
            yield call(loadQueuedForecast, loadingForecast);
            loadingForecast = undefined;
        }
    }
}

function queueLoadForecast(scenario?: Scenario) {
    lastInFirstOutLoadingQueue.push({scenario});
}

export function* reloadForecast(){
    loadedEndDates = {};
    loadingForecast = lastInFirstOutLoadingQueue[lastInFirstOutLoadingQueue.length - 1];
    if (undefined !== loadingForecast) {
        yield call(loadQueuedForecast, loadingForecast);
        loadingForecast = undefined;
        yield call(loadNextQueuedForecast);
    }
}

// -------------

function* loadCurrentForecast(scenario?: Scenario) {
    if (yield call(selectIsForecastEnabled)) {
        queueLoadForecast(scenario);
        yield call(loadNextQueuedForecast);
    }
}

function* selectIsForecastEnabled(): Generator<StrictEffect, boolean, boolean> {
    return yield select(isFeatureActive, FlaggedFeature.forecast);
}

export default function* loadForecast() {
    yield call(loadCurrentForecast);
}

export function* loadForecastForScenario({payload}: ReduceSelectScenario) {
    yield call(loadCurrentForecast, payload);
}
