import * as actions from './actionTypes';
import insertStatementRow from "../../model/Statement/insertStatementRow";
import {hasStatement, yieldStatementRows} from "./selectors"
import {
    mutateStatementRow,
    mutateStatementWithCollapsing,
    mutateStatementWithRow,
    mutateStatementWithRows,
    mutateStateWithStatement,
    yieldMutateStatementRows
} from "./functions";
import getKeyPathByRowId from "../../model/Statement/getKeyPathByRowId";
import getStatementRowById from "../../model/Statement/getStatementRowById";

function getStatement(state: StatementState, statementId: string): Statement {
    if (undefined === state.statements[statementId]) {
        throw new Error('Statement not found: ' + statementId);
    }
    return state.statements[statementId];
}

export default function reducer(state: StatementState = {
    loading: true,
    statements: {},
    controls: {
        companyUnit: undefined
    },
    error: ''
}, action: actions.StatementReducerAction): StatementState {
    switch (action.type) {
        case actions.REDUCE_STATEMENTS_INITIALIZED: {
            return {
                ...state,
                loading: false
            };
        }
        case actions.REDUCE_STATEMENT_LOADING: {
            return {
                ...state,
                loading: true
            };
        }
        case actions.REDUCE_STATEMENT_LOADED: {
            const newStatement: Statement = action.payload;
            const statementId = newStatement.id;
            if (hasStatement(state, statementId)) {
                const newStatementWithCollapsing = mutateStatementWithCollapsing(
                    newStatement,
                    getStatement(state, statementId)
                );
                return mutateStateWithStatement(state, newStatementWithCollapsing);
            } else {
                return mutateStateWithStatement(state, newStatement);
            }
        }
        case actions.REDUCE_COMPANY_UNIT_CONTROL: {
            const companyUnitControl = action.payload;
            if (companyUnitControl.options.length > 1) {
                return {
                    ...state,
                    controls: {
                        ...state.controls,
                        companyUnit: companyUnitControl
                    }
                };
            } else {
                return state;
            }
        }
        case actions.REDUCE_STATEMENT_ROW_CREATE: {
            const { statementId, row, destination } = action.payload;
            const statement = getStatement(state, statementId);
            const newStatement = insertStatementRow(
                statement,
                row,
                destination
            );
            return {...mutateStateWithStatement(state, newStatement), loading: false};
        }
        case actions.REDUCE_STATEMENT_ROW_UPDATE: {
            const { statementId, row } = action.payload;
            const rowId = row.id;
            const statement = getStatement(state, statementId);
            const statementRowKeyPath = getKeyPathByRowId(statement, rowId);
            const newStatement = {
                ...statement,
                rows: statement.rows.setIn(statementRowKeyPath, row),
            };
            return mutateStateWithStatement(state, newStatement);
        }
        case actions.REDUCE_STATEMENT_ROW_RELOCATE: {
            const { statementId, rowId, destination } = action.payload;
            const statement = getStatement(state, statementId);
            const statementRow = getStatementRowById(statement, rowId);
            // delete former row
            const statementRowKeyPath = getKeyPathByRowId(statement, rowId);
            const statementWithDeleted = {
                ...statement,
                rows: statement.rows.deleteIn(statementRowKeyPath),
            };
            // re-insert
            const newStatement = insertStatementRow(
                statementWithDeleted,
                statementRow,
                destination
            );
            //
            return mutateStateWithStatement(state, newStatement);
        }
        case actions.REDUCE_STATEMENT_ROW_DELETE: {
            const { statementId, rowId } = action.payload;
            const statement = getStatement(state, statementId);
            // assert row existence
            // noinspection JSUnusedLocalSymbols
            getStatementRowById(statement, rowId);
            // delete row
            const statementRowKeyPath = getKeyPathByRowId(statement, rowId);
            const statementWithDeleted = {
                ...statement,
                rows: statement.rows.deleteIn(statementRowKeyPath),
            };
            //
            return mutateStateWithStatement(state, statementWithDeleted);
        }
        /**
         * 
         * Collapsing
         * 
         */
        case actions.REDUCE_STATEMENT_ROW_COLLAPSE: {
            const { statementId, rowId } = action.payload;
            const statement = getStatement(state, statementId);
            const statementRow = getStatementRowById(statement, rowId);
            const newStatementRow = mutateStatementRow(statementRow, {
                isCollapsed: true
            });
            const newStatement = mutateStatementWithRow(statement, newStatementRow);
            return mutateStateWithStatement(state, newStatement);
        }
        case actions.REDUCE_STATEMENT_ROW_EXPAND: {
            const { statementId, rowId } = action.payload;
            const statement = getStatement(state, statementId);
            const statementRow = getStatementRowById(statement, rowId);
            const newStatementRow = mutateStatementRow(statementRow, {
                isCollapsed: false
            });
            const newStatement = mutateStatementWithRow(statement, newStatementRow);
            return mutateStateWithStatement(state, newStatement);
        }
        case actions.REDUCE_STATEMENT_COLLAPSE_LEVEL: {
            const { statementId, level } = action.payload;
            const statement = getStatement(state, statementId);
            const statementRows = yieldStatementRows(
                state,
                statementId,
                row => (row.level === level && true !== row.isCollapsed)
            );
            const mutatedStatementRows = yieldMutateStatementRows(statementRows, {
                isCollapsed: true
            });
            const newStatement = mutateStatementWithRows(statement, mutatedStatementRows);
            return (statement === newStatement) ? state : mutateStateWithStatement(state, newStatement);
        }
        case actions.REDUCE_STATEMENT_EXPAND_TO_LEVEL: {
            const { statementId, level } = action.payload;
            const statement = getStatement(state, statementId);
            const statementRows = yieldStatementRows(
                state,
                statementId,
                row => (row.level <= level && true === row.isCollapsed)
            );
            const mutatedStatementRows = yieldMutateStatementRows(statementRows, {
                isCollapsed: false
            });
            const newStatement = mutateStatementWithRows(statement, mutatedStatementRows);
            return (statement === newStatement) ? state : mutateStateWithStatement(state, newStatement);
        }
        /**
         *
         * Drag and Drop
         *
         */
        case actions.REDUCE_STATEMENT_ROW_DRAG: {
            const { statementId, rowId } = action.payload;
            const statement = getStatement(state, statementId);
            const statementRow = getStatementRowById(statement, rowId);
            const newStatementRow = mutateStatementRow(statementRow, {
                isDragging: true,
                isCollapsed: true,
            });
            const newStatement = mutateStatementWithRow(statement, newStatementRow);
            newStatement.draggingRowId = rowId;
            newStatement.draggingRowOriginalCollapsed = statementRow.isCollapsed;
            return mutateStateWithStatement(state, newStatement);
        }
        case actions.REDUCE_STATEMENT_ROW_DROP: {
            const { statementId } = action.payload;
            const statement = getStatement(state, statementId);
            const rowId = statement.draggingRowId;
            if (undefined === rowId) {
                throw Error("REDUCE_STATEMENT_ROW_DROP called but no draggingRowId set for statement " + statementId);
            }
            const statementRow = getStatementRowById(statement, rowId);
            const newStatementRow = mutateStatementRow(statementRow, {
                isDragging: false,
                isCollapsed: statement.draggingRowOriginalCollapsed,
            });
            const newStatement = mutateStatementWithRow(statement, newStatementRow);
            newStatement.draggingRowId = undefined;
            newStatement.draggingRowOriginalCollapsed = undefined;
            return mutateStateWithStatement(state, newStatement);
        }
        case actions.REDUCE_STATEMENT_ERROR: {
            return {...state, error: action.payload}
        }
        default: {
            return state;
        }
    }
}


// -----------------------------------------------------------------------------
// PUBLIC SELECTORS
// -----------------------------------------------------------------------------

export function findStatementById(state: StatementState, statementId: string): Statement|null {
    return undefined === state.statements[statementId] ? null : state.statements[statementId];
}

export function getStatementById(state: StatementState, statementId: string): Statement {
    if (undefined === state.statements[statementId]) {
        throw new Error('Statement not found: ' + statementId);
    }
    return state.statements[statementId];
}
