import * as actionTypes from "./actionTypes";

export default function reducer(state: AuthState = {
    initialized: false,
}, action: actionTypes.AuthReducerAction): AuthState {
    switch (action.type) {
        case actionTypes.REDUCE_LOGIN: {
            return {
                ...state,
                login: action.payload || null,
                initialized: true,
            };
        }
        case actionTypes.REDUCE_LOGOUT: {
            const login: AuthLogin|undefined = state.login
                ? {...state.login, active:false}
                : undefined;
            return {
                ...state,
                login,
            };
        }
        default: {
            return state;
        }
    }
}


// -----------------------------------------------------------------------------
// PUBLIC SELECTORS
// -----------------------------------------------------------------------------

export function getLoginRoles(state: AuthState): AuthRole[] {
    return state.login ? state.login.roles : [];
}

export function isLoginOfRole(state: AuthState, role: AuthRole): boolean {
    return getLoginRoles(state).indexOf(role) > -1;
}

export function isLoginWithEmail(state: AuthState, email: string|string[]): boolean {
    return undefined !== state.login?.userEmail && Array.isArray(email)
        ? email.indexOf(state.login.userEmail) > -1
        : state.login?.userEmail === email;
}

export function isCompany(state: AuthState, companyNameLookup: string): boolean {
    return state.login ? (companyNameLookup === state.login.companyName) : false;
}

export function findLoginNamespace(state: AuthState): string|null {
    return state.login ? state.login.namespace : null;
}

export function isMultiUnit(state: AuthState): boolean {
    if (!state.login) {
        throw Error("Logic exception. Access to multi unit status in unauthenticated state.");
    }
    return state.login.isMultiUnit;
}
