import React from "react";

import {Radio, Switch} from "antd";

import {useTranslation} from "react-i18next";
import InputProbability from "../../FormInput/InputProbability";
import InputSingleLineText255 from "../../FormInput/InputSingleLineText255";
import {PlanningTypeEnum} from "../../../model/Planning/Enums";
import CurrencyAmountInfo from "../../FormControl/CurrencyAmountInfo";
import {PlanningFormInternalValues} from "../PlanningForm";

export type ExpertFormValues = {
    isReversal: boolean,
    probability: number,
    plannedAmount: number,
    planningType: PlanningType,
    annotation?: string,
    receiptNumber?: string,
}

type ExpertFormProps = SubFormProps<ExpertFormValues>;

export function filterExpertSubFormValue(formValues: PlanningFormInternalValues): ExpertFormValues {
    return {
        isReversal: formValues.isReversal,
        probability: formValues.probability,
        plannedAmount: formValues.plannedAmount,
        planningType: formValues.planningType,
        annotation: formValues.annotation,
        receiptNumber: formValues.receiptNumber,
    };
}

const ExpertSubForm: React.VFC<ExpertFormProps> = ({decorateFormItem}) => {
    const {t} = useTranslation();

    const field: FormFieldSet<ExpertFormValues> = {
        isReversal: decorateFormItem(
            "isReversal",
            <Switch/>,
            {valuePropName: "checked", label: t("cancel / refund")}
        ),
        probability: decorateFormItem(
            "probability",
            <InputProbability/>,
            {label: t("probability")}
        ),
        plannedAmount: decorateFormItem(
            "plannedAmount",
            <CurrencyAmountInfo/>,
            {label: t("planned amount")}
        ),
        planningType: decorateFormItem(
            "planningType",
            <Radio.Group buttonStyle="solid" >
                <Radio.Button value={PlanningTypeEnum.FORECAST}>{t("forecast")}</Radio.Button>
                <Radio.Button value={PlanningTypeEnum.ADJUSTMENT}>{t("adjustment")}</Radio.Button>
            </Radio.Group>,
            {
                label: t("planning type"),
                rules: [{required: true, message: t("no planning type selected")}]
            }
        ),
        annotation: decorateFormItem(
            "annotation",
            <InputSingleLineText255/>,
            {label: t("annotation")}
        ),
        receiptNumber: decorateFormItem(
            "receiptNumber",
            <InputSingleLineText255/>,
            {label: t("receipt number")}
        ),
    };

    return <>{[
        field["isReversal"],
        field["probability"],
        field["plannedAmount"],
        field["planningType"],
        field["annotation"],
        field["receiptNumber"],
    ]}</>;
}

export default ExpertSubForm;
