import {all, put} from "redux-saga/effects"
import axios from "../../../axios";
import * as actionTypes from "../actionTypes";
import {ResultHandlerActionWithPayload} from "../../../model/Action";
import {Modal} from "antd";
import i18n from "../../../i18n";
import React from 'react';
import tld from "../../../tld";

type DeleteStatementRowActionPayload = {
    statementId: string,
    rowId: string
}
type DeleteStatementRowAction = ResultHandlerActionWithPayload<DeleteStatementRowActionPayload>;

export default function* deleteStatementRow(action: DeleteStatementRowAction) {
    try {
        const statementId = action.payload.statementId;
        const rowId = action.payload.rowId;
        const url = tld(process.env.REACT_APP_URL_API_STATEMENT) + "/v1/statements/" + statementId +  "/rows/" + rowId;

        yield axios.delete(url, {
            withCredentials: true,
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json"
            }
        });

        yield all([
            put({ type: actionTypes.REDUCE_STATEMENT_ROW_DELETE, payload: action.payload })
        ]);
    } catch (error) {
        Modal.error({
            title: i18n.t("Fehler"),
            content: (<div><p>{i18n.t("Diese Zeile enthält Daten und kann nicht gelöscht werden.")}</p><p>{i18n.t("Löschen oder verschieben Sie zunächst die enthaltenen Daten.")}</p></div>)
        });
    }
}
