export default function reducer(state = {
    loading: false,
    error: null,
    categories: null
}, action) {
    switch (action.type) {
        case 'GET_CATEGORIES_PENDING': {
            return {
                ...state,
                loading: true,
            };
        }
        case 'GET_CATEGORIES_FULFILLED': {
            return {
                ...state,
                loading: false,
                error: null,
                categories: action.payload.data
            };
        }
        case 'GET_CATEGORIES_REJECTED': {
            let message = ('undefined' === typeof action.payload.response)
                ? 'GET_CATEGORIES_REJECTED'
                : action.payload.response.data.message;
            return {
                ...state,
                loading: false,
                error: message,
            };
        }
        default: {
            return state;
        }
    }
}
