import {List} from "immutable";
import i18n from "i18next";
import {all, call, put, select, StrictEffect} from "redux-saga/effects"
import * as actionTypes from "../actionTypes";
import {loadRemoteStandardStatements} from "./loadStatement";
import {StatementProfileType, StatementRowType} from "../../../model/Statement";
import loadStatementControls from "./loadStatementControls";
import {loadScenarios} from "../../Scenarios/actions";
import {initializeSettings} from "../../Settings/actions";
import {initializeAccounting} from "../../Accounting/actions";
import {waitForStateReady} from "../../functions";
import {activeTimeRangeSelector} from "../../TimeTravel/selectors";
import {loadPlannings} from "../../Planning/actions";
import {loadForecast} from "../../Forecast/actions";
import {initializeBank} from "../../Bank/actions";

function enhanceCashFlowStatement(statement: Statement):Statement {
    const totalLabel: string = i18n.t("Cashflow Total");
    const totalRow: StatementRow = {
        statementId: statement.id,
        id: statement.id + ".total",
        constraintType: {mainType: "group", subType: "total"},
        type: StatementRowType.GROUP,
        label: totalLabel,
        level: 0,
        isGroup: true,
        isTitle: true,
        isVirtual: true,
    };
    return {
        ...statement,
        rows: statement.rows.unshift(totalRow),
    };
}

function enhanceCashInBankStatement(statement: Statement): Statement {
    const accountIdMap = {};
    statement.rows.forEach(row => {
        if (row.accountIds) {
            row.accountIds.forEach(accountId => accountIdMap[accountId] = true);
        }
        const refinedRowLevel = row.level + 1;
        const flowInRow: StatementRow = {
            statementId: statement.id,
            id: row.id + ".flowIn",
            accountIds: row.accountIds,
            constraintType: {mainType: "category", subType: "flowIn"},
            type: StatementRowType.CATEGORY,
            label: i18n.t("Einzahlungen"),
            level: refinedRowLevel + 1,
            isGroup: false,
            isTitle: false,
            isVirtual: true,
        };
        const flowOutRow: StatementRow = {
            statementId: statement.id,
            id: row.id + ".flowOut",
            accountIds: row.accountIds,
            constraintType: {mainType: "category", subType: "flowOut"},
            type: StatementRowType.CATEGORY,
            label: i18n.t("Auszahlungen"),
            level: refinedRowLevel + 1,
            isGroup: false,
            isTitle: false,
            isVirtual: true,
        };
        if (!row.rows) {
            row.type = StatementRowType.GROUP;
            row.isGroup = true;
            row.level = refinedRowLevel;
            row.isCollapsed = true;
            row.rows = List([flowInRow, flowOutRow]);
        }
    });
    const totalLabel: string = i18n.t("cash in bank") + " " + i18n.t("Total");
    const totalRow: StatementRow = {
        statementId: statement.id,
        id: statement.id + ".total",
        constraintType: {mainType: "group", subType: "total"},
        type: StatementRowType.GROUP,
        label: totalLabel,
        level: 0,
        isGroup: true,
        isTitle: true,
        isVirtual: true,
        accountIds: Object.keys(accountIdMap),
        isCollapsed: true,
        isCollapsible: true,
        rows: statement.rows,
    };
    return {
        ...statement,
        rows: List([totalRow]),
    };
}

function enhanceStatement(statement: Statement): Statement {
    switch (statement.profileType) {
        case StatementProfileType.CASH_FLOW:
            return enhanceCashFlowStatement(statement);
        case StatementProfileType.CASH_IN_BANK:
            return enhanceCashInBankStatement(statement);
        default:
            return statement;
    }
}

function* initialLoadStandardStatements(): Generator<StrictEffect, void, Statement> {
    const standardStatements = yield* loadRemoteStandardStatements();
    for (let standardStatement of standardStatements) {
        const enhancedStatement = enhanceStatement(standardStatement);
        const reduceAction: actionTypes.ReduceStatementLoadedAction = {
            type: actionTypes.REDUCE_STATEMENT_LOADED,
            payload: enhancedStatement
        };
        yield put(reduceAction);
    }
}

function* initializeApplication(): Generator<
    StrictEffect,
    void,
    any
> {
    yield all([
        put(loadScenarios()),
        put(initializeBank()),
        put(initializeSettings()),
        put(initializeAccounting()),
        put(loadForecast()),
    ]);
    yield* waitForStateReady("timeTravel");
    yield* waitForStateReady("accounting");
    const timeRange = yield select(activeTimeRangeSelector);
    yield put(loadPlannings(timeRange));
}

export function* initializeStatements() {
    yield all([
        call(initializeApplication),
        call(initialLoadStandardStatements),
        call(loadStatementControls),
    ]);
    yield put({type: actionTypes.REDUCE_STATEMENTS_INITIALIZED});
    yield put({type: actionTypes.X_STATEMENT_CHANGED});
}
