import {select, spawn, StrictEffect, take} from "redux-saga/effects"
import {BankAction} from "../actions";
import loadBankAccounts from "./loadBankAccounts";
import {isFeatureActive} from "../../selectors";
import {FlaggedFeature} from "../../../flags";
import {loadWorkflow} from "./workflow";

export default function* initAll(): Generator<StrictEffect, void, any> {
    yield take(BankAction.INITIALIZE_BANK);
    if (yield select(isFeatureActive, FlaggedFeature.finApi)) {
        yield spawn(loadBankAccounts);
        yield spawn(loadWorkflow);
    }
}
