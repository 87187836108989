import {UserAnalytics} from "./functions";

export default function track(
    featureName: string,
    actionName: string,
    actionContext: object = {},
    componentName?: string,
) {
    const trackName = featureName.replace(/\s/g, "") + ": " + actionName;
    const trackProps = { feature: featureName, component: componentName, ...actionContext };
    UserAnalytics.track(trackName, trackProps);
}
